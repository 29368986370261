/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';

import { iStatisticsReportItem } from '../../libs/interfaces';
import Config from '../../config/Config';

import { getLatLngArrayForMap } from '../../libs/helpers/statistics.helpers';
import { addDomMarkers } from '../../libs/helpers/here.helpers';

declare const window: any;

interface iProps {
  data: iStatisticsReportItem[];
  onMapInit?: () => void;
}

const StatisticsMap: React.FC<iProps> = ({ data, onMapInit }: iProps) => {
  const mapRef = useRef(null);
  const [mapH, mapHSet] = useState<any>(null);
  const [dataMarkers, dataMarkersSet] = useState<any[]>([]);

  async function setDataForMarker() {
    if (data.length) {
      const array = await getLatLngArrayForMap(data);
      dataMarkersSet(array);
    } else {
      dataMarkersSet([]);
    }
  }

  useEffect(() => {
    setDataForMarker();
  }, [data]);

  useEffect(() => {
    if (mapH) {
      mapH.removeObjects(mapH.getObjects());
      if (dataMarkers.length) {
        addDomMarkers(mapH, dataMarkers);
      }

      if (onMapInit) {
        onMapInit();
      }
    }
  }, [dataMarkers, mapH]);

  useLayoutEffect(() => {
    if (mapH) {
      return;
    }
    const { H } = window;
    const platform = new H.service.Platform({ apikey: Config.here_com_apikey });
    const defaultLayers = platform.createDefaultLayers();
    const map = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
      center: { lat: 41.90322, lng: 12.49565 },
      zoom: 6,
      pixelRatio: window.devicePixelRatio || 1,
    });
    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
    const ui = H.ui.UI.createDefault(map, defaultLayers);
    mapHSet(map);
  }, [mapRef]);

  return <div ref={mapRef} style={{ height: '500px' }} />;
};

StatisticsMap.defaultProps = {
  onMapInit: () => null,
};

export default StatisticsMap;
