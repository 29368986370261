import React from 'react';
import { iOrganization, iProfileData, iProfileFormData } from '../interfaces/index';
import Images from '../../assets/images';

export function getProfileImage(image: any, small = true): string {
  if (!image) {
    return '';
  }
  if (small && image.square_300) {
    return image.square_300.url;
  }
  return image.url;
}

export function mapProfileData(orig: iProfileData): iProfileFormData {
  const data = { ...orig };
  let sub: any[] = [];
  if (data.subAccounts) {
    sub = data.subAccounts.map((s: any) => {
      const newSubData = { ...s };
      return {
        email: newSubData.email,
        phone: newSubData.phone,
        active: newSubData.status === 'active',
      };
    });
  }

  if (!data.operationalData) {
    data.operationalData = { city: '', phone: '', state: '', zip: '', street: '' };
  }

  if (!data.administrativeData) {
    data.administrativeData = { city: '', phone: '', state: '', zip: '', street: '' };
  }

  const mapped = {
    name: data.name,
    phone: data.phone,
    sdiCode: data.sdiCode,
    taxCode: data.taxCode,
    vatNumber: data.vatNumber,
    administrativeCity: data.administrativeData.city,
    administrativePhone: data.administrativeData.phone,
    administrativeState: data.administrativeData.state,
    administrativeStreet: data.administrativeData.street,
    administrativeZip: data.administrativeData.zip,
    operationalCity: data.operationalData.city,
    operationalPhone: data.operationalData.phone,
    operationalState: data.operationalData.state,
    operationalStreet: data.operationalData.street,
    operationalZip: data.operationalData.zip,
    lat: data.lat || null,
    lng: data.lng || null,
    subAccounts: sub,
    image: data.image,
    enabled2fa: data.enabled2fa || false,
    secret2fa: data.secret2fa || '',
  };

  return mapped;
}

export function mapProfileDataToPayload(data: iProfileFormData): iProfileData {
  const sub = data.subAccounts.map((s: any) => {
    const newSubData = { ...s };
    if (!newSubData.status) {
      newSubData.status = newSubData.active ? 'active' : 'inactive';
    }
    delete newSubData.active;
    return newSubData;
  });

  const mapped: iProfileData = {
    name: data.name,
    phone: data.phone,
    sdiCode: data.sdiCode,
    taxCode: data.taxCode,
    vatNumber: data.vatNumber,
    administrativeData: {
      city: data.administrativeCity,
      phone: data.administrativePhone,
      state: data.administrativeState,
      street: data.administrativeStreet,
      zip: data.administrativeZip,
    },
    operationalData: {
      city: data.operationalCity,
      phone: data.operationalPhone,
      state: data.operationalState,
      street: data.operationalStreet,
      zip: data.operationalZip,
    },
    lat: data.lat || null,
    lng: data.lng || null,
    enabled2fa: data.enabled2fa,
    secret2fa: data.secret2fa,
    subAccounts: sub,
  };
  if (data.image) {
    mapped.image = data.image;
  }

  return mapped;
}

export const formInitialProfileData: iProfileFormData = {
  name: '',
  phone: '',
  sdiCode: '',
  taxCode: '',
  vatNumber: '',
  administrativeCity: '',
  administrativePhone: '',
  administrativeState: '',
  administrativeStreet: '',
  administrativeZip: '',
  operationalCity: '',
  operationalPhone: '',
  operationalState: '',
  operationalStreet: '',
  operationalZip: '',
  subAccounts: [],
  enabled2fa: false,
  secret2fa: '',
};

export function renderAvatar(profile: iProfileData | iOrganization): any {
  let image = null;
  if (profile && profile.image) {
    image = profile.image.square_300.url;
  }
  return <img src={image || Images.icoAvatar.src} alt={Images.icoAvatar.alt} />;
}
