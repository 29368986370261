import React from 'react';
import { SvgCheck, SvgDiamond } from '../../assets/images';

interface iProps {
  children: any;
  svgColor?: string;
  diamond?: boolean;
}

const CardList: React.FC<iProps> = ({ children, svgColor, diamond }: iProps) => {
  return (
    <li>
      {diamond && svgColor ? SvgDiamond(svgColor) : SvgCheck()}
      <div>{children}</div>
    </li>
  );
};

CardList.defaultProps = {
  svgColor: '',
  diamond: false,
};

export default CardList;
