/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getOffersApi } from '../../api/offers.api';
import { asyncThunkErrorHelper } from '../../libs/helpers/commons';
import { enumRoles } from '../../libs/enums';
import { decodeCredits, getTotOrderWeight } from '../../libs/helpers/offers.helpers';

function mapData(data: any[]) {
  const mapped = data.map((item: any) => {
    const unitCost = decodeCredits(item.offer.unitCost);
    return {
      idTransaction: item.offerId,
      noProfit: item.noprofit.name,
      donations: item.offer.donor.name,
      bookingDate: item.createdAt,
      category: item.offer.category.name,
      subCategory: item.offer.subCategory.name,
      specs: item.offer.unitAmount, // “specifiche prodotto” (“Formato” in tabella, quantità unitaria)
      udm: item.offer.measure.name,
      categoryType: item.offer.categoryType,
      unitCost,
      totalUnits: item.units,
      credits: decodeCredits(unitCost * item.units * 100),
      deliveryType: item.offer.deliveryType
        .join(', ')
        .replace('shipment', 'Spedizione')
        .replace('collection', 'Ritiro'),
      shipmentCost: decodeCredits(item.offer.shipmentCost),
      weight: getTotOrderWeight(item.offer, item.units),
      co2: item.info.co2,
    };
  });
  return mapped;
}

interface iReservationsState {
  list: any[];
}

const initialState: iReservationsState = {
  list: [],
};

const slice = createSlice({
  name: 'reservations',
  initialState,
  reducers: {
    reservationsSetListAction: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const { reservationsSetListAction } = slice.actions;
export default slice.reducer;

export const reservationsAsynGetAction = createAsyncThunk(
  'reservations/reservationsAsynGetAction',
  async (payload: null, { rejectWithValue, dispatch, getState }) => {
    try {
      const response: any[] = await getOffersApi(
        'filter={}&orderBy=price-desc',
        enumRoles.admin,
        'reservations',
      );
      if (response && response.length) {
        const list = mapData(response);
        dispatch(reservationsSetListAction(list));
      } else {
        dispatch(reservationsSetListAction([]));
      }
      return null;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);
