import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Row, Button } from 'antd';
import moment from 'moment';

import { RootState } from '../../store';
import { enumSubscriptions, enumSubDonor, enumSubNoProfit } from '../../libs/enums';
import { plansData, planEnumToProfileEnum } from '../../libs/helpers/subscriptions.helpers';

import Images from '../../assets/images';
import './style.sass';

interface iProps {
  month: boolean;
  onContractSelected: (plan: enumSubDonor | enumSubNoProfit, header: any) => void;
  onMoreInfo: () => void;
  type: enumSubscriptions;
}

const SubscriptionCard: React.FC<iProps> = ({
  month,
  onContractSelected,
  onMoreInfo,
  type,
}: iProps) => {
  const { profile } = useSelector((state: RootState) => state);
  const request = profile.subscription?.subscription_req;
  const current = profile.subscription?.subscription;
  const endSubscription = profile.subscription?.date_orig
    ? moment(profile.subscription?.date_orig).add(1, 'y').format('DD-MM-YYYY')
    : '';

  const isCustom =
    type === enumSubscriptions.donorCustom || type === enumSubscriptions.noProfitCustom;

  const myData: any = plansData[type];
  const { btnText, color, hasInfo, list, price, simpleTitle, title } = myData;

  const myProfileType: enumSubDonor | enumSubNoProfit = planEnumToProfileEnum[type];

  function renderPrice() {
    return month ? price : price * 12 - Math.floor(((price * 12) / 100) * 20);
  }

  function renderHeaderContract() {
    return (
      <div className={`subCard--${color}`}>
        <h1 className={`bg-${color}`}>
          <div>Attivazione Abbonamento - Piano {title}</div>
          <div className="mt-10">
            €{renderPrice()}+IVA/{month ? 'mese' : 'anno'}
          </div>
        </h1>
        <div className="subCard__main">{list && <ul>{list()}</ul>}</div>
      </div>
    );
  }

  function onPlanSelected() {
    const header: ReactNode = renderHeaderContract();
    onContractSelected(myProfileType, header);
  }

  function isDisabled() {
    if (current) {
      if (
        Boolean(request) ||
        (current === enumSubDonor.advanced && myProfileType === enumSubDonor.report) ||
        (current === enumSubNoProfit.absolute && myProfileType === enumSubNoProfit.opportunity)
      ) {
        return true;
      }
    }
    return false;
  }

  function planOrInfo() {
    if (request && request === myProfileType) {
      return (
        <div className="c-light text-center">
          <div className="mb-5">
            <img src={Images.icoClock.src} alt="" />
          </div>
          <div>Richiesta inoltrata</div>
        </div>
      );
    }
    if (current && current === myProfileType) {
      return (
        <>
          <div className="text-center">IL TUO PIANO ATTUALE</div>
          <div className="text-center f-s-12">Fino al {endSubscription}</div>
        </>
      );
    }
    return (
      <>
        {isCustom ? (
          <a href="mailto:info@regusto.eu" target="_blank" rel="noreferrer" className="ant-btn">
            {btnText}
          </a>
        ) : (
          <Button onClick={onPlanSelected} disabled={isDisabled()}>
            {btnText}
          </Button>
        )}
      </>
    );
  }

  return (
    <article className={`subCard subCard--${color}`}>
      <div>
        <header>
          <h1>
            {simpleTitle ? (
              <>
                <div>&nbsp;</div>
                <em>{title}</em>
              </>
            ) : (
              <>
                <div>{title}</div>
                <Row justify="space-between" align="bottom">
                  <div>
                    <em>€{renderPrice()}</em>
                    <span>/{month ? 'mese' : 'anno'}</span>
                  </div>
                  {!month && <span className="strike">€{price * 12}</span>}
                </Row>
              </>
            )}
          </h1>
        </header>
        <div className="subCard__main">{list && <ul>{list()}</ul>}</div>
      </div>
      {btnText && (
        <footer>
          {planOrInfo()}
          <div className="subCard__info">
            <h2>
              {isCustom ? (
                <>Maggiori info</>
              ) : (
                <button type="button" onClick={onMoreInfo}>
                  Maggiori info
                </button>
              )}
            </h2>
            {hasInfo && (
              <p>
                Il prezzo &egrave; IVA esclusa e relativo all&apos;abbonamento annuale con pagamento
                {month ? ' mensile' : ' annuale'}.
              </p>
            )}
          </div>
        </footer>
      )}
    </article>
  );
};

export default SubscriptionCard;
