import React, { ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';

import { RootState } from '../../store';

import EnvRibbon from '../envRibbon/envRibbon';

import './style.sass';

interface iProps {
  children: ReactNode;
}

const Wrapper: React.FC<iProps> = ({ children }: iProps) => {
  const loading = useSelector((state: RootState) => state.app?.loading);
  return (
    <>
      {children}
      <ToastContainer position="top-center" />
      {loading && (
        <div className="loading">
          <Spin size="large" />
        </div>
      )}
      <EnvRibbon />
    </>
  );
};

export default Wrapper;
