import { lazy } from 'react';

// internal libs
import { type } from '../../components/helpers/router';
import { AppLayout, AuthLayout, WidgetLayout } from '../../components/layouts';
import Localize from '../../libs/localization';
import { enumRoutes, enumRoles } from '../../libs/enums';
import { registerService } from '../../libs/helpers/services';

import Profile from '../../views/profile/profile.view';
import ProfileEditScreen from '../../views/profile/profile.edit.view';
import Subscriptions from '../../views/subscriptions/subscriptions.view';
import Categories from '../../views/categories/categories.view';
import Reservations from '../../views/reservations/reservations.view';
import MessageFromServer from '../../views/messageFromServer/messageFromServer.view';
import Widget from '../../views/widget/widget.view';

import RoutesAuth from './routes.auth';
import RoutesOffers from './routes.offers';
import RoutesOrganizations from './routes.organizations';
import RoutesStatistics from './routes.statistics';

// const Login = lazy(() => import("../containers/Login/Login"));
// const Home = lazy(() => import("../containers/Home/Home"));

const ROUTES = [
  {
    id: 'home',
    title: 'Home',
    path: '/',
    type: type.AUTHENTICATION,
    redirectTo: '/offers',
  },

  ...RoutesAuth,
  ...RoutesOffers,
  ...RoutesOrganizations,
  ...RoutesStatistics,

  {
    id: 'profile',
    title: 'Profilo',
    path: enumRoutes.profile,
    layout: AppLayout,
    component: Profile,
    type: type.AUTHENTICATION,
    roles: [
      enumRoles.donor,
      enumRoles.donorSub,
      enumRoles.noprofit,
      enumRoles.noprofitSub,
      enumRoles.noprofitSecond,
      enumRoles.noprofitSecondSub,
    ],
  },
  {
    id: 'profileEdit',
    title: 'Modifica Profilo',
    path: enumRoutes.profileEdit,
    layout: AppLayout,
    component: ProfileEditScreen,
    type: type.AUTHENTICATION,
    roles: [enumRoles.noprofit, enumRoles.donor, enumRoles.noprofitSecond],
  },

  {
    id: 'subscriptions',
    title: 'Subscriptions',
    path: enumRoutes.subscriptions,
    layout: AppLayout,
    component: Subscriptions,
    type: type.AUTHENTICATION,
    roles: [
      enumRoles.donor,
      enumRoles.donorSub,
      enumRoles.noprofit,
      enumRoles.noprofitSub,
      enumRoles.noprofitSecond,
      enumRoles.noprofitSecondSub,
    ],
  },
  {
    id: 'categories',
    title: 'Categories',
    path: enumRoutes.categories,
    layout: AppLayout,
    component: Categories,
    type: type.AUTHENTICATION,
    roles: [enumRoles.admin],
  },

  {
    id: 'reservations',
    title: 'Prenotazioni',
    path: enumRoutes.reservations,
    layout: AppLayout,
    component: Reservations,
    type: type.AUTHENTICATION,
    roles: [enumRoles.admin],
  },

  {
    id: 'messages',
    title: 'Messages',
    path: enumRoutes.messages,
    layout: AuthLayout,
    component: MessageFromServer,
    type: type.PUBLIC,
  },

  {
    id: 'widget',
    title: 'Widget',
    path: enumRoutes.widget,
    layout: WidgetLayout,
    component: Widget,
    type: type.PUBLIC,
  },
];

registerService('routes', ROUTES);
export default ROUTES;
