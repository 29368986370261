import Config from '../config/Config';
import { Api } from '../libs/callApi';

import { iGetLoginApiResponse, iLoginPayload } from '../libs/interfaces';

const api = new Api(Config.apiBaseurl);

export const getLoginApi = async (body: iLoginPayload): Promise<iGetLoginApiResponse> => {
  const call = await api.makeCall({
    path: Config.apis.auth.login,
    method: 'POST',
    body,
  });
  return call.body;
};

export const getNewPasswordApi = async (body: string): Promise<any> => {
  const call = await api.makeCall({
    path: Config.apis.auth.newpassword,
    method: 'POST',
    body,
  });
  return call.body;
};

export const forgotPasswordApi = async (email: string): Promise<any> => {
  const call = await api.makeCall({
    path: Config.apis.auth.resetPassword,
    method: 'POST',
    body: { email },
  });
  return call;
};

export const changePasswordApi = async (body: {
  oldPassword: string;
  newPassword: string;
}): Promise<any> => {
  const call = await api.makeCall({
    path: Config.apis.auth.changePassword,
    method: 'POST',
    body,
    autorized: true,
  });

  return call;
};

export const setImpersonateApi = async (body: string): Promise<any> => {
  const call = await api.makeCall({
    path: `${Config.apis.impersonate}${body}`,
    method: 'POST',
    autorized: true,
  });

  return call.body;
};
