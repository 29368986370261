import { Col, Row, Select } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createAgreementApi,
  deleteAgreementApi,
  getAgreementsForDonorApi,
  getAgreementsForNoProfitApi,
} from '../../../api/agreements.api';
import Images from '../../../assets/images';
import { ProfileCard } from '../../../components';
import {
  iAgreement,
  iOrganization,
  iOrganizationsStatus,
  iProfileData,
  retrieveCurrentPlan,
} from '../../../libs/interfaces';
import { RootState } from '../../../store';
import { organizationsGetAsyncAction } from '../../../store/features/organizationsSlice';

interface iAgreementProp {
  org: iOrganization;
  agreement: iAgreement;
  readonly: boolean;
  onDelete: () => void | undefined;
}

const AgreementRow: React.FC<iAgreementProp> = ({
  org,
  agreement,
  readonly,
  onDelete,
}: iAgreementProp) => {
  const boxes = useMemo(() => {
    const { administrativeData, image, name } = org;
    const { createdAt } = agreement;

    let city = '';
    let state = '';
    let street = '';
    let zip = '';
    if (administrativeData) {
      city = administrativeData.city;
      state = administrativeData.state;
      street = administrativeData.street;
      zip = administrativeData.zip;
    }
    const separator = street || zip || state ? '-' : '';
    const stateTxt = state ? `(${state})` : '';
    const date = moment(new Date(createdAt)).format('DD/MM/YYYY');
    return (
      <article className="box box--grey-xl" style={{ marginBottom: '20px' }}>
        <Row justify="space-between">
          <Col sm={12} className="organizations__card">
            <button type="button">
              <div className="organizations__img">
                <img
                  src={image ? image.square_300.url : Images.icoAvatar.src}
                  alt={Images.icoAvatar.alt}
                  className="profile__avatar"
                />
              </div>
              <div>
                <h1>{name}</h1>
                <p className="f-s-17">
                  {city} {separator} {street} {zip} {stateTxt}
                </p>
                <p>Data creazione: {date}</p>
              </div>
            </button>
          </Col>

          <Col sm={12} className="organizations__btns">
            {!readonly && onDelete && (
              <button type="button" onClick={onDelete} className="ant-btn ant-btn-primary">
                Elimina
              </button>
            )}
          </Col>
        </Row>
      </article>
    );
  }, [org, agreement, readonly, onDelete]);
  return <>{boxes}</>;
};

interface iListAgreementProp {
  agreements: iAgreement[];
  orgs: Record<string, iOrganization>;
  readonly: boolean;
  onDelete: (agr: iAgreement) => void | undefined;
}

const AgreementList: React.FC<iListAgreementProp> = ({
  agreements,
  orgs,
  readonly,
  onDelete,
}: iListAgreementProp) => {
  const boxes = useMemo(() => {
    return agreements.map((item) => {
      return (
        <AgreementRow
          key={item.id}
          org={orgs[item.id]}
          agreement={item}
          onDelete={() => onDelete(item)}
          readonly={readonly}
        />
      );
    });
  }, [agreements, orgs, readonly, onDelete]);
  return <>{boxes}</>;
};

const filterOrgs = (
  agreements: iAgreement[],
  orgs: iOrganization[],
  searchBen: boolean,
): Record<string, iOrganization> => {
  const res: Record<string, iOrganization> = {};
  agreements.forEach((agr) => {
    const searchId = searchBen ? agr.ben_accn_id : agr.don_accn_id;
    if (orgs) {
      const el = orgs.find((org) => org.id === searchId);
      if (el) {
        res[agr.id] = el;
      }
    }
  });
  return res;
};

interface iCreateAgreementMngProp {
  organizations: iOrganization[];
  agreements: iAgreement[];
  onCreate: (orgId: string) => void;
}
const CreateAgreementMng: React.FC<iCreateAgreementMngProp> = ({
  organizations,
  agreements,
  onCreate,
}: iCreateAgreementMngProp) => {
  const { Option } = Select;
  const donIds = agreements.map((agr) => agr.don_accn_id);
  const orgs = organizations.filter((org) => !donIds.includes(org.id));
  const [value, setValue] = useState<string>();
  const boxes = useMemo(() => {
    function onChange(v: string) {
      setValue(v);
    }
    return (
      <>
        <h2 className="h1 mb-30">Nuovo Accordo</h2>
        <Row justify="space-between" style={{ paddingBottom: '20px' }}>
          <Col sm={12}>
            <Select
              showSearch
              placeholder="Seleziona l'azienda"
              optionFilterProp="children"
              value={value}
              onChange={onChange}
              filterOption={(input, option) =>
                option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: '100%' }}
            >
              {orgs.map((org) => {
                return (
                  <Option value={org.id} key={org.id}>
                    {org.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
          <Col sm={1} />
          <Col sm={11}>
            <button
              type="button"
              onClick={() => {
                if (value) {
                  onCreate(value);
                  setValue('');
                }
              }}
              className="ant-btn ant-btn-primary"
            >
              Aggiungi Accordo
            </button>
          </Col>
        </Row>
      </>
    );
  }, [orgs, value, Option, onCreate]);
  return <>{boxes}</>;
};

interface iAgreementMngProp {
  organization: iOrganization | iProfileData;
  fromDonor: boolean;
  readOnly: boolean;
}
export const AgreementMng: React.FC<iAgreementMngProp> = ({
  organization,
  fromDonor,
  readOnly,
}: iAgreementMngProp) => {
  const [agreements, setAgreements] = useState<iAgreement[]>([]);
  const reloadAgreements = useCallback(async () => {
    if (organization.id)
      setAgreements(
        fromDonor
          ? await getAgreementsForDonorApi(organization.id)
          : await getAgreementsForNoProfitApi(organization.id),
      );
  }, [fromDonor, organization.id]);
  useEffect(() => {
    reloadAgreements();
  }, [reloadAgreements]);

  const organizations: iOrganizationsStatus = useSelector((state: any) => state.organizations);
  const { list } = organizations;
  if (!list) {
    return <div />;
  }
  const listOrgs = fromDonor ? list.noprofits : list.donors;
  const usedDons = agreements.map((a) => a.don_accn_id);
  const otherDonors = list.donors.filter((d) => !usedDons.includes(d.id));
  const orgs = filterOrgs(agreements, listOrgs, fromDonor);
  const title = fromDonor ? 'Accordi con beneficiari' : 'Accordi con donatori';
  return (
    <>
      <hr className="div my-30" />
      {organization.id && !fromDonor && !readOnly && (
        <CreateAgreementMng
          agreements={agreements}
          organizations={otherDonors}
          onCreate={(id) => {
            createAgreementApi(id, organization.id as string)
              .then(reloadAgreements)
              .catch(() => {
                console.debug('err');
              });
          }}
        />
      )}
      <h2 className="h1 mb-30">{title}</h2>
      <AgreementList
        agreements={agreements}
        readonly={readOnly}
        orgs={orgs}
        onDelete={(agr) => {
          deleteAgreementApi(agr.id)
            .then(reloadAgreements)
            .catch(() => {
              console.debug('err');
            });
        }}
      />
    </>
  );
};
interface iProps {
  organization: iProfileData;
}

const OrganizationDonorDeals: React.FC<iProps> = ({ organization }: iProps) => {
  const dispatch = useDispatch();
  const { subscriptions } = useSelector((state: RootState) => state);
  useEffect(() => {
    dispatch(organizationsGetAsyncAction(null));
  }, [dispatch]);

  const currentPlan = retrieveCurrentPlan(subscriptions.userHistoryPlans);

  const profileCard = useMemo(() => {
    return organization ? <ProfileCard profile={organization} currentPlan={currentPlan} /> : null;
  }, [organization, currentPlan]);

  if (!organization) {
    return <div />;
  }
  return (
    <div>
      <Row gutter={16}>
        <Col md={24}>{profileCard}</Col>
      </Row>
      <AgreementMng organization={organization} fromDonor readOnly={false} />
    </div>
  );
};

export default OrganizationDonorDeals;
