/* eslint-disable import/prefer-default-export */
export const apis = {
  auth: {
    registration: 'v2/p/accounts',
    login: 'v2/p/accounts/login',
    newpassword: 'v2/p/accounts/new-password',
    changePassword: 'v2/a/accounts/change-password',
    resetPassword: 'v2/p/accounts/reset-password',
  },
  profile: 'v2/a/accounts/me',
  profileById: 'v2/a/accounts/:id/profile',
  creditsById: 'v2/a/accounts/:id/credits',
  profileCreatePA: 'v2/a/accounts/pa',
  privacy: 'v2/a/accounts/privacy',
  organizations: 'v2/a/accounts',
  organizationsStatus: 'v2/a/accounts',
  impersonate: 'v2/a/accounts/impersonate/',
  offers: {
    base: 'v2/a/offers',
    getDonor: 'v2/a/offers/mine',
    categories: 'v2/a/categories',
    categoriesUsed: 'v2/a/categories/used',
    measures: 'v2/a/measures',
    donors: 'v2/a/donors',
    csv: 'v2/a/offers/csv',
  },
  orders: {
    create: 'v2/a/orders',
    getReceived: 'v2/a/orders/received',
    getMine: 'v2/a/orders/mine',
    accept: 'v2/a/orders/accept',
    reject: 'v2/a/orders/reject',
  },
  statistics: {
    widget: 'v2/p/report/kpi/indexes',
    cards: 'v2/a/report/kpi/indexes',
    columns: 'v2/a/report/kpi/orders/time',
    pieCategory: 'v2/a/report/kpi/orders/category',
    pieDonor: 'v2/a/report/kpi/orders/donor',
    map: 'v2/a/report/kpi/orders/state',
    transactions: 'v2/a/report/orders/transactions',
  },
  subscriptions: {
    request: 'v2/a/subscription/request',
    listAvailable: 'v2/a/subscription/list/',
    userHistoryPlans: 'v2/a/subscription/',
    // subscriptionUpsert - > da admin cambio piano utente
    set: 'v2/a/subscription/upsert',
    delete: 'v2/a/subscription/',
  },
  category: {
    specifications: 'v2/a/category/specifications',
    categories: 'v2/a/category/categories',
  },
  agreements: {
    donor: 'v2/a/agreements/donor',
    details: 'v2/a/agreements/donor/details',
    noprofit: 'v2/a/agreements/noprofit',
    crud: 'v2/a/agreements',
  },
};
