import React, { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { getService } from '../../libs/helpers/services';
import { iProfileState } from '../../libs/interfaces';
import { enumRoutes, enumSubscriptions } from '../../libs/enums';
import { isNoProfit, isDonor, canSeeStatistics } from '../../libs/helpers/privileges.helpers';

import { iNavNode } from './navigation';
import './style.sass';

interface iProps {
  i: iNavNode;
  onButtonClicked: () => void;
  recentSubDate: string | null;
  userPlan: enumSubscriptions;
  userRole: string;
}

function getRouteFromPath(Routes: any, path: any) {
  return Routes.find((r: any) => r.path === path);
}

const HeaderNavItem: React.FC<iProps> = ({
  i,
  onButtonClicked,
  recentSubDate,
  userPlan,
  userRole,
}: iProps) => {
  const history = useHistory();
  const currentPath = history.location.pathname;
  const Routes = useMemo(() => getService('routes'), []);

  const isUserDonor = isDonor(userRole);

  function renderLink(item: iNavNode, to: string) {
    return (
      <Link to={to}>
        {item.icon && <img className="l-nav__ico" src={item.icon} alt={item.label} />}
        {item.label}
      </Link>
    );
  }

  function renderLinkOrButton(item: iNavNode, to: string) {
    if (to !== enumRoutes.statistics || canSeeStatistics(userRole, userPlan, recentSubDate)) {
      return renderLink(item, to);
    }

    return (
      <button type="button" onClick={onButtonClicked}>
        {item.icon && <img className="l-nav__ico" src={item.icon} alt={item.label} />}
        {item.label}
      </button>
    );
  }

  function setNavItem(item: iNavNode) {
    const route = getRouteFromPath(Routes, item.path);
    if (!route || (route.roles && !route.roles.includes(userRole))) {
      return null;
    }

    // TODO eliminare quando i beneficiari potranno condividere
    if (route.path === enumRoutes.offersReservations && isNoProfit(userRole)) {
      return null;
    }

    const isCurrentPath = item.path === currentPath;

    let myClass = isCurrentPath ? 'is-active' : undefined;

    if (!isCurrentPath && item.children) {
      item.children.forEach((c) => {
        if (currentPath.includes(c.path)) {
          myClass = 'has-active-child';
        }
      });
    }

    let to = item.path;
    if (item.path === enumRoutes.offers) {
      if (isNoProfit(userRole)) {
        to = enumRoutes.offersAvailable;
      }
    }

    return (
      <li className={myClass} key={item.path + item.label}>
        {isUserDonor ? renderLinkOrButton(item, to) : renderLink(item, to)}
        {item.children && myClass && (
          <ul className="l-nav__second">
            {item.children.map((navItem: iNavNode) => setNavItem(navItem))}
          </ul>
        )}
      </li>
    );
  }

  return setNavItem(i);
};

export default HeaderNavItem;
