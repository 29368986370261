import React, { ReactNode } from 'react';
import { Layout } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store';
import Header from '../../header/header';
import { enumRoutes } from '../../../libs/enums';
import { canEditProfile } from '../../../libs/helpers/privileges.helpers';
import Config from '../../../config/Config';

const { Content } = Layout;

interface iProps {
  children: ReactNode;
}

const AppLayout: React.FC<iProps> = ({ children }: iProps) => {
  const history = useHistory();
  const { profile, user } = useSelector((state: RootState) => state);
  if (canEditProfile(user.role)) {
    const hasProfileAndGDPR = profile.complete && profile.gdpr;
    const currentPath = history.location.pathname;
    if (!hasProfileAndGDPR && !user.realUser && currentPath !== enumRoutes.profileEdit) {
      history.replace(enumRoutes.profileEdit);
    }
  }

  return (
    <Layout className={`app-layout env-${Config.environment.env}`}>
      <Header />
      <Content className="py-30 container">{children}</Content>
    </Layout>
  );
};

export default AppLayout;
