import React, { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Row, Modal, Col } from 'antd';
import { Link, useHistory } from 'react-router-dom';

import { canEditProfile, isAdmin } from '../../libs/helpers/privileges.helpers';
import { enumRoutes, enumSubscriptions } from '../../libs/enums';
import Images from '../../assets/images';
import Logo from '../logo/logo';
import SubscriptionCard from '../subscriptionCard/subscriptionCard';

import ModalDonorSubscriptions from '../modalDonorSubscriptions/modalDonorSubscriptions';
import HeaderNavItem from './headerNavItem';
import HeaderNavUser from './headerNavUser';
import NAV, { iNavNode } from './navigation';
import './style.sass';

const Header: React.FC = () => {
  const history = useHistory();
  const [showNawMobile, showNawMobileSet] = useState<boolean>(false);
  const [showUserNav, showUserNavSet] = useState<boolean>(false);
  const [showModalPlans, showModalPlansSet] = useState<boolean>(false);

  const { profile, user } = useSelector((state: any) => state);
  const userRole = user.role;
  const hasProfileAndGDPR = profile.complete && profile.gdpr;

  const userPlan = profile.subscription?.planWithRole;
  const recentSubDate = profile.subscription?.recent_sub_date;

  const setNavMemo = useMemo(() => {
    if (!hasProfileAndGDPR && !isAdmin(userRole) && !user.realUser) {
      const redirectPath = canEditProfile(userRole) ? enumRoutes.profileEdit : enumRoutes.profile;
      history.push(redirectPath);
      return <div>&nbsp;</div>;
    }

    return (
      <nav className="l-nav__main">
        <ul className="l-nav__first">
          {NAV.map((i: iNavNode) => (
            <HeaderNavItem
              key={i.path}
              i={i}
              userRole={userRole}
              userPlan={userPlan}
              recentSubDate={recentSubDate}
              onButtonClicked={() => showModalPlansSet(true)}
            />
          ))}
        </ul>
      </nav>
    );
  }, [userRole, user.realUser, hasProfileAndGDPR, userPlan, recentSubDate, history]);

  const profileData = profile.data;
  const avatar = useCallback(() => {
    if (!profileData) {
      return null;
    }
    const { credits, name, image } = profileData;
    const creditsToRender: number = credits > 0 ? credits : 0;
    const creditsTxt = creditsToRender === 1 ? 'Credito' : 'Crediti';
    return (
      <>
        <span>{name}</span>
        {creditsToRender > 0 && (
          <div>
            {creditsToRender} {creditsTxt}
          </div>
        )}
        <i>
          <img src={image || Images.icoAvatar.src} alt={Images.icoAvatar.alt} />
        </i>
      </>
    );
  }, [profileData]);

  function onCloseModal() {
    showModalPlansSet(false);
  }

  return (
    <header
      className={`l-header${showNawMobile ? ' nav-mobile-active' : ''} ${
        showUserNav ? ' hasNavUserVisible' : ''
      }`}
    >
      <Row justify="space-between" align="middle" className="l-header--main">
        <div className="d-flex ">
          <button type="button" className="burger" onClick={() => showNawMobileSet(!showNawMobile)}>
            <img src={Images.burger.src} alt={Images.burger.alt} />
          </button>
          <Logo small />
        </div>
        <div className="l-nav__user-name">{avatar()}</div>
        <div className="l-nav">
          {setNavMemo}
          <HeaderNavUser
            avatar={avatar}
            onUserClicked={(v: boolean) => showUserNavSet(v)}
            showUserNav={showUserNav}
          />
        </div>
      </Row>
      <ModalDonorSubscriptions visibility={showModalPlans} onCloseModal={onCloseModal} />
    </header>
  );
};

export default Header;
