/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
import { toast } from 'react-toastify';

type HttpErrorResponse = {
    response: {
        label?: {
            it: string
        }
    }
}
export const manageErr = (err: HttpErrorResponse): any => {
    const errorMessage = err.response.label ? err.response.label.it : 'Si è verificato un errore';
    toast.error(errorMessage);
    throw err;
}
export const manageNoErr = (err: HttpErrorResponse): any => {
    const errorMessage = err.response.label ? err.response.label.it : 'Si è verificato un errore';
    toast.error(errorMessage);
}