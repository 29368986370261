/* eslint-disable prettier/prettier */
import { Col, Row, Tooltip } from 'antd';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Images from '../../assets/images';
import { iProfileFormData, iProfileSubAccounts } from '../../libs/interfaces/index';
import './style.sass';

interface iProps {
    data: iProfileFormData;
    canEdit: boolean;
    showGdprAlert?: boolean;
}

const ProfileDetail: React.FC<iProps> = ({ data, canEdit, showGdprAlert }: iProps) => {
    const history = useHistory();
    function Details(label: string, fieldData: string | number, colNum = 12) {
        return (
            <Col sm={colNum} className="mb-15">
                <p className="f-s-17 f-w-500 mb-5">{label}</p>
                <p>{fieldData || '...'}</p>
            </Col>
        );
    }

    const editPath = `${history.location.pathname}/edit`;
    return (
        <div className="container-small">
            {showGdprAlert && (
                <>
                    <h4 className="c-red">Finché l’amministratore del profilo non accetterà le regole della privacy e i termini di utilizzo  non potrai operare sulla piattaforma.</h4>
                    <hr className="div" />
                </>
            )}
            <Row align="middle" justify="space-between">
                <div className="profile__head">
                    <div className="profile__avatar">
                        <div>
                            <img src={data.image || Images.icoAvatar.src} alt={Images.icoAvatar.alt} />
                        </div>
                    </div>
                    <div>
                        <h2 className="h1">{data.name}</h2>
                    </div>
                    {/* <div>
                {data.credits}
                <p>{}</p>
              </div> */}
                </div>
                {canEdit && (
                    <Link to={editPath} className="ant-btn">
                        Modifica
                    </Link>
                )}
            </Row>

            {data.subAccounts.map((sub: iProfileSubAccounts) => (
                <div className="box--grey-xl pb-0 mb-30" key={sub.email}>
                    <Row gutter={16}>
                        {Details('Email', sub.email, 8)}
                        {Details('Numero di telefono', sub.phone, 8)}
                        {Details('Stato', sub.active ? 'Attivo' : 'Non attivo', 8)}
                    </Row>
                </div>
            ))}

            <hr className="div" />

            <h2 className="h1 with-tooltip mb-30">Dati amministrativi</h2>
            <Row gutter={16}>{Details('Ragione sociale', data.name)}</Row>
            <Row gutter={16}>
                {Details('Indirizzo', data.administrativeStreet)}
                {Details('Città', data.administrativeCity)}
            </Row>

            <Row gutter={16}>
                <Col sm={12}>
                    <Row gutter={16}>
                        {Details('Provincia', data.administrativeState)}
                        {Details('CAP', data.administrativeZip)}
                    </Row>
                </Col>
                {Details('Telefono', data.administrativePhone)}
            </Row>

            <hr className="div" />

            <Row gutter={16}>
                {Details('Partita iva', data.vatNumber)}
                {Details('Codice fiscale', data.taxCode)}
            </Row>
            <Row gutter={16}>{Details('Codice SDI', data.sdiCode)}</Row>

            <hr className="div" />

            <h2 className="h1 with-tooltip  mb-30">
                Dati operativi
                <Tooltip title="I dati operativi riguardano l'indirizzo relativo al luogo da dove partirà la merce in caso di spedizione, o presso il quale sarà possibile prelevarla in caso di ritiro">
                    <img className="tooltip__info" src={Images.icoinfo.src} alt={Images.icoinfo.alt} />
                </Tooltip>
            </h2>
            <Row gutter={16}>
                {Details('Indirizzo', data.operationalStreet)}
                {Details('Città', data.operationalCity)}
            </Row>
            <Row gutter={16}>
                <Col sm={12}>
                    <Row gutter={16}>
                        {Details('Provincia', data.operationalState)}
                        {Details('CAP', data.operationalZip)}
                    </Row>
                </Col>
                {Details('Numero di telefono', data.operationalPhone)}
            </Row>

            <hr className="div" />
            <h2 className="h1 mb-30">
              Autenticazione a due fattori
            </h2>
            <Row gutter={16}>
              {Details('Stato', data.enabled2fa ? 'Attiva' : 'Non attiva', 8)}
            </Row>
        </div>
    );
};

ProfileDetail.defaultProps = {
    showGdprAlert: false,
  };

export default ProfileDetail;
