import React, { useState } from 'react';
import { Row, Modal, Col } from 'antd';
import { Link } from 'react-router-dom';

import { enumRoutes, enumSubscriptions } from '../../libs/enums';
import Images from '../../assets/images';
import SubscriptionCard from '../subscriptionCard/subscriptionCard';

interface iProps {
  onCloseModal: () => void;
  visibility: boolean;
  onlyAdvanced?: boolean;
}

const ModalDonorSubscriptions: React.FC<iProps> = ({
  onCloseModal,
  visibility,
  onlyAdvanced,
}: iProps) => {
  return (
    <Modal
      visible={visibility}
      width={815}
      onCancel={onCloseModal}
      modalRender={() => (
        <div className="modalCustom ant-modal-content text-center">
          <button type="button" className="modal__close" onClick={onCloseModal}>
            <img src={Images.icoCloseDark.src} alt={Images.icoCloseDark.alt} />
          </button>
          <p className="mb-30 f-s-26">
            Per accedere a questa funzione passa ad un piano {onlyAdvanced ? 'Advanced' : 'Premium'}
          </p>
          <Row gutter={16} justify="center">
            {!onlyAdvanced && (
              <Col lg={12}>
                <SubscriptionCard
                  month
                  onContractSelected={() => null}
                  onMoreInfo={() => null}
                  type={enumSubscriptions.donorReport}
                />
              </Col>
            )}
            <Col lg={12}>
              <SubscriptionCard
                month
                onContractSelected={() => null}
                onMoreInfo={() => null}
                type={enumSubscriptions.donorAdvanced}
              />
            </Col>
          </Row>
          <p>
            <Link className="ant-btn" to={enumRoutes.subscriptions}>
              SCOPRI DI PIÙ
            </Link>
          </p>
        </div>
      )}
    />
  );
};

ModalDonorSubscriptions.defaultProps = {
  onlyAdvanced: false,
};

export default ModalDonorSubscriptions;
