import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Row } from 'antd';
import { toast } from 'react-toastify';

import { RootState } from '../../store';
import { Api } from '../../libs/callApi';
import Localize from '../../libs/localization';
import { userLoginAsyncAction } from '../../store/features/userSlice';
import { changePasswordApi } from '../../api/user.api';

import Config from '../../config/Config';

const api = new Api(Config.apiBaseurl);

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const ChangePasswordScreen: React.FC = () => {
  const { user } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [submitDisabled, submitDisabledSet] = useState<boolean>(true);

  async function onSend() {
    const { password, oldpassword } = form.getFieldsValue();
    const dataToSend = {
      oldPassword: oldpassword,
      newPassword: password,
    };
    try {
      const call = await changePasswordApi(dataToSend);
      if (call.payload?.ok) {
        dispatch(userLoginAsyncAction({ email: user.email, password, otp: '' }));
      }
    } catch (err) {
      toast.error(err.response.label ? err.response.label.it : 'Si è verificato un errore');
    }
  }

  function onValuesChange(values: any) {
    const { password, confirm } = form.getFieldsValue();
    const isDisabled = Boolean(password && confirm);
    submitDisabledSet(!isDisabled);
  }

  return (
    <>
      <h1 className="mb-30">{Localize('PAGES.AUTH.NEWPASSWORD.TITLE')}</h1>
      <Form
        {...layout}
        form={form}
        layout="vertical"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onSend}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          label="Vecchia password"
          name="oldpassword"
          rules={[
            {
              required: true,
              message: Localize('FORMS.MANDATORY'),
            },
          ]}
        >
          <Input.Password placeholder="Inserisci la nuova password" />
        </Form.Item>

        <Form.Item
          label="Nuova password"
          name="password"
          rules={[
            {
              required: true,
              message: Localize('FORMS.MANDATORY'),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const regex = Config.regex.password;
                if (regex.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(Localize('PAGES.AUTH.NEWPASSWORD.ERROR_SECURITY')));
              },
            }),
          ]}
        >
          <Input.Password placeholder="Inserisci la nuova password" />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Conferma nuova password"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: Localize('FORMS.MANDATORY'),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(Localize('PAGES.AUTH.NEWPASSWORD.ERROR_CONFIRM')));
              },
            }),
          ]}
        >
          <Input.Password placeholder="Conferma la tua password" />
        </Form.Item>

        <Row justify="end">
          <Button htmlType="submit" disabled={submitDisabled}>
            {Localize('COMMON.CONFIRM')}
          </Button>
        </Row>
      </Form>
    </>
  );
};

export default ChangePasswordScreen;
