import React from 'react';
import { Row } from 'antd';

import Images from '../../assets/images';
import { iProfileData } from '../../libs/interfaces';
import Config from '../../config/Config';

interface iProps {
  data: iProfileData;
}

const OfferDetailsInfo: React.FC<iProps> = ({ data }: iProps) => {
  return (
    <div className="box--grey-xl my-30 oDetails__donor">
      <Row align="middle">
        <div className="oDetails__image--small">
          <img
            src={data.image?.square_300?.url || Images.icoAvatar.src}
            alt={Images.icoAvatar.alt}
          />
        </div>
        <h2 className="h1">{data && data.name}</h2>
      </Row>
      <dl className="my-30">
        <dt>Email</dt>
        <dd>{data && data.account && data.account.email}</dd>
        <dt>Telefono</dt>
        <dd>{data && data.account && data.account.phone}</dd>
      </dl>
      <Row className="oDetails__donor-data">
        <div>
          <h3>Dati operativi</h3>
          <dl className="my-30">
            <dt>Indirizzo</dt>
            <dd>
              {data &&
                data.operationalData &&
                `${data.operationalData.street}, ${data.operationalData.city}, ${data.operationalData.zip}`}
            </dd>
            <dt>Telefono</dt>
            <dd>{data && data.operationalData && data.operationalData.phone}</dd>
          </dl>
        </div>
        <div>
          <h3>Dati Amministrativi</h3>
          <dl className="my-30">
            <dt>Ragione Sociale</dt>
            <dd>{data && data.name}</dd>
            <dt>Indirizzo</dt>
            <dd>
              {data &&
                data.administrativeData &&
                `${data.administrativeData.street}, ${data.administrativeData.city}, ${data.administrativeData.zip}`}
            </dd>
            <dt>Telefono</dt>
            <dd>{data && data.administrativeData && data.administrativeData.phone}</dd>
            <dt>Partita IVA</dt>
            <dd>{data && data.vatNumber}</dd>
            <dt>Codice fiscale</dt>
            <dd>{data && data.taxCode}</dd>
            <dt>Codice SDI</dt>
            <dd>{data && data.sdiCode}</dd>
          </dl>
        </div>
      </Row>
    </div>
  );
};

export default OfferDetailsInfo;
