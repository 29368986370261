/* eslint-disable prettier/prettier */
import React from 'react';
import { Modal } from 'antd';

interface iProps {
  visible: boolean;
  onCloseModal: () => void;
}

const TermsModal: React.FC<iProps> = ({ visible, onCloseModal }: iProps) => {
  return (
    <Modal
      title="Termini e Condizioni"
      visible={visible}
      onCancel={onCloseModal}
      footer={() => null}
      width={900}
    >
      <h2>Termini e condizioni generali </h2>
      <p>
        L’utilizzo dei servizi da parte dell’Utente attraverso la Piattaforma “Regusto” è
        esclusivamente regolato da quanto descritto di seguito (da qui in avanti unitariamente
        denominate “Termini e Condizioni”).
      </p>
      <p>
        Queste Condizioni sono riportate sulla Piattaforma “Regusto” per informazione, presa visione
        ed espressa accettazione da parte degli Utenti della Piattaforma come di seguito
        specificato.
      </p>
      <p className="text-center">Premesso che</p>
      <ol type="a">
        <li>
          La società Recuperiamo srl Società Benefit, società di diritto italiano con sede in
          Corciano (PG) in Via Camillo Bozza 14, P. IVA 03535510543 (di seguito anche “Gestore”) ha
          l’obiettivo di proporre soluzioni per prevenire e ridurre lo spreco alimentare, sia in
          ambito profit che non- profit, e sin dalla sua costituzione, ha ideato e sviluppato una
          piattaforma digitale B2B basata su tecnologia blockchain, contraddistinta dall’espressione
          “Regusto”;
        </li>
        <li>
          Tale espressione, oggetto di registrazione per marchio comunitario EU n. 018718257, è di
          titolarità del Gestore ed è stata adottata da quest’ultimo per contraddistinguere
          l’anzidetta piattaforma ed in generale il servizio tramite il quale vengono gestite le
          eccedenze alimentari e gli altri beni di prima necessità, messi a disposizione dalle
          Aziende aderenti (di seguito Aziende o Fornitori) a favore di associazioni, enti e società
          non profit a livello regionale e nazionale (di seguito Enti);
        </li>
        <li>
          La piattaforma digitale “Regusto” (di seguito anche, “Piattaforma Regusto” o
          “Piattaforma”) di titolarità del Gestore, mette dunque a disposizione delle Aziende
          aderenti una vetrina virtuale per agevolare la donazione e/o vendita dei propri prodotti
          in stock; Tramite tale “Piattaforma” sono gestiti i flussi di acquisto e donazione di
          prodotti e beni con garanzia di trasparenza e tracciabilità. Gli Enti possono prenotare e
          acquistare i prodotti messi a disposizione dai Fornitori. La transazione di prodotti in
          donazione e in vendita tramite la Piattaforma genera dati statistici e indici di impatto,
          tramite l’utilizzo di algoritmi brevettati, sviluppati insieme a taluni partner accademici
          del Gestore secondo standard internazionali.
        </li>
        <li>
          Tali indici sono certificati e tracciati in blockchain e sono messi a disposizione delle
          Aziende donatrici/fornitrici e degli enti non-profit. Il Gestore, oltre ad elaborare e
          calcolare gli anzidetti indici, li certifica e ne autorizza l’utilizzo in forma gratuita
          da parte degli utenti prevedendo che venga sempre citata la fonte (piattaforma Regusto)
          nei successivi usi.
        </li>
        <li>
          I dati generati dalla piattaforma Regusto, attraverso algoritmi di aggregazione, generano
          crediti multidimensionali di impatto sociale e ambientale (“Crediti Regusto”). I crediti
          di titolarità del Gestore (univoci, autenticati tramite NFT e certificati in blockchain)
          sono commercializzati dal Gestore attraverso canali diretti o indiretti e consentono la
          localizzazione territoriale dell’impatto positivo generato a livello sociale e ambientale.
        </li>
      </ol>
      <p>
        Recuperiamo svolge dunque il ruolo di mero titolare e gestore della Piattaforma e, pertanto,
        è estranea al rapporto tra Fornitori ed Enti (insieme, di seguito anche Utenti della
        Piattaforma) relativi a donazione, vendita o acquisto dei prodotti. Recuperiamo non instaura
        nessuna relazione contrattuale diretta o indiretta sulle donazioni, vendite o acquisti dei
        prodotti in questione. I prodotti disponibili nella Piattaforma “Regusto” si riferiscono ai
        prodotti dei singoli Fornitori, cui appartiene in via esclusiva la titolarità, sia dei
        medesimi che dei successivi contratti conclusi tra essi e gli Enti in questione.{' '}
      </p>
      <p>
        Come indicato nelle premesse -lett.e)- Recuperiamo è titolare invece dei “Crediti Regusto”
        che, in base a quanto sopra dettagliato, vengono generati, certificati e commercializzati da
        Recuperiamo attraverso canali diretti o indiretti. La cessione dei “Crediti Regusto“ è
        oggetto di specifico e separato contratto a cui si rinvia.{' '}
      </p>
      <h3>Il servizio REGUSTO ®</h3>
      <p>Il servizio di cui alla “Piattaforma Regusto”, in breve consente di:</p>
      <ul>
        <li>
          connettere i Fornitori con gli Enti (tra cui anche i Comuni e altri enti pubblici),
          tracciando, certificando e facilitando lo scambio di prodotti e la riduzione degli
          sprechi alimentari e non;
        </li>
        <li>
          garantire una semplificazione e digitalizzazione delle donazioni e del recupero
          alimentare all’interno del territorio, sfruttando i benefici concessi dalla Legge 166
          del 19/08/2016 (c.d. Legge Gadda). La tecnologia blockchain garantisce inoltre
          trasparenza e tracciabilità delle transazioni/donazioni, consentendo di certificare la
          riduzione dell’impatto ambientale ad esse associata;
        </li>
        <li>
          mettere a disposizione dei Fornitori una vetrina virtuale per i propri prodotti e
          permette agli Enti di prenotare i beni donati dai Fornitori o di prenotare, attraverso
          un portafoglio elettronico, i beni messi in vendita dai Fornitori.
        </li>
        <li>
          valorizzare economicamente il potenziale spreco abbinandolo al recupero del prodotto
          attraverso la generazione di Crediti di impatto univoci, certificati e tracciati in
          blockchain.
        </li>
        <li>
          conferire in particolare agli Utenti la possibilità di monitorare i flussi di beni
          acquisiti da questi ultimi fornendo statistiche puntuali e periodiche.
        </li>
      </ul>
      <ol>
        <li>
          <h3>Registrazione “Utenti”</h3>
          <p>
            Per poter usufruire dei servizi offerti dalla Piattaforma, è necessario richiedere al
            Gestore la registrazione alla Piattaforma che conferisce sia al Fornitore che all’Ente
            registrato, lo status di “Utente”. Il richiedente dovrà registrarsi alla Piattaforma
            fornendo tutti i dati richiesti e scegliendo le credenziali di accesso (username, email
            e password). Caricati i dati, il Gestore verificherà l’esistenza, l’effettiva
            operatività, la qualità e l’affidabilità del richiedente e, qualora tale verifica abbia
            esito positivo, invierà al richiedente una e-mail di conferma. Recuperiamo si riserva
            quindi il diritto di non registrare un Utente e non mettere a disposizione la propria
            Piattaforma o procedere alla cancellazione di un Utente registrato, a proprio
            insindacabile giudizio, in caso a titolo esemplificativo e non esaustivo di dati mendaci
            e/o non verificabili, fatti salvi altri motivi. La registrazione alla Piattaforma viene
            perfezionata in maniera automatica al primo accesso conclusosi con successo. Per
            completare tale procedura è sufficiente inserire le credenziali d’accesso ed accettare i
            presenti Termini e Condizioni e l’informativa relativa alla Privacy.
          </p>
          <p>
            La condizione di Utente consentirà di accedere al proprio profilo personale e verificare
            in qualsiasi momento lo stato delle prenotazioni effettuate o ricevute. L’Utente si
            impegna a conservare in modo sicuro le credenziali di accesso e a non permetterne
            l’utilizzo a terzi attraverso le medesime. L’Utente è il solo e unico responsabile della
            conservazione, della riservatezza e dell’utilizzo del proprio username e della propria
            password.
          </p>
          <p>Accettando di utilizzare la Piattaforma l’Utente si impegna espressamente a NON:</p>
          <ul>
            <li>
              Pubblicare, in una qualunque pagina della Piattaforma o diffondere attraverso la
              Piattaforma contenuti diversi dall’inserzione di prodotti o la cui pubblicazione sia
              in qualsiasi modo contraria alle norme di legge vigenti;
            </li>
            <li>
              Comunicare al Gestore dati falsi, inesatti, fuorvianti, o che comportino conseguenze
              pregiudizievoli per terze parti, in particolare al momento della registrazione ai fini
              della creazione del profilo, al momento della pubblicazione di prodotti disponibili e
              al momento di prenotazione della merce attraverso la Piattaforma;
            </li>
            <li>
              Trasferire l’Account o comunicare la user id in forma di e-mail e la password a
              soggetti terzi senza il preventivo consenso scritto del Gestore;
            </li>
            <li>
              Diffondere virus, malware o qualsiasi altra tecnologia volta a danneggiare la
              Piattaforma, gli apparati degli Utenti, a violare i diritti del Gestore o degli altri
              Utenti o comunque ad impedire o disturbare la fruizione dei Servizi da parte degli
              altri Utenti;
            </li>
            <li>
              Usare qualsiasi meccanismo, software o procedimento che possa interferire con il
              corretto funzionamento della Piattaforma;
            </li>
            <li>
              Aggirare gli strumenti predisposti dal Gestore per garantire la sicurezza della
              Piattaforma e prevenire intrusioni o accessi da parte di utenze automatizzate non
              sollecitate (robot, spam, spiders, etc);
            </li>
            <li>
              Compiere qualsiasi azione che (ad insindacabile giudizio del Gestore) possa causare un
              irragionevole sovraccarico di attività delle infrastrutture tecnologiche relative alla
              Piattaforma;
            </li>
            <li>
              Copiare, modificare o diffondere il contenuto della Piattaforma o delle informazioni
              di titolarità esclusiva del Gestore e/o di terzi;
            </li>
            <li>
              Utilizzare la Piattaforma o i Servizi in assenza della piena capacità di agire e di
              stipulare contratti legalmente vincolanti.
            </li>
          </ul>
          <p>
            Come sopra accennato, il Gestore a sua discrezione potrà in ogni momento ed a suo
            insindacabile giudizio sospendere o cancellare la registrazione al sito degli Utenti che
            non si atterranno a quanto sopra espressamente indicato.
          </p>
        </li>
        <li>
          <h3>Modifica delle informazioni personali</h3>
          <p>
            Gli Utenti registrati possono aggiornare e rettificare in qualunque momento le
            informazioni personali inserite durante la registrazione. Gli Utenti sono responsabili
            della veridicità e dell&#39;esattezza dei dati personali inseriti mentre il Gestore non
            assume alcuna responsabilità a tale riguardo. Gli Utenti possono in ogni momento
            richiedere la cancellazione della propria registrazione alla Piattaforma. Il Gestore
            provvederà entro i tempi tecnici necessari, dandone comunicazione scritta per email
            all’Utente.
          </p>
        </li>
        <li>
          <h3>Promozione dei prodotti attraverso la Piattaforma</h3>
          <p>
            Il Gestore consente attraverso la Piattaforma ai Fornitori registrati di promuovere, con
            annunci dedicati, i propri prodotti. I prodotti messi a disposizione dalle Aziende sono
            visibili e possono essere prenotati dagli Enti, concordando altresì il successivo ritiro
            presso il Fornitore (o la ricezione degli stessi tramite spedizione da parte del
            Fornitore). I Fornitori non trasferiscono al Gestore la proprietà dei beni
            commercializzati. Il passaggio di proprietà dei beni, così come il rapporto contrattuale
            alla base del medesimo, avverrà esclusivamente tra Fornitore ed Enti. La descrizione dei
            prodotti in Piattaforma avviene a cura e sotto la diretta responsabilità dei Fornitori,
            ha una funzione meramente rappresentativa ed ha il solo scopo di illustrare i prodotti
            disponibili. Sarà onere ed obbligo del Fornitore indicare in relazione a ciascun
            prodotto le relative quantità in peso specifico (espresse in kg o in lt). La mancata
            indicazione del peso del prodotto non consentirà il caricamento dei prodotti in
            Piattaforma. Il Gestore declina, conseguentemente, ogni responsabilità e non presta
            alcuna garanzia con riferimento alla corrispondenza dei prodotti pubblicati nella
            piattaforma e quella dei prodotti presenti presso il punto di ritiro del Fornitore o
            spediti dal Fornitore medesimo. Il Fornitore dichiara e garantisce direttamente,
            inoltre, che i prodotti indicati sulla Piattaforma sono stati realizzati, confezionati e
            conservati rispettando le normative ed i disciplinari alimentari applicabili e che
            rispettano i requisiti qualitativi e di etichettatura imposti dalla legislazione
            nazionale e comunitaria in materia e, come tali, richiesti da Recuperiamo S.r.l. SB.
          </p>
        </li>
        <li>
          <h3>Informazioni, prenotazione e ritiro dei prodotti oggetto di donazione</h3>
          <p>
            Tutti i prodotti visibili agli Utenti in Piattaforma e contraddistinti dal prezzo pari
            ad € 0 (zero). sono oggetto di donazione e possono pertanto essere prenotati dagli Enti
            senza necessità di utilizzo del portafoglio virtuale. Accettata la prenotazione,
            Fornitori ed Enti concorderanno tempi e modalità per il ritiro/spedizione dei beni; in
            mancanza di accordo, la fase di ritiro è totalmente a carico e sotto la responsabilità
            degli Enti anzidetti. Con riferimento ai prodotti donati sopra menzionati, gli Enti,
            constatato di persona lo stato della merce, potranno comunque decidere di non procedere
            al ritiro, senza che ciò comporti responsabilità da parte del Gestore.
          </p>
        </li>
        <li>
          <h3>Informazioni, prenotazione, acquisto dei prodotti oggetto di vendita</h3>
          <p>
            I prodotti visibili agli Utenti in Piattaforma e contraddistinti dal prezzo espresso in
            “monete virtuali” sono oggetto di vendita e possono pertanto essere prenotati ed
            acquistati dagli Enti attraverso l’utilizzo di tali monete. Le informazioni sui
            prodotti, le condizioni di vendita e la disponibilità dei prodotti stessi vengono
            predisposte sulla base di indicazioni, informazioni e istruzioni caricate direttamente
            dai Fornitori. Per ogni prodotto in vendita in Piattaforma viene indicato il nome o la
            ragione sociale e i dati utili all’identificazione del Fornitore, il prezzo espresso in
            moneta virtuale e le quantità di prodotto. Come meglio precisato al punto 4, sarà onere
            ed obbligo del Fornitore indicare in relazione a ciascun prodotto, oltre al prezzo di
            vendita, il relativo peso specifico (espresso in kg o in lt). La mancata indicazione del
            peso del prodotto non consentirà il caricamento dei prodotti in Piattaforma. Il prezzo
            dei prodotti in vendita, comprensivo di iva, verrà corrisposto direttamente dall’Ente al
            Fornitore nei modi e nei termini che saranno indicati dal Fornitore. Recuperiamo, quale
            mero gestore della Piattaforma, accrediterà nel portafoglio elettronico del profilo
            “Utente” dell’Ente una (n.1) moneta virtuale per ogni Euro (€) che potrà utilizzare
            all’interno della Piattaforma. Pertanto, una (n.1) moneta virtuale corrisponde ad un (1)
            Euro (€). Il Gestore non si assume in alcun modo la responsabilità per la veridicità e
            fondatezza delle monete virtuali accreditate nel portafoglio elettronico dell’Ente. In
            caso di mancato pagamento dei prodotti acquistati tramite l’utilizzo delle monete
            virtuali, il Gestore si riserva il diritto di procedere alla cancellazione del profilo
            Utente dell’Ente dalla Piattaforma.
          </p>
          <p>
            Il prezzo totale dei prodotti prenotati attraverso l’utilizzo delle monete virtuali,
            comprensivo delle spese di spedizione, dovrà essere successivamente pagato dall’Ente
            direttamente al/i Fornitore/i. Salvo che non sia diversamente concordato tra le parti,
            le spese di spedizione e consegna non sono incluse nel prezzo e sono indicate a parte.
            Essendo la consegna dei prodotti gestita direttamente dal Fornitore, le spese di
            spedizione sono calcolate in base alle indicazioni del singolo Fornitore. Esse possono
            quindi variare a seconda dei prodotti acquistati. Resta salva la facoltà per il
            Fornitore e l’Ente di concordare differenti modalità di consegna e/o ritiro dei prodotti
            anche direttamente presso il luogo indicato dal Fornitore.
          </p>
        </li>
        <li>
          <h3>Attivazione del portafoglio elettronico</h3>
          <p>
            Per l’attivazione del portafoglio elettronico che consente la prenotazione dei prodotti
            disponibili in piattaforma, gli Enti possono procedere a specifica richiesta scritta al
            Gestore al seguente indirizzo mail: support@regusto.eu
          </p>
        </li>
        <li>
          <h3>Conclusione del contratto </h3>
          <p>
            Il contratto tra Fornitore ed Ente si intenderà concluso solo dopo l’avvenuta consegna
            dei prodotti prenotati tramite la Piattaforma. Nel caso di mancata o irregolare consegna
            il contratto non sarà ritenuto validamente concluso.
          </p>
        </li>
        <li>
          <h3>Condizioni di utilizzo della Piattaforma</h3>
          <p>
            Con riferimento alle condizioni economiche di utilizzo della piattaforma da parte di
            Aziende ed Enti profit ovvero da parte di Enti ed Associazioni non-profit si precisa che
            tutti i servizi offerti dal Gestore sono a titolo gratuito. A titolo oneroso è la
            cessione dei “Crediti Regusto“ oggetto di specifico contratto a cui si rinvia.
          </p>
        </li>
        <li>
          <h3>Responsabilità e manleva</h3>
          <p>
            Con l’accettazione dei presenti “Termini e Condizioni” gli Utenti della Piattaforma
            (Fornitori ed Enti) si impegnano espressamente a tenere indenne e manlevato il Gestore
            nonché i propri dipendenti, dirigenti, collaboratori da qualsiasi pretesa o richiesta di
            risarcimento danni, rimborsi o rivendicazioni provenienti a vario titolo dagli Utenti
            stessi o da terzi, in merito alla violazione anche di una sola delle condizioni incluse
            nei presenti Termini e condizioni ovvero degli obblighi di legge o diritti di terzi.
          </p>
          <p>A mero titolo esemplificativo e non esaustivo si tenga dunque presente che:</p>
          <ul>
            <li>è il Fornitore, e non il Gestore, a cedere e/o vendere i prodotti;</li>
            <li>
              {' '}
              è il Fornitore, e non il Gestore, la parte che conclude il contratto con l’Ente;
            </li>
            <li>
              è il Fornitore, e non il Gestore, il solo responsabile per la cessione, vendita e/o
              donazione dei prodotti;
            </li>
            <li>
              è il Fornitore, e non il Gestore, il solo responsabile della prestazione delle
              garanzie, tutte, dei prodotti;
            </li>
            <li>
              è il Fornitore, e non il Gestore, il solo responsabile per la spedizione e consegna
              dei prodotti;
            </li>
            <li>è l’Ente, e non il Gestore, il solo responsabile per il ritiro dei prodotti;</li>
            <li>
              è l’Ente, e non il Gestore, il solo responsabile per il pagamento del prezzo dei
              prodotti.
            </li>
          </ul>
          <p>
            Né il Gestore, né i propri dipendenti, dirigenti, collaboratori potranno essere dunque
            ritenuti responsabili per qualsiasi pretesa o richiesta di risarcimento danni, rimborsi
            o rivendicazioni provenienti a vario titolo dagli Utenti e/o da terzi ed in particolare
            per:
          </p>
          <ul>
            <li> la lesione o il danno del nome e della reputazione dei Fornitori;</li>
            <li>
              per l’inadeguatezza delle informazioni rese dai Fornitori riguardanti le tariffe, la
              disponibilità e la classificazione dei prodotti;
            </li>
            <li> per la qualità e la tipologia dei prodotti offerti dai Fornitori;</li>
            <li>
              per qualsivoglia danno (diretto, indiretto e/o consequenziale), per perdite o costi
              subiti, pagati a qualsiasi titolo dagli Utenti o da terzi ovvero a cui si è andati
              incontro, in seguito all’incapacità di utilizzo della Piattaforma o per cause tecniche
              del Web, o diverse.
            </li>
          </ul>
        </li>
        <li className="mt-20">
          <h3>Proprietà intellettuale</h3>
          <p>
            Gli Utenti della Piattaforma accettano espressamente e si impegnano a riconoscere e non
            contestare:
          </p>
          <ul>
            <li>
              la circostanza che la “Piattaforma Regusto” e tutti i contenuti ivi presenti, sono di
              ideazione ed esclusiva titolarità di Recuperiamo, inclusi in via esemplificativa e non
              esaustiva la grafica delle pagine web, i marchi, design, loghi, software, data base ed
              altri materiali protetti dalle disposizioni vigenti in materia di proprietà
              intellettuale e diritto d’autore, di cui Recuperiamo ne conserva tutti i diritti ivi
              incluso il relativo diritto di sfruttamento economico;
            </li>
            <li>
              la validità in particolare del marchio Eu indicato in premessa, registrato a
              denominazione “Regusto®” ed il relativo logo grafico nonché i nomi a dominio
              costituiti da tale denominazione di cui Recuperiamo ne conserva tutti i diritti
              relativi;
            </li>
            <li>
              la titolarità del progetto “Regusto”, il relativo know-how e tutte le informazioni di
              natura tecnica e commerciale connesse a tale progetto di cui Recuperiamo è esclusiva
              titolare ivi incluse le metodiche, gli algoritmi aggregatori ed i crediti generati, in
              merito al quale gli Utenti si impegnano a non porre in essere alcuna violazione o
              turbativa.
            </li>
            <li>
              l’adozione e l’utilizzo da parte della società Recuperiamo dei marchi aziendali e dei
              loghi degli Utenti per attività di comunicazione -in particolare a titolo
              esemplificativo e non esaustivo per la creazione di presentazioni, video, newsletter,
              social media, ecc.- limitatamente al Progetto di cui al presente Contratto.
            </li>
          </ul>
        </li>
        <li className="mt-20">
          <h3>Recesso degli utenti e cancellazione del profilo</h3>
          <p>
            Gli Utenti hanno facoltà di recedere dai presenti Termini e Condizioni e richiedere la
            cancellazione del profilo attraverso l’invio di richiesta scritta al Gestore. La
            richiesta di cancellazione del profilo equivale a manifestazione di volontà di recedere
            dai presenti Termini e Condizioni. Il Gestore provvederà alla cancellazione del profilo
            entro 7 (sette giorni) dal ricevimento della richiesta di cancellazione. Alla
            cancellazione del profilo, le informazioni personali dell’Utente saranno automaticamente
            eliminate e il Gestore non conserverà alcuna di tali informazioni, salve quelle
            informazioni per cui la legge ponga un obbligo di conservazione a carico del Gestore.
            Resta salvo ogni corrispettivo economico maturato nei confronti di Recuperiamo fino alla
            data di cancellazione del profilo.
          </p>
        </li>
        <li>
          <h3>Risoluzione anticipata</h3>
          <p>
            Il Gestore si riserva il diritto di risolvere il presente contratto in caso di
            cessazione della propria attività.
          </p>
        </li>
        <li>
          <h3>Privacy</h3>
          <p>
            Gli Utenti della Piattaforma dichiarano di aver preso visione della Politica di tutela
            dei dati personali (Privacy Policy) e ne accettano espressamente il contenuto. La
            Privacy Policy sarà sempre e comunque consultabile nel sito www.regusto.eu e all’interno
            della piattaforma. Il Gestore in particolare procederà al trattamento dei dati personali
            degli Utenti nel rispetto della normativa in materia di privacy la cui accettazione da
            parte degli Utenti viene espressa al momento della registrazione alla Piattaforma.
          </p>
          <p>
            Accedendo al proprio profilo, l’Utente potrà consultare i dati forniti e modificarli,
            nonché scegliere di non ricevere determinate comunicazioni. La compilazione e presa
            visione dell’Informativa Privacy è condizione essenziale per la fornitura dei Servizi e
            l’utilizzo della Piattaforma. L’Informativa Privacy costituisce parte integrante dei
            presenti Termini e condizioni di utilizzo della “Piattaforma Regusto”.
          </p>
        </li>
        <li>
          <h3>Efficacia del contratto</h3>
          <p>
            Il Gestore si riserva il diritto di modificare in qualsiasi momento il contenuto dei
            presenti Termini e Condizioni, pubblicando nuove versioni. Salvo quando diversamente
            specificato, le nuove versioni saranno automaticamente efficaci dal primo accesso
            effettuato nella Piattaforma e in ogni caso decorsi 30 (trenta) giorni dalla data di
            pubblicazione 07/11/2022.
          </p>
        </li>
        <li>
          <h3>Sicurezza</h3>
          <p>
            Il Gestore si impegna per fare in modo che la Piattaforma sia sicura, ma non presta
            alcuna garanzia a riguardo. Il Gestore si impegna comunque a mettere in atto tutte le
            politiche aziendali orientate a garantire la massima sicurezza della propria Piattaforma
            e ad utilizzare tutti i tool che operino in questo senso. Il Gestore non si assume
            nessuna responsabilità per problemi (virus, malware, spyware, ecc.) che possano essere
            causati dal collegamento alla Piattaforma.
          </p>
        </li>
        <li>
          <h3>Legge applicabile e foro esclusivo territorialmente competente</h3>
          <p>
            I presenti Termini e condizioni e la loro interpretazione sono soggetti esclusivamente
            alla legge italiana. Qualsiasi controversia dovesse derivare dall’esecuzione e/o
            interpretazione dei presenti Termini e condizioni nonché connessi e/o collegati
            all’utilizzo della Piattaforma e dei servizi ivi contemplati sarà rimessa alla
            competenza esclusiva al Foro di Perugia con deroga espressa di qualsiasi altro foro
            territorialmente competente.
          </p>
        </li>
        <li>
          <h3>Contatti</h3>
          <p>
            È possibile contattare il Servizio Clienti della Piattaforma Regusto inviando una email
            a support@regusto.eu o chiamando il Servizio Clienti al 075-9280215. Il servizio di
            assistenza Clienti è attivo dal lunedì al venerdì dalle 9:00 alle 13:00 e dalle 14:00
            alle 18:00.
          </p>
        </li>
      </ol>
      <h3>Clausole finali ed accettazione espressa</h3>
      <p>
        Per tutto quanto non disciplinato dai presenti Termini e Condizioni, si rimanda alle
        disposizioni di legge italiane applicabili in materia. Ai sensi e per gli effetti degli
        artt. 1341 e 1342 e seg. c.c., l’Utente dichiara di aver letto e compreso e di approvare
        espressamente i seguenti articoli: 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
        18. Sono parte integrante e sostanziale dei presenti Termini e Condizioni tutti gli
        articoli, ivi incluse le premesse. Tale testo abroga e sostituisce ogni accordo, intesa,
        negoziazione, scritta od orale, intervenuta in precedenza.
      </p>
    </Modal>
  );
};

export default TermsModal;
