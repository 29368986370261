import React from 'react';
import { Modal } from 'antd';

interface iProps {
  visible: boolean;
  onCloseModal: () => void;
}

const PrivacyModal: React.FC<iProps> = ({ visible, onCloseModal }: iProps) => {
  return (
    <Modal
      title="Privacy Policy"
      visible={visible}
      onCancel={onCloseModal}
      footer={() => null}
      width={900}
    >
      <h2>Informativa sul trattamento dei dati personali</h2>
      <p>
        Ai sensi degli artt. 13 e 14 del Regolamento 2016/679/UE (nel seguito &ldquo;GDPR&rdquo;)
        RECUPERIAMO SRL (nel seguito &ldquo;Titolare&rdquo;) con sede in Corciano (PG), via Camillo
        Bozza, 16 &ndash; 06073, in persona del legale rappresentante p.t. Marco Raspati c.f.
        RSPMRC79A05G478C, e titolare della piattaforma digitale/marketplace B to B, contraddistinta
        dal marchio &ldquo;Regusto&rdquo;, nella sua veste di &ldquo;Titolare del
        trattamento&rdquo;, La informa che i Suoi dati personali raccolti ai fini della conclusione
        del contratto col Cliente e/o nell&rsquo;ambito dell&rsquo;esecuzione e/o della stipula
        dello stesso e/o nella fornitura del servizio richiesto saranno trattati nel rispetto delle
        normativa citata, al fine di garantire i diritti, le libert&agrave; fondamentali,
        nonch&eacute; la dignit&agrave; delle persone fisiche, con particolare riferimento alla
        riservatezza e all&apos;identit&agrave; personale. La informiamo che qualora le
        attivit&agrave; a lei prestate prevedano il trattamento di dati personali di terzi nella sua
        titolarit&agrave; sar&agrave; sua responsabilit&agrave; assicurare di aver adempiuto a
        quanto previsto dalla normativa nei riguardi dei soggetti Interessati al fine di rendere
        legittimo il loro trattamento da parte nostra.
      </p>

      <p className="mt-30">
        <b>Origine, finalità, base giuridica e natura dei dati trattati</b>
      </p>
      <p>
        Il trattamento dei Suoi dati personali, da Lei direttamente forniti, è effettuato da
        RECUPERIAMO SRL ai fini della conclusione del contratto col Cliente/Utente e/o nell’ambito
        dell’esecuzione e/o della stipula dello stesso e/o nella fornitura del servizio richiesto.
      </p>
      <p>
        Altresì, è possibile il verificarsi di un trattamento di dati personali di terzi soggetti
        comunicati dal Cliente alla Società. Rispetto a tale ipotesi, il Cliente si pone come
        autonomo titolare del trattamento e si assume i conseguenti obblighi e responsabilità
        legali, manlevando la Società rispetto a ogni contestazione, pretesa e/o richiesta di
        risarcimento del danno da trattamento che dovesse pervenire alla Società da terzi
        interessati.
      </p>
      <p>
        Nel rispetto della normativa vigente in materia di protezione dei dati personali i Dati
        saranno archiviati, raccolti e trattati dalla Società per i seguenti fini:
      </p>
      <ul style={{ listStyleType: 'lower-alpha' }}>
        <li>
          Nel rispetto della normativa vigente in materia di protezione dei dati personali i Dati
          saranno archiviati, raccolti e trattati dalla Società per i seguenti fini:
        </li>
        <li className="mt-10">
          assolvimento a eventuali obblighi normativi, alle disposizioni fiscali e tributarie
          derivanti dallo svolgimento dell’attività d’impresa e a obblighi connessi ad attività
          amministrativo-contabili;
        </li>
        <li className="mt-10">
          invio, direttamente o tramite terzi fornitori di servizi di marketing e comunicazione,
          newsletter e comunicazioni con finalità di marketing diretto attraverso email, sms, posta
          cartacea, telefono con operatore, in relazione a prodotti erogati dalla altre;
        </li>
        <li className="mt-10">
          comunicazione dei Dati a società terze per l’invio di newsletter e comunicazioni con
          finalità di marketing attraverso email, sms, mms, notifiche push, fax, posta cartacea,
          telefono con operatore ai sensi dell’art. 130 c. 1 e 2 del Codice.
        </li>
      </ul>
      <p>
        Le basi giuridiche del trattamento per le finalità a) e b) sopra indicate sono gli artt.
        6.1.b) e 6.1.c) del Regolamento.
      </p>
      <p>
        Il conferimento dei Dati per i suddetti fini è facoltativo, ma l’eventuale mancato
        conferimento dei Dati stessi e il rifiuto a fornirli comporterebbero l’impossibilità per la
        Società di eseguire e/o stipulare il contratto ed erogare le prestazioni richieste dallo
        stesso.
      </p>
      <p>
        La base giuridica del trattamento di dati personali per le finalità c) e d) è l’art. 6.1.a)
        del GDPR in quanto i trattamenti sono basati sul consenso.
      </p>
      <p>
        Tra i Dati raccolti vi sono anche dati personali di natura identificativa, dati bancari o,
        comunque, relativi alle modalità di pagamento.
      </p>

      <p className="mt-30">
        <b>Comunicazione</b>
      </p>
      <p>
        I dati potranno essere comunicati a soggetti terzi nominati responsabili del trattamento ai
        sensi dell’articolo 28 del GDPR e in particolare a istituti bancari, a società attive nel
        campo assicurativo, a fornitori di servizi strettamente necessari allo svolgimento
        dell’attività d’impresa, ovvero a consulenti dell’azienda, ove ciò si riveli necessario per
        ragioni fiscali, amministrative, contrattuali o per esigenze tutelate dalle vigenti
        normative.
      </p>
      <p>
        I Suoi dati personali, ovvero i dati personali di terzi nella sua titolarit&agrave;,
        potranno altres&igrave; essere comunicati a societ&agrave; esterne, individuate di volta in
        volta, cui RECUPERIAMO SRL affidi l&apos;esecuzione di obblighi derivanti
        dall&rsquo;incarico ricevuto alle quali saranno trasmessi i soli dati necessari alle
        attivit&agrave; loro richieste. Tutti i dipendenti, consulenti, interinali e/o ogni altra
        &ldquo;persona fisica&rdquo; che svolgono la propria attivit&agrave; sulla base delle
        istruzioni ricevute da RECUPERIAMO SRL, ai sensi dell&rsquo;art. 29 del GDPR, sono nominati
        &ldquo;Incaricati del trattamento&rdquo; (nel seguito anche &ldquo;Incaricati&rdquo;). Agli
        Incaricati o ai Responsabili, eventualmente designati, RECUPERIAMO SRL impartisce adeguate
        istruzioni operative, con particolare riferimento all&rsquo;adozione ed al rispetto delle
        misure di sicurezza, al fine di poter garantire la riservatezza e la sicurezza dei dati.
        Proprio in riferimento agli aspetti di protezione dei dati personali il Cliente &egrave;
        invitato, ai sensi dell&rsquo;art. 33 del GDPR a segnalare a RECUPERIAMO SRL eventuali
        circostanze o eventi dai quali possa discendere una potenziale &ldquo;violazione dei dati
        personali (data breach)&rdquo; al fine di consentire una immediata valutazione e
        l&rsquo;adozione di eventuali azioni volte a contrastare tale evento inviando una
        comunicazione a RECUPERIAMO SRL ai recapiti nel seguito indicati.
      </p>
      <p>I Dati non saranno diffusi.</p>
      <p>
        Resta fermo l&apos;obbligo di RECUPERIAMO SRL di comunicare i dati ad Autorità Pubbliche su
        specifica richiesta.
      </p>
      <p>
        <b className="mt-20">Trasferimento all&apos;estero</b>
      </p>
      <p>
        Il trasferimento all&apos;estero dei Suoi dati personali pu&ograve; avvenire qualora risulti
        necessario per la gestione dell&rsquo;incarico ricevuto. Per il trattamento delle
        informazioni e dei dati che saranno eventualmente comunicati a questi soggetti saranno
        richiesti gli equivalenti livelli di protezione adottati per il trattamento dei dati
        personali dei propri dipendenti. In ogni caso saranno comunicati i soli dati necessari al
        perseguimento degli scopi previsti e saranno applicati gli strumenti normativi previsti dal
        Capo V del GDPR.
      </p>
      <p>
        <b className="mt-20">Modalità, logiche del trattamento e tempi di conservazione</b>
      </p>
      <p>
        I Suoi dati sono raccolti e registrati in modo lecito e secondo correttezza per le finalità
        sopra indicate nel rispetto dei principi e delle prescrizioni di cui all’art. 5 c 1 del
        GDPR.
      </p>
      <p>
        Il trattamento dei dati personali avviene mediante strumenti manuali, informatici e
        telematici, applicazioni e piattaforme informatiche, con logiche strettamente correlate alle
        finalità stesse e, comunque, in modo da garantirne la sicurezza e la riservatezza.
      </p>
      <p>
        I Dati personali verranno trattati da RECUPERIAMO SRL per tutta la durata dell’incarico ed
        anche successivamente per far valere o tutelare i propri diritti ovvero per finalità
        amministrative e/o per dare esecuzione ad obblighi derivanti dal quadro regolamentare e
        normativo pro tempore applicabile e nel rispetto degli specifici obblighi di legge sulla
        conservazione dei dati.
      </p>
      <p>
        <b className="mt-20">Diritti dell’Interessato</b>
      </p>
      <p>
        In conformit&agrave;, nei limiti ed alle condizioni previste dalla normativa in materia di
        protezione dati personali riguardo l&apos;esercizio dei diritti degli Interessati per quanto
        concerne i trattamenti oggetto della presente Informativa, in qualit&agrave; di Interessato
        il diritto di chiedere conferma che sia o meno in corso un trattamento di suoi dati
        personali, accedere ai dati personali che La riguardano ed in relazione ad essi ha il
        diritto di richiederne la rettifica, la cancellazione, la notifica delle rettifiche e delle
        cancellazioni ai coloro i quali i dati sono stati eventualmente trasmessi dalla nostra
        Organizzazione, la limitazione del trattamento nelle ipotesi previste dalla norma, la
        portabilit&agrave; dei dati personali - da Lei forniti - nei casi indicati dalla norma, di
        opporsi al trattamento dei suoi dati e, specificamente, ha il diritto di opporsi a decisioni
        che lo riguardano se basate unicamente su trattamenti automatizzati dei suoi dati,
        profilazione inclusa. Nel caso in cui ritenga che i trattamenti che La riguardano violino le
        norme del GDPR, ha diritto a proporre reclamo al Garante ai sensi dell&rsquo;art. 77 del
        GDPR.
      </p>
      <p>
        Se intende richiedere ulteriori informazioni sul trattamento dei Suoi dati personali o per
        l&apos;eventuale esercizio dei Suoi diritti, potr&agrave; rivolgersi per iscritto al dott.
        RASPATI MARCO, all&rsquo;indirizzo pec recuperiamo@legalmail.it
      </p>
      <p>
        <b className="mt-20">Titolare e Responsabile del Trattamento</b>
      </p>
      <p>
        Titolare del trattamento, ai sensi dell’art. 4 del GDPR, è RECUPERIAMO SRL, in persona del
        legale rappresentante p.t. Dott. Raspati Marco, via Camillo Bozza, 16 – 06073 Corciano (PG)
        P.IVA: 03535510543 - CF: 03535510543. L’elenco completo dei Responsabili del Trattamento è a
        disposizione presso la sede dell’impresa.
      </p>
    </Modal>
  );
};

export default PrivacyModal;
