import { Col, Row } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileCard } from '../../../components';

import { iProfileData, retrieveCurrentPlan } from '../../../libs/interfaces';
import { RootState } from '../../../store';
import { organizationsGetAsyncAction } from '../../../store/features/organizationsSlice';
import { AgreementMng } from './organizationDonorDeals';

interface iProps {
  organization: iProfileData;
}

const OrganizationNoProfitDeals: React.FC<iProps> = ({ organization }: iProps) => {
  const dispatch = useDispatch();
  const { subscriptions } = useSelector((state: RootState) => state);
  useEffect(() => {
    dispatch(organizationsGetAsyncAction(null));
  }, [dispatch]);

  const currentPlan = retrieveCurrentPlan(subscriptions.userHistoryPlans);

  const profileCard = useMemo(() => {
    return organization ? <ProfileCard profile={organization} currentPlan={currentPlan} /> : null;
  }, [organization, currentPlan]);

  if (!organization) {
    return <div />;
  }
  return (
    <div>
      <Row gutter={16}>
        <Col md={24}>{profileCard}</Col>
      </Row>
      <AgreementMng organization={organization} fromDonor={false} readOnly={false} />
    </div>
  );
};

export default OrganizationNoProfitDeals;
