/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import moment from 'moment';

import { RootState } from '../../store';
import {
  statisticsAsynGetCardsAction,
  statisticsAsynGetColumnsAction,
  statisticsAsynGetPieColumnsAction,
  statisticsAsynGetPieDonorsAction,
  statisticsAsynGetMapAction,
} from '../../store/features/statisticsSlice';
import StatisticsCard from '../../components/statisticsCard/statisticsCard';
import {
  statisticsCardTypesSource,
  initStatisticsFilters,
  statisticsTitles,
} from '../../libs/helpers/statistics.helpers';
import { getRoleType, roleTypes } from '../../libs/helpers/privileges.helpers';
import { iStatisticsFilters } from '../../libs/interfaces';

import StatisticsColumsChart from './statisticsColumnsChart';
import StatisticsPieChart from './statisticsPieChart';
import StatisticsMap from './statisticsMap';

import './statisticsReport.sass';

interface iProps {
  year: number;
  quartal: number;
}

const StatisticsReport: React.FC<iProps> = ({ year, quartal }: iProps) => {
  const dispatch = useDispatch();
  const { user, statistics } = useSelector((state: RootState) => state);
  const [lastFilters, lastFiltersSet] = useState<iStatisticsFilters>(initStatisticsFilters);
  const [month, monthSet] = useState<number>(1);

  const [mapInit, mapInitSet] = useState<boolean>(false);

  const myRole: roleTypes = getRoleType(user.role);
  const myTitles: any = statisticsTitles[myRole];

  const { cards, columns, pieCategories, pieDonors, map } = statistics;

  function callDataColumns(inFilters: iStatisticsFilters | null, inMonth: number | null) {
    if (typeof inMonth === 'number') {
      monthSet(inMonth);
    }
    const f = inFilters || lastFilters;
    const m = typeof inMonth === 'number' ? inMonth : month;
    const outData: iStatisticsFilters = { ...f, month: Boolean(m) };
    dispatch(statisticsAsynGetColumnsAction(outData));
  }

  function hideChat() {
    const css =
      '#hubspot-messages-iframe-container.widget-align-left { display: none !important; }';
    const head = document.head || document.getElementsByTagName('head')[0];
    const style: any = document.createElement('style');

    head.appendChild(style);

    style.type = 'text/css';
    if (style.styleSheet) {
      // This is required for IE8 and below.
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
  }

  function callData(filters: iStatisticsFilters) {
    dispatch(statisticsAsynGetCardsAction(filters));
    dispatch(statisticsAsynGetPieColumnsAction(filters));
    dispatch(statisticsAsynGetPieDonorsAction(filters));
    dispatch(statisticsAsynGetMapAction(filters));
    callDataColumns(filters, null);
    lastFiltersSet(filters);
  }

  function normalizeMonth(num: number) {
    let stringMonth = String(num);
    if (stringMonth.length === 1) {
      stringMonth = `0${stringMonth}`;
    }
    return stringMonth;
  }

  useEffect(() => {
    hideChat();

    const endMonth = quartal * 3;
    const stringEndMonth = normalizeMonth(endMonth);

    const startMonth = endMonth - 2;
    const stringStartMonth = normalizeMonth(startMonth);

    const firstDayLastMonth = `01-${stringEndMonth}-${year}`;
    const endOfMonth = moment(firstDayLastMonth, 'DD-MM-YYYY')
      .clone()
      .endOf('month')
      .format('YYYY-MM-DD');

    callData({
      ...initStatisticsFilters,
      from: `${year}-${stringStartMonth}-01`,
      to: endOfMonth,
    });
  }, []);

  function onMapInit() {
    setTimeout(() => {
      mapInitSet(true);
    }, 4000);
  }
  return (
    <div className="p-40">
      <div className="statisticsReport-colors">
        <Row justify="center" className="pb-30">
          <span className="logo" style={{ width: '65px' }} />
        </Row>
        <div className="statisticsCards break">
          <div className="mt-30">
            {statisticsCardTypesSource.map((s) => (
              <StatisticsCard key={s.value} type={s.value} data={cards} titles={myTitles} />
            ))}
          </div>
        </div>
      </div>

      <div className="break colChart">
        <StatisticsColumsChart data={columns} titles={myTitles} />
      </div>

      <div className="break pies">
        <div>
          <StatisticsPieChart
            data={pieCategories}
            id="pie_categories"
            title={myTitles.pies.categories}
          />
        </div>
        <div>
          <StatisticsPieChart data={pieDonors} id="pie_donors" title={myTitles.pies.user} />
        </div>
      </div>

      <div className="break">
        <h2 className="text-center f-s-18 mb-20">{myTitles.map}</h2>
        <StatisticsMap data={map} onMapInit={onMapInit} />
        {mapInit && <div id="mapInit" />}
      </div>
    </div>
  );
};

export default StatisticsReport;
