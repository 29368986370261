/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  getAllCategoriesApi,
  getMeasuresApi,
  getSpecificationsApi,
} from '../../api/categories.api';
import { enumOffersCategoryType } from '../../libs/enums';
import { iCategoriesApi, iCategory, iMeasures, iSpecification } from '../../libs/interfaces';
// eslint-disable-next-line import/no-named-as-default
import CategoryEdit, { CategoryList } from './categories.comp';
import ModalSpecifications, { SpecificationTag } from './modalSpecifications';
import OfferCategoryTypePicker from './offerCategoryTypePicker';

const Statistics: React.FC = () => {
  const [specifications, setSpecifications] = useState<iSpecification[]>([]);
  const [specsUsed, setSpecsUsed] = useState<string[]>([]);
  const [measures, setMeasures] = useState<iMeasures[]>([]);
  const [offerType, setOfferType] = useState(enumOffersCategoryType.food);
  const [modalSpecs, modalSpecsSet] = useState<boolean>(false);
  const [category, setCategory] = useState<iCategoriesApi | iCategory | undefined>();
  const [categories, setCategories] = useState<iCategoriesApi[]>([]);

  const reloadMeasures = async () => {
    setMeasures(await getMeasuresApi());
  };
  const reloadSpecifications = async () => {
    setSpecifications(await getSpecificationsApi());
  };
  const reloadCategories = async () => {
    const rows = await getAllCategoriesApi();
    setCategories(rows);
    const usds: string[] = [];
    rows.forEach((row) => {
      if (row.tags && row.tags.length > 0) {
        row.tags.forEach((tag) => {
          if (tag && !usds.includes(tag)) {
            usds.push(tag);
          }
        });
      }
    });
    setSpecsUsed(usds);
  };
  useEffect(() => {
    reloadMeasures();
    reloadSpecifications();
    reloadCategories();
  }, []);
  function onCloseModal() {
    modalSpecsSet(false);
  }
  function onNewCategory() {
    setCategory({ type: offerType } as iCategoriesApi);
  }
  if (category && measures) {
    return (
      <CategoryEdit
        category={category}
        measures={measures}
        handleClose={() => {
          setCategory(undefined);
          reloadCategories();
        }}
        specifications={specifications.filter((s) => s.type === offerType)}
      />
    );
  }
  return (
    <div>
      <OfferCategoryTypePicker value={offerType} onChange={(v) => setOfferType(v)} />
      <Row justify="space-between" align="top" className="mt-30">
        <div>
          <strong>Specifiche tipologia {offerType}</strong>
          <div className="mt-15">
            {specifications
              .filter((s) => s.type === offerType)
              .map((spec) => {
                return (
                  <SpecificationTag
                    key={spec.id}
                    specification={spec}
                    closable={false}
                    handleClose={onCloseModal}
                  />
                );
              })}
          </div>
        </div>
        <div>
          <Button className="btn--green" onClick={() => modalSpecsSet(!modalSpecs)}>
            Gestione Specifiche
          </Button>
        </div>
      </Row>
      <hr className="div" />
      <Row justify="space-between" className="mb-20">
        <h2 className=" mb-0">Categorie</h2>
        <div>
          <Button onClick={() => onNewCategory()}>Nuova Categoria</Button>
        </div>
      </Row>
      <CategoryList
        categories={categories.filter((c) => c.type === offerType.toString())}
        specifications={specifications.filter((s) => s.type === offerType)}
        onEditCategory={setCategory}
        onEditSubCategory={(cat) => setCategory(cat)}
        measures={measures}
      />
      <ModalSpecifications
        visibility={modalSpecs}
        onCloseModal={onCloseModal}
        specifications={specifications.filter((s) => s.type === offerType)}
        specsUsed={specsUsed}
        onDataChange={reloadSpecifications}
        type={offerType}
      />
    </div>
  );
  /*
  const [form] = Form.useForm();
  const [formValues, formValuesSet] = useState<any>({ categoryType: enumOffersCategoryType.food });
  return (
    <div>
      <Form form={form} name="categories">
        <Form.Item
          label=""
          name="categoryType"
          rules={[{ required: true }]}
          className="offerNew__type"
        >
          <Radio.Group
            disabled={false}
            defaultValue={formValues.categoryType}
            onChange={(v) => {
              const currentValues = form.getFieldsValue();
              currentValues.categoryType = v.target.value;
              form.setFieldsValue(currentValues);
              formValuesSet(currentValues);
            }}
          >
            {offersCategoryTypeSource.map((i) => (
              <Radio.Button key={i.value} value={i.value}>
                {i.label.toUpperCase()}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>
      </Form>
    </div>
  );
  */
};

export default Statistics;
