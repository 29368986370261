/* eslint-disable import/prefer-default-export */
export enum enumRoles {
  donor = 'donor',
  donorSub = 'donor-sub',
  noprofit = 'noprofit1',
  noprofitSub = 'noprofit1-sub',
  noprofitSecond = 'noprofit2',
  noprofitSecondSub = 'noprofit2-sub',
  admin = 'admin',
  pa = 'pa',
}

export enum enumViews {
  available = 'available',
  booked = 'booked',
  mydonation = 'mydonation',
  reservations = 'reservations',
  all = 'all',
}

export enum enumBadges {
  co2 = 'co2',
  red = 'red',
  green = 'green',
}

export enum enumOrganizationsTypes {
  companies = 'donors',
  noprofit = 'noprofits',
  pa = 'pas',
}
