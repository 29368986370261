import React from 'react';
import { Form, Input, Row, Col, Tooltip } from 'antd';

import { iProfileFormData } from '../../libs/interfaces/index';
// import FormPhone from '../../components/forms/formPhone';
// import FormZip from '../../components/forms/formZip';
import { FormPhone, FormZip, FormProvince, FormCity } from '../../components/forms';
import Config from '../../config/Config';
import Images from '../../assets/images';
import './style.sass';

interface iProps {
  data: iProfileFormData;
  copyData: () => void;
}

const ProfileEditOperational: React.FC<iProps> = ({ data, copyData }: iProps) => {
  return (
    <>
      <Row justify="space-between">
        <h2 className="h1 with-tooltip">
          Dati operativi
          <Tooltip title="Altro testo di prova per tooltip">
            <img className="tooltip__info" src={Images.icoinfo.src} alt={Images.icoinfo.alt} />
          </Tooltip>
        </h2>

        <button type="button" className="c-green" onClick={copyData}>
          Copia dati amministrativi
        </button>
      </Row>
      <Row gutter={16}>
        <Col sm={12}>
          <Form.Item
            label="Indirizzo"
            name="operationalStreet"
            initialValue={data.operationalStreet}
            rules={[{ required: true }]}
          >
            <Input placeholder="Inserisci l'indirizzo" />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <FormCity initialValue={data.operationalCity} name="operationalCity" />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col sm={12}>
          <Row gutter={16}>
            <Col span={12}>
              <FormProvince initialValue={data.operationalState} name="operationalState" />
            </Col>
            <Col span={12}>
              <FormZip initialValue={data.operationalZip} name="operationalZip" />
            </Col>
          </Row>
        </Col>
        <Col sm={12}>
          <FormPhone
            name="operationalPhone"
            initialValue={data.operationalPhone}
            label="Numero di telefono"
          />
        </Col>
      </Row>
    </>
  );
};

export default ProfileEditOperational;
