import { Dispatch } from '@reduxjs/toolkit';

import Config from '../config/Config';
import { Api } from '../libs/callApi';

import { getProfileImage } from '../libs/helpers/profile.helpers';
import { decodeCredits } from '../libs/helpers/offers.helpers';
import { iProfileData, iProfileContactData, iProfileSubscription } from '../libs/interfaces';

const api = new Api(Config.apiBaseurl);

export interface igetProfileReturn {
  data: iProfileData;
  complete: boolean;
  withAgreements: boolean;
  account: any;
  subscription: iProfileSubscription;
}

export const getProfileByIdApi = async (id: string): Promise<igetProfileReturn> => {
  const call = await api.makeCall({
    path: Config.apis.profileById.replace(':id', id),
    method: 'GET',
    autorized: true,
  });

  const {
    profile,
    subAccounts,
    profileComplete,
    withAgreements,
    account,
    subscription,
  } = call.body;

  const emptyData: iProfileContactData = {
    city: '',
    phone: '',
    state: '',
    street: '',
    zip: '',
  };

  const data = {
    name: profile.name,
    phone: profile.phone,
    sdiCode: profile.sdiCode,
    taxCode: profile.taxCode,
    vatNumber: profile.vatNumber,
    subAccounts: subAccounts || [],
    operationalData: profile.operationalData || emptyData,
    administrativeData: profile.administrativeData || emptyData,
    image: getProfileImage(profile.image),
    credits: decodeCredits(Number(profile.credits)) || 0,
    lat: profile.lat,
    lng: profile.lng,
  };

  return { data, complete: profileComplete, withAgreements, account, subscription };
};

export const getProfileApi = async (): Promise<igetProfileReturn> => {
  const call = await api.makeCall({
    path: Config.apis.profile,
    method: 'GET',
    autorized: true,
  });

  const {
    profile,
    subAccounts,
    profileComplete,
    withAgreements,
    account,
    subscription,
  } = call.body;

  const emptyData: iProfileContactData = {
    city: '',
    phone: '',
    state: '',
    street: '',
    zip: '',
  };

  const data = {
    name: profile.name,
    phone: profile.phone,
    sdiCode: profile.sdiCode,
    taxCode: profile.taxCode,
    vatNumber: profile.vatNumber,
    subAccounts: subAccounts || [],
    operationalData: profile.operationalData || emptyData,
    administrativeData: profile.administrativeData || emptyData,
    image: getProfileImage(profile.image),
    credits: decodeCredits(Number(profile.credits)) || 0,
    lat: profile.lat,
    lng: profile.lng,
    enabled2fa: account.enabled2fa,
    secret2fa: '',
  };

  return { data, complete: profileComplete, withAgreements, account, subscription };
};

export const updateProfileApi = async (body: iProfileData): Promise<iProfileData> => {
  const call = await api.makeCall({
    path: Config.apis.profile,
    method: 'PATCH',
    body,
    autorized: true,
  });
  return call;
};
export const updateProfileByIdApi = async (id: string, body: any): Promise<iProfileData> => {
  const call = await api.makeCall({
    path: Config.apis.profileById.replace(':id', id),
    method: 'PATCH',
    body,
    autorized: true,
  });
  return call;
};
export const updateCreditsByIdApi = async (id: string, body: any): Promise<iProfileData> => {
  const call = await api.makeCall({
    path: Config.apis.creditsById.replace(':id', id),
    method: 'PATCH',
    body,
    autorized: true,
  });
  return call;
};
export const createProfilePAApi = async (body: any): Promise<string> => {
  const call = await api.makeCall({
    path: Config.apis.profileCreatePA,
    method: 'POST',
    body,
    autorized: true,
  });
  return call.body;
};

export const setPrivacyApi = async (): Promise<any> => {
  const call = await api.makeCall({
    path: Config.apis.privacy,
    method: 'POST',
    autorized: true,
  });
  return call;
};
