/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import { asyncThunkErrorHelper } from '../../libs/helpers/commons';
import {
  iStatisticsState,
  iStatisticsCards,
  iStatisticsFilters,
  iStatisticsColumns,
  iStatisticsReportItem,
  iTransaction,
} from '../../libs/interfaces';
import {
  getStatisticsCardsApi,
  getStatisticsColumnsApi,
  getStatisticsPieCategoriesApi,
  getStatisticsPieDonorsApi,
  getStatisticsMapApi,
  getStatisticsTransactionsApi,
} from '../../api/statistics.api';

import { mapTransactionsData } from '../../libs/helpers/statistics.helpers';

const initialState: iStatisticsState = {
  cards: {
    social_impact: 0,
    social_impact_meal: 0,
    enviroment_impact: 0,
    economic_impact: 0,
  },
  columns: {
    date: [],
    don_tot: [],
    ven_tot: [],
  },
  pieCategories: [],
  pieDonors: [],
  map: [],
  transactions: [],
};

const slice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setCardsAction: (state, action: PayloadAction<iStatisticsCards>) => {
      state.cards = action.payload;
    },
    setColumnsAction: (state, action: PayloadAction<iStatisticsColumns>) => {
      state.columns = action.payload;
    },
    setPieCategoriesAction: (state, action: PayloadAction<iStatisticsReportItem[]>) => {
      state.pieCategories = action.payload;
    },
    setPieDonorsAction: (state, action: PayloadAction<iStatisticsReportItem[]>) => {
      state.pieDonors = action.payload;
    },
    setMapAction: (state, action: PayloadAction<iStatisticsReportItem[]>) => {
      state.map = action.payload;
    },
    setTransactionsAction: (state, action: PayloadAction<iTransaction[]>) => {
      state.transactions = action.payload;
    },
    resetTransactionsAction: (state) => {
      state.transactions = [];
    },
    resetDashboardAction: () => {
      return initialState;
    },
  },
});

export const {
  setCardsAction,
  setColumnsAction,
  setPieCategoriesAction,
  setPieDonorsAction,
  setMapAction,
  setTransactionsAction,
  resetTransactionsAction,
  resetDashboardAction,
} = slice.actions;
export default slice.reducer;

export const statisticsAsynGetCardsAction = createAsyncThunk(
  'statistics/statisticsAsynGetCardsAction',
  async (filters: iStatisticsFilters, { rejectWithValue, dispatch }) => {
    try {
      const response: iStatisticsCards = await getStatisticsCardsApi(filters);
      return dispatch(setCardsAction(response));
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const statisticsAsynGetColumnsAction = createAsyncThunk(
  'statistics/statisticsAsynGetColumnsAction',
  async (filters: iStatisticsFilters, { rejectWithValue, dispatch }) => {
    try {
      const response: iStatisticsColumns = await getStatisticsColumnsApi(filters);
      return dispatch(setColumnsAction(response));
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const statisticsAsynGetPieColumnsAction = createAsyncThunk(
  'statistics/statisticsAsynGetPieColumnsAction',
  async (filters: iStatisticsFilters, { rejectWithValue, dispatch }) => {
    try {
      const response: iStatisticsReportItem[] = await getStatisticsPieCategoriesApi(filters);
      return dispatch(setPieCategoriesAction(response));
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const statisticsAsynGetPieDonorsAction = createAsyncThunk(
  'statistics/statisticsAsynGetPieDonorsAction',
  async (filters: iStatisticsFilters, { rejectWithValue, dispatch }) => {
    try {
      const response: iStatisticsReportItem[] = await getStatisticsPieDonorsApi(filters);
      return dispatch(setPieDonorsAction(response));
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const statisticsAsynGetMapAction = createAsyncThunk(
  'statistics/statisticsAsynGetMapAction',
  async (filters: iStatisticsFilters, { rejectWithValue, dispatch }) => {
    try {
      const response: iStatisticsReportItem[] = await getStatisticsMapApi(filters);
      return dispatch(setMapAction(response));
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const statisticsAsynGetTransactionsAction = createAsyncThunk(
  'statistics/statisticsAsynGetMapAction',
  async (filters: iStatisticsFilters, { rejectWithValue, dispatch }) => {
    try {
      const response: iTransaction[] = await getStatisticsTransactionsApi(filters);

      const transactions: iTransaction[] = mapTransactionsData(response);
      return dispatch(setTransactionsAction(transactions));
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);
