import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { acceptOrderApi, createOrderApi, rejectOrderApi } from '../../api/orders.api';
import { asyncThunkErrorHelper } from '../../libs/helpers/commons';
import { offerDetailAsyncGetAction, offersAsynGetAction } from './offersSlice';
import { profileAsynGetAction } from './profileSlice';

// eslint-disable-next-line import/prefer-default-export
export const ordersAsyncCreateAction = createAsyncThunk(
  'orders/ordersAsyncCreateAction',
  async (payload: any, { rejectWithValue, dispatch }) => {
    const { data, filters } = payload;
    try {
      const response: any = await createOrderApi(data);
      if (response.payload?.ok) {
        dispatch(offerDetailAsyncGetAction(response.body.offer.id));
        dispatch(offersAsynGetAction(null));
        dispatch(profileAsynGetAction(null));
        toast.success('Prenotazione avvenuta');
      }
      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const ordersAsyncAcceptAction = createAsyncThunk(
  'orders/ordersAsyncAcceptAction',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await acceptOrderApi(id);
      dispatch(offersAsynGetAction(null));
      toast.success('Prenotazione confermata');
      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const ordersAsyncRejectAction = createAsyncThunk(
  'orders/ordersAsyncRejectAction',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await rejectOrderApi(id);
      dispatch(offersAsynGetAction(null));
      toast.error('Prenotazione rifiutata');
      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);
