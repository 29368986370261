/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { iCategoriesApi, iSpecification } from '../../libs/interfaces';
import {
  offersTypeSource,
  offersCategoryTypeSource,
  offersDeliverySource,
  offersStatusSource,
} from '../../libs/helpers/offers.helpers';

import './style.sass';

enum orderBy {
  priceAsc = 'price-asc',
  priceDesc = 'price-desc',
}

export interface iOffersFilters {
  orderBy: orderBy;
  filters: any;
}

interface iProps {
  categories: any[];
  filters: any;
  onTagClicked: (key: string, value: string) => void;
  subCategories: any[] | undefined;
  donors: any[];
  specifications: Record<string, iSpecification>;
}

const OffersFiltersTags: React.FC<iProps> = ({
  categories,
  filters,
  onTagClicked,
  subCategories,
  donors,
  specifications,
}: iProps) => {
  const keyFilters = filters ? Object.keys(filters) : [];

  function getTagLabel(key: string, value: any) {
    let valueTxt = '';
    let item = null;
    switch (key) {
      case 'offerType':
        item = offersTypeSource.find((i: any) => i.value === value);
        valueTxt = item?.label || '';
        break;
      case 'status':
        item = offersStatusSource.find((i: any) => i.value === value);
        valueTxt = item?.label || '';
        break;
      case 'categoryType':
        item = offersCategoryTypeSource.find((i: any) => i.value === value);
        valueTxt = item?.label || '';
        break;
      case 'categoryId':
        if (value === 'none') {
          valueTxt = 'Tutte le categorie';
          break;
        }
        valueTxt = categories.find((c: iCategoriesApi) => c.id === value).name;
        break;
      case 'subCategoryId':
        valueTxt = subCategories?.find((c: iCategoriesApi) => c.id === value).name;
        break;
      case 'deliveryType':
        item = offersDeliverySource.find((i: any) => i.value === value);
        valueTxt = item?.label || '';
        break;
      case 'donorId':
        item = donors.find((i: any) => i.id === value);
        valueTxt = item?.name;
        break;
      case 'tags':
        item = specifications[value];
        valueTxt = item?.name;
        break;
      case 'maxKm':
        valueTxt = `${value} Km`;
        break;
      default:
        valueTxt = '';
    }
    return valueTxt;
  }

  const setFiltersTag = useCallback(() => {
    const tags: any[] = [];
    keyFilters.forEach((k: string) => {
      const keyValues = filters[k];
      if (Array.isArray(keyValues)) {
        keyValues.forEach((item: string) => {
          if (item) {
            tags.push({ key: k, label: getTagLabel(k, item), value: item });
          }
        });
      } else if (keyValues) {
        tags.push({ key: k, label: getTagLabel(k, keyValues), value: keyValues });
      }
    });

    const tagsElements = tags.map((tag) => {
      if (!tag.value) {
        return null;
      }
      return (
        <Button
          icon={<CloseOutlined />}
          onClick={() => onTagClicked(tag.key, tag.value)}
          className="mr-10 filters__tag"
          key={tag.value}
          size="small"
        >
          {tag.label}
        </Button>
      );
    });

    return tagsElements;
  }, [filters]);

  return <div>{keyFilters.length > 0 && setFiltersTag()}</div>;
};

export default OffersFiltersTags;
