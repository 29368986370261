export enum enumSubscriptions {
  donorFree = 'donorFree',
  donorReport = 'donorReport',
  donorAdvanced = 'donorAdvanced',
  donorCustom = 'donorCustom',
  noProfitFree = 'noProfitFree',
  noProfitOpportunity = 'noProfitOpportunity',
  noProfitAbsolute = 'noProfitAbsolute',
  noProfitCustom = 'noProfitCustom',
}

export enum enumSubDonor {
  free = 'free',
  report = 'report',
  advanced = 'advanced',
  custom = 'custom',
}

export enum enumSubNoProfit {
  free = 'free',
  opportunity = 'opportunity',
  absolute = 'absolute',
  custom = 'custom',
}

export enum enumSubCurrentStatus {
  now = 'now',
  future = 'future',
  past = 'past',
}
