/* eslint-disable import/prefer-default-export */
import { createAction } from '@reduxjs/toolkit';

export const userImpersonateEmailAction = createAction(
  'user/userImpersonateEmail',
  function prepare(text: string) {
    return {
      payload: text,
    };
  },
);
