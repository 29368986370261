/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Modal, Col } from 'antd';
import { Link } from 'react-router-dom';

import { RootState } from '../../store';
import { ordersAsyncAcceptAction, ordersAsyncRejectAction } from '../../store/features/ordersSlice';
import {
  offersAsyncGetCategoriesUsedAction,
  offersAsynGetAction,
  offerSetDetailAction,
  offersAsyncDeleteAction,
  offerSetCurrentFiltersAction,
  offerSetCurrentViewAction,
  offersAsyncGetDonorsAction,
} from '../../store/features/offersSlice';
import { enumViews, enumRoutes, enumSubscriptions, enumOrdersStatus } from '../../libs/enums';
import { isNoProfit } from '../../libs/helpers/privileges.helpers';
import { iOffer, iSpecification } from '../../libs/interfaces';
import {
  OffersButtons,
  OffersCard,
  OfferDetails,
  OffersFilters,
  SubscriptionCard,
} from '../../components';
import { iOffersFilters } from '../../components/offersFilters/offersFilter';
import Images from '../../assets/images';
import './style.sass';
import { getSpecificationsMapApi } from '../../api/categories.api';

interface iProps {
  match: any;
}

const OffersScreen: React.FC<iProps> = ({ match }: iProps) => {
  const dispatch = useDispatch();
  const { user, profile } = useSelector((state: RootState) => state);
  const { params } = match;
  const [modalVisibile, modalVisibileSet] = useState<boolean>(false);
  const [offerId, setOfferId] = useState<string>('');
  const [orderId, setOrderId] = useState<string>('');
  const [dropdownVisibility, setDropdownVisibility] = useState<boolean>(false);
  const [filters, setFilters] = useState<string>('');
  const [offerDetail, setOfferDetail] = useState<iOffer | undefined>(undefined);
  const [showModalPlans, showModalPlansSet] = useState<boolean>(false);
  const [offersSorted, offersSortedSet] = useState<iOffer[]>([]);
  const [specifications, setSpecifications] = useState<Record<string, iSpecification>>({});

  const donors = useSelector((state: RootState) => state.offers.donors);
  const categories = useSelector((state: RootState) => state.offers.categoriesUsed);
  const offers: iOffer[] = useSelector((state: RootState) => state.offers.list);

  const kgToUse = profile.subscription
    ? profile.subscription.kg_month - profile.subscription.kg_order
    : 0;

  const { lat, lng } = profile.data;

  const userNoProfit = isNoProfit(user.role);

  const view: enumViews = useMemo(() => {
    return params.offersView as enumViews;
  }, [params]);

  useEffect(() => {
    if (userNoProfit) {
      dispatch(offersAsyncGetDonorsAction(null));
    }
  }, []);

  useEffect(() => {
    dispatch(offersAsyncGetCategoriesUsedAction(null));
    dispatch(offerSetCurrentViewAction(view));
    dispatch(offersAsynGetAction(null));
    getSpecificationsMapApi().then(setSpecifications);

    return () => {
      dispatch(offerSetCurrentViewAction(null));
    };
  }, []);

  useEffect(() => {
    if (!offers[0]?.order || view !== enumViews.reservations) {
      offersSortedSet(offers);
    } else {
      const openArr = offers.filter((i: iOffer) => i.order.status === enumOrdersStatus.open);
      const confirmedArr = offers.filter(
        (i: iOffer) => i.order.status === enumOrdersStatus.accepted,
      );
      const refusedArr = offers.filter((i: iOffer) => i.order.status === enumOrdersStatus.rejected);
      const sortedArr: iOffer[] = [...openArr, ...confirmedArr, ...refusedArr];
      offersSortedSet(sortedArr);
    }
  }, [offers]);

  useEffect(() => {
    if (!offerDetail) {
      modalVisibileSet(false);
    }
  }, [offerDetail]);

  useEffect(() => {
    if (offerId) {
      const foundDetailOffer = offersSorted.find((o: iOffer) => o.id === offerId);
      setOfferDetail(foundDetailOffer);
    }

    if (orderId) {
      const foundDetailOrder = offersSorted.find((o: iOffer) => o.order?.id === orderId);
      setOfferDetail(foundDetailOrder);
    }
  }, [offerId, orderId, offersSorted]);

  // Crea la chiamata per popolare la lista delle offerte. La esegue il component dei filtri.
  function onApplyFilters(values: iOffersFilters) {
    const stringParams = `orderBy=${values.orderBy}&filter=${JSON.stringify(values.filters)}`;
    setFilters(stringParams);
    dispatch(offerSetCurrentFiltersAction(stringParams));
    // TODO valutare se farla eseguire alla action dei filtri
    // Chiamata per caricare la lista offerta per la vista specifica
    dispatch(offersAsynGetAction(null));
  }

  function onCloseModal() {
    modalVisibileSet(false);
    dispatch(offerSetDetailAction(null));
    setOfferId('');
  }

  function onDelete(id: string) {
    const payload = { offerId: id, filters };
    dispatch(offersAsyncDeleteAction(payload));
  }

  function showDetails(offer: iOffer) {
    if (!dropdownVisibility) {
      modalVisibileSet(true);
      if (offer.order) {
        setOrderId(offer.order.id);
      } else {
        setOfferId(offer.id);
      }
    }
  }

  function onCardClicked(offer: iOffer) {
    // TODO per riabilitare limite decommentare ciclo if
    // if (userNoProfit && kgToUse <= 0 && view === enumViews.available) {
    //   showModalPlansSet(true);
    // } else {
    //   showDetails(offer);
    // }

    showDetails(offer);
  }

  function onCloseModalSubscriptions() {
    showModalPlansSet(false);
  }

  const offersList = useMemo(() => {
    return offersSorted.map((offer: iOffer) => {
      if (!offer.category) {
        return null;
      }
      return (
        <Col
          md={12}
          lg={8}
          onClick={() => {
            onCardClicked(offer);
          }}
          aria-hidden="true"
          key={offer.order ? offer.order.id : offer.id}
        >
          <OffersCard
            offer={offer}
            specifications={specifications}
            view={view}
            user={user}
            setDropdownVisibility={setDropdownVisibility}
            onDelete={onDelete}
            latLng={{ lat, lng }}
          />
        </Col>
      );
    });
  }, [offersSorted]);

  return (
    <div>
      <OffersFilters
        offersButtons={() => <OffersButtons user={user} profile={profile} view={view} />}
        view={view}
        onApplyFilters={onApplyFilters}
        specifications={specifications}
        categories={categories}
        user={user}
        donors={donors}
      />

      <Row className="offers" gutter={16}>
        {offers && offersList}
      </Row>

      <Modal
        className="offer-modal"
        title="Basic Modal"
        visible={modalVisibile}
        onCancel={onCloseModal}
        footer={() => null}
        modalRender={() => (
          <div className="ant-modal-content">
            <button type="button" className="modal__close" onClick={onCloseModal}>
              <img src={Images.icoCloseDark.src} alt={Images.icoCloseDark.alt} />
            </button>
            <div className="ant-modal-body">
              {offerDetail && (
                <OfferDetails
                  view={view}
                  user={user}
                  specifications={specifications}
                  kgToUse={kgToUse}
                  onLimitExceeded={() => showModalPlansSet(true)}
                  data={offerDetail}
                  filters={filters}
                  onDelete={onDelete}
                  onOfferConfirm={(id: string) => dispatch(ordersAsyncAcceptAction(id))}
                  onOfferReject={(id: string) => dispatch(ordersAsyncRejectAction(id))}
                />
              )}
            </div>
          </div>
        )}
      />
      <Modal
        visible={showModalPlans}
        width={815}
        onCancel={onCloseModalSubscriptions}
        modalRender={() => (
          <div className="modalContract ant-modal-content text-center">
            <button type="button" className="modal__close" onClick={onCloseModalSubscriptions}>
              <img src={Images.icoCloseDark.src} alt={Images.icoCloseDark.alt} />
            </button>
            <p className="mb-30 f-s-26">
              Hai raggiunto la soglia massima consentita per il tuo piano Free
            </p>
            <Row gutter={16}>
              <Col lg={12}>
                <SubscriptionCard
                  month
                  onContractSelected={() => null}
                  onMoreInfo={() => null}
                  type={enumSubscriptions.noProfitOpportunity}
                />
              </Col>
              <Col lg={12}>
                <SubscriptionCard
                  month
                  onContractSelected={() => null}
                  onMoreInfo={() => null}
                  type={enumSubscriptions.noProfitAbsolute}
                />
              </Col>
            </Row>
            <p>
              <Link className="ant-btn" to={enumRoutes.subscriptions}>
                SCOPRI I PIANI DISPONIBILI
              </Link>
            </p>
          </div>
        )}
      />
    </div>
  );
};

export default OffersScreen;
