/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enumRoles } from '../../libs/enums';
import { canEditProfile } from '../../libs/helpers/privileges.helpers';
import { formInitialProfileData, mapProfileData } from '../../libs/helpers/profile.helpers';
import { iProfileFormData } from '../../libs/interfaces/index';
import { RootState } from '../../store';
import { profileAsynGetAction } from '../../store/features/profileSlice';
import ProfileDetail from './profile.detail';
import './style.sass';

const ProfileScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { profile, user } = useSelector((state: RootState) => state);
  const [data, formDataSet] = useState<iProfileFormData>(formInitialProfileData);

  const showGdprAlert =
    profile.complete &&
    !profile.gdpr &&
    (user.role === enumRoles.donorSub || user.role === enumRoles.noprofitSub);

  useEffect(() => {
    dispatch(profileAsynGetAction(null));
  }, []);

  useEffect(() => {
    const newData: iProfileFormData = mapProfileData(profile.data);
    formDataSet(newData);
  }, [profile.data]);

  return (
    <ProfileDetail data={data} canEdit={canEditProfile(user.role)} showGdprAlert={showGdprAlert} />
  );
};

export default ProfileScreen;
