import React from 'react';

import Config from '../../config/Config';
import packageJson from '../../../package.json';
import './style.scss';

const environment = Config.environment.env;
const labelEnvironment = Config.environment.label;

interface iProps {
  label?: string | undefined;
  env?: string | undefined;
}

const EnvRibbon: React.FC<iProps> = ({ label, env }: iProps) => {
  const cssEnv = label || environment;
  const text = env || labelEnvironment;

  if (environment === 'prod') return null;
  return <div className={`env-ribbon ${cssEnv}`}>{`${text} ${packageJson.version}`}</div>;
};

EnvRibbon.defaultProps = {
  label: undefined,
  env: undefined,
};

export default EnvRibbon;
