/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';

import { RootState } from '../../store';
import {
  iCategoriesApi,
  iSpecification,
  iAgreement,
  iVisibilityNoProfits,
} from '../../libs/interfaces';
import { enumOffersOfferType, enumOffersExpiryType, enumOffersVisibility } from '../../libs/enums';
import {
  offersAsyncGetCategoriesAction,
  offersAsyncGetMeasuresAction,
  offersAsyncCreateAction,
  offersAsyncEditAction,
  offerDetailAsyncGetAction,
  offerSetDetailAction,
  offersAsyncGetCategoriesUsedAction,
} from '../../store/features/offersSlice';
import {
  findMainCategory,
  offersEmptyApi,
  mapDetailToOffersEmptyApi,
  encodeCredits,
} from '../../libs/helpers/offers.helpers';

import OfferNewMain from './offerNewMain';
import OfferNewTypologies from './offerNewTypologies';
import OfferNewQuantity from './offerNewQuantity';
import OfferNewOthers from './offerNewOthers';
import './style.sass';
import { getSpecificationsApi, getSpecificationsMapApi } from '../../api/categories.api';
import { profileAsynGetAction } from '../../store/features/profileSlice';
import {
  getAgreementsForDonorApi,
  getAgreementsForDonorWithDetails,
} from '../../api/agreements.api';

const validateMessages = {
  required: 'Campo obbligatorio',
};

interface iProps {
  match: any;
  history: any;
}

const OfferNewScreen: React.FC<iProps> = ({ match, history }: iProps) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [shipment, shipmentSet] = useState<boolean>(false);
  const [offerTypeSale, offerTypeSaleSet] = useState<boolean>(false);
  const [mainCategory, setMainCategory] = useState<iCategoriesApi[]>([]);
  const [formValues, formValuesSet] = useState<any>(form.getFieldsValue());
  const [specifications, setSpecifications] = useState<Record<string, iSpecification>>({});
  const [agreements, setAgreements] = useState<iAgreement[]>([]);
  const [agreementsForDonorWithDetails, setAgreementsForDonorWithDetails] = useState<
    iVisibilityNoProfits[]
  >([]);

  const isEditMode = Boolean(match.params?.offerId);

  const offers = useSelector((state: RootState) => state.offers);
  const { categories, measures, detail, categoriesUsed } = offers;
  const { profile } = useSelector((state: RootState) => state);
  const { withAgreements, account } = profile;
  const wasNotPublic =
    detail &&
    (detail.visibility === enumOffersVisibility.private ||
      detail.visibility === enumOffersVisibility.timed);
  useEffect(() => {
    if (isEditMode) {
      dispatch(offersAsyncGetCategoriesUsedAction(null));
    } else {
      dispatch(offersAsyncGetCategoriesAction(null));
    }
    dispatch(offersAsyncGetMeasuresAction(null));
    getSpecificationsMapApi().then(setSpecifications);
    if (isEditMode) {
      dispatch(offerSetDetailAction(null));
      dispatch(offerDetailAsyncGetAction(match.params.offerId));
    }
    dispatch(profileAsynGetAction(null));
    return () => formValuesSet(offersEmptyApi);
  }, []);

  function renderMainCategory(type = null) {
    const foundedMainCategory = findMainCategory(
      isEditMode ? categoriesUsed : categories,
      type || formValues.categoryType,
    );
    if (foundedMainCategory) {
      setMainCategory(foundedMainCategory);
    }
  }

  function setControls(values: any) {
    shipmentSet(values.deliveryType.includes('shipment'));
    offerTypeSaleSet(values.type === enumOffersOfferType.sale);
  }

  useEffect(() => {
    if (detail && isEditMode) {
      renderMainCategory(detail.categoryType);
      setControls(detail);
      const newValues = mapDetailToOffersEmptyApi(detail);
      form.setFieldsValue(newValues);
      formValuesSet(newValues);
    }
  }, [detail]);

  useEffect(() => {
    renderMainCategory();
  }, [formValues.categoryType]);

  function onChange() {
    const values = form.getFieldsValue();
    setControls(values);
    formValuesSet(values);
  }

  function onCreate() {
    const data = form.getFieldsValue();
    const { endDate, expiryDate } = data;
    data.endDate = endDate ? endDate.toISOString() : null;
    data.expiryDate = expiryDate ? expiryDate.toISOString() : null;
    if (data.unitSize) {
      data.unitSize = data.unitSize.split('-').map((stringNumber: string) => Number(stringNumber));
    } else {
      data.unitSize = null;
    }
    if (data.unitCost) {
      data.unitCost = encodeCredits(data.unitCost);
    }
    if (data.shipmentCost) {
      data.shipmentCost = encodeCredits(data.shipmentCost);
    } else {
      data.shipmentCost = 0;
    }

    if (isEditMode) {
      data.id = match.params.offerId;
      dispatch(offersAsyncEditAction(data));
    } else {
      dispatch(offersAsyncCreateAction(data));
    }
  }

  const reloadAgreements = useCallback(async () => {
    if (account.id) {
      setAgreements(await getAgreementsForDonorApi(account.id));
      setAgreementsForDonorWithDetails(await getAgreementsForDonorWithDetails(account.id));
    }
  }, [account.id]);
  useEffect(() => {
    reloadAgreements();
  }, [reloadAgreements]);

  return (
    <div className="container-small offerNew">
      <Button type="text" className="mb-20" onClick={() => history.goBack()}>
        <ArrowLeftOutlined />
        Indietro
      </Button>
      <h1 className="mb-30">{isEditMode ? 'Modifica offerta' : 'Nuova offerta'}</h1>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={offersEmptyApi}
        layout="vertical"
        name="new-offer"
        onFinish={onCreate}
        validateMessages={validateMessages}
        onValuesChange={onChange}
      >
        <OfferNewMain
          measures={measures}
          specifications={specifications}
          mainCategories={mainCategory}
          form={form}
          isEditMode={isEditMode}
        />

        <hr className="div" />

        <OfferNewTypologies
          offerTypeSale={offerTypeSale}
          offerTypeCategory={formValues.categoryType as string}
          isEditMode={isEditMode}
          form={form}
        />

        <hr className="div" />

        <OfferNewQuantity formValues={formValues} isEditMode={isEditMode} />

        <hr className="div" />

        <OfferNewOthers
          shipment={shipment}
          isEditMode={isEditMode}
          withAgreements={withAgreements}
          wasNotPublic={wasNotPublic}
          agreementsForDonorWithDetails={agreementsForDonorWithDetails}
          form={form}
        />
      </Form>
    </div>
  );
};

export default OfferNewScreen;
