import Images from '../../assets/images';
import { enumRoutes } from '../../libs/enums';

export interface iNavNode {
  label: string;
  path: string;
  children?: iNavNode[];
  icon?: string;
}

export default [
  {
    label: 'Offerte',
    icon: Images.icoOfferte.src,
    path: enumRoutes.offers,
    children: [
      {
        label: 'Tutte le offerte',
        path: enumRoutes.offersAll,
      },
      {
        label: 'Offerte disponibili',
        path: enumRoutes.offersAvailable,
      },
      {
        label: 'Prenotate',
        path: enumRoutes.offersBooked,
      },
      // {
      //   label: 'Le mie donazioni',
      //   path: enumRoutes.offersMyDonation,
      // },
      {
        label: 'Prenotazioni ricevute',
        path: enumRoutes.offersReservations,
      },
    ],
  },
  {
    label: 'Prenotazioni',
    icon: Images.icoCalendarEmpty.src,
    path: '/reservations',
  },
  {
    label: 'Organizzazioni',
    icon: Images.icoUsers.src,
    path: enumRoutes.organizations,
    children: [
      {
        label: 'Aziende',
        path: enumRoutes.organizationsDonors,
      },
      {
        label: 'Non Profit',
        path: enumRoutes.organizationsNoProfit,
      },
      {
        label: 'P.A.',
        path: enumRoutes.organizationsPa,
      },
    ],
  },
  {
    label: 'Statistiche',
    icon: Images.icoGraph.src,
    path: enumRoutes.statistics,
    children: [
      {
        label: 'Dashboard',
        path: enumRoutes.statisticsDashboard,
      },
      {
        label: 'Elenco transazioni',
        path: enumRoutes.statisticsTransactions,
      },
    ],
  },
  {
    label: 'Categorie',
    icon: Images.icoCopy.src,
    path: enumRoutes.categories,
  },
];
