interface iService {
  [x: string]: any;
}

const services: iService = {};

export const getService = (serviceName: string): iService => services[serviceName];

export const registerService = (serviceName: string, service: iService): any => {
  services[serviceName] = service;
};
