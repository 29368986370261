import React, { useEffect, useCallback } from 'react';
import { Form, Button, Row, Col, Select, Radio, DatePicker } from 'antd';
import { capitalize } from 'lodash';
import moment from 'moment';

import { iSubPlan, iSubSetData, iSubRequestResponse } from '../../libs/interfaces';
import './style.sass';

const { Option } = Select;

const validateMessages = {
  required: 'Campo obbligatorio',
  types: {
    email: 'Il campo non è un indirizzo email valido',
  },
};

interface iProps {
  optionsPlans: iSubPlan[];
  organizationId: string;
  onSendData: (data: iSubSetData) => void;
  labelButton?: string;
  startPlan?: iSubRequestResponse | null;
}

const SubscriptionForm: React.FC<iProps> = ({
  onSendData,
  optionsPlans,
  organizationId,
  startPlan,
  labelButton,
}: iProps) => {
  const [form] = Form.useForm();

  const setFormValue = useCallback(() => {
    function getOptionPlanActive() {
      if (startPlan) {
        if (startPlan.subscription) {
          return startPlan.subscription;
        }
        if (startPlan.subscription_req && startPlan.subscription_req !== 'custom') {
          return startPlan.subscription_req;
        }
      }
      return '';
    }
    return {
      account_id: organizationId,
      date_from: startPlan?.date_from ? moment(startPlan.date_from) : '',
      payment: startPlan ? startPlan.payment : '',
      subscription: getOptionPlanActive(),
      id: startPlan ? startPlan.id : '',
      subscription_req: startPlan ? startPlan.subscription_req : '',
    };
  }, [startPlan, organizationId]);

  useEffect(() => {
    if (startPlan) {
      const newValues = setFormValue();
      form.setFieldsValue(newValues);
    }
  }, [startPlan, form, setFormValue]);

  const initForm: iSubSetData = setFormValue();

  function onSend() {
    const currentData: any = form.getFieldsValue();
    currentData.date_from = currentData.date_from.toISOString();
    const baseData: iSubSetData = setFormValue();
    const dataToSend = { ...baseData, ...currentData };
    onSendData(dataToSend);
  }

  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      layout="vertical"
      name="plan"
      onFinish={onSend}
      validateMessages={validateMessages}
      className="formSubscriptions"
      initialValues={initForm}
    >
      <Row gutter={16}>
        <Col md={12}>
          <Form.Item name="subscription" label="Piano" rules={[{ required: true }]}>
            <Select>
              {optionsPlans.map((i: iSubPlan) => (
                <Option key={i.name} value={i.name}>
                  Piano {capitalize(i.name)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item name="payment" rules={[{ required: true }]} label="Pagamento">
            <Radio.Group>
              <Radio value="month">Mensile</Radio>
              <Radio value="year">Annuale</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={12}>
          <Form.Item name="date_from" label="Data attivazione piano" rules={[{ required: true }]}>
            <DatePicker format="DD-MM-YYYY" placeholder="gg-mm-aaaa" />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Row className="fullheight pb-20" justify="center" align="bottom">
            <Button htmlType="submit">{labelButton}</Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

SubscriptionForm.defaultProps = {
  labelButton: 'AGGIUNGI NUOVO PIANO',
  startPlan: null,
};

export default SubscriptionForm;
