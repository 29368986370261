import React from 'react';
import { Link } from 'react-router-dom';
import Localize from '../../libs/localization';

const NewPasswordSendScreen: React.FC = () => {
  return (
    <div className="registration-thank">
      <h1 className="mb-30">{Localize('PAGES.AUTH.NEWPASSWORDSEND.TITLE')}</h1>
      <p>{Localize('PAGES.AUTH.NEWPASSWORDSEND.TXT')}</p>

      <p>
        <Link to="/login">{Localize('PAGES.AUTH.NEWPASSWORDSEND.BACK')}</Link>
      </p>
    </div>
  );
};

export default NewPasswordSendScreen;
