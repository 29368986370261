// import {
//   APP_START,
//   goTo,
//   ROUTER_LOCATION_CHANGE,
//   SET_ERROR,
// } from "../actions/app.actions";
// import {
//   AUTHORIZE_DONE,
//   USERINFO_LOADED,
//   authorizeAction,
//   getUserInfoAction,
//   updateAuth,
// } from "../actions/auth.actions.ts";
import {
  getFromLocalStorage,
  removeFromLocalStorage,
  setOnLocalStorage,
} from '../helpers/localstorage';
import Config from '../../config/Config';
import { getCookie, setCookie } from '../helpers/cookie';

import generatePermFromScopes from '../helpers/generatePermFromScopes';
// import { push } from "connected-react-router";
import { refreshPage } from '../helpers/commons';
import { register } from '../helpers/ioc';

// import routes from "../routes";

import { profileAsynGetAction } from '../../store/features/profileSlice';

const getStateAuthInfo = (store) => (store.getState() && store.getState().auth) || {};

const checkUserLocale = (lang) => {
  const userLocale = getCookie(Config.localeCookieName);
  if (userLocale) {
    setOnLocalStorage(userLocale, 'lang');
  }
};

// const checkUser = (store, force = false) => {
//   const ls = getFromLocalStorage();

//   const storageInfoId = ls && ls.user && ls.user.id;
//   const storageInfoToken = ls && ls.token;

//   const { user, token } = getStateAuthInfo(store);

//   if (storageInfoId && user && user.id && user.id !== storageInfoId) {
//     removeFromLocalStorage("user");
//     removeFromLocalStorage("token");
//     refreshPage();
//   } else if (token && force) {
//     console.log("TODO ADD USER CHECK");
//     // store.dispatch(getUserInfoAction());
//   } else if (
//     // (!token || token !== storageInfoToken) &&
//     storageInfoToken &&
//     storageInfoId
//   ) {
//     store.dispatch(updateAuth({ user: ls.user, token: ls.token }));
//   } else if (force) {
//     removeFromLocalStorage("user");
//     removeFromLocalStorage("token");
//   }
// };

// const checkQueryParams = (store) => {
//   let { hash } = store.getState().router.location;
//   const { query } = store.getState().router.location;

//   if (query && (query.authorization_code || query.code)) {
//     store.dispatch(
//       authorizeAction({ code: query.authorization_code || query.code })
//     );
//     return true;
//   } else if (query && query.logout === "OK") {
//     window.location = "/";
//   } else if (query && query.locale) {
//     setCookie(Config.localeCookieName, query.locale);
//     setOnLocalStorage(query.locale, "lang");
//     window.location.href = ".?";
//   } else if (hash) {
//     [, hash] = hash.split("#");
//     const params = {};
//     hash.split("&").forEach((t) => {
//       const [k, v] = t.split("=");
//       params[k] = v;
//     });
//   }
//   return false;
// };

const resetAppSession = () => {
  setCookie('_keeptrack_i_0202', null, -1);
};

const checkAppSession = () => {
  // console.log(getCookie('_keeptrack_i_0202'));
  return !!getCookie('_keeptrack_i_0202');
};

const authMiddleware = (store) => (next) => (action) => {
  if (action.type === 'persist/REHYDRATE') {
    if (action.payload?.user?.token) {
      setTimeout(() => {
        store.dispatch(profileAsynGetAction(null));
      }, 1000);
    }
  }

  // if (action.type === APP_START) {
  //   checkUserLocale();
  //   if (!checkQueryParams(store)) {
  //     checkUser(store);
  //   }
  //   resetAppSession();
  // } else if (action.type === AUTHORIZE_DONE) {
  //   const { user, token } = action.data;
  //   if (user && token) {
  //     setOnLocalStorage(action.data);
  //     if (user.role && user.role.scopes) {
  //       register("scopes", generatePermFromScopes(user.role.scopes));
  //     }
  //   } else {
  //     console.error("ERROR", "Session not saved");
  //   }
  // } else if (action.type === USERINFO_LOADED) {
  //   const user = action.data;
  //   if (user) {
  //     setOnLocalStorage({ user });
  //     if (user.role && user.role.scopes) {
  //       register("scopes", generatePermFromScopes(user.role.scopes));
  //     }
  //   } else {
  //     console.error("ERROR", "Session not saved");
  //   }
  // } else if (action.type === SET_ERROR) {
  //   // next(action);
  //   // store.dispatch(push("/error"));
  // } else if (action.type === ROUTER_LOCATION_CHANGE) {
  //   // if (
  //   //   action.payload &&
  //   //   action.payload.location &&
  //   //   action.payload.location.pathname !== "/" &&
  //   //   !checkAppSession()
  //   // ) {
  //   //   window.location.href = "/";
  //   // }
  //   window.scrollTo(0, 0);
  //   if (
  //     action.payload &&
  //     action.payload.location &&
  //     action.payload.location.pathname
  //   ) {
  //     checkUserLocale();
  //     const currentPath = null;
  //     // getFlatRouteList(routes).find((v) =>
  //     //   matchPath(action.payload.location.pathname, {
  //     //     path: v.path,
  //     //     exact: true,
  //     //   })
  //     // );

  //     checkUser(
  //       store,
  //       (currentPath && currentPath.type === type.SECURED) || false
  //     );
  //   }
  // }

  next(action);
};

export default authMiddleware;
