/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { profileAsynUpdateAction } from '../../store/features/profileSlice';
import ProfileEdit from './profile.edit';
import ProfileEditTermsPrivacy from './profile.edit.termsPrivacy';
import './style.sass';

const ProfileEditScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { profile, user } = useSelector((state: RootState) => state);
  return (
    <div className="container-small">
      {!profile.gdpr ? (
        <ProfileEditTermsPrivacy role={user.role} />
      ) : (
        <ProfileEdit
          back={undefined}
          complete={profile.complete}
          org={profile.data}
          onUpdate={(payload) => {
            return dispatch(profileAsynUpdateAction(payload));
          }}
        />
      )}
    </div>
  );
};

export default ProfileEditScreen;
