/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Form, Input, InputNumber, Radio, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormLabelWithTooltip } from '../../components';
import { enumOffersDonationType } from '../../libs/enums';
import {
  offersCategoryTypeSource,
  offersPackaging,
  renderCategoryObject,
} from '../../libs/helpers/offers.helpers';
import { iCategory, iMeasures, iSpecification } from '../../libs/interfaces';

const { Option } = Select;

interface iProps {
  form: any;
  isEditMode: boolean;
  measures: iMeasures[];
  specifications: Record<string, iSpecification>;
  mainCategories: any;
}

const OfferNewMain: React.FC<iProps> = ({
  form,
  isEditMode,
  measures,
  mainCategories,
  specifications,
}: iProps) => {
  const formValues = form.getFieldsValue();
  const [categoryToShow, setCategoryToShow] = useState<any>(undefined);
  const [subCatToShow, setSubCatToShow] = useState<any>(undefined);

  useEffect(() => {
    if (formValues && mainCategories) {
      const selected = { key: formValues.categoryId };
      setCategoryToShow(renderCategoryObject(mainCategories, selected));
    }
  }, [formValues?.categoryId, mainCategories]);

  const renderMeasuresOption = () => {
    if (!measures) {
      return null;
    }
    let currentMisures: any[] = [];
    if (subCatToShow) {
      currentMisures = [...measures];
    } else if (formValues.measureId) {
      currentMisures = [...measures.filter((measure: any) => measure.id === formValues.measureId)];
    }
    if (subCatToShow?.measureType === 'weight') {
      currentMisures = measures.filter((measure: any) => measure.type === subCatToShow.measureType);
    }

    return currentMisures.map((measure: iMeasures) => (
      <Option key={measure.id} value={measure.id}>
        {measure.name}
      </Option>
    ));
  };

  return (
    <>
      <Form.Item
        label="Tipologia"
        name="categoryType"
        rules={[{ required: true }]}
        className="offerNew__type"
      >
        <Radio.Group
          disabled={isEditMode}
          onChange={(v) => {
            const currentValues = form.getFieldsValue();
            currentValues.categoryId = '';
            currentValues.subCategoryId = '';
            currentValues.measureId = null;
            currentValues.packagingType = '';
            form.setFieldsValue(currentValues);
          }}
        >
          {offersCategoryTypeSource.map((i) => (
            <Radio.Button key={i.value} value={i.value}>
              {i.label.toUpperCase()}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>

      <Row gutter={16}>
        <Col sm={12}>
          <Form.Item label="Categoria" name="categoryId" rules={[{ required: true }]}>
            <Select
              disabled={isEditMode}
              placeholder="Seleziona una categoria"
              onSelect={(value, option) => {
                if (mainCategories.length === 0) {
                  return;
                }
                const currentValues = form.getFieldsValue();
                currentValues.subCategoryId = '';
                currentValues.measureId = null;
                currentValues.tags = [];
                setSubCatToShow(undefined);
                form.setFieldsValue(currentValues);
              }}
            >
              {mainCategories.length > 0 ? (
                mainCategories.map((category: any) => (
                  <Option key={category.id} value={category.id}>
                    {category.name}
                  </Option>
                ))
              ) : (
                <Option key="1" value="">
                  Seleziona una tipologia
                </Option>
              )}
            </Select>
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Form.Item label="Sottocategoria" name="subCategoryId" rules={[{ required: true }]}>
            <Select
              placeholder="Seleziona la sottocategoria"
              disabled={isEditMode}
              onSelect={(value) => {
                const currentValues = form.getFieldsValue();
                const subCatFound =
                  categoryToShow &&
                  categoryToShow.subCategories.find((sc: iCategory) => sc.id === value);
                if (subCatFound) {
                  setSubCatToShow(subCatFound);
                  currentValues.measureId = subCatFound.defaultMeasureId;
                  form.setFieldsValue(currentValues);
                }
              }}
            >
              {categoryToShow !== undefined ? (
                categoryToShow &&
                categoryToShow.subCategories &&
                categoryToShow.subCategories.map((sc: any) => {
                  return (
                    <Option value={sc.id} key={sc.id}>
                      {sc.name}
                    </Option>
                  );
                })
              ) : (
                <Option key="1" value="">
                  Seleziona una categoria
                </Option>
              )}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col sm={12}>
          <Form.Item
            name="packagingType"
            rules={[{ required: true }]}
            label={
              <FormLabelWithTooltip
                label="Tipo di confezionamento"
                tooltip="Indica la tipologia di formato con cui è confezionato il prodotto"
              />
            }
          >
            <Select placeholder="Pacco, etc." disabled={isEditMode}>
              {offersPackaging.sort().map((p: any) => (
                <Option key={p} value={p}>
                  {p}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Row gutter={16}>
            <Col sm={16}>
              <Form.Item
                label="Peso unità"
                name="unitAmount"
                rules={[
                  {
                    required:
                      form.getFieldsValue().typeDonation !==
                      enumOffersDonationType.voluntaryDonation,
                  },
                ]}
              >
                <InputNumber placeholder="Indica la quantità per unità" disabled={isEditMode} />
              </Form.Item>
            </Col>
            <Col sm={8}>
              <Form.Item label="Unità di misura" name="measureId" rules={[{ required: true }]}>
                <Select placeholder="gr, Kg, l, ..." disabled={isEditMode}>
                  {renderMeasuresOption()}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col sm={16}>
          <Form.Item label="Specifiche" name="tags" style={{ display: 'none' }}>
            <Select
              placeholder="Aggiungi specifiche riguardo al prodotto"
              mode="multiple"
              showArrow
              disabled={isEditMode}
            >
              {categoryToShow &&
                categoryToShow.tags &&
                categoryToShow.tags.map((tag: string) => (
                  <Option value={tag} key={tag}>
                    {specifications[tag] ? specifications[tag].name : tag}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col sm={8}>
          <Form.Item
            name="productCode"
            label={
              <FormLabelWithTooltip
                label="Codice prodotto"
                tooltip="Puoi utilizzare questo campo per riportare un codice identificativo per questo prodotto, questa informazione non sarà resa pubblica ai beneficiari"
              />
            }
          >
            <Input placeholder="Inserisci il codice del prodotto" disabled={isEditMode} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default OfferNewMain;
