/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Radio } from 'antd';

import { RootState } from '../../store';
import {
  statisticsAsynGetCardsAction,
  statisticsAsynGetColumnsAction,
  statisticsAsynGetPieColumnsAction,
  statisticsAsynGetPieDonorsAction,
  statisticsAsynGetMapAction,
  resetDashboardAction,
} from '../../store/features/statisticsSlice';
import StatisticsFilter from '../../components/statisticsFilters/statisticsFilters';
import StatisticsCard from '../../components/statisticsCard/statisticsCard';
import {
  statisticsCardTypesSource,
  initStatisticsFilters,
  statisticsTitles,
} from '../../libs/helpers/statistics.helpers';
import { getRoleType, roleTypes } from '../../libs/helpers/privileges.helpers';
import { iStatisticsFilters } from '../../libs/interfaces';

import StatisticsColumsChart from './statisticsColumnsChart';
import StatisticsPieChart from './statisticsPieChart';
import StatisticsMap from './statisticsMap';

const Statistics: React.FC = () => {
  const dispatch = useDispatch();
  const { user, statistics } = useSelector((state: RootState) => state);
  const [lastFilters, lastFiltersSet] = useState<iStatisticsFilters>(initStatisticsFilters);
  const [month, monthSet] = useState<number>(1);

  const myRole: roleTypes = getRoleType(user.role);
  const myTitles: any = statisticsTitles[myRole];

  const { cards, columns, pieCategories, pieDonors, map } = statistics;

  useEffect(() => {
    return () => {
      dispatch(resetDashboardAction());
    };
  }, []);

  function callDataColumns(inFilters: iStatisticsFilters | null, inMonth: number | null) {
    if (typeof inMonth === 'number') {
      monthSet(inMonth);
    }
    const f = inFilters || lastFilters;
    const m = typeof inMonth === 'number' ? inMonth : month;
    const outData: iStatisticsFilters = { ...f, month: Boolean(m) };
    dispatch(statisticsAsynGetColumnsAction(outData));
  }

  function callData(filters: iStatisticsFilters) {
    dispatch(statisticsAsynGetCardsAction(filters));
    dispatch(statisticsAsynGetPieColumnsAction(filters));
    dispatch(statisticsAsynGetPieDonorsAction(filters));
    dispatch(statisticsAsynGetMapAction(filters));
    callDataColumns(filters, null);
    lastFiltersSet(filters);
  }

  return (
    <div>
      <StatisticsFilter role={user.role} onApplyFilters={(v: iStatisticsFilters) => callData(v)} />
      <Row className="mt-30" justify="space-between" gutter={16}>
        {statisticsCardTypesSource.map((s) => (
          <Col className="mb-15" key={s.value} lg={6}>
            <StatisticsCard type={s.value} data={cards} titles={myTitles} />
          </Col>
        ))}
      </Row>
      <div className="my-50">
        <Radio.Group onChange={(v) => callDataColumns(null, v.target.value)} value={month}>
          <Radio value={1}>Mesi</Radio>
          <Radio value={0}>Giorni</Radio>
        </Radio.Group>
        <StatisticsColumsChart data={columns} titles={myTitles} />
      </div>
      <Row gutter={16}>
        <Col lg={12}>
          <StatisticsPieChart
            data={pieCategories}
            id="pie_categories"
            title={myTitles.pies.categories}
          />
        </Col>
        <Col lg={12}>
          <StatisticsPieChart data={pieDonors} id="pie_donors" title={myTitles.pies.user} />
        </Col>
      </Row>
      <h2 className="text-center f-s-18 mb-20">{myTitles.map}</h2>
      <StatisticsMap data={map} />
    </div>
  );
};

export default Statistics;
