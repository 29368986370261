import { configureStore, Action } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ThunkAction } from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { registerService } from '../libs/helpers/services';

import history from './history';
import rootReducer from './rootReducer';
import appMiddlewares from '../libs/middlewares/index';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'profile'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer(history));

const middlewares = [routerMiddleware(history), ...appMiddlewares];
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
  // devTools: process.env.NODE_ENV !== 'production',
  devTools: true,
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

// After adding redux-persist to avoid type errors of RootState inside a component
// export type RootState = ReturnType<typeof store.getState>;
export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export const persistor = persistStore(store);
// persistor.purge();

registerService('store', store);
store.dispatch({ type: 'profile/profileAsynGetAction', payload: null });

export default store;
