import React from 'react';
import { Link } from 'react-router-dom';

import { enumOrganizationsTypes, enumRoutes } from '../../libs/enums';
import { organizationsTabs } from '../../libs/helpers/organizations.helpers';

interface iProps {
  currentRoute: enumRoutes;
  id: string;
  type: enumOrganizationsTypes;
}

const OrganizationTabs: React.FC<iProps> = ({ currentRoute, id, type }: iProps) => {
  const current = organizationsTabs[type];
  return (
    <ul className="tabs mb-30">
      {current.map((i: any) => (
        <li key={i.to} className={currentRoute === i.to ? 'is-active' : ''}>
          <Link to={i.to.replace(':id', id)}>{i.label}</Link>
        </li>
      ))}
    </ul>
  );
};

export default OrganizationTabs;
