/* eslint-disable eqeqeq */
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { push } from 'connected-react-router';
import { Dispatch } from '@reduxjs/toolkit';
import { Headers } from 'form-data';
import { toast } from 'react-toastify';

import { setError, appSetLoadingAction } from '../store/features/appSlice';

import { getService } from './helpers/services';
import { getCurrentLocale } from './localization';

import log from './helpers/logger';
import { get } from './helpers/ioc';
import appInfo from './helpers/appInfo';
import { getFromLocalStorage, removeFromLocalStorage } from './helpers/localstorage';
import ErrorAPI from './helpers/ErrorApi';

export const API_ERROR = 'API_ERROR';
export const API_LOADING = 'API_LOADING';

const defaultErrorProcessor = (error: any, dispatch: Dispatch, errorAction: string) => {
  /* Default error processor, clears the user if the API response status is 401 */
  log.error(error.stack, error);

  // Internal mock
  // {"id":"voucher.creation.existent_code","code":"70-202","label":"Il codice del voucher è già presente","status":422,"message":""}

  let { message } = error;
  const { name, status, id, date, details, code } = error;

  const { label } = details || {};

  message = label || message;

  if (status == 401 || status == 403) {
    removeFromLocalStorage('user');
    removeFromLocalStorage('token');
    dispatch(push('/login'));
  } else {
    dispatch(
      setError({
        name,
        status,
        message,
        id,
        date,
        code,
      }),
    );
  }
};

export const headersBuilder = () => ({
  // "Accept-Language": getCurrentLocaleExtended(),
  'Accept-Language': getCurrentLocale(),
  'App-Launch-Count': _.get(getFromLocalStorage(), 'app-launch-count', 1), // How many times the app was launched
  'App-Unique-Run-Id': _.get(getFromLocalStorage(), 'app-run-id', null), // Unique Id to this concrete execution
  'App-Id': appInfo.name, // Packagename/Bundle Identifier
  'App-Build-Type': appInfo.env, // Debug/Release
  'App-Version-Code': appInfo.version, // Version Code
  'Content-Type': 'application/json',
  'Device-OS': navigator.platform, // Operating System ios/android
  'Device-OS-Version': navigator.appVersion || navigator.userAgent, // Operating System Version
  'Device-Screen-Width': window.screen.availWidth, // Screen width in pixels
  'Device-Screen-Height': window.screen.availHeight, // Screen height in pixels
  // "Device-Push-Notifications-Enabled":
  //   _.get(Notification, "permission") === "granted" ? true : false, //true/false
  'Device-Screen-DPR': `${window.devicePixelRatio}x`, // DPR zoom !== DPI
  TimeOffset: moment().format('ZZ'), // Offset from UTC in Format +-HHMM (See ISO8601 Specs)
});

type ApiCallType = {
  apiCall: any;
  startAction: string;
  successAction: string;
  secured?: boolean;
  permissions?: any;
  errorAction?: string;
  processResult?: (result: any, dispatch: Dispatch, getState: () => any, params?: any) => void;
  processError?: (
    error: any,
    dispatch: Dispatch,
    errorAction: string,
    getState: () => any,
    params?: any,
  ) => void;
  headers?: () => void;
  queryAuthParamName?: string;
};

/**
 * Handles API calls and manages actions and errors
 *
 * @param {Function} apiCall
 * @param {String} startAction The first action to be called (default: API_LOADING)
 * @param {String} successAction The success action to be called
 * @param {Boolean} secured Injects access_token (default: false)
 * @param {Object} permissions Checks for user permissions (schema: { section: ['create', 'read', 'update', 'delete']})
 * @param {String} errorAction The error action to be called (default: API_ERROR)
 * @param {Function} processResult The optional function to be called for result processing {result, dispatch, getState}
 * @param {Function} processError The optional function to be called for error processing {error, dispatch, errorAction, getState} (default: defaultErrorProcessor)
 * @param {Function} headers The optional function to be called to build headers {user} (default: headersBuilder)
 * @param {String} queryAuthParamName The optional choice to put token as a queryParam (default: false)
 */
export default ({
  apiCall,
  secured = false,
  permissions,
  startAction = API_LOADING,
  successAction,
  errorAction = API_ERROR,
  processResult,
  processError = defaultErrorProcessor,
  headers = headersBuilder,
  queryAuthParamName,
}: ApiCallType) => {
  return (params: any = {}, callback?: (data: unknown) => void) => async (
    dispatch: Dispatch,
    getState: () => any,
  ) => {
    const { token } = getState().auth;
    // params.headers = headers();
    // if (secured && token) {
    //   /* If secured, injects access_token in the apiCall function */
    //   if (queryAuthParamName) {
    //     params[queryAuthParamName] = token;
    //   } else {
    //     params.headers.Authorization = `Bearer ${token}`;
    //   }
    // }

    const found: any = {};

    if (permissions) {
      const userPerm = get('scopes');
      // eslint-disable-next-line no-return-assign
      Object.keys(permissions).map(
        // eslint-disable-next-line no-return-assign
        (sect) =>
          (found[sect] = permissions[sect].filter(
            (p: string) => _.get(userPerm, `${sect}`, []).indexOf(p) > -1,
          )),
      );
    }

    if (!permissions || _.isEqual(found, permissions)) {
      dispatch({ type: startAction, params });

      try {
        let result = await apiCall(params);
        if (processResult) {
          result = processResult(result, dispatch, getState, params);
        }

        if (successAction) {
          dispatch({
            type: successAction,
            data: result,
            params,
          });
        }
        if (callback) {
          callback(result);
        }
      } catch (error) {
        if (processError) processError(error, dispatch, errorAction, getState, params);
        dispatch({
          type: errorAction,
          error,
        });
        if (callback) {
          callback(error);
        }
      }
    }
  };
};

type MakeCallApi = {
  baseUrl?: string;
  path: string;
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  uriParams?: any;
  uriString?: string;
  body?: any | string;
  json?: boolean;
  customHeaders?: Headers;
  dispatch?: any;
  getState?: any;
  autorized?: boolean;
};

export class Api {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  setBaseUrl(baseUrl: string): string {
    this.baseUrl = baseUrl;
    return this.baseUrl;
  }

  async makeCall({
    baseUrl = this.baseUrl,
    path,
    method = 'GET',
    uriParams,
    uriString,
    body = undefined,
    json = true,
    customHeaders = {},
    autorized,
  }: MakeCallApi): Promise<any> {
    const store = getService('store');
    store.dispatch(appSetLoadingAction(true));

    const headers = new globalThis.Headers(customHeaders);
    if (json) {
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
    }

    if (autorized) {
      const { user } = store.getState();
      const { token } = user;
      headers.append('Authorization', `Bearer ${token}`);
    }
    // if (["POST", "PATCH"].indexOf(method) === 0) {
    //   headers.append("X-Requested-With", "XMLHttpRequest");
    // }

    const queryParamString: any = uriParams
      ? `?${Object.keys(uriParams)
          .map((key: string) => `${key}=${uriParams[key]}`)
          .join('&')}`
      : '';

    const queryString = uriString ? `?${uriString}` : '';

    const url = `${baseUrl}${path}${queryString}${queryParamString}`;
    const fetchBody = body && json ? JSON.stringify(body) : body?.toString();
    const res = await fetch(url, { method, body: fetchBody, headers });

    store.dispatch(appSetLoadingAction(false));

    const result: { payload: Response; body?: string | any } = {
      payload: res.clone(),
    };

    try {
      result.body = await res.clone().json();
    } catch (e) {
      result.body = await res.clone().text();
      result.body = result.body.length > 0 ? result.body : undefined;
    }

    if (res.ok) {
      return result;
    }

    if (res.status === 401) {
      toast.error(
        <p>
          Sessione scaduta. <br />
          Effettuare un nuovo accesso.
        </p>,
        { toastId: 401 },
      );
      store.dispatch({ type: 'profile/profileResetAction' });
      return store.dispatch({ type: 'user/userLogoutAction' });
    }

    throw new ErrorAPI(res.statusText, result.body);
  }
}
