/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Row } from 'antd';
import moment from 'moment';
import { capitalize } from 'lodash';

import {
  enumOffersDelivery,
  enumOffersOfferType,
  enumOrdersStatus,
  enumRoles,
  enumRoutes,
  enumViews,
} from '../../libs/enums';

import { iOffer, iSpecification, iUserState } from '../../libs/interfaces';
import {
  getSecondValueUnit,
  renderIcon,
  decodeCredits,
  getTotOrderWeight,
  distanceLatLng,
} from '../../libs/helpers/offers.helpers';
import { isAdmin } from '../../libs/helpers/privileges.helpers';

import Images from '../../assets/images';
import DottedMenu from '../dottedMenu/dottedMenu';
import ModalDeleteOffer from '../modalDeleteOffer/modalDeleteOffer';
import Delivery from '../delivery/delivery';
import OffersCardUnit from './offersCardUnit';
import OffersCardBadges from './offersCardBadges';
import './style.sass';
import { SpecificationTag } from '../../views/categories/modalSpecifications';

interface iProps {
  offer: iOffer;
  user: iUserState;
  view: enumViews;
  setDropdownVisibility: (visible: boolean) => void;
  onDelete: (id: string) => void;
  latLng: { lat: number | null; lng: number | null };
  specifications: Record<string, iSpecification>;
}

const OffersCard: React.FC<iProps> = ({
  offer,
  onDelete,
  latLng,
  setDropdownVisibility,
  user,
  view,
  specifications,
}: iProps) => {
  const history = useHistory();
  const { order, info } = offer;
  const { role } = user;

  const distance: string = useMemo(() => {
    let d = 0;
    const donorLat = offer.donor?.lat;
    const donorLng = offer.donor?.lng;
    if (latLng.lat && latLng.lng && donorLat && donorLng) {
      d = distanceLatLng(latLng.lat, latLng.lng, donorLat, donorLng);
    }
    // Se è più vicino di 100 metri non faccio vedere nulla
    return d > 0.09 ? d.toFixed(1) : '0.1';
  }, [latLng, offer.donor]);

  const [modalDeleteVisibile, modalDeleteVisibileSet] = useState<boolean>(false);

  const renderCategoryTags = useMemo(() => {
    const arr = offer.tags ? offer.tags.slice(0, 2) : [];
    const arrFiltered = arr.filter((t) => specifications[t]);

    let more;
    if (offer.tags && offer.tags.length > 2) {
      more = offer.tags.length - 2;
    }

    return (
      <div className="oCard__tags">
        <div>
          {arrFiltered.map((tag: any) => {
            return (
              <SpecificationTag
                key={tag}
                specification={specifications[tag]}
                closable={false}
                handleClose={() => {}}
              />
            );
          })}
          {more && <span>{`+ ${more}`}</span>}
        </div>
      </div>
    );
  }, [offer.tags, specifications]);

  const renderBody = () => {
    const renderDeliveryList = (isOffer = true) => {
      if (isOffer) {
        return (
          <ul>
            {offer.deliveryType.includes(enumOffersDelivery.collection) && (
              <Delivery
                deliveryType={enumOffersDelivery.collection}
                isOffer
                offer={offer}
                distance={distance}
              />
            )}
            {offer.deliveryType.includes(enumOffersDelivery.shipment) && (
              <Delivery deliveryType={enumOffersDelivery.shipment} isOffer offer={offer} />
            )}
          </ul>
        );
      }
      return (
        <ul>
          {order && order.deliveryType.includes(enumOffersDelivery.collection) && (
            <Delivery
              deliveryType={enumOffersDelivery.collection}
              isOffer={false}
              offer={offer}
              distance={distance}
            />
          )}
          {order && order.deliveryType.includes(enumOffersDelivery.shipment) && (
            <Delivery deliveryType={enumOffersDelivery.shipment} isOffer={false} offer={offer} />
          )}
        </ul>
      );
    };

    switch (view) {
      case enumViews.available:
      case enumViews.all:
        return (
          <>
            {offer.donor?.name && <h2>{offer.donor?.name}</h2>}
            <Row className="oCard__main" justify="space-between" align="bottom">
              <div className="oCard__info">{renderDeliveryList()}</div>
              {offer.tags.length ? renderCategoryTags : null}
            </Row>
          </>
        );
      case enumViews.booked:
        return (
          <>
            {offer.donor?.name && <h2>{offer.donor?.name}</h2>}
            <Row className="oCard__main" justify="space-between" align="bottom">
              <div className="oCard__info">{renderDeliveryList(false)}</div>
              {offer.tags.length ? renderCategoryTags : null}
            </Row>
          </>
        );
      case enumViews.mydonation:
        return <Row justify="end">{renderCategoryTags}</Row>;
      case enumViews.reservations:
        return (
          <Row className="oCard__main" justify="space-between" align="bottom">
            <div className="oCard__info">
              {order?.noprofit && <h2>{order?.noprofit.name}</h2>}
              {renderDeliveryList(false)}
            </div>
            {offer.tags.length ? renderCategoryTags : null}
          </Row>
        );
      default:
        return null;
    }
  };

  const renderFooter = () => {
    let perc = 0;
    const calcPercentage = () => {
      const { availableUnits, totalUnits } = offer;
      if (info) {
        if (info.acceptedOrders === 0 && info.openOrders > 0) {
          return 100;
        }
        if (info.status === enumOrdersStatus.closed || offer.availableUnits === 0) {
          return 0.1;
        }
      }

      if (availableUnits && totalUnits && info) {
        perc = ((totalUnits - info.confirmedUnits) / totalUnits) * 100;
        return perc;
      }

      return 100;
    };

    const renderOrderStatus = () => {
      if (order) {
        switch (order.status) {
          case enumOrdersStatus.open:
            return (
              <div className="oCard__orderStatus--booked">
                <h3>
                  Prenotato {offer.unitWeight && `(${getTotOrderWeight(offer, order.units)} Kg)`}
                </h3>
                <p>{moment(order.createdAt).format('DD/MM/YYYY')}</p>
              </div>
            );
          case enumOrdersStatus.accepted:
            return (
              <>
                <div className="oCard__orderStatus--accepted">
                  <h3>
                    Confermato {offer.unitWeight && `(${getTotOrderWeight(offer, order.units)} Kg)`}
                  </h3>
                  <p>{moment(order.createdAt).format('DD/MM/YYYY')}</p>
                </div>
                {/* condivisione nascosta per il deploy
                isNoProfit(role) && <Button>CONDIVIDI</Button>
                 */}
              </>
            );
          case enumOrdersStatus.shared:
            return (
              <>
                <div className="oCard__orderStatus--shared">
                  <h3>
                    Condiviso {offer.unitWeight && `(${getTotOrderWeight(offer, order.units)} Kg)`}
                  </h3>
                  <p>{moment(order.createdAt).format('DD/MM/YYYY')}</p>
                </div>
                <Button>CONDIVIDI</Button>
              </>
            );
          case enumOrdersStatus.rejected:
            return (
              <div className="oCard__orderStatus--rejected">
                <h3>
                  Rifiutata {order.units} unità
                  {offer.unitWeight && ` (${getTotOrderWeight(offer, order.units)} Kg)`}
                </h3>
                <p>{moment(order.createdAt).format('DD/MM/YYYY')}</p>
              </div>
            );
          default:
            return null;
        }
      }

      return null;
    };

    switch (view) {
      case enumViews.available:
        return (
          <Row justify="end">
            <Button
              // eslint-disable-next-line prettier/prettier
              className={`${offer.type === enumOffersOfferType.sale ? 'oCard__btn--yellow' : 'oCard__btn--orange'}`}
            >
              PRENOTA
            </Button>
          </Row>
        );
      case enumViews.booked:
        return <Row justify="space-between">{renderOrderStatus()}</Row>;
      case enumViews.mydonation:
      case enumViews.all:
        if (role === enumRoles.admin) {
          return null;
        }
        if (!offer.availableUnits && !info?.openOrders) {
          return <div className="oCard__status">Tutta la merce è stata ritirata</div>;
        }
        return (
          <>
            {info?.openOrders > 0 ? (
              <div className="oCard__status oCard__status--on">
                <span>{info.openOrders}</span>
                Prenotazioni ricevute
              </div>
            ) : (
              <div className="oCard__status">Nessuna prenotazione ricevuta</div>
            )}
            <OffersCardUnit perc={calcPercentage()} />
          </>
        );
      case enumViews.reservations:
        return <Row justify="space-between">{renderOrderStatus()}</Row>;
      default:
        return null;
    }
  };

  const renderDottedMenu = () => {
    switch (view) {
      case enumViews.mydonation:
      case enumViews.all:
        return (
          <div style={{ position: 'absolute', right: 10, top: 20 }}>
            <DottedMenu
              onVisibleChange={(visible: boolean) => {
                setDropdownVisibility(visible);
              }}
              onEdit={() => {
                history.push(enumRoutes.offerEditLink + offer.id);
                setDropdownVisibility(false);
              }}
              onDelete={() => {
                modalDeleteVisibileSet(true);
              }}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <article className="oCard">
      <div>
        <OffersCardBadges offer={offer} role={role} view={view} />
        <header>
          <div className="oCard__image">
            <img src={renderIcon(offer).src} alt={renderIcon(offer).alt} />
          </div>
          <div>
            <h1>{offer.subCategory.name}</h1>
            <h2>{offer.category.name}</h2>
            {info?.openOrders === 0 && !isAdmin(role) && renderDottedMenu()}
            <Row align="middle">
              <OffersCardUnit
                firstValue={
                  info
                    ? (offer.totalUnits! - info.confirmedUnits).toLocaleString()
                    : offer.availableUnits?.toLocaleString()
                }
                secondValue={
                  info && getSecondValueUnit(info, offer.availableUnits!, offer.totalUnits!)
                }
                orderedQuantity={order && order.units}
                orderStatus={order && order.status}
              />
              <p className="m-0 f-s-12">
                {capitalize(offer.packagingType)} {offer.unitAmount}
                {offer.measure.name}
              </p>
            </Row>
          </div>
        </header>
        {!order &&
          (offer.unitCost! > 0 && offer.type !== enumOffersOfferType.donation ? (
            <Row justify="space-between" align="middle" className="oCard__credits">
              <hr className="div flex1 mr-5" />
              <p>
                <img src={Images.icoYellowStar.src} alt="" />
                {offer.unitCost} Crediti Unità
              </p>
            </Row>
          ) : (
            <hr className="div" />
          ))}
        {order &&
          (order.credits > 0 ? (
            <Row justify="space-between" align="middle" className="oCard__credits">
              <hr className="div flex1 mr-5" />
              <p>
                <img src={Images.icoYellowStar.src} alt="" />
                {decodeCredits(order.credits)} Crediti
              </p>
            </Row>
          ) : (
            <hr className="div" />
          ))}
        {renderBody()}
      </div>
      <footer>{renderFooter()}</footer>
      <ModalDeleteOffer
        onDelete={() => onDelete(offer.id)}
        onCancel={() => {
          modalDeleteVisibileSet(false);
          setDropdownVisibility(false);
        }}
        visible={modalDeleteVisibile}
      />
    </article>
  );
};

export default OffersCard;
