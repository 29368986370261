/* eslint-disable react-hooks/exhaustive-deps */
import { Radio } from 'antd';
import React from 'react';
import { enumOffersCategoryType } from '../../libs/enums';
import { offersCategoryTypeSource } from '../../libs/helpers/offers.helpers';

interface iProps {
  value: enumOffersCategoryType;
  onChange: (v: enumOffersCategoryType) => void;
}

const OfferCategoryTypePicker: React.FC<iProps> = ({ value, onChange }: iProps) => {
  return (
    <Radio.Group
      disabled={false}
      defaultValue={value}
      className="tabs"
      onChange={(v) => {
        onChange(v.target.value);
      }}
    >
      {offersCategoryTypeSource.map((i) => (
        <Radio.Button key={i.value} value={i.value}>
          {i.label.toUpperCase()}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export default OfferCategoryTypePicker;
