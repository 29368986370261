/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
import { toast } from 'react-toastify';
import Config from '../config/Config';
import { Api } from '../libs/callApi';
import { iCategoriesApi, iCategory, iMeasures, iSpecification } from '../libs/interfaces';

const api = new Api(Config.apiBaseurl);
type HttpErrorResponse = {
    response: {
        label?: {
            it: string
        }
    }
}
const manageErr = (err: HttpErrorResponse) => {
    const errorMessage = err.response?.label ? err.response.label.it : 'Si è verificato un errore';
    toast.error(errorMessage);
    throw err;
}
const manageNoErr = (err: HttpErrorResponse) => {
    const errorMessage = err.response?.label ? err.response.label.it : 'Si è verificato un errore';
    toast.error(errorMessage);
}

export const getMeasuresApi = async (): Promise<iMeasures[]> => {
    try {
        const call = await api.makeCall({
            path: Config.apis.offers.measures,
            method: 'GET',
            autorized: true,
        });
        return call.body;
    } catch (err) {
        manageNoErr(err);
        return [];
    }
};


export const getSpecificationsApi = async (): Promise<iSpecification[]> => {
    try {
        const call = await api.makeCall({
            path: Config.apis.category.specifications,
            method: 'GET',
            autorized: true,
        });
        return call.body;
    } catch (err) {
        manageNoErr(err);
        return [];
    }
};
export const getSpecificationsMapApi = async (): Promise<Record<string, iSpecification>> => {
    const rows = await getSpecificationsApi();
    const map: Record<string, iSpecification> = {};
    rows.forEach((element: iSpecification) => {
        map[element.id] = element;
    });
    return map;
};
export const createSpecificationApi = async (body: iSpecification): Promise<iSpecification> => {
    try {
        const call = await api.makeCall({
            path: Config.apis.category.specifications,
            method: 'POST',
            body,
            autorized: true,
        });
        return call;
    } catch (err) {
        return manageErr(err);
    }
};
export const updateSpecificationApi = async (body: iSpecification): Promise<iSpecification> => {
    try {
        const call = await api.makeCall({
            path: Config.apis.category.specifications,
            method: 'PATCH',
            body,
            autorized: true,
        });
        return call;
    } catch (err) {
        return manageErr(err);
    }
};
export const deleteSpecificationApi = async (id: string): Promise<iSpecification> => {
    try {
        const call = await api.makeCall({
            path: `${Config.apis.category.specifications}/${id}`,
            method: 'DELETE',
            autorized: true,
        });
        return call;
    } catch (err) {
        return manageErr(err);
    }
};
export const getAllCategoriesApi = async (): Promise<iCategoriesApi[]> => {
    try {
        const call = await api.makeCall({
            path: Config.apis.offers.categories,
            method: 'GET',
            autorized: true,
        });
        return call.body;
    } catch (err) {
        manageNoErr(err);
        return [];
    }
};

export const createCategoryApi = async (body: iCategoriesApi | iCategory): Promise<iCategoriesApi | iCategory> => {
    try {
        const call = await api.makeCall({
            path: Config.apis.category.categories,
            method: 'POST',
            body,
            autorized: true,
        });
        return call;
    } catch (err) {
        return manageErr(err);
    }
};
export const updateCategoryApi = async (body: iCategoriesApi | iCategory): Promise<iCategoriesApi | iCategory> => {
    try {
        const call = await api.makeCall({
            path: Config.apis.category.categories,
            method: 'PATCH',
            body,
            autorized: true,
        });
        return call;
    } catch (err) {
        return manageErr(err);
    }
};
