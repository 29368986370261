import React from 'react';

import { iProfileData } from '../../../libs/interfaces';

interface iProps {
  organization: iProfileData;
}

const OrganizationNoProfitLevel: React.FC<iProps> = ({ organization }: iProps) => {
  return (
    <div>
      <h1>Livello</h1>
    </div>
  );
};

export default OrganizationNoProfitLevel;
