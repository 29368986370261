/* eslint-disable import/prefer-default-export */
import Config from '../config/Config';
import { Api } from '../libs/callApi';
import { iCategoriesApi, iOfferApi, iDonors, iOffer } from '../libs/interfaces';
import { enumViews } from '../libs/enums';
import { isAdmin, isDonor } from '../libs/helpers/privileges.helpers';

const api = new Api(Config.apiBaseurl);

export const getOffersApi = async (
  filter: string,
  role: string,
  view?: string | null,
): Promise<any[]> => {
  let path = '';

  if (isDonor(role)) {
    path = Config.apis.offers.getDonor;
    if (view === 'reservations') {
      path = Config.apis.orders.getReceived;
    }
  } else if (view === enumViews.booked) {
    path = Config.apis.orders.getMine;
  } else if (isAdmin(role) && view === 'reservations') {
    path = Config.apis.orders.getReceived;
  } else {
    path = Config.apis.offers.base;
  }

  const call = await api.makeCall({
    path,
    method: 'GET',
    autorized: true,
    uriString: filter,
  });

  return call.body;
};

export const getOfferDetailApi = async (id: string): Promise<iOffer> => {
  const call = await api.makeCall({
    path: `${Config.apis.offers.base}/${id}`,
    method: 'GET',
    autorized: true,
  });
  return call.body;
};

export const createOfferApi = async (body: iOfferApi): Promise<iOfferApi> => {
  const call = await api.makeCall({
    path: Config.apis.offers.base,
    method: 'POST',
    body,
    autorized: true,
  });
  return call;
};

export const editOfferApi = async (body: iOfferApi): Promise<iOfferApi> => {
  const call = await api.makeCall({
    path: Config.apis.offers.base,
    method: 'PATCH',
    body,
    autorized: true,
  });
  return call;
};

export const deleteOfferApi = async (id: string): Promise<iOfferApi> => {
  const call = await api.makeCall({
    path: `${Config.apis.offers.base}/${id}`,
    method: 'DELETE',
    autorized: true,
  });
  return call;
};
const onlyActiveCat = (cats: iCategoriesApi[]): iCategoriesApi[] => {
  const arr: iCategoriesApi[] = [];
  cats.forEach((cat) => {
    if (cat.active === 'Y' && cat.subCategories) {
      const subCategories = cat.subCategories.filter((el) => el.active === 'Y');
      if (subCategories.length > 0) arr.push({ ...cat, subCategories });
    }
  });
  return arr;
};
export const getCategoriesApi = async (used = false): Promise<iCategoriesApi> => {
  const call = await api.makeCall({
    path: used ? Config.apis.offers.categoriesUsed : Config.apis.offers.categories,
    method: 'GET',
    autorized: true,
  });
  call.body = used ? call.body : onlyActiveCat(call.body);
  return call;
};

export const getMeasuresApi = async (): Promise<any> => {
  const call = await api.makeCall({
    path: Config.apis.offers.measures,
    method: 'GET',
    autorized: true,
  });
  return call;
};

export const getDonorsApi = async (): Promise<iDonors> => {
  const call = await api.makeCall({
    path: Config.apis.offers.donors,
    method: 'GET',
    autorized: true,
  });
  return call;
};

// export const importOffersApi = async (body: any): Promise<iOfferApi> => {
//   const call = await api.makeCall({
//     path: Config.apis.offers.csv,
//     method: 'POST',
//     body,
//     autorized: true,
//   });
//   return call;
// };
