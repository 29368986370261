import React from 'react';
import { enumOrdersStatus } from '../../libs/enums';

interface iProps {
  firstValue?: string;
  secondValue?: string;
  perc?: number;
  orderedQuantity?: number;
  orderStatus?: enumOrdersStatus;
}

const OffersCardUnit: React.FC<iProps> = ({
  firstValue,
  secondValue,
  perc,
  orderedQuantity,
  orderStatus,
}: iProps) => {
  const colorBadgeSelector = () => {
    switch (orderStatus) {
      case enumOrdersStatus.open:
        return 'oCard__unit__open';
      case enumOrdersStatus.rejected:
        return 'oCard__unit__rejected';
      case enumOrdersStatus.accepted:
        return 'oCard__unit__accepted';
      default:
        return '';
    }
  };

  return (
    <div className="oCard__unit">
      {orderedQuantity ? (
        <span className={colorBadgeSelector()}>{orderedQuantity} unità</span>
      ) : (
        <>
          {firstValue && !secondValue && <span>{firstValue} unità</span>}
          {firstValue && secondValue && (
            <>
              <span>{firstValue}</span>
              <span className="oCard__unit__rest">{secondValue} unità</span>
            </>
          )}
          {perc && <div style={{ width: `${100 - perc}%` }} />}
        </>
      )}
    </div>
  );
};

OffersCardUnit.defaultProps = {
  firstValue: '',
  secondValue: '',
  perc: undefined,
  orderedQuantity: undefined,
  orderStatus: undefined,
};

export default OffersCardUnit;
