import { type } from '../../components/helpers/router';
import { AppLayout } from '../../components/layouts';
import Localize from '../../libs/localization';
import { enumRoutes, enumRoles, enumOrganizationsTypes } from '../../libs/enums';
import Organizations from '../../views/organizations/organizations.view';
import Organization from '../../views/organization/organization.view';
import ProfileOrgEditScreen from '../../views/profile/profile.org.edit.view';

const RoutesOrganizations = [
  // ORGANIZATIONS
  {
    id: 'organizations',
    title: Localize('PAGES.ORGANIZATION.TITLE'),
    path: enumRoutes.organizations,
    layout: AppLayout,
    component: Organizations,
    type: type.AUTHENTICATION,
    redirectTo: enumRoutes.organizationsDonors,
    roles: [enumRoles.admin],
  },
  // Donor
  {
    id: 'organizationsDonors',
    title: Localize('PAGES.ORGANIZATION.TITLE'),
    path: enumRoutes.organizationsDonors,
    layout: AppLayout,
    component: Organizations,
    type: type.AUTHENTICATION,
    params: { organizationsType: enumOrganizationsTypes.companies },
    roles: [enumRoles.admin],
  },
  {
    id: 'organizationsDonorsProfile',
    title: Localize('PAGES.ORGANIZATION.TITLE'),
    path: enumRoutes.organizationsDonorsProfile,
    layout: AppLayout,
    component: Organization,
    type: type.AUTHENTICATION,
    params: { organizationsType: enumOrganizationsTypes.companies },
    roles: [enumRoles.admin],
  },
  {
    id: 'organizationsDonorsDeals',
    title: Localize('PAGES.ORGANIZATION.TITLE'),
    path: enumRoutes.organizationsDonorsDeals,
    layout: AppLayout,
    component: Organization,
    type: type.AUTHENTICATION,
    params: { organizationsType: enumOrganizationsTypes.companies },
    roles: [enumRoles.admin],
  },
  {
    id: 'organizationsDonorsSubscriptions',
    title: Localize('PAGES.ORGANIZATION.TITLE'),
    path: enumRoutes.organizationsDonorsSubscriptions,
    layout: AppLayout,
    component: Organization,
    type: type.AUTHENTICATION,
    params: { organizationsType: enumOrganizationsTypes.companies },
    roles: [enumRoles.admin],
  },
  {
    id: 'organizationsNoProfitCredits',
    title: Localize('PAGES.ORGANIZATION.TITLE'),
    path: enumRoutes.organizationsNoProfitCredits,
    layout: AppLayout,
    component: Organization,
    type: type.AUTHENTICATION,
    params: { organizationsType: enumOrganizationsTypes.noprofit },
    roles: [enumRoles.admin],
  },
  // No Profit
  {
    id: 'organizationsNoProfit',
    title: Localize('PAGES.ORGANIZATION.TITLE'),
    path: enumRoutes.organizationsNoProfit,
    layout: AppLayout,
    component: Organizations,
    type: type.AUTHENTICATION,
    params: { organizationsType: enumOrganizationsTypes.noprofit },
    roles: [enumRoles.admin],
  },
  {
    id: 'organizationsNoProfitProfile',
    title: Localize('PAGES.ORGANIZATION.TITLE'),
    path: enumRoutes.organizationsNoProfitProfile,
    layout: AppLayout,
    component: Organization,
    type: type.AUTHENTICATION,
    params: { organizationsType: enumOrganizationsTypes.noprofit },
    roles: [enumRoles.admin],
  },
  {
    id: 'organizationsNoProfitLevel',
    title: Localize('PAGES.ORGANIZATION.TITLE'),
    path: enumRoutes.organizationsNoProfitLevel,
    layout: AppLayout,
    component: Organization,
    type: type.AUTHENTICATION,
    params: { organizationsType: enumOrganizationsTypes.noprofit },
    roles: [enumRoles.admin],
  },
  {
    id: 'organizationsNoProfitDeals',
    title: Localize('PAGES.ORGANIZATION.TITLE'),
    path: enumRoutes.organizationsNoProfitDeals,
    layout: AppLayout,
    component: Organization,
    type: type.AUTHENTICATION,
    params: { organizationsType: enumOrganizationsTypes.noprofit },
    roles: [enumRoles.admin],
  },
  {
    id: 'organizationsNoProfitDealsNoProfits',
    title: Localize('PAGES.ORGANIZATION.TITLE'),
    path: enumRoutes.organizationsNoProfitDealsNoProfits,
    layout: AppLayout,
    component: Organization,
    type: type.AUTHENTICATION,
    params: { organizationsType: enumOrganizationsTypes.noprofit },
    roles: [enumRoles.admin],
  },
  {
    id: 'organizationsNoProfitSubscriptions',
    title: Localize('PAGES.ORGANIZATION.TITLE'),
    path: enumRoutes.organizationsNoProfitSubscriptions,
    layout: AppLayout,
    component: Organization,
    type: type.AUTHENTICATION,
    params: { organizationsType: enumOrganizationsTypes.noprofit },
    roles: [enumRoles.admin],
  },
  // PA
  {
    id: 'organizationsNoProfitProfile',
    title: Localize('PAGES.ORGANIZATION.TITLE'),
    path: enumRoutes.organizationsPaProfile,
    layout: AppLayout,
    component: Organization,
    type: type.AUTHENTICATION,
    params: { organizationsType: enumOrganizationsTypes.pa },
    roles: [enumRoles.admin],
  },
  {
    id: 'organizationsNoProfitProfileEdit',
    title: 'Modifica Profilo',
    path: enumRoutes.organizationsNoProfitProfileEdit,
    layout: AppLayout,
    component: ProfileOrgEditScreen,
    type: type.AUTHENTICATION,
    params: { organizationsType: enumOrganizationsTypes.noprofit },
    roles: [enumRoles.admin],
  },
  {
    id: 'organizationsDonorsProfileEdit',
    title: 'Modifica Profilo',
    path: enumRoutes.organizationsDonorsProfileEdit,
    layout: AppLayout,
    component: ProfileOrgEditScreen,
    type: type.AUTHENTICATION,
    params: { organizationsType: enumOrganizationsTypes.companies },
    roles: [enumRoles.admin],
  },
  {
    id: 'organizationsPaProfileEdit',
    title: 'Modifica Profilo',
    path: enumRoutes.organizationsPaProfileEdit,
    layout: AppLayout,
    component: ProfileOrgEditScreen,
    type: type.AUTHENTICATION,
    params: { organizationsType: enumOrganizationsTypes.pa },
    roles: [enumRoles.admin],
  },
  {
    id: 'organizationsPA',
    title: Localize('PAGES.ORGANIZATION.TITLE'),
    path: enumRoutes.organizationsPa,
    layout: AppLayout,
    component: Organizations,
    type: type.AUTHENTICATION,
    params: { organizationsType: enumOrganizationsTypes.pa },
    roles: [enumRoles.admin],
  },
  // END ORGANIZATIONS
];

export default RoutesOrganizations;
