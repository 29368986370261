import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';

import app from './features/appSlice';
import user from './features/userSlice';
import profile from './features/profileSlice';
import organizations from './features/organizationsSlice';
import offers from './features/offersSlice';
import statistics from './features/statisticsSlice';
import subscriptions from './features/subscriptionSlice';
import reservations from './features/reservationsSlice';

const rootReducer = (history: History): any =>
  combineReducers({
    app,
    offers,
    organizations,
    profile,
    reservations,
    router: connectRouter(history),
    statistics,
    subscriptions,
    user,
  });

export default rootReducer;
