import { type } from '../../components/helpers/router';
import { AppLayout } from '../../components/layouts';
import { enumRoutes, enumRoles } from '../../libs/enums';

import Offers from '../../views/offers/offers.view';
import OfferNew from '../../views/offerNew/offerNew.view';

const RoutesOffers = [
  // OFFERS
  {
    id: 'offers',
    title: 'Offerte',
    path: enumRoutes.offers,
    layout: AppLayout,
    component: Offers,
    type: type.AUTHENTICATION,
    redirectTo: enumRoutes.offersAll,
    roles: [
      enumRoles.admin,
      enumRoles.noprofit,
      enumRoles.noprofitSub,
      enumRoles.noprofitSecond,
      enumRoles.noprofitSecondSub,
      enumRoles.donor,
      enumRoles.donorSub,
    ],
  },
  {
    id: 'offersAll',
    title: 'Offerte',
    path: enumRoutes.offersAll,
    layout: AppLayout,
    component: Offers,
    type: type.AUTHENTICATION,
    roles: [enumRoles.admin, enumRoles.donor, enumRoles.donorSub],
    params: { offersView: 'all' },
  },
  {
    id: 'offersAvailable',
    title: 'Offerte',
    path: enumRoutes.offersAvailable,
    layout: AppLayout,
    component: Offers,
    type: type.AUTHENTICATION,
    roles: [
      enumRoles.noprofit,
      enumRoles.noprofitSub,
      enumRoles.noprofitSecond,
      enumRoles.noprofitSecondSub,
    ],
    params: { offersView: 'available' },
  },
  {
    id: 'offersBooked',
    title: 'Offerte',
    path: enumRoutes.offersBooked,
    layout: AppLayout,
    component: Offers,
    type: type.AUTHENTICATION,
    roles: [
      enumRoles.noprofit,
      enumRoles.noprofitSub,
      enumRoles.noprofitSecond,
      enumRoles.noprofitSecondSub,
    ],
    params: { offersView: 'booked' },
  },
  {
    id: 'offersMyDonation',
    title: 'Offerte',
    path: enumRoutes.offersMyDonation,
    layout: AppLayout,
    component: Offers,
    type: type.AUTHENTICATION,
    roles: [enumRoles.noprofit, enumRoles.noprofitSub],
    params: { offersView: 'mydonation' },
  },
  {
    id: 'offersReservations',
    title: 'Offerte',
    path: enumRoutes.offersReservations,
    layout: AppLayout,
    component: Offers,
    type: type.AUTHENTICATION,
    roles: [enumRoles.noprofit, enumRoles.noprofitSub, enumRoles.donor, enumRoles.donorSub],
    params: { offersView: 'reservations' },
  },
  {
    id: 'offerNew',
    title: 'Nuova Offerta',
    path: enumRoutes.offerNew,
    layout: AppLayout,
    component: OfferNew,
    type: type.AUTHENTICATION,
    roles: [enumRoles.donor, enumRoles.donorSub],
  },
  {
    id: 'offerEdit',
    title: 'Modifica Offerta',
    path: enumRoutes.offerEdit,
    layout: AppLayout,
    component: OfferNew,
    type: type.AUTHENTICATION,
    roles: [enumRoles.donor, enumRoles.donorSub],
  },

  // END OFFERS
];

export default RoutesOffers;
