import React from 'react';
import { Menu, Dropdown, Space, Button } from 'antd';
import './styles.sass';

interface iProps {
  onEdit: () => void;
  onDelete: () => void;
  onVisibleChange: (visible: boolean) => void;
}

const DottedMenu: React.FC<iProps> = ({ onEdit, onDelete, onVisibleChange }: iProps) => {
  const renderDots = () => (
    <div className="dotContainer">
      <div className="dot" />
      <div className="dot" />
      <div className="dot" />
    </div>
  );

  const menu = () => (
    <Menu className="menu">
      <Menu.Item onClick={onEdit}>Modifica</Menu.Item>
      <Menu.Item onClick={onDelete}>Elimina</Menu.Item>
    </Menu>
  );

  return (
    <Space direction="vertical">
      <Space wrap>
        <Dropdown overlay={menu} placement="bottomRight" onVisibleChange={onVisibleChange}>
          {renderDots()}
        </Dropdown>
      </Space>
    </Space>
  );
};

export default DottedMenu;
