/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { iWidgetCards, iWidgetFilters } from '../../libs/interfaces';
import { getStatisticsWidgetApi } from '../../api/statistics.api';

import HearthIcon from '../../assets/images/heart-icon.png';
import ArrowIcon from '../../assets/images/arrow.png';
import SocialeIcon from '../../assets/images/sociale.png';
import LeafIcon from '../../assets/images/leaf.png';
import AcquaIcon from '../../assets/images/acqua.png';
import MapIcon from '../../assets/images/map.png';

import './style.sass';
//  import '../../assets/stylesheets/_css/responsive.css';  // TO BE VERIFY
//  import '../../assets/stylesheets/_css/style.css';       // TO BE VERIFY

interface ParamTypes {
  id: string;
  from: string;
  to: string;
}

const coefficient = {
  water: 1.9,
  earth: 2.9,
};

const Widget: React.FC = () => {
  const params = useParams<ParamTypes>();
  const [hearth, setHearth] = useState(0);
  const [sociale, setSociale] = useState(0);
  const [leaf, setLeaf] = useState(0);
  const [acqua, setAcqua] = useState(0);
  const [map, setMap] = useState(0);
  const [dateFrom, setDateFrom] = useState(moment());
  const [dateTo, setDateTo] = useState(moment());

  const getWidgetData = async () => {
    const filters: iWidgetFilters = { id: params.id };
    if (params.from) {
      filters.from = params.from;
    }
    if (params.to) {
      filters.to = params.to;
    }

    const response: iWidgetCards = await getStatisticsWidgetApi(filters);
    setHearth(response.social_impact);
    setSociale(response.social_impact_meal);
    setLeaf(response.enviroment_impact);
    setAcqua(Math.floor(response.enviroment_impact * coefficient.water));
    setMap(Math.floor(response.enviroment_impact * coefficient.earth));
    setDateFrom(moment(response.date_from));
    setDateTo(moment(response.date_to));
  };

  useEffect(() => {
    getWidgetData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      document
        .getElementById('hubspot-messages-iframe-container')!
        .setAttribute('style', 'display: none !important;');
    }, 1200);

    return () => {
      setTimeout(() => {
        document
          .getElementById('hubspot-messages-iframe-container')!
          .setAttribute('style', 'display: block !important;');
      }, 1200);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/jquery/2.2.4/jquery.min.js';
    script.integrity =
      'sha512-DUC8yqWf7ez3JD1jszxCWSVB0DMP78eOyBpMa5aJki1bIRARykviOuImIczkxlj1KhVSyS16w2FSQetkD4UU2w==';
    script.crossOrigin = 'anonymous';
    script.referrerPolicy = 'no-referrer';

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');

    script.src =
      'https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.2/js/bootstrap.bundle.min.js';

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/noUiSlider/15.6.0/nouislider.min.js';

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/wnumb/1.2.0/wNumb.min.js';

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="wrapper">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-8 bottom-space">
            <div className="heart-card">
              <div>
                <img src={HearthIcon} alt="" className="img-fluid" />
              </div>
              <div>
                <h2>
                  <b>{hearth.toLocaleString()}</b>
                  <span>kg</span>
                </h2>
                <p>Prodotti a rischio spreco recuperati</p>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="text-center arrow-img">
              <img src={ArrowIcon} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-4 col-lg-5 col-sm-4 bottom-space">
            <div className="box">
              <h6>Indice sociale</h6>
              <div className="inner-box">
                <div className="img-holder">
                  <img src={SocialeIcon} alt="" className="img-fluid" />
                </div>
                <span>Pasti totali distribuiti</span>
                <h5>{sociale.toLocaleString()}</h5>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-7 col-sm-8">
            <div className="box box-2">
              <h6>Indici Ambientali</h6>
              <div className="inner-boxes">
                <div className="inner-box leaf-box">
                  <div className="img-holder">
                    <img src={LeafIcon} alt="" className="img-fluid" />
                  </div>
                  <span>
                    Kg CO <sub>2</sub> Risparmiata{' '}
                  </span>
                  <h5>{leaf.toLocaleString()}</h5>
                </div>
                <div className="inner-box aqua-box">
                  <div className="img-holder">
                    <img src={AcquaIcon} alt="" className="img-fluid" />
                  </div>
                  <span>
                    M <sup>3</sup> Acqua Risparmiata{' '}
                  </span>
                  <h5>{acqua.toLocaleString()}</h5>
                </div>
                <div className="inner-box map-box">
                  <div className="img-holder">
                    <img src={MapIcon} alt="" className="img-fluid" />
                  </div>
                  <span>
                    M <sup>2</sup> Suolo Risparmiato{' '}
                  </span>
                  <h5>{map.toLocaleString()}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="para text-center">
              <p>
                Gli indici sono calcolati e generati dalla piattaforma <span>Regusto </span>
                condivisi e certificati in BlockChain
                <br />
                <i>dati aggiornati settimanalmente</i>
                <p className="date-text">
                  Ultimo aggiornamento dati: {dateTo.format('DD/MM/YYYY')} |{' '}
                  <a href="/">Norme sulla privacy</a>
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Widget;
