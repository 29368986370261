import React, { useState, ReactNode } from 'react';
import { Modal, Button, Checkbox, Row } from 'antd';

import Images from '../../assets/images';

import './style.sass';

interface iProps {
  header: ReactNode;
  modalVisible: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const SubscriptionContract: React.FC<iProps> = ({
  modalVisible,
  onClose,
  onConfirm,
  header,
}: iProps) => {
  const [checked, checkedSet] = useState<boolean>(false);

  function onChange(e: any) {
    checkedSet(e.target.checked);
  }

  return (
    <Modal
      visible={modalVisible}
      width={900}
      onCancel={onClose}
      modalRender={() => (
        <div className="modalContract ant-modal-content">
          <button type="button" className="modal__close" onClick={onClose}>
            <img src={Images.icoCloseDark.src} alt={Images.icoCloseDark.alt} />
          </button>
          {header}
          <div className="box--grey-xl">
            <strong>
              Una volta confermato riceverai entro pochi giorni una email di converma attivazione.
            </strong>
            per qualsiasi informazioni contattaci su cc@regusto.eu o attraverso la chat che trovi in
            basso a sinistra di ogni pagina
          </div>
          <div className="my-30">
            <Checkbox onChange={onChange} checked={checked}>
              <strong className="f-s-17">
                Confermo di voler accettare la sottoscrizione del contratto
              </strong>
            </Checkbox>
          </div>
          <Row justify="center">
            <Button disabled={!checked} onClick={onConfirm}>
              CONFERMA
            </Button>
          </Row>
        </div>
      )}
    />
  );
};

export default SubscriptionContract;
