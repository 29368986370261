import React from 'react';
import { Col } from 'antd';
import { enumOffersDelivery } from '../../libs/enums';
import Images from '../../assets/images';
import { iOffer } from '../../libs/interfaces';

interface iProps {
  deliveryType: enumOffersDelivery;
  isOffer: boolean;
  offer: iOffer;
  distance?: string;
}

const Delivery: React.FC<iProps> = ({ deliveryType, isOffer, offer, distance }: iProps) => {
  const renderBody = () => {
    switch (deliveryType) {
      case enumOffersDelivery.shipment:
        return (
          <li>
            <img src={Images.icoDelivery.src} alt={Images.icoDelivery.alt} />
            {/* <span>{isOffer ? 'Possibilità di spedizione' : 'Spedizione'}</span> */}
            <span>Spedizione</span>
          </li>
        );
      case enumOffersDelivery.collection:
        return (
          <li>
            <img src={Images.icoPickup.src} alt={Images.icoPickup.alt} />
            <Col>
              <div>Ritiro</div>
              {isOffer && (
                <span className="f-w-500">
                  {distance && `${distance} Km - `}
                  {offer?.donor?.operationalData && offer.donor.operationalData.city}
                </span>
              )}
            </Col>
          </li>
        );
      default:
        return null;
    }
  };

  return renderBody();
};

Delivery.defaultProps = {
  distance: '',
};

export default Delivery;
