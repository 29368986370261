/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { RootState } from '../../store';

import { reservationsAsynGetAction } from '../../store/features/reservationsSlice';

import './style.sass';

const formatNumber = (num: any): string => {
  if (!num || num.value === undefined) return '';
  return num.value.toString().replace('.', ',');
};

const baseCol = { sortable: true, filter: true, resizable: true };

function mapCols() {
  const cols = [
    { headerName: 'ID transazione', field: 'idTransaction', width: 350 },
    { headerName: 'Beneficiario', field: 'noProfit' },
    { headerName: 'Donatore', field: 'donations' },
    { headerName: 'Data prenotazione', field: 'bookingDate' },
    { headerName: 'Categoria', field: 'category' },
    { headerName: 'Sotto categoria', field: 'subCategory' },
    { headerName: 'Peso unità', field: 'specs', valueFormatter: formatNumber },
    { headerName: 'udm', field: 'udm', width: 100 },
    { headerName: 'Tipologia offerta', field: 'categoryType', width: 160 },
    {
      headerName: 'Crediti per unità',
      field: 'unitCost',
      width: 160,
      valueFormatter: formatNumber,
    },
    {
      headerName: 'Quantità totale',
      field: 'totalUnits',
      width: 150,
      valueFormatter: formatNumber,
    },
    { headerName: 'Crediti totali', field: 'credits', width: 140, valueFormatter: formatNumber },
    { headerName: 'Modalità di consegna', field: 'deliveryType' },
    {
      headerName: 'Crediti spedizione',
      field: 'shipmentCost',
      width: 170,
      valueFormatter: formatNumber,
    },
    { headerName: 'Peso totale (kg)', field: 'weight', width: 160, valueFormatter: formatNumber },
    { headerName: 'Kg CO2 risparmiata', field: 'co2', valueFormatter: formatNumber },
  ];

  return cols.map((c: { headerName: string; field: string }) => {
    return { ...c, ...baseCol };
  });
}

const columns = mapCols();

const Reservations: React.FC = () => {
  const dispatch = useDispatch();
  const { reservations } = useSelector((state: RootState) => state);
  const { list } = reservations;

  const [data, dataSet] = useState<any[]>([]);
  const [gridApi, gridApiSet] = useState<any>(null);

  useEffect(() => {
    dispatch(reservationsAsynGetAction(null));
  }, []);

  useEffect(() => {
    dataSet(list);
  }, [list]);

  function onGridReady(params: any) {
    gridApiSet(params.api);
  }

  function onCsvClicked() {
    if (gridApi) {
      gridApi.exportDataAsCsv({
        processCellCallback: (cell: any) => {
          // Manipulate the value however you need.
          if (typeof cell.value === 'number' || typeof cell.value === 'bigint') {
            return formatNumber(cell);
          }
          return cell.value;
        },
      });
    }
  }

  return (
    <>
      <div className="my-30">
        <Button onClick={onCsvClicked}>Download CSV</Button>
      </div>
      <div className="transactions ag-theme-alpine">
        <AgGridReact onGridReady={onGridReady} columnDefs={columns} rowData={data} />
      </div>
    </>
  );
};

export default Reservations;
