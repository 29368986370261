import React, { useMemo } from 'react';
import { Row } from 'antd';
import moment from 'moment';
import { capitalize } from 'lodash';

import { iOrganization, iProfileData, iSubRequestResponse } from '../../libs/interfaces';
import { renderAvatar } from '../../libs/helpers/profile.helpers';
import { getSubscriptionColor } from '../../libs/helpers/subscriptions.helpers';
import { SvgDiamond } from '../../assets/images';
import Badge from '../badges/badges';
import './style.sass';

interface iProps {
  currentPlan: iSubRequestResponse | undefined;
  profile: iProfileData | iOrganization;
}

const ProfileCard: React.FC<iProps> = ({ currentPlan, profile }: iProps) => {
  const avatar = useMemo(() => {
    return renderAvatar(profile);
  }, [profile]);

  const planName = currentPlan ? `Piano ${capitalize(currentPlan.subscription)}` : 'Piano Free';
  const color = getSubscriptionColor(currentPlan?.subscription || 'free');
  return (
    <Row className="profileCard">
      <div className="profileCard__avatar">{avatar}</div>
      <div className="c-light ml-20">
        <h1>{profile.name}</h1>
        <div>Data creazione: {moment(profile.created_at).format('DD/MM/YYYY')}</div>
        <Row>
          <Badge
            text={planName}
            image={SvgDiamond('ffffff')}
            moreClass={`badge--plan bg-${color}`}
          />
          {currentPlan && <div>Scadenza: {moment(currentPlan.date_end).format('DD/MM/YYYY')}</div>}
        </Row>
      </div>
    </Row>
  );
};

export default ProfileCard;
