import { type } from '../../components/helpers/router';
import { AuthLayout } from '../../components/layouts';
import Localize from '../../libs/localization';
import { enumRoutes } from '../../libs/enums';

import Login from '../../views/auth/login.view';
import Registration from '../../views/auth/registration.view';
import Thanks from '../../views/auth/thankyou.view';
import NewPasword from '../../views/auth/newPassword.view';
import NewPaswordSend from '../../views/auth/newPasswordSend.view';
import ChangePassword from '../../views/auth/changePassword.view';

const RoutesAuth = [
  // AUTH
  {
    id: 'login',
    title: Localize('PAGES.LOGIN.TITLE'),
    path: enumRoutes.login,
    layout: AuthLayout,
    component: Login,
    type: type.PUBLIC,
  },
  {
    id: 'registrationNoprofit',
    title: Localize('PAGES.REGISTRATION.TITLE'),
    path: enumRoutes.registrationNoprofit,
    layout: AuthLayout,
    component: Registration,
    type: type.PUBLIC,
    params: { organizationsType: 'noprofit' },
  },
  {
    id: 'registrationDonor',
    title: Localize('PAGES.REGISTRATION.TITLE'),
    path: enumRoutes.registrationDonor,
    layout: AuthLayout,
    component: Registration,
    type: type.PUBLIC,
    params: { organizationsType: 'donor' },
  },
  {
    id: 'thankyou',
    title: Localize('PAGES.REQUEST.TITLE'),
    path: enumRoutes.loginConfirm,
    layout: AuthLayout,
    component: Thanks,
    type: type.PUBLIC,
  },
  {
    id: 'newpassword',
    title: Localize('PAGES.REQUEST.TITLE'),
    path: enumRoutes.newpassword,
    layout: AuthLayout,
    component: NewPasword,
    type: type.PUBLIC,
  },
  {
    id: 'newpassword',
    title: Localize('PAGES.REQUEST.TITLE'),
    path: enumRoutes.newpasswordConfim,
    layout: AuthLayout,
    component: NewPaswordSend,
    type: type.PUBLIC,
  },
  {
    id: 'changePassword',
    title: 'Nuova password',
    path: enumRoutes.changePassword,
    layout: AuthLayout,
    component: ChangePassword,
    type: type.AUTHENTICATION,
  },
  // END AUTH
];

export default RoutesAuth;
