/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Button } from 'antd';

import { Link } from 'react-router-dom';
import { iOrganizationsList } from '../../libs/interfaces/organizations.interfaces';
import { organizationsGetAsyncAction } from '../../store/features/organizationsSlice';
import { debounce } from '../../libs/helpers/commons';
import { enumOrganizationsTypes } from '../../libs/enums';
import OrganizationsCard from './organizationsCard';

import './style.sass';

interface iProps {
  match: any;
}

const Organizations: React.FC<iProps> = ({ match }: iProps) => {
  const dispatch = useDispatch();
  const list: iOrganizationsList = useSelector((state: any) => state.organizations.list);
  const [filterText, filterTextSet] = useState<string>('');
  const [filterType, filterTypeSet] = useState<enumOrganizationsTypes | undefined>();
  const [listFiltered, listFilteredSet] = useState<any[]>([]);

  const [orgName, orgNameSet] = useState<string>('Aziende');

  useEffect(() => {
    dispatch(organizationsGetAsyncAction(null));
  }, []);

  useEffect(() => {
    const { params } = match;
    if (params.organizationsType) {
      filterTypeSet(params.organizationsType);
    }
  }, [match]);

  useEffect(() => {
    let arrOrganizations: any[] = [];
    if (filterType === enumOrganizationsTypes.companies) {
      arrOrganizations = [...list.donors];
      orgNameSet('Aziende');
    } else if (filterType === enumOrganizationsTypes.noprofit) {
      arrOrganizations = [...list.noprofits];
      orgNameSet('Non Profit');
    } else if (filterType === enumOrganizationsTypes.pa) {
      orgNameSet('P.A.');
      arrOrganizations = [...list.pas];
    } else {
      arrOrganizations = [...list.donors, ...list.noprofits, ...list.pas];
    }

    const listFilteredWithtext = arrOrganizations.filter((i: any) =>
      i.name.toLowerCase().includes(filterText.toLowerCase()),
    );
    listFilteredSet(listFilteredWithtext);
  }, [filterText, filterType, list]);

  const debouncedSave = useCallback(
    debounce((nextValue: string) => filterTextSet(nextValue), 300),
    [],
  );

  function onSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    debouncedSave(e.target.value);
  }

  const attivi = listFiltered.filter((o) => o.account.status === 'active');

  return (
    <div>
      <Row justify="space-between" className="organizations__head">
        <Col sm={12} className="organizations__search">
          <div className="box box--grey-xl">
            <input type="search" onChange={onSearchChange} />
          </div>
          <Button>Cerca</Button>
        </Col>
        <div className="box box--grey-xl">
          Tot. {orgName} attive: &nbsp;<strong>{attivi.length}</strong>
        </div>
        {filterType === enumOrganizationsTypes.pa && (
          <Link to="/organizations/pa/new/profile/edit" className="ant-btn ant-btn-primary">
            AGGIUNGI P.A.
          </Link>
        )}
      </Row>
      <div className="organizations">
        {listFiltered.map((organization: any) => (
          <OrganizationsCard
            key={organization.id}
            organization={organization}
            organizationType={filterType}
          />
        ))}
      </div>
    </div>
  );
};

export default Organizations;
