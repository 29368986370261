import React, { useMemo } from 'react';
import moment from 'moment';

import { isAdmin } from '../../libs/helpers/privileges.helpers';
import { enumViews, enumBadges } from '../../libs/enums';
import { iOffer } from '../../libs/interfaces';
import Images from '../../assets/images';
import Badge from '../badges/badges';

function isExpiring(endDate: string | null) {
  if (!endDate) {
    return false;
  }
  const nextWeek = moment().add(7, 'days').format();
  return moment(endDate).isBefore(nextWeek) && moment(endDate).isAfter(moment());
}

interface iProps {
  offer: iOffer;
  role: string;
  view: enumViews;
}

const OffersCardBadges: React.FC<iProps> = ({ offer, role, view }: iProps) => {
  const { endDate, info, order } = offer;
  const hasBadges = useMemo(() => {
    let ok = false;
    if (isExpiring(endDate) || info?.co2) {
      ok = true;
    }
    return ok;
  }, [endDate, info]);

  function co2Tag() {
    return (
      <li>
        <Badge
          text={
            <>
              -{order ? Math.floor(order.info.co2) : Math.floor(info.co2)} kg di CO<sup>2</sup>
            </>
          }
          type={enumBadges.co2}
        />
      </li>
    );
  }

  const renderBadges = () => {
    switch (view) {
      case enumViews.available:
      case enumViews.booked:
      case enumViews.all:
        return (
          <>
            {isExpiring(endDate) && (
              <li>
                <Badge text="Ancora per poco" type={enumBadges.red} />
              </li>
            )}
            {(info?.co2 > 0 || order?.info?.co2) && co2Tag()}
          </>
        );
      case enumViews.mydonation:
        return (
          <>
            <li>
              <Badge text="Condivisa" />
            </li>
            {(info?.co2 > 0 || order?.info?.co2) && co2Tag()}
          </>
        );
      case enumViews.reservations:
        return co2Tag();
      default:
        return null;
    }
  };

  return <>{hasBadges ? <ul className="oCard__badges">{renderBadges()}</ul> : null}</>;
};

export default OffersCardBadges;
