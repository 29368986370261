/* eslint-disable import/prefer-default-export */

import Config from '../config/Config';
import { Api } from '../libs/callApi';

import { iOrganizationsList } from '../libs/interfaces/organizations.interfaces';

const api = new Api(Config.apiBaseurl);

export const getOrganizationsApi = async (): Promise<iOrganizationsList> => {
  const call = await api.makeCall({
    path: Config.apis.organizations,
    method: 'GET',
    autorized: true,
  });

  return call.body;
};

export const setOrganizationStatusApi = async (body: string): Promise<iOrganizationsList> => {
  const call = await api.makeCall({
    path: `${Config.apis.organizationsStatus}/${body}`,
    method: 'POST',
    autorized: true,
  });

  return call;
};
