import React, { useEffect } from 'react';
import { Form, InputNumber, Select, Row, Col, Radio, DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/it_IT';

import {
  enumOffersOfferType,
  enumOffersExpiryType,
  enumOffersDonationType,
} from '../../libs/enums';
import { FormItemWithLetter, FormLabelWithTooltip } from '../../components';
import Config from '../../config/Config';

const { Option } = Select;

interface iProps {
  isEditMode: boolean;
  offerTypeSale: boolean;
  offerTypeCategory: string;
  form: any;
}

const OfferNewTypologies: React.FC<iProps> = ({
  isEditMode,
  offerTypeSale,
  offerTypeCategory,
  form,
}: iProps) => {
  useEffect(() => {
    const currentValues = form.getFieldsValue();
    if (offerTypeSale) {
      currentValues.typeDonation = null;
    } else {
      currentValues.typeDonation = enumOffersDonationType.riskOfWaste;
    }
    form.setFieldsValue(currentValues);
  }, [form, offerTypeSale]);

  return (
    <>
      <Row gutter={16}>
        <Col sm={12}>
          <Form.Item label="Tipologia offerta" name="type" rules={[{ required: true }]}>
            <Radio.Group disabled={isEditMode}>
              <Radio value={enumOffersOfferType.donation}>Donazione</Radio>
              <Radio value={enumOffersOfferType.sale}>Vendita</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col sm={12}>
          <FormItemWithLetter letter={offerTypeSale ? '' : '€'}>
            <Form.Item
              name="unitCost"
              label={
                <FormLabelWithTooltip
                  label={offerTypeSale ? 'Crediti per unità' : 'Valore per unità'}
                  tooltip={
                    offerTypeSale
                      ? 'Devi indicare un numero (anche decimale) relativo al costo esatto di una unità di prodotto'
                      : 'In caso di donazione puoi indicare il valore indicativo del bene che stai donando'
                  }
                  required={offerTypeSale}
                />
              }
              rules={[
                () => ({
                  validator(rule, value) {
                    if (!offerTypeSale) {
                      return Promise.resolve();
                    }
                    if (value) {
                      const regex = Config.regex.maxTwoDecimal;
                      if (regex.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('Può contenere un massimo di due cifre decimali'),
                      );
                    }

                    return Promise.reject(
                      new Error(
                        'Il campo è obbligatorio se la tipologia offerta è di tipo vendita',
                      ),
                    );
                  },
                }),
              ]}
            >
              <InputNumber
                placeholder={
                  offerTypeSale
                    ? 'Numero di crediti richiesti per singola unita prenotata'
                    : 'inserisci un valore in euro per unità'
                }
              />
            </Form.Item>
          </FormItemWithLetter>
        </Col>
      </Row>

      {!offerTypeSale && (
        <>
          <hr className="div" />

          <Row gutter={16}>
            <Col sm={24}>
              <Form.Item
                label="Tipologia donazione"
                name="typeDonation"
                rules={[{ required: true }]}
              >
                <Radio.Group disabled={isEditMode}>
                  <Radio value={enumOffersDonationType.riskOfWaste}>A rischio spreco</Radio>
                  <Radio value={enumOffersDonationType.voluntaryDonation}>
                    Donazione volontaria
                  </Radio>
                  {offerTypeCategory === 'non-food' && (
                    <>
                      <Radio value={enumOffersDonationType.returned}>Reso</Radio>
                      <Radio value={enumOffersDonationType.slowMovers}>Slow movers</Radio>
                      <Radio value={enumOffersDonationType.obsolete}>Obsoleti</Radio>
                      <Radio value={enumOffersDonationType.defective}>Difettosi</Radio>
                    </>
                  )}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      {offerTypeCategory === 'food' && (
        <>
          <hr className="div" />

          <Row gutter={16}>
            <Col sm={12}>
              <Form.Item label="Tipologia scadenza" name="expiryType">
                <Select placeholder="TMC, scadenza" disabled={isEditMode}>
                  <Option value={enumOffersExpiryType.tmc}>TMC</Option>
                  <Option value={enumOffersExpiryType.expiration}>Data scadenza</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item label="Data" name="expiryDate">
                <DatePicker
                  disabled={isEditMode}
                  placeholder="gg/mm/aaaa"
                  format="DD-MM-YYYY"
                  locale={locale}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default OfferNewTypologies;
