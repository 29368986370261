/* eslint-disable react/require-default-props */
import React from 'react';
import { Layout } from 'antd';

import Logo from '../../logo/logo';
import './style.sass';

interface iProps {
  children: any;
}

const AuthLayout = ({ children }: iProps) => {
  return (
    <Layout className="layout-auth">
      <div>
        <Logo />
        <div className="box--white mt-30">{children}</div>
      </div>
    </Layout>
  );
};

export default AuthLayout;
