/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import history from '../history';

import { userImpersonateEmailAction } from './userActions';
import { getProfileApi, updateProfileApi, setPrivacyApi } from '../../api/profile.api';
import { iProfileData, iProfileState, iProfileSubscription } from '../../libs/interfaces';
import { asyncThunkErrorHelper } from '../../libs/helpers/commons';
import { setPlanWithRole } from '../../libs/helpers/subscriptions.helpers';
import { enumRoutes } from '../../libs/enums';

export const initialProfileDataState: iProfileData = {
  name: '',
  phone: '',
  taxCode: '',
  vatNumber: '',
  sdiCode: '',
  subAccounts: [],
  operationalData: {
    city: '',
    street: '',
    zip: '',
    state: '',
    phone: '',
  },
  administrativeData: {
    street: '',
    city: '',
    zip: '',
    state: '',
    phone: '',
  },
  lat: null,
  lng: null,
};

const initialState: iProfileState = {
  account: null,
  data: initialProfileDataState,
  complete: false,
  withAgreements: false,
  gdpr: false,
  subscription: null,
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    profileSetAccountAction: (state, action: PayloadAction<any>) => {
      state.account = action.payload;
    },
    profileSetDataAction: (state, action: PayloadAction<iProfileData>) => {
      state.data = action.payload;
    },
    profileSetSubscriptionAction: (state, action: PayloadAction<iProfileSubscription>) => {
      state.subscription = action.payload;
    },
    profileSetCompleteAction: (state, action: PayloadAction<boolean>) => {
      state.complete = action.payload;
    },
    profileSetWithAgreementsAction: (state, action: PayloadAction<boolean>) => {
      state.withAgreements = action.payload;
    },
    profileSetGDPRAction: (state, action: PayloadAction<boolean>) => {
      state.gdpr = action.payload;
    },
    profileResetAction: () => {
      return initialState;
    },
  },
});

export const {
  profileSetAccountAction,
  profileSetDataAction,
  profileResetAction,
  profileSetCompleteAction,
  profileSetWithAgreementsAction,
  profileSetGDPRAction,
  profileSetSubscriptionAction,
} = slice.actions;

export default slice.reducer;

export const profileAsynGetAction = createAsyncThunk(
  'profile/profileAsynGetAction',
  async (payload: any, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await getProfileApi();
      const { data, complete, withAgreements, account, subscription } = response;
      const state: any = getState();
      const cloneAccount: any = { ...account };
      if (Object.prototype.hasOwnProperty.call(cloneAccount, 'password')) {
        delete cloneAccount.password;
      }
      subscription.planWithRole = setPlanWithRole(subscription.subscription, state.user.role);

      // account.gdpr = false;

      dispatch(profileSetAccountAction(cloneAccount));
      dispatch(profileSetDataAction(data));
      dispatch(profileSetCompleteAction(complete));
      dispatch(profileSetWithAgreementsAction(withAgreements));
      dispatch(profileSetGDPRAction(account.gdpr));
      dispatch(userImpersonateEmailAction(account.email));
      dispatch(profileSetSubscriptionAction(subscription));

      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const profileAsynUpdateAction = createAsyncThunk(
  'profile/profileAsynUpdateAction',
  async (payload: iProfileData, { rejectWithValue, dispatch, getState }) => {
    try {
      const state: any = getState();
      const currentStatusComplete = state.profile.complete;

      const profile: any = { ...payload };
      delete profile.subAccounts;
      const data: any = {
        profile,
        subAccounts: payload.subAccounts,
      };

      const response: any = await updateProfileApi(data);
      if (response.payload?.ok) {
        dispatch(profileSetDataAction(payload));
        if (!currentStatusComplete) {
          dispatch(profileSetCompleteAction(true));
        }
        const newRoute: string = currentStatusComplete ? enumRoutes.profileEdit : enumRoutes.offers;
        history.replace(newRoute);
        toast.success('Il profilo è stato salvato.');
      }

      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const privacyAsynPostAction = createAsyncThunk(
  'profile/privacyAsynPostAction',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await setPrivacyApi();
      if (response.payload?.ok) {
        dispatch(profileSetGDPRAction(true));
      }
      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);
