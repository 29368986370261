/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { iOffer, iOfferApi, iUserState, iCategoriesApi } from '../../libs/interfaces';
import { asyncThunkErrorHelper } from '../../libs/helpers/commons';
import { mapOfferWithOrder, decodeOfferCredits } from '../../libs/helpers/offers.helpers';

import {
  createOfferApi,
  getCategoriesApi,
  getMeasuresApi,
  getOfferDetailApi,
  getOffersApi,
  editOfferApi,
  deleteOfferApi,
  getDonorsApi,
} from '../../api/offers.api';
import { enumRoutes } from '../../libs/enums';

import history from '../history';

interface iOffersState {
  categories: iCategoriesApi[];
  categoriesUsed: iCategoriesApi[];
  currentFilers: string;
  currentView: string | null;
  detail: iOffer | null;
  list: iOffer[];
  measures: any[];
  donors: any[];
}

const initialState: iOffersState = {
  categories: [],
  categoriesUsed: [],
  currentView: null,
  currentFilers: '',
  detail: null,
  list: [],
  measures: [],
  donors: [],
};

const slice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    offersSetCategoriesAction: (state, action: PayloadAction<any>) => {
      state.categories = action.payload;
    },
    offersSetCategoriesUsedAction: (state, action: PayloadAction<any>) => {
      state.categoriesUsed = action.payload;
    },
    offersSetMeasuresAction: (state, action: PayloadAction<any>) => {
      state.measures = action.payload;
    },
    offersSetListAction: (state, action: PayloadAction<iOffer[]>) => {
      state.list = action.payload;
    },
    offersResetListAction: (state) => {
      state.list = [];
    },
    offerSetDetailAction: (state, action: PayloadAction<any>) => {
      state.detail = action.payload;
    },
    offerSetCurrentFiltersAction: (state, action: PayloadAction<any>) => {
      state.currentFilers = action.payload;
    },
    offerSetCurrentViewAction: (state, action: PayloadAction<any>) => {
      state.currentView = action.payload;
    },
    offersSetDonorsAction: (state, action: PayloadAction<any>) => {
      state.donors = action.payload;
    },
  },
});

export const {
  offersSetCategoriesAction,
  offersSetCategoriesUsedAction,
  offersSetMeasuresAction,
  offersSetListAction,
  offersResetListAction,
  offerSetDetailAction,
  offerSetCurrentFiltersAction,
  offerSetCurrentViewAction,
  offersSetDonorsAction,
} = slice.actions;

export default slice.reducer;

export const offersAsynGetAction = createAsyncThunk(
  'offers/offersAsynGetAction',
  async (payload: null, { rejectWithValue, dispatch, getState }) => {
    try {
      const { user, offers } = getState() as { user: iUserState; offers: iOffersState };
      const uriParams = offers.currentFilers;
      const view = offers.currentView;
      if (uriParams && view) {
        dispatch(offersSetListAction([]));
        const response: any[] = await getOffersApi(uriParams, user.role, view);
        const offersList: iOffer[] = mapOfferWithOrder(response);
        dispatch(offersSetListAction(offersList));
        return response;
      }
      return null;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const offersAsyncDeleteAction = createAsyncThunk(
  'offers/offersAsyncCreateAction',
  async (
    payload: { offerId: string; filters: string },
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const { offerId, filters } = payload;
      const response: any = await deleteOfferApi(offerId);
      if (response.payload?.ok) {
        dispatch(offerSetDetailAction(null));
        dispatch(offersAsynGetAction(null));
        toast.success('Offerta eliminata!');
      }
      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const offersAsyncCreateAction = createAsyncThunk(
  'offers/offersAsyncCreateAction',
  async (payload: iOfferApi, { rejectWithValue, dispatch, getState }) => {
    try {
      const response: any = await createOfferApi(payload);
      if (response.payload?.ok) {
        toast.success('Offerta creata!');
        history.push(enumRoutes.offersAll);
      }
      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const offersAsyncEditAction = createAsyncThunk(
  'offers/offersAsyncCreateAction',
  async (payload: iOfferApi, { rejectWithValue, dispatch }) => {
    try {
      const response: any = await editOfferApi(payload);
      if (response.payload?.ok) {
        toast.success('Modifica effettuata!');
        const data = decodeOfferCredits(response.body);
        dispatch(offerSetDetailAction(data));
      }
      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const offersAsyncGetCategoriesAction = createAsyncThunk(
  'offers/offersAsyncGetCategoriesAction',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response: any = await getCategoriesApi();
      dispatch(offersSetCategoriesAction(response.body));
      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const offersAsyncGetCategoriesUsedAction = createAsyncThunk(
  'offers/offersAsyncGetCategoriesUsedAction',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response: any = await getCategoriesApi(true);
      dispatch(offersSetCategoriesUsedAction(response.body));
      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const offersAsyncGetMeasuresAction = createAsyncThunk(
  'offers/offersAsyncGetMeasuresAction',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response: any = await getMeasuresApi();
      dispatch(offersSetMeasuresAction(response.body));
      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const offerDetailAsyncGetAction = createAsyncThunk(
  'offers/offerDetailAsyncGetAction',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response: any = await getOfferDetailApi(id);
      const data: iOffer = decodeOfferCredits(response);
      dispatch(offerSetDetailAction(data));
      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const offersAsyncGetDonorsAction = createAsyncThunk(
  'offers/offersAsyncGetDonorsAction',
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      const response: any = await getDonorsApi();
      dispatch(offersSetDonorsAction(response.body));
      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);
