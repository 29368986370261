import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

import Config from '../../config/Config';
import { enumRoutes, enumViews, enumSubscriptions } from '../../libs/enums';
import { iProfileState, iUserState } from '../../libs/interfaces';

// import { importOffersApi } from '../../api/offers.api';
import { isAdmin, isDonor } from '../../libs/helpers/privileges.helpers';

import ModalDonorSubscriptions from '../modalDonorSubscriptions/modalDonorSubscriptions';

interface iProps {
  user: iUserState;
  profile: iProfileState;
  view: enumViews;
}

const OffersButtons: React.FC<iProps> = ({ user, profile, view }: iProps) => {
  const [modalSub, modalSubSet] = useState<boolean>(false);
  const canCreate = isDonor(user.role) && view === enumViews.all && profile.complete;

  // async function onCsvChange(file: any) {
  //   try {
  //     const data = { file };
  //     console.log(data);
  //     const response: any = await importOffersApi(data);
  //     if (response.payload?.ok) {
  //       toast.success(
  //         "Il file è stato inviato correttamente. Riceverai un'email di conferma quando l'operazione di importazione delle offerte sarà completata",
  //       );
  //     }
  //   } catch (err) {
  //     toast.error("Si è verificato un problema nell'importazione del file");
  //   }
  // }

  const propsUpload = {
    action: `${Config.apiBaseurl}v2/a/offers/csv`,
    headers: {
      authorization: `Bearer ${user.token}`,
    },
    multiple: false,
    onChange(info: any) {
      // if (info.file.status !== 'uploading') {
      //   console.log(info.file, info.fileList);
      // }
      if (info.file.status === 'done') {
        toast.success(
          "Il file è stato inviato correttamente. Riceverai un'email di conferma quando l'operazione di importazione delle offerte sarà completata",
          { autoClose: 10000 },
        );
      } else if (info.file.status === 'error') {
        toast.error("Si è verificato un problema nell'importazione del file");
      }
    },
  };

  const propsUploadAdminTransactions = {
    action: `${Config.apiBaseurl}v2/a/offers/transactions/csv`,
    headers: {
      authorization: `Bearer ${user.token}`,
    },
    multiple: false,
    onChange(info: any) {
      if (info.file.status === 'done') {
        toast.success(
          "Il file è stato inviato correttamente. Riceverai un'email di conferma quando l'operazione di importazione delle transazioni sarà completata",
          { autoClose: 10000 },
        );
      } else if (info.file.status === 'error') {
        toast.error("Si è verificato un problema nell'importazione del file");
      }
    },
  };

  function showSubscriptions() {
    modalSubSet(true);
  }

  function onCloseModal() {
    modalSubSet(false);
  }

  return (
    <>
      {canCreate && (
        <Link className="ant-btn" to={enumRoutes.offerNew}>
          NUOVA OFFERTA
        </Link>
      )}
      {isDonor(user.role) && (
        <>
          {profile.subscription?.planWithRole !== enumSubscriptions.donorAdvanced ? (
            <Button onClick={showSubscriptions} icon={<UploadOutlined />} className="ml-15">
              Importa file csv
            </Button>
          ) : (
            <Upload {...propsUpload} showUploadList={false} className="ml-15" accept=".csv">
              <Button icon={<UploadOutlined />}>Importa file csv</Button>
            </Upload>
          )}
          <ModalDonorSubscriptions visibility={modalSub} onCloseModal={onCloseModal} onlyAdvanced />
        </>
      )}
      {isAdmin(user.role) && (
        <>
          <Upload {...propsUpload} showUploadList={false} className="ml-15" accept=".csv">
            <Button icon={<UploadOutlined />}>IMPORTA OFFERTE DA CSV</Button>
          </Upload>

          <Upload
            {...propsUploadAdminTransactions}
            showUploadList={false}
            className="ml-15"
            accept=".csv"
          >
            <Button icon={<UploadOutlined />}>IMPORTA TRANSAZIONI DA CSV</Button>
          </Upload>
        </>
      )}
    </>
  );
};

export default OffersButtons;
