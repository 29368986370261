/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  createProfilePAApi,
  getProfileByIdApi,
  igetProfileReturn,
  updateProfileByIdApi,
} from '../../api/profile.api';
import { enumRoutes } from '../../libs/enums';
import ProfileEdit from './profile.edit';
import './style.sass';

interface iProps {
  match: any;
}

const ProfileOrgEditScreen: React.FC<iProps> = ({ match }: iProps) => {
  const history = useHistory();
  const { params } = match;
  const { id } = params;

  const [org, setOrg] = useState<igetProfileReturn | undefined>(undefined);

  const reloadOrg = async () => {
    if (id === 'new') {
      const subAcc = {
        email: '',
        phone: '',
        status: 'active',
      };
      setOrg({ data: { subAccounts: [subAcc] } } as any);
      return;
    }
    getProfileByIdApi(id)
      .then(setOrg)
      .catch((ex) => {
        console.error(ex);
      });
  };
  useEffect(() => {
    reloadOrg();
  }, []);

  if (!org) return <div />;
  const back = id === 'new' ? enumRoutes.organizationsPa : undefined;
  return (
    <div className="container-small">
      <ProfileEdit
        back={back}
        complete={org.complete}
        org={org.data}
        onUpdate={async (payload) => {
          const data = {
            profile: payload,
            subAccounts: payload.subAccounts ? payload.subAccounts : [],
          };
          if (id === 'new') {
            const idNew = await createProfilePAApi(data);
            const newRoute: string = enumRoutes.organizationsPaProfileEdit.replace(':id', idNew);
            history.replace(newRoute);
            toast.success('Il profilo è stato creato.');
          } else {
            await updateProfileByIdApi(id, data);
            toast.success('Il profilo è stato salvato.');
            reloadOrg();
          }
        }}
      />
    </div>
  );
};

export default ProfileOrgEditScreen;
