/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Row, Col, Select, Checkbox, Form, Button } from 'antd';

import { enumViews } from '../../libs/enums';
import { iCategoriesApi, iDonors, iSpecification } from '../../libs/interfaces';
import {
  findMainCategory,
  renderCategoryObject,
  offersCategoryTypeSource,
  offersTypeSource,
  offersDeliverySource,
  offersStatusSource,
} from '../../libs/helpers/offers.helpers';
import { isNoProfit, isDonor } from '../../libs/helpers/privileges.helpers';
import Images from '../../assets/images';

import OffersFiltersTags from './offersFilterTags';
import './style.sass';

const { Option } = Select;

enum enumOrderBy {
  empty = '',
  dataAsc = 'end-date-asc',
  dataDesc = 'end-date-desc',
  distance = 'distance',
  priceAsc = 'price-asc',
  priceDesc = 'price-desc',
}

const arrayKm = [10, 20, 50, 100];

export interface iOffersFilters {
  orderBy: enumOrderBy;
  filters: any;
}

const initFilters: iOffersFilters = {
  orderBy: enumOrderBy.empty,
  filters: {},
};

interface iProps {
  offersButtons: any;
  categories: any;
  donors: iDonors[];
  onApplyFilters: (values: iOffersFilters) => void;
  specifications: Record<string, iSpecification>;
  user: any;
  view: enumViews;
}

// TODO verificare se selectedType, mainCategories, selectedCategory e categoryToShow sono necessari
const OffersFilters: React.FC<iProps> = ({
  offersButtons,
  categories,
  donors,
  onApplyFilters,
  specifications,
  user,
  view,
}: iProps) => {
  const userRole = user.role;
  const [form] = Form.useForm();
  const [more, moreSet] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<any>(null);
  const [mainCategories, setMainCategories] = useState<iCategoriesApi[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const [categoryToShow, setCategoryToShow] = useState<iCategoriesApi | null>(null);
  const [filter, filterSet] = useState<iOffersFilters>(initFilters);

  const el = document.querySelectorAll('.filters__more');
  const height = el[0] ? el[0].scrollHeight : 0;

  const currentValue = form.getFieldsValue();

  useEffect(() => {
    if (filter) {
      onApplyFilters(filter);
    }
  }, [filter]);

  useEffect(() => {
    setCategoryToShow(renderCategoryObject(mainCategories, selectedCategory));
  }, [selectedCategory]);

  function renderMainCategory() {
    const foundedMainCategories: iCategoriesApi[] = findMainCategory(categories, selectedType);
    if (foundedMainCategories) {
      setMainCategories(foundedMainCategories);
    }
  }

  useEffect(() => {
    renderMainCategory();
  }, [selectedType]);

  function getSort() {
    return (
      <div className="filters__sort">
        <Row align="middle">
          <span>Ordina per</span>
          <Select
            defaultValue={enumOrderBy.empty}
            onSelect={(v) => {
              filterSet({ ...filter, orderBy: v as enumOrderBy });
            }}
          >
            <Option value={enumOrderBy.empty}>...</Option>
            {isNoProfit(userRole) && <Option value={enumOrderBy.distance}>Distanza</Option>}
            <Option value={enumOrderBy.priceAsc}>Prezzo crescente</Option>
            <Option value={enumOrderBy.priceDesc}>Prezzo decrescente</Option>
            <Option value={enumOrderBy.dataAsc}>Data scadenza crescente</Option>
            <Option value={enumOrderBy.dataDesc}>Data scadenza decrescente</Option>
          </Select>
        </Row>
      </div>
    );
  }

  function onFinish() {
    const values = form.getFieldsValue();
    filterSet({ ...filter, filters: values });
  }

  function emptyField(field: string) {
    const currentValues = form.getFieldsValue();
    if (field === 'tags' || field === 'categoryType' || field === 'categoryId') {
      currentValues.tags = [];
    }
    if (field === 'categoryType') {
      setSelectedType(null);
      currentValues.categoryId = null;
    }
    if (field === 'categoryType' || field === 'categoryId') {
      currentValues.subCategoryId = [];
      currentValues.tags = [];
      setCategoryToShow(null);
    }
    form.setFieldsValue(currentValues);
  }

  function onTagClicked(key: string, value: string) {
    const formValue = form.getFieldsValue();
    if (typeof formValue[key] === 'string' || typeof formValue[key] === 'number') {
      formValue[key] = null;
    } else {
      formValue[key] = formValue[key].filter((i: any) => i !== value);
    }
    form.setFieldsValue(formValue);
    emptyField(key);
    onFinish();
  }

  return (
    <>
      <section className="filters">
        <button type="button" className="filters__header" onClick={() => moreSet(!more)}>
          <div>
            <img src={Images.icoFilter.src} alt="" />
            FILTRI
          </div>
          <div className={`${more ? 'accordion--active' : ''} accordion `}>
            Mostra
            {more ? ' meno' : ' più'}
            <img src={Images.icoArrowDown.src} alt="" />
          </div>
        </button>
        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          name="new-offer"
          onFinish={onFinish}
        >
          <div className="filters__main">
            <Row align="middle">
              <Col lg={8} md={12}>
                <Form.Item name="offerType">
                  <Checkbox.Group>
                    {offersTypeSource.map((i) => (
                      <Checkbox key={i.value} value={i.value}>
                        {i.label}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              </Col>
              {isDonor(userRole) && view !== enumViews.reservations && (
                <Col lg={8} md={12}>
                  <Form.Item label="Stato offerta" name="status">
                    <Select placeholder="Seleziona stato offerta">
                      {offersStatusSource.map((i) => (
                        <Option key={i.value} value={i.value}>
                          {i.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>

            <Row
              align="middle"
              className={`${more ? 'filters__more--active' : ''} filters__more `}
              style={{ height: more ? height : 0 }}
            >
              <Col lg={8} md={12}>
                <Form.Item label="Tipologia" name="categoryType">
                  <Select
                    placeholder="Seleziona tipologia"
                    onSelect={(value) => {
                      emptyField('categoryType');
                      setCategoryToShow(null);
                      setSelectedCategory(null);
                      setSelectedType(value);
                    }}
                  >
                    {offersCategoryTypeSource.map((i) => (
                      <Option key={i.value} value={i.value}>
                        {i.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={8} md={12}>
                <Form.Item label="Categorie" name="categoryId">
                  <Select
                    placeholder="Tutte"
                    onSelect={(value, option) => {
                      emptyField('tags');
                      setSelectedCategory(option);
                    }}
                  >
                    {!currentValue.categoryType && (
                      <Option disabled value="">
                        Selezionare prima una tipologia
                      </Option>
                    )}
                    {mainCategories.map((category: any) => (
                      <Option key={category.id} value={category.id}>
                        {category.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={8} md={12}>
                <Form.Item label="Sottocategorie" name="subCategoryId">
                  <Select mode="multiple" onSelect={() => emptyField('tags')}>
                    {categoryToShow && categoryToShow.subCategories ? (
                      categoryToShow.subCategories.map((sc: any) => (
                        <Option value={sc.id} key={sc.id}>
                          {sc.name}
                        </Option>
                      ))
                    ) : (
                      <Option disabled value="">
                        Selezionare prima un categoria
                      </Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>

              <Col lg={8} md={12}>
                <Form.Item label="Specifiche prodotto" name="tags">
                  <Select placeholder="Seleziona specifiche prodotto" mode="multiple">
                    {categoryToShow &&
                      categoryToShow.tags &&
                      categoryToShow.tags.map((tag: string) => (
                        <Option value={tag} key={tag}>
                          {specifications[tag] ? specifications[tag].name : tag}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={8} md={12}>
                <Form.Item name="deliveryType">
                  <Checkbox.Group>
                    {offersDeliverySource.map((i) => (
                      <Checkbox key={i.value} value={i.value}>
                        {i.label}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              </Col>
              {isNoProfit(userRole) && (
                <>
                  <Col lg={8} md={12}>
                    <Form.Item label="Donatore" name="donorId">
                      <Select placeholder="Seleziona il donatore">
                        {donors?.map((d: iDonors) => (
                          <Option value={d.id} key={d.id}>
                            {d.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg={8} md={12}>
                    <Form.Item label="Raggio di ricerca" name="maxKm">
                      <Select placeholder="Seleziona raggio di ricerca">
                        {arrayKm.map((item: number) => (
                          <Option key={item} value={item}>
                            {item}km
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>

            <Row justify="end">
              <Button htmlType="submit">Applica filtri</Button>
            </Row>
          </div>
        </Form>
      </section>
      <Row justify="space-between" className="my-30 filters__bottom">
        <OffersFiltersTags
          categories={categories}
          subCategories={categoryToShow?.subCategories}
          filters={filter.filters}
          onTagClicked={onTagClicked}
          donors={donors}
          specifications={specifications}
        />
        <Row>
          {getSort()}
          {offersButtons()}
        </Row>
      </Row>
    </>
  );
};

export default OffersFilters;
