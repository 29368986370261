import React from 'react';
import Images from '../../assets/images';
import { enumBadges } from '../../libs/enums';

import './style.sass';

const badges: any = {
  [enumBadges.co2]: {
    classBg: 'badge--green',
    img: Images.icoLeaf.src,
  },
  [enumBadges.red]: {
    classBg: 'badge--red',
  },
  [enumBadges.green]: {
    classBg: 'badge--green',
  },
};

interface iProps {
  text: any;
  bgColor?: string | null;
  ico?: string | null;
  image?: any;
  moreClass?: string;
  type?: enumBadges | null;
}

const Badge: React.FC<iProps> = ({ text, bgColor, ico, image, moreClass, type }: iProps) => {
  let myClass = 'badge';
  let myIco = ico;
  const myStyle: React.CSSProperties = bgColor ? { backgroundColor: bgColor } : {};
  if (type && badges[type]) {
    const b = badges[type];
    myClass += ` ${b.classBg}`;
    if (b.img) {
      myIco = b.img;
    }
  }
  if (moreClass) {
    myClass += ` ${moreClass}`;
  }

  return (
    <div className={myClass} style={myStyle}>
      {myIco && <img src={myIco} alt="" />}
      {image && image}
      {text}
    </div>
  );
};

Badge.defaultProps = {
  bgColor: null,
  image: null,
  ico: null,
  moreClass: '',
  type: null,
};

export default Badge;
