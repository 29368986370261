/* eslint-disable prettier/prettier */
import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Form, Input, InputNumber, Menu, Row, Select, Switch, TreeSelect } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { createCategoryApi, updateCategoryApi } from '../../api/categories.api';
import Images from '../../assets/images';
import { iCategoriesApi, iCategory, iMeasures, iSpecification } from '../../libs/interfaces';
import { SpecificationTag } from './modalSpecifications';

const { Option } = Select;
interface iCategoryProps {
    specifications: iSpecification[];
    handleClose: () => void;
    category: iCategoriesApi | iCategory;
    measures: iMeasures[];
}

export const CategoryEdit: React.FC<iCategoryProps> = ({ handleClose, category, specifications, measures }: iCategoryProps) => {
    const [form] = Form.useForm();
    form.setFieldsValue(form.getFieldsValue());
    const measureConvReq = measures.filter(m => m.type === "capacity").map(m => m.id);
    const [formValues, formValuesSet] = useState<any>(form.getFieldsValue());
    const [convReq, setConvReq] = useState(measureConvReq.includes(category.defaultMeasureId));
    // eslint-disable-next-line no-debugger

    useEffect(() => {
        const currentValues = { ...category };
        formValuesSet(currentValues);
        form.setFieldsValue(currentValues);
    }, [category, form]);
    let title = "";
    if (category.id) {
        if (category.parentCategory) {
            title = `sottocategoria ${category.parentCategory}`;
        } else {
            title = `categoria ${category.type}`;
        }
    } else if (category.parentCategory) {
        title = `Nuova sottocategoria ${category.parentCategory}`;
    } else {
        title = `Nuova categoria ${category.type}`;
    }
    const treeData = specifications.map(spec => {
        return {
            title: spec.name,
            value: spec.id,
            key: spec.id,
        }
    });
    const { SHOW_PARENT } = TreeSelect;
    const onChange = (value: any) => {
        //  console.log('onChange ', value);
    };
    const tProps = {
        treeData,
        value: [],
        onChange,
        treeCheckable: true,
        showCheckedStrategy: SHOW_PARENT,
        placeholder: 'Seleziona le specifiche',
        style: {
            width: '100%',
        },
    };
    const isUpd = !!category.id;

    const formCat = (
        <Form
            form={form}
            onFinish={() => {
                if (isUpd) {
                    updateCategoryApi(form.getFieldsValue()).then(handleClose).catch(() => { });
                } else {
                    createCategoryApi(form.getFieldsValue()).then(handleClose).catch(() => { });
                }
            }}
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="category"
        >
            <div className="filters__main">
                <Form.Item name="id" className="profile__field-hidden">
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="type" className="profile__field-hidden">
                    <Input type="hidden" />
                </Form.Item>
                <Row align="middle">
                    <Col lg={12} md={12} className="px-10">

                        <Form.Item name="name" label="Nome Categoria" rules={[{ required: true, message: 'Il campo nome è obbligatorio' }]}>
                            <Input placeholder="Inserisci il nome della categoria" disabled={false} />
                        </Form.Item>
                    </Col>
                    <Col lg={12} md={12} className="px-10">
                        <Form.Item name="tags" label="Specifiche">
                            <TreeSelect {...tProps} />
                        </Form.Item>
                    </Col>

                </Row>
                <Row justify="end">
                    <Col sm={8} className="organizations__btns">
                        <Form.Item name="active" label="">
                            <Switch onChange={() => {
                                const currentValues = { ...form.getFieldsValue() };
                                currentValues.active = (currentValues.active || "") === "Y" ? "N" : "Y";
                                formValuesSet(currentValues);
                                form.setFieldsValue(currentValues);
                            }} checked={(form.getFieldsValue().active || "N") === "Y"} />
                            <span className="ml-10">Attivo</span>
                        </Form.Item>
                    </Col>
                </Row>
                <div className="pt-20">
                    <Col lg={24} md={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button className="btn--grey-xl mr-20" htmlType="button" onClick={() => handleClose()}>
                            Annulla
                    </Button>
                        {!isUpd && <Button htmlType="submit" >
                            Crea categoria
                    </Button>}
                        {isUpd &&
                            <Button htmlType="submit" >
                                Salva
                        </Button>}


                    </Col>
                </div>
            </div>
        </Form>


    );
    const renderMeasuresOption = () => {
        return measures.map((measure: iMeasures) => (
            <Option key={measure.id} value={measure.id}>
                {measure.name}
            </Option>
        ));
    };

    const formSubCat = (
        <Form
            form={form}
            onFinish={() => {
                if (isUpd) {
                    updateCategoryApi(form.getFieldsValue()).then(handleClose).catch(() => { });
                } else {
                    createCategoryApi(form.getFieldsValue()).then(handleClose).catch(() => { });
                }
            }}
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onFieldsChange={() => {
                const req = measureConvReq.includes(form.getFieldsValue().defaultMeasureId);
                setConvReq(req);
            }
            }
            name="subCategory"
        >
            <div className="filters__main">
                <Form.Item name="id" className="profile__field-hidden">
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="type" className="profile__field-hidden">
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="parentCategoryId" className="profile__field-hidden">
                    <Input type="hidden" />
                </Form.Item>
                <Row align="middle">
                    <Col lg={12} md={12} className="px-10">

                        <Form.Item name="name" label="Nome sottocategoria" rules={[{ required: true, message: 'Il campo nome è obbligatorio' }]}>
                            <Input placeholder="Inserisci il nome della sottocategoria" disabled={false} />
                        </Form.Item>
                    </Col>
                    <Col lg={12} md={12} className="px-10">
                        <Form.Item label="Unità di misura consigliata" name="defaultMeasureId" rules={[{ required: true, message: 'Il campo è obbligatorio' }]}>
                            <Select placeholder="seleziona l'unità di misura consigliata" >
                                {renderMeasuresOption()}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle">
                    <Col lg={12} md={12} className="px-10">

                        <Form.Item label="Coefficiente di conversione da l a kg" name="convLtToKg" rules={[{ required: convReq, message: 'Il campo è obbligatorio' }]}>
                            <InputNumber placeholder="Inserisci il valore di conversione" />
                        </Form.Item>
                    </Col>
                    <Col lg={12} md={12} className="px-10">
                        <Form.Item label="Co2" name="co2PerUnit">
                            <InputNumber placeholder="Inserisci il valore di Co2 risparmiata per kg" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle">
                    <Col lg={12} md={12} className="px-10">

                        <Form.Item label="TMC" name="tmc">
                            <InputNumber placeholder="Inserisci il TMC in giorni" />
                        </Form.Item>
                    </Col>
                    <Col lg={12} md={12} className="px-10" push={6} >
                        <Form.Item name="active" label="">
                            <Switch onChange={() => {
                                const currentValues = { ...form.getFieldsValue() };
                                //  console.log("currentValues", currentValues);
                                currentValues.active = (currentValues.active || "") === "Y" ? "N" : "Y";
                                formValuesSet(currentValues);
                                form.setFieldsValue(currentValues);
                            }} checked={formValues.active === "Y"} />
                            <span className="ml-10">Attivo</span>
                        </Form.Item>
                    </Col>
                </Row>

                <div className="pt-20">
                    <Col lg={24} md={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button className="btn--grey-xl mr-20" htmlType="button" onClick={() => handleClose()}>
                            Annulla
                    </Button>
                        {!isUpd && <Button htmlType="submit" >
                            Crea Sottocategoria
                    </Button>}
                        {isUpd &&
                            <Button htmlType="submit" >
                                Salva
                        </Button>}


                    </Col>
                </div>
            </div>
        </Form>


    );
    return (
        <div>
            <button type="button" className="organization__back" onClick={handleClose}>
                <img src={Images.icoBack.src} alt={Images.icoBack.alt} />
            Categorie
        </button>
            <Col sm={16}>
                <h3 className=" mb-0 mt-15 p-0">{title}</h3>
            </Col>
            {category.parentCategory ? formSubCat : formCat}

        </div>
    );
};


interface iSubCategoryRowProps {
    category: iCategory;
    onEditCategory: () => void;
    measures: iMeasures[];
}

export const SubCategoryRow: React.FC<iSubCategoryRowProps> = ({
    category,
    onEditCategory,
    measures,
}: iSubCategoryRowProps) => {
    const menu = (
        <Menu>
            <Menu.Item>
                <button type="button" className="" onClick={onEditCategory} >Modifica</button>
            </Menu.Item>
        </Menu>
    );
    const measure = measures.find(m => m.id === category.defaultMeasureId);
    const measureDesc = measure ? measure.name : "";
    const co2 = category.co2PerUnit ? `${category.co2PerUnit.toFixed(2).toString()}kg di Co2` : "";
    const tmc = category.tmc ? `TMC: ${category.tmc.toString()} giorni` : "";
    const arrSpecifications = [measureDesc, co2, tmc]
    return (
        <article className="subcategory">
            <Row justify="space-between">
                <Col sm={12} className="organizations__card">
                    <button type="button" onClick={() => { }}>
                        <div>
                            <h3>{category.name}</h3>

                        </div>
                    </button>
                </Col>
                <Col sm={12} className="organizations__btns">
                    <ul className="subcategory__specs">
                        {arrSpecifications.map(s => <li>{s}</li>)}
                    </ul>
                    <div>
                        <Switch onChange={() => { }} checked={category.active === "Y"} />
                    Attivo
                    </div>

                    <Dropdown overlay={menu} placement="bottomLeft" arrow>
                        <EllipsisOutlined />
                    </Dropdown>

                </Col>
            </Row>
        </article>
    );
};

interface iSubListProps {
    categories: iCategory[];
    onEditCategory: (cat: iCategory) => void;
    measures: iMeasures[];
}

const CategorySubList: React.FC<iSubListProps> = ({ categories, onEditCategory, measures }: iSubListProps) => {
    const boxes = useMemo(() => {
        return categories.map((item) => {
            return (
                <SubCategoryRow key={item.id} category={item} onEditCategory={() => onEditCategory(item)} measures={measures} />
            );
        });
    }, [categories, onEditCategory, measures]);
    return <>{boxes}</>;
};


interface iCategoryRowProps {
    category: iCategoriesApi;
    onEditCategory: () => void;
    onEditSubCategory: (cat: iCategory) => void;
    specifications: iSpecification[];
    measures: iMeasures[];
}

export const CategoryRow: React.FC<iCategoryRowProps> = ({
    category,
    onEditCategory,
    specifications,
    onEditSubCategory,
    measures,
}: iCategoryRowProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const onNewSubCategory = () => {
        const el = { parentCategoryId: category.id, parentCategory: category.name, type: category.type } as iCategory;

        onEditSubCategory(el);
    }
    const onEditSubCategoryRef = (ref: iCategory) => {
        const el = ref;
        el.parentCategory = category.name;
        onEditSubCategory(el);
    }
    const menu = (
        <Menu>
            <Menu.Item>
                <button type="button" className="" onClick={onNewSubCategory}>Aggiungi Sottocategoria</button>
            </Menu.Item>
            <Menu.Item>
                <button type="button" className="" onClick={onEditCategory} >Modifica</button>
            </Menu.Item>
        </Menu>
    );

    const specs = specifications.filter(s => category.tags && category.tags.includes(s.id));
    return (
        <div>
            <article className="mt-10 category">
                <Row justify="space-between" className="category-row box box--grey-sm">
                    <Col sm={12} className="organizations__card">
                        <button type="button" onClick={() => setIsOpen(!isOpen)}>
                            <div>
                                <h3>{category.name}</h3>
                            </div>
                        </button>
                    </Col>

                    <Col sm={12} className="organizations__btns">
                        {specs.map((spec) => {
                            return (
                                // eslint-disable-next-line prettier/prettier
                                <SpecificationTag key={spec.id} specification={spec} closable={false} handleClose={() => { }} />
                            );
                        })}
                        <div>
                            <Switch onChange={() => { }} checked={category.active === "Y"} />
                            Attivo
                        </div>

                        <Dropdown overlay={menu} placement="bottomLeft">
                            <EllipsisOutlined />
                        </Dropdown>
                        <button type="button" className={`${isOpen ? 'accordion--active' : ''} accordion ml-10 `} onClick={() => setIsOpen(!isOpen)}>
                            <img src={Images.icoArrowDown.src} alt="" />
                        </button>
                    </Col>
                </Row>
                {isOpen &&
                    <>
                        <Row justify="end" className="my-20">
                            <Button className="btn--green" onClick={() => onNewSubCategory()}>Nuova Sottocategoria</Button>
                        </Row>
                    </>
                }
                {isOpen && (
                    <div>
                        <CategorySubList categories={category.subCategories || []} onEditCategory={onEditSubCategoryRef} measures={measures} />
                    </div>
                )}
            </article>

        </div>
    );
};


interface iListProps {
    categories: iCategoriesApi[];
    specifications: iSpecification[];
    onEditCategory: (cat: iCategoriesApi) => void;
    onEditSubCategory: (cat: iCategory) => void;
    measures: iMeasures[];
}

export const CategoryList: React.FC<iListProps> = ({ categories, specifications, onEditCategory, onEditSubCategory, measures }: iListProps) => {
    const boxes = useMemo(() => {
        return categories.map((item: iCategoriesApi) => {
            return (
                <CategoryRow key={item.id} category={item} specifications={specifications} onEditCategory={() => onEditCategory(item)} onEditSubCategory={onEditSubCategory} measures={measures} />
            );
        });
    }, [categories, specifications, onEditCategory, onEditSubCategory, measures]);
    return <>{boxes}</>;
};


export default CategoryEdit;
