import moment from 'moment';
import { enumOffersOfferType, enumOffersCategoryType, enumOffersExpiryType } from '../enums';
import { iProfileData } from './profile.interfaces';

export interface iOfferApi {
  type: string;
  deliveryType: string[];
  categoryType: string;
  packagingType: string;
  measureId: string | null;
  categoryId: string;
  subCategoryId: string;
  expiryType: string;
  expiryDate: string | null;
  tags: string[];
  productCode: string;
  unitAmount: number | null;
  unitSize: number[] | null;
  unitWeight: number | null;
  minUnits: number | null;
  totalUnits: number | null;
  unitCost: number | null;
  shipmentCost: number | null;
  endDate: string | null;
  notes: string;
  visibility?: string;
  typeDonation: string | null;
  visibilityNoProfits: string[];
}

export interface iOffer extends iOfferApi {
  donor: iProfileData;
  noprofit: iProfileData;
  availableUnits: number | null;
  order?: any;
  measure: iMeasures;
  [x: string]: any;
}

export interface iCategory {
  id: string;
  type: string;
  name: string;
  active: string;
  description: string;
  measureId: string;
  tags: string[];
  parentCategoryId: string;
  defaultMeasureId: string;
  parentCategory: string;
  defaultMeasure: iMeasures;
  convLtToKg: number;
  tmc: number;
  co2PerUnit: number;
}

export interface iCategoriesApi {
  id: string;
  type: string;
  name: string;
  active: string;
  description: string;
  defaultMeasureId: string;
  tags: string[];
  parentCategoryId: string;
  parentCategory: any;
  measureType: {
    id: string;
    name: string;
  };
  subCategories: iCategory[];
  [x: string]: any;
}

export interface iMeasures {
  id: string;
  name: string;
  type: string;
  coefficient: number;
  description: string;
}

export interface iDonors {
  id: string;
  name: string;
}
export const calcOfferDueDate = (offer: iOffer): string => {
  if (offer && offer.expiryDate) {
    if (offer.expiryDate && offer.expiryType && offer.expiryType === enumOffersExpiryType.tmc) {
      const sub: iCategory = offer.subCategory;
      if (sub.tmc && sub.tmc > 0) {
        return moment(offer.expiryDate).add(sub.tmc, 'days').format('DD/MM/YYYY');
      }
    }
    return moment(offer.expiryDate).format('DD/MM/YYYY');
  }
  return '';
};
