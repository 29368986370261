/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getOrganizationsApi, setOrganizationStatusApi } from '../../api/organizations.api';

import { iOrganizationsStatus, iOrganizationsList } from '../../libs/interfaces';
import { enumOrganizationsTypes } from '../../libs/enums';

const list: any = {};
Object.values(enumOrganizationsTypes).forEach((i: string) => {
  list[i] = [];
});
const initialState: iOrganizationsStatus = {
  list,
  hasList: false,
};

const slice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    organizationsSetListAction: (state, action) => {
      state.list = action.payload;
    },
    organizationsSetHasListAction: (state) => {
      state.hasList = true;
    },
  },
});

export const { organizationsSetListAction, organizationsSetHasListAction } = slice.actions;
export default slice.reducer;

export const organizationsGetAsyncAction = createAsyncThunk(
  'organizations/organizationsGetAsyncAction',
  async (payload: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const response: iOrganizationsList = await getOrganizationsApi();
      dispatch(organizationsSetListAction(response));
      dispatch(organizationsSetHasListAction());
      return response;
    } catch (err) {
      const errorMessage = err.response.label ? err.response.label.it : 'Si è verificato un errore';
      toast.error(errorMessage);
      return rejectWithValue(err);
    }
  },
);

export const organizationSetStatusAsyncAction = createAsyncThunk(
  'organizations/organizationSetStatusAsyncAction',
  async (payload: string, { rejectWithValue, getState, dispatch }) => {
    try {
      const response: any = await setOrganizationStatusApi(payload);

      if (response.payload?.ok) {
        dispatch(organizationsGetAsyncAction(null));
      }
      return response;
    } catch (err) {
      const errorMessage = err.response.label ? err.response.label.it : 'Si è verificato un errore';
      toast.error(errorMessage);
      return rejectWithValue(err);
    }
  },
);
