import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { RootState } from './store';

import history from './store/history';
import routes from './config/routes/routes';
import rollbar from './libs/helpers/rollbar';

import Wrapper from './components/wrapper/wrapper';
import Router from './components/helpers/router';

import { iUserState } from './libs/interfaces';
import { initialUserState, userLoginAction } from './store/features/userSlice';
import StatisticsReport from './views/statistics/statisticsReport.view';
import { objFromUrlParams } from './libs/helpers/commons';

import './App.css';

const App: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const [stateRollbar, stateRollbarSet] = useState<any>(null);
  useEffect(() => {
    if (rollbar) {
      stateRollbarSet(rollbar);
    }
  }, []);

  let token = '';
  let role = '';
  const { user } = useSelector((state: RootState) => state);
  if (user) {
    token = user.token;
    role = user.role;
  }
  const authorized = !!token;

  const { location } = window;
  const objFromLocationSearch = useMemo(() => {
    const obj = objFromUrlParams();
    if (obj.token) {
      const newUser: iUserState = {
        ...initialUserState,
        token: obj.token,
      };
      dispatch(userLoginAction(newUser));
    }
    return obj;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  // statistics-report è escluso dal routing perchè deve effettuare un finto login prima di essere visualizzato

  return (
    <Wrapper>
      {objFromLocationSearch.token && location.pathname.includes('statistics-report') ? (
        <StatisticsReport
          year={Number(objFromLocationSearch.year)}
          quartal={Number(objFromLocationSearch.quartal)}
        />
      ) : (
        <ConnectedRouter history={history}>
          <Router routes={routes} authorized={authorized} userRole={role} />
        </ConnectedRouter>
      )}
    </Wrapper>
  );
};

export default App;
