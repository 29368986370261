/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Images from '../../assets/images';
import { iProfileData, iOrganizationsStatus } from '../../libs/interfaces';
import { organizationsGetAsyncAction } from '../../store/features/organizationsSlice';
import { enumOrganizationsTypes, enumRoutes } from '../../libs/enums';
import { setTabView } from '../../libs/helpers/organizations.helpers';

import OrganizationsTabs from './organizationTabs';
import './style.sass';

interface iProps {
  match: any;
}

const Organization: React.FC<iProps> = ({ match }: iProps) => {
  const dispatch = useDispatch();
  const { path, params } = match;
  const { organizationsType, id } = params;
  const organizations: iOrganizationsStatus = useSelector((state: any) => state.organizations);
  const { list, hasList } = organizations;
  const currentEnumRoute = path.replace(id, ':id');

  useEffect(() => {
    if (!hasList) {
      dispatch(organizationsGetAsyncAction(null));
    }
  }, []);

  const currentProfile: iProfileData = useMemo(() => {
    const type: enumOrganizationsTypes = organizationsType;
    const currentList = list[type];
    return currentList.find((i: any) => i.id === id);
  }, [list, id, organizationsType]);

  function back() {
    let obj: any = {};
    switch (organizationsType) {
      case enumOrganizationsTypes.noprofit:
        obj = { txt: 'Non Profit', to: enumRoutes.organizationsNoProfit };
        break;
      case enumOrganizationsTypes.pa:
        obj = { txt: 'P.A.', to: enumRoutes.organizationsPa };
        break;
      default:
        obj = { txt: 'Aziende', to: enumRoutes.organizationsDonors };
    }
    return (
      <Link to={obj.to} className="organization__back">
        <img src={Images.icoBack.src} alt={Images.icoBack.alt} />
        {obj.txt}
      </Link>
    );
  }

  return (
    <div>
      <div>{back()}</div>
      <OrganizationsTabs id={id} type={organizationsType} currentRoute={currentEnumRoute} />
      {setTabView(currentEnumRoute, currentProfile)}
    </div>
  );
};

export default Organization;
