/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Highcharts from 'highcharts';

import { iStatisticsColumns } from '../../libs/interfaces';
import { renderColumnsData } from '../../libs/helpers/statistics.helpers';

interface iProps {
  data: iStatisticsColumns;
  titles: any;
}

const StatisticsColumsChart: React.FC<iProps> = ({ data, titles }: iProps) => {
  const { date, don_tot, ven_tot } = data;

  function renderHC() {
    const newData = renderColumnsData(date, don_tot, ven_tot, titles);
    Highcharts.chart(newData);
  }

  useEffect(() => {
    renderHC();
  }, [data]);

  return <div id="chart" />;
};

export default StatisticsColumsChart;
