import React from 'react';
import { Modal, Button } from 'antd';

import Images from '../../assets/images';

import './style.sass';

interface iProps {
  modalVisible: boolean;
  onClose: () => void;
}

const SubscriptionThank: React.FC<iProps> = ({ modalVisible, onClose }: iProps) => {
  return (
    <Modal
      visible={modalVisible}
      width={460}
      onCancel={onClose}
      modalRender={() => (
        <div className="modalContract ant-modal-content text-center">
          <button type="button" className="modal__close" onClick={onClose}>
            <img src={Images.icoCloseDark.src} alt={Images.icoCloseDark.alt} />
          </button>
          <p className="mb-30 f-s-26">Grazie!</p>
          <p className="f-s-22">
            <strong>La tua richiesta di sottoscrizione è stata inoltrata.</strong>
          </p>
          <p className="f-s-17 mb-40">
            Verrai contattato per procedere con il pagamento del piano selezionato.
          </p>
          <div>
            <Button onClick={onClose}>CHIUDI</Button>
          </div>
        </div>
      )}
    />
  );
};

export default SubscriptionThank;
