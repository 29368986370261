/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { Row, Col } from 'antd';

import { toast } from 'react-toastify';
import history from '../../../store/history';
import { iProfileData } from '../../../libs/interfaces';
import { ProfileCardCredits, CreditsForm } from '../../../components';
import { updateCreditsByIdApi } from '../../../api/profile.api';
import { enumRoutes } from '../../../libs/enums';

interface iProps {
  organization: iProfileData;
}

const OrganizationNoProfitCredits: React.FC<iProps> = ({ organization }: iProps) => {
  const id = organization.id || '';
  const credits = (organization.credits || 0) / 100;

  const profileCard = useMemo(() => {
    return organization ? <ProfileCardCredits profile={organization} /> : null;
  }, [organization]);

  function onSendData(dataToSend: any) {
    updateCreditsByIdApi(dataToSend.id, {
      profile: {
        credits: dataToSend.credits * 100,
      },
    }).then(() => {
      const newRoute: string = enumRoutes.organizationsNoProfit;
      history.replace(newRoute);
      toast.success('I crediti sono stati salvati.');
    });
  }

  return (
    <div>
      <Row gutter={16}>
        <Col md={12}>{profileCard}</Col>
      </Row>
      <hr className="div my-30" />
      <h2 className="h1 mb-30">Crediti</h2>

      <CreditsForm onSendData={onSendData} profileId={id} profileCredits={credits} />
    </div>
  );
};

export default OrganizationNoProfitCredits;
