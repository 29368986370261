/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Button, Row, Checkbox, Col } from 'antd';

import { PrivacyModal, TermsModal } from '../../components';
import { privacyAsynPostAction } from '../../store/features/profileSlice';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const initData = {
  terms: false,
  privacy: false,
};

const validateMessages: any = {
  required: '${label} è obbligatorio!',
  types: {
    email: '${label} non è un email valida!',
  },
};

interface iProps {
  role: string;
}

const ProfileEditTermsPrivacy: React.FC<iProps> = ({ role }: iProps) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState<any>(initData);
  const [submitDisabled, submitDisabledSet] = useState<boolean>(true);
  const [modalTerms, modalTermsSet] = useState<boolean>(false);
  const [modalPrivacy, modalPrivacySet] = useState<boolean>(false);

  async function onSend() {
    dispatch(privacyAsynPostAction(null));
  }

  function checkButton() {
    const disabled = !data.privacy || !data.terms;
    submitDisabledSet(disabled);
  }

  useEffect(() => {
    checkButton();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  function onValuesChange(values: any) {
    setData({ ...data, ...values });
  }

  return (
    <>
      <h1 className="mb-30">
        Prima di poter operare si devono accettare la Privacy Policy i Termini e le Condizioni{' '}
      </h1>
      <Form
        {...layout}
        form={form}
        layout="vertical"
        name="registration"
        initialValues={data}
        onFinish={onSend}
        onValuesChange={onValuesChange}
        validateMessages={validateMessages}
      >
        <Form.Item
          name="terms"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) => {
                return value
                  ? Promise.resolve()
                  : Promise.reject(new Error('Il campo è obbligatorio'));
              },
            },
          ]}
        >
          <Checkbox>
            Accetto i
            <button type="button" onClick={() => modalTermsSet(true)} className="text-underline">
              Termini e le Condizioni
            </button>{' '}
            <span className="required-sign">*</span>
          </Checkbox>
        </Form.Item>

        <Form.Item
          name="privacy"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) => {
                return value
                  ? Promise.resolve()
                  : Promise.reject(new Error('Il campo è obbligatorio'));
              },
            },
          ]}
        >
          <Checkbox>
            Accetto la
            <button type="button" onClick={() => modalPrivacySet(true)} className="text-underline">
              Privacy Policy
            </button>
            <span className="required-sign">*</span>
          </Checkbox>
        </Form.Item>

        <Row justify="space-between" align="middle">
          <Col sm={12}>
            <Button htmlType="submit" disabled={submitDisabled}>
              Accetta
            </Button>
          </Col>
        </Row>
      </Form>
      <TermsModal visible={modalTerms} onCloseModal={() => modalTermsSet(false)} />
      <PrivacyModal visible={modalPrivacy} onCloseModal={() => modalPrivacySet(false)} />
    </>
  );
};

export default ProfileEditTermsPrivacy;
