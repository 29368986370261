/* eslint-disable import/prefer-default-export */
import Config from '../config/Config';
import { Api } from '../libs/callApi';
import { iSubRequestPayload, iSubRequestResponse, iSubPlan, iSubSetData } from '../libs/interfaces';

const api = new Api(Config.apiBaseurl);

export const requestScriptionsApi = async (
  body: iSubRequestPayload,
): Promise<{ payload: any; body: iSubRequestResponse }> => {
  const call = await api.makeCall({
    path: Config.apis.subscriptions.request,
    method: 'POST',
    autorized: true,
    body,
  });
  return call;
};

export const getUserListAvailableApi = async (
  id: string,
): Promise<{ payload: any; body: iSubPlan[] }> => {
  const call = await api.makeCall({
    path: Config.apis.subscriptions.listAvailable + id,
    method: 'GET',
    autorized: true,
  });
  return call;
};

export const getUserHistoryPlansApi = async (
  id: string,
): Promise<{ payload: any; body: iSubRequestResponse[] }> => {
  const call = await api.makeCall({
    path: Config.apis.subscriptions.userHistoryPlans + id,
    method: 'GET',
    autorized: true,
  });
  return call;
};

export const setPlanApi = async (
  body: iSubSetData,
): Promise<{ payload: any; body: iSubRequestResponse }> => {
  const call = await api.makeCall({
    path: Config.apis.subscriptions.set,
    method: 'POST',
    body,
    autorized: true,
  });
  return call;
};

export const deletePlanApi = async (accountId: string, planId: string): Promise<any> => {
  const call = await api.makeCall({
    path: `${Config.apis.subscriptions.delete + accountId}/${planId}`,
    method: 'DELETE',
    autorized: true,
  });
  return call;
};
