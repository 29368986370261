import React from 'react';
import { Form, Input } from 'antd';
import Config from '../../config/Config';

interface iProps {
  label?: string;
  placeholder?: string;
  name?: any;
  initialValue?: string;
  required?: boolean;
}

const FormPhone: React.FC<iProps> = ({
  label,
  placeholder,
  name,
  initialValue,
  required,
}: iProps) => {
  return (
    <Form.Item
      label={label}
      name={name}
      initialValue={initialValue}
      rules={[
        { required },
        ({ getFieldValue }: any) => ({
          validator(rule, value) {
            if (value) {
              const regex = Config.regex.phone.it;
              if (regex.test(value)) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(
                  'Il campo deve iniziare con +39 seguito da un minimo di 7 e un massimo di 16 cifre',
                ),
              );
            }
            return Promise.resolve();
          },
        }),
      ]}
    >
      <Input placeholder={placeholder} />
    </Form.Item>
  );
};

FormPhone.defaultProps = {
  label: 'Telefono',
  placeholder: 'Inserisci il numero di telefono',
  name: 'phone',
  initialValue: '',
  required: false,
};

export default FormPhone;
