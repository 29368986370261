import React from 'react';
import { enumOrganizationsTypes, enumRoutes } from '../enums';
import { iProfileData } from '../interfaces';
import {
  OrganizationAllSubscription,
  OrganizationNoProfitCredits,
  OrganizationDonorProfile,
  OrganizationDonorDeals,
  OrganizationNoProfitProfile,
  OrganizationNoProfitLevel,
  OrganizationNoProfitDeals,
  OrganizationNoProfitDealsNoProfits,
} from '../../views/organization/tabsViews';

// eslint-disable-next-line import/prefer-default-export
export const organizationsTabs = {
  [enumOrganizationsTypes.companies]: [
    { label: 'Dati Azienda', to: enumRoutes.organizationsDonorsProfile },
    { label: 'Accordi con Beneficiari', to: enumRoutes.organizationsDonorsDeals },
    { label: 'Subscriptions', to: enumRoutes.organizationsDonorsSubscriptions },
  ],
  [enumOrganizationsTypes.noprofit]: [
    { label: 'Dati Non-Profit', to: enumRoutes.organizationsNoProfitProfile },
    // { label: 'Livello', to: enumRoutes.organizationsNoProfitLevel },
    //  { label: 'Accordi con Aziende', to: enumRoutes.organizationsNoProfitDealsNoProfits },
    { label: 'Accordi con Donatori', to: enumRoutes.organizationsNoProfitDeals },
    { label: 'Subscriptions', to: enumRoutes.organizationsNoProfitSubscriptions },
    { label: 'Crediti', to: enumRoutes.organizationsNoProfitCredits },
  ],
  [enumOrganizationsTypes.pa]: [{ label: 'Dati P.A.', to: enumRoutes.organizationsPaProfile }],
};

export function setTabView(route: enumRoutes, profile: iProfileData): any {
  let comp = null;
  switch (route) {
    case enumRoutes.organizationsDonorsProfile:
      comp = <OrganizationDonorProfile organization={profile} />;
      break;
    case enumRoutes.organizationsDonorsDeals:
      comp = <OrganizationDonorDeals organization={profile} />;
      break;
    case enumRoutes.organizationsDonorsSubscriptions:
      comp = <OrganizationAllSubscription organization={profile} />;
      break;

    case enumRoutes.organizationsNoProfitProfile:
      comp = <OrganizationNoProfitProfile organization={profile} />;
      break;
    case enumRoutes.organizationsNoProfitLevel:
      comp = <OrganizationNoProfitLevel organization={profile} />;
      break;
    case enumRoutes.organizationsNoProfitDeals:
      comp = <OrganizationNoProfitDeals organization={profile} />;
      break;
    case enumRoutes.organizationsNoProfitDealsNoProfits:
      comp = <OrganizationNoProfitDealsNoProfits organization={profile} />;
      break;
    case enumRoutes.organizationsNoProfitSubscriptions:
      comp = <OrganizationAllSubscription organization={profile} />;
      break;
    case enumRoutes.organizationsNoProfitCredits:
      comp = <OrganizationNoProfitCredits organization={profile} />;
      break;

    case enumRoutes.organizationsPaProfile:
      comp = <OrganizationNoProfitProfile organization={profile} />;
      break;
    default:
      comp = null;
  }

  return comp;
}
