import React from 'react';
import { Link } from 'react-router-dom';
import './style.sass';

interface iProps {
  small?: boolean;
}

const Logo: React.FC<iProps> = ({ small }: iProps) => {
  return (
    <Link to="/" className={small ? 'logo logo--small' : 'logo'}>
      Regusto
    </Link>
  );
};

Logo.defaultProps = {
  small: false,
};

export default Logo;
