import React from 'react';
import moment from 'moment';

import { calcOfferDueDate, iOffer, iSpecification } from '../../libs/interfaces';

interface iProps {
  data: iOffer;
  specifications: Record<string, iSpecification>;
}

const OfferDetailsInfoProduct: React.FC<iProps> = ({ data, specifications }: iProps) => {
  return (
    <>
      <h2 className="h1">Info Prodotto</h2>
      <div className="oDetails__info">
        <p>
          {data.categoryType && data.categoryType.toUpperCase()} -{' '}
          {data.category && data.category.name} - {data.subCategory && data.subCategory.name}
        </p>
        <hr className="div" />
        <dl>
          <dt>Quantità disponibile:</dt>
          <dd>
            {data.availableUnits} unità - {data.packagingType} da {data.unitAmount}
            {data.measure && data.measure.name}
          </dd>
          <dt>
            {/* <Tooltip title="Testo di prova per tooltip">
              <img className="tooltip__info" src={Images.icoinfo.src} alt={Images.icoinfo.alt} />
            </Tooltip> */}
            Lotto:
          </dt>
          <dd>
            {data.minUnits} unità ({data.unitWeight}kg ) - Ingombro lotto{' '}
            {data.unitSize && `${data.unitSize[0]}x${data.unitSize[1]}x${data.unitSize[2]}`} (cm)
          </dd>
        </dl>
        <hr className="div" />
        <dl>
          <dt>Specifiche:</dt>
          {data.tags
            ?.filter((t: string) => specifications[t])
            .map((t: string) => (
              <dd key={t} style={{ paddingLeft: '10px' }}>
                {specifications[t].name}
              </dd>
            ))}
          <dt>Codice prodotto:</dt>
          <dd>{data.productCode}</dd>
          {data.expiryDate && (
            <>
              <dt>Scadenza:</dt>
              <dd>{calcOfferDueDate(data)}</dd>
            </>
          )}
          <dt>Validità offerta:</dt>
          <dd>{data.endDate && moment(data.endDate).format('DD/MM/YYYY')}</dd>
          <dt>Note:</dt>
          <dd style={{ width: 650 }}>{data.notes}</dd>
        </dl>
      </div>
    </>
  );
};

export default OfferDetailsInfoProduct;
