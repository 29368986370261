import React, { useMemo } from 'react';

import { enumCardTypes } from '../../libs/enums';
import { iStatisticsCards } from '../../libs/interfaces';
import { numberWithDot } from '../../libs/helpers/commons';

import './style.sass';
import Images from '../../assets/images';

interface iProps {
  data: iStatisticsCards;
  titles: any;
  type: enumCardTypes;
}

const StatisticsCard: React.FC<iProps> = ({ data, titles, type }: iProps) => {
  const cardData = useMemo(() => {
    let obj: any = {};
    const { social_impact, social_impact_meal, enviroment_impact, economic_impact } = data;
    switch (type) {
      case enumCardTypes.donations:
        obj = {
          color: 'red',
          ico: Images.icoCardHeart.src,
          title: titles.cards.donations(),
          myData: numberWithDot(social_impact),
          unit: 'Kg',
        };
        break;
      case enumCardTypes.social:
        obj = {
          color: 'yellow',
          ico: Images.icoCardMeals.src,
          title: titles.cards.social(),
          myData: numberWithDot(social_impact_meal),
        };
        break;
      case enumCardTypes.environmental:
        obj = {
          color: 'green',
          ico: Images.icoCardLeaf.src,
          title: titles.cards.environmental(),
          subTitle: 'INDICE IMPATTO AMBIENTALE',
          txt: 'KG DI CO2 RISPARMIATA',
          myData: numberWithDot(enviroment_impact),
          unit: 'Kg',
        };
        break;
      case enumCardTypes.economic:
        obj = {
          color: 'grey',
          ico: Images.icoCardEuro.src,
          title: titles.cards.economic(),
          myData: numberWithDot(economic_impact),
          unit: '€',
        };
        break;
      default:
        obj = null;
    }
    const { color, ico, title, myData, unit } = obj;
    if (obj) {
      return (
        <article className={`sCard sCard--${color}`}>
          <div>
            <header>
              <img src={ico} alt="" />
            </header>
            <h1>
              {myData}
              <span>{unit}</span>
            </h1>
          </div>
          <p>{title}</p>
        </article>
      );
    }
    return null;
  }, [type, data, titles.cards]);

  return <>{cardData}</>;
};

export default StatisticsCard;
