import React, { useEffect, useCallback } from 'react';
import { Form, Button, Row, Col, Input } from 'antd';

import './style.sass';

const validateMessages = {
  required: 'Campo obbligatorio',
};

interface iProps {
  profileId: string;
  profileCredits: number;
  onSendData: (data: any) => void;
  labelButton?: string;
}

const CreditsForm: React.FC<iProps> = ({
  onSendData,
  profileId,
  profileCredits,
  labelButton,
}: iProps) => {
  const [form] = Form.useForm();

  const setFormValue = useCallback(() => {
    return {
      id: profileId,
      credits: profileCredits,
    };
  }, [profileId, profileCredits]);

  useEffect(() => {
    const newValues = setFormValue();
    form.setFieldsValue(newValues);
  }, [form, setFormValue]);

  const initForm: any = setFormValue();

  function onSend() {
    const currentData: any = form.getFieldsValue();
    const dataToSend: any = setFormValue();
    dataToSend.credits = parseFloat(parseFloat(currentData.credits).toFixed(2));
    onSendData(dataToSend);
  }

  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      layout="vertical"
      name="plan"
      onFinish={onSend}
      validateMessages={validateMessages}
      className="formCredits"
      initialValues={initForm}
    >
      <Row gutter={16}>
        <Col md={12}>
          <Form.Item name="credits" label="Crediti" rules={[{ required: true }]}>
            <Input type="number" min={0} step=".01" placeholder="Inserisci i crediti" />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Row className="fullheight pb-24" justify="start" align="bottom">
            <Button htmlType="submit">{labelButton}</Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

CreditsForm.defaultProps = {
  labelButton: 'MODIFICA CREDITI',
};

export default CreditsForm;
