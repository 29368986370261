/* eslint-disable prettier/prettier */
import { Button, Col, Form, Input, Modal, Row, Tag } from 'antd';
import Item from 'antd/lib/list/Item';
import React, { useEffect, useMemo, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';

import { createSpecificationApi, deleteSpecificationApi, updateSpecificationApi } from '../../api/categories.api';
import Images from '../../assets/images';
import { iSpecification } from '../../libs/interfaces';


import './style.sass';

interface iProps {
  onCloseModal: () => void;
  onDataChange: () => void;
  visibility: boolean;
  specifications: iSpecification[];
  specsUsed: string[];
  type: string;
}

interface iSpecificationTagProps {
  specification: iSpecification;
  closable: boolean;
  handleClose: () => void;
}

export const SpecificationTag: React.FC<iSpecificationTagProps> = ({
  specification,
  closable,
  handleClose,
}: iSpecificationTagProps) => {
  return (
    <Tag style={{ borderColor: specification.color }} className="edit-tag cat-tag" closable={closable} onClose={() => handleClose && handleClose()}>
      <span style={{ color: specification.color }}>{specification.name}</span>
    </Tag>
  );
};

interface iListProps {
  specifications: iSpecification[];
  specsUsed: string[];
  onEdit: (item: iSpecification) => void;
  onDelete: (item: iSpecification) => void;
}

const SpecificationList: React.FC<iListProps> = ({ specifications, onEdit, onDelete, specsUsed }: iListProps) => {
  const boxes = useMemo(() => {
    return specifications.map((item: iSpecification) => {
      return (
        <Row
          className="box  mb-15 mt-15 p-0"
          justify="space-between"
          align="middle"
          key={item.id}
        >
          <div>
            <Row align="middle">
              <SpecificationTag specification={item} closable={!specsUsed.includes(item.id)} handleClose={() => { onDelete(item) }} />
              <button type="button" onClick={() => onEdit(item)}> <small><EditOutlined /> Modifica</small></button>
            </Row>
            <div />
          </div>
        </Row>
      );
    });
  }, [specifications, onEdit, onDelete, specsUsed]);
  return <>{boxes}</>;
};
const ModalSpecifications: React.FC<iProps> = ({
  onCloseModal,
  visibility,
  specifications,
  onDataChange,
  specsUsed,
  type,
}: iProps) => {
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [color, setColor] = useState<string | undefined>();

  const onReset = () => {
    form.resetFields();
    setIsEdit(false);
    setColor(undefined);
  };
  const onFinish = (values: any) => {
    // console.log(values);
  };


  const onFill = (item: iSpecification) => {
    form.setFieldsValue({
      ...item
    });
    setIsEdit(true);
    setColor(item.color);
  };
  const onDelete = (item: iSpecification) => {
    deleteSpecificationApi(item.id).then(() => {
      onDataChange();
      onReset();
    }).catch(() => { });

  };


  function onCreate() {
    const currentData: iSpecification = form.getFieldsValue();
    currentData.type = type;
    createSpecificationApi(currentData).then(() => {
      onDataChange();
      onReset();
    }).catch(() => { });
  }
  function onUpdate() {
    const currentData: iSpecification = form.getFieldsValue();

    updateSpecificationApi(currentData).then(() => {
      onDataChange();
      onReset();
    }).catch(() => { });
  }

  return (
    <Modal
      visible={visibility}
      width={815}
      onCancel={onCloseModal}
      modalRender={() => (
        <div className="modalCustom ant-modal-content text-center">
          <button type="button" className="modal__close" onClick={onCloseModal}>
            <img src={Images.icoCloseDark.src} alt={Images.icoCloseDark.alt} />
          </button>
          <Row gutter={16}>
            <Col sm={24} style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <h1>Crea nuova specifica</h1>
            </Col>
          </Row>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            name="new-specification"
            onFieldsChange={() => {
              setColor(form.getFieldsValue().color);
            }
            }

          >
            <div className="filters__main">
              <Form.Item name="id" className="profile__field-hidden">
                <Input type="hidden" />
              </Form.Item>
              <Form.Item name="type" className="profile__field-hidden">
                <Input type="hidden" />
              </Form.Item>
              <Row align="middle">
                <Col lg={12} md={12}>

                  <Form.Item name="name" label="Nome" rules={[{ required: true, message: 'Il campo è obbligatorio' }]} >
                    <Input placeholder="Nome" disabled={false} />
                  </Form.Item>
                </Col>
                <Col lg={3} md={3} />
                <Col lg={9} md={9}>
                  <Row align="middle" justify="space-between">
                    <Col lg={16} md={16}>
                      <Form.Item name="color" label="Colore  (Codice esadecimale)" rules={[{ required: true, message: 'Il campo è obbligatorio' }]}>
                        <Input placeholder="Colore" disabled={false} />
                      </Form.Item>
                    </Col>
                    <Col lg={3} md={3} />
                    <Col lg={5} md={5}>
                      <div style={{ height: 36, width: 36, backgroundColor: form.getFieldsValue().color }} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div>
                <Col lg={24} md={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button className="btn--grey-xl mr-20" htmlType="button" onClick={() => onReset()}>
                    Annulla
                  </Button>
                  {!isEdit && <Button htmlType="button" onClick={() => onCreate()}>
                    Crea specifica
                  </Button>}
                  {isEdit &&
                    <Button htmlType="button" onClick={() => onUpdate()}>
                      Salva
                  </Button>}


                </Col>
              </div>
            </div>
          </Form>
          <hr className="div" />

          <Row gutter={16}>
            <Col sm={24} style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <h2 className="m-0">Specifiche</h2>
            </Col>
          </Row>

          <SpecificationList specifications={specifications} specsUsed={specsUsed} onEdit={(item) => onFill(item)} onDelete={(item) => onDelete(item)} />
        </div>
      )}
    />
  );
};
export default ModalSpecifications;
