import React from 'react';

import { iProfileData } from '../../../libs/interfaces';

interface iProps {
  organization: iProfileData;
}

const OrganizationNoProfitDealsNoProfits: React.FC<iProps> = ({ organization }: iProps) => {
  return (
    <div>
      <h1>Accordi con Beneficiari</h1>
    </div>
  );
};

export default OrganizationNoProfitDealsNoProfits;
