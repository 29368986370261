/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Input, Radio, Row, Select } from 'antd';
import { capitalize } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import {
  enumOffersCategoryType,
  enumOffersDelivery,
  enumOffersOfferType,
  enumRoutes,
  enumViews,
} from '../../libs/enums';
import OffersCardUnit from '../offersCard/offersCardUnit';

import Images from '../../assets/images';

import { iOffer, iSpecification, iUserState } from '../../libs/interfaces';
import { ordersAsyncCreateAction } from '../../store/features/ordersSlice';
import ModalDeleteOffer from '../modalDeleteOffer/modalDeleteOffer';
import { RootState } from '../../store';
import { enumOrdersStatus } from '../../libs/enums/orders.enum';
import {
  getSecondValueUnit,
  renderIcon,
  decodeCredits,
  getTotOrderWeight,
} from '../../libs/helpers/offers.helpers';
import { isAdmin, isDonor, isNoProfit } from '../../libs/helpers/privileges.helpers';

import OfferDetailsInfo from './offerDetailsInfo';
import OfferDetailsInfoProduct from './offerDetailsInfoProduct';
import './style.sass';
import { SpecificationTag } from '../../views/categories/modalSpecifications';

const { Option } = Select;

interface iProps {
  user: iUserState;
  view: enumViews;
  data: iOffer;
  filters: any;
  kgToUse: number;
  onLimitExceeded: () => void;
  onDelete: (offerId: string) => void;
  onOfferReject: (id: string) => void;
  onOfferConfirm: (id: string) => void;
  specifications: Record<string, iSpecification>;
}

const OfferDetails: React.FC<iProps> = ({
  data,
  user,
  view,
  filters,
  kgToUse,
  onLimitExceeded,
  onDelete,
  onOfferReject,
  onOfferConfirm,
  specifications,
}: iProps) => {
  const dispatch = useDispatch();
  const [selectOptionQuantity, setSelectOptionQuantity] = useState<number[]>([]);
  const [selectedQuantity, setSelectedQuantity] = useState<number>(0);
  const [hasCredits, setHasCredits] = useState<boolean>();
  const [deliveryType, setDeliveryType] = useState<any>('');
  const { profile } = useSelector((state: RootState) => state);
  const [modalDeleteVisibile, modalDeleteVisibileSet] = useState<boolean>(false);
  const [totCreditsToPay, totCreditsToPaySet] = useState<number>(0);

  const { order, info } = data;
  const { role } = user;

  // let totCreditsToPay = 0;
  const hasOpenOrders = Boolean(data.info?.openOrders > 0);

  function calcTotCredits(): number {
    let unitCost = 0;
    if (data.type === enumOffersOfferType.sale && data.unitCost) {
      unitCost = data.unitCost;
    }
    return Number((selectedQuantity * unitCost).toFixed(2));
  }

  const createOrder = () => {
    // TODO per riabilitare limite decommentare ciclo if
    // if (
    //   isNoProfit(user.role) &&
    //   getTotOrderWeight(data, selectedQuantity) > kgToUse &&
    //   view === enumViews.available
    // ) {
    //   onLimitExceeded();
    //   return;
    // }

    const dataParams = {
      offerId: data.id,
      units: selectedQuantity,
      deliveryType,
    };
    dispatch(ordersAsyncCreateAction({ data: dataParams, filters }));
  };

  const getSelectQuantityValues = () => {
    let availableUnits = 0;
    let minUnits = 0;
    const valuesArr = [];
    if (data) {
      availableUnits = data.availableUnits as number;
      minUnits = data.minUnits as number;
    }
    for (let i = minUnits; i <= availableUnits; i += minUnits) {
      valuesArr.push(i);
    }

    setSelectOptionQuantity(valuesArr);
  };

  useEffect(() => {
    getSelectQuantityValues();
    return () => {
      setSelectedQuantity(0);
      setSelectOptionQuantity([]);
      setHasCredits(false);
    };
  }, [data]);

  const renderTotCost = () => {
    let shipmentCost = 0;
    // let unitCost = 0;
    if (data) {
      shipmentCost = data.shipmentCost as number;
      // if (data.type === enumOffersOfferType.sale) {
      //   unitCost = data.unitCost as number;
      // }
    }
    const totCredits = calcTotCredits();
    if (deliveryType === enumOffersDelivery.shipment) {
      const res = selectedQuantity / data.minUnits!;
      const shipmentTotCost = Number((res * shipmentCost).toFixed(2));
      // totCreditsToPay = totCredits + shipmentTotCost;
      return totCredits + shipmentTotCost;
    }

    // totCreditsToPay = totCredits;
    return totCredits;
  };

  useEffect(() => {
    const tot = renderTotCost();
    totCreditsToPaySet(tot);
    setHasCredits(tot <= (profile.data.credits || 0));
  }, [data, selectedQuantity, deliveryType]);

  const renderDelivery = (isOffer = true) => {
    const renderCredits = () => {
      if (view === enumViews.reservations || view === enumViews.booked) {
        return (
          <>
            <Row justify="end">
              <img src={Images.icoYellowStar.src} alt="" className="icon-small" />
              <p className="ml-10 mt-10">
                Tot. Crediti Dovuti: <b>{decodeCredits(order.credits)}</b>
              </p>
            </Row>
            <hr className="div mt-10" />
          </>
        );
      }
      if (isNoProfit(role)) {
        return (
          <Row align="middle" justify="space-between" className="oDetails__delivery">
            <hr className="div flex1 mr-10" />
            <div>
              {data.unitWeight && data.minUnits && (
                <>Tot.kg: {data.unitWeight && <b>{getTotOrderWeight(data, selectedQuantity)}</b>}</>
              )}
              <img src={Images.icoYellowStar.src} alt="" className="ml-20 icon-small" />
              Tot. crediti: <b>{totCreditsToPay}</b>
            </div>
          </Row>
        );
      }
      return null;
    };

    const renderDeliveryComponents = () => {
      const CollectionComponent = () => {
        let address = '';
        if (data.donor?.operationalData) {
          const { street, city, zip } = data.donor.operationalData;
          const arr = [street, city, zip];
          const addressArr: string[] = [];
          arr.forEach((item: string | null) => {
            if (item) {
              addressArr.push(item);
            }
          });
          address = addressArr.join(', ');
        }
        return (
          <Row
            align="top"
            className={isAdmin(role) || (isDonor(role) && view === enumViews.all) ? 'mt-30' : ''}
          >
            {isOffer && <Radio value="collection" />}
            <img className="icon-big ml-10" src={Images.icoPickup.src} alt={Images.icoPickup.alt} />
            <div>
              <div>Ritiro</div>
              {!isDonor(role) && (
                <div>
                  <b>
                    {data.donor && data.donor?.operationalData && data.donor?.operationalData.city}
                  </b>
                  <p>{address}</p>
                </div>
              )}
            </div>
          </Row>
        );
      };

      const ShipmentComponent = () => (
        <Row
          align="top"
          className={isAdmin(role) || (isDonor(role) && view === enumViews.all) ? 'mt-30' : ''}
        >
          {isOffer && <Radio value="shipment" />}
          <img
            className="icon-big ml-10"
            src={Images.icoDelivery.src}
            alt={Images.icoDelivery.alt}
          />
          <div>
            <div>Spedizione</div>
            <div>
              <img className="icon-small" src={Images.icoYellowStar.src} alt="" />
              <span className="ml-10 f-s-15">{data.shipmentCost} crediti per lotto</span>
            </div>
          </div>
        </Row>
      );

      if (isOffer || isAdmin(role) || isDonor(role)) {
        return (
          <>
            {data.deliveryType && data.deliveryType.includes(enumOffersDelivery.collection) && (
              <CollectionComponent />
            )}
            {data.deliveryType && data.deliveryType.includes(enumOffersDelivery.shipment) && (
              <ShipmentComponent />
            )}
          </>
        );
      }
      return (
        <>
          {data.order && data.order.deliveryType.includes(enumOffersDelivery.collection) && (
            <CollectionComponent />
          )}
          {data.order && data.order.deliveryType.includes(enumOffersDelivery.shipment) && (
            <ShipmentComponent />
          )}
        </>
      );
    };

    return (
      <div className={`${!isOffer && view !== enumViews.all && 'mt-30'}`}>
        <Radio.Group onChange={({ target }) => setDeliveryType(target.value)}>
          <Row className="oDetails__delivery">{renderDeliveryComponents()}</Row>
        </Radio.Group>
        {renderCredits()}
      </div>
    );
  };

  const renderShareSelect = () => (
    // condivisione nascosta per il deploy
    <div className="oDetails__share">
      <div className="box--grey-xl">
        <p>
          Quantità che si desidera condividere
          <sup>*</sup>
        </p>
        <Select showSearch>
          {selectOptionQuantity.map((sq: number) => (
            <Option key={sq} value={sq}>
              {sq}
            </Option>
          ))}
        </Select>
      </div>
      <Row justify="end">
        <Button>CONDIVIDI</Button>
      </Row>
    </div>
  );

  const renderQuantitySelect = () => (
    <Row className="box--grey-xl my-30 oDetails__share" justify="space-between">
      <Col span={data.type === enumOffersOfferType.sale ? 15 : 30}>
        <p>
          Quantità che si desidera prenotare
          <sup>*</sup>
        </p>
        <div className="select-for-mobile">
          <select onBlur={(e) => setSelectedQuantity(Number(e.target.value))}>
            {selectOptionQuantity.map((i: any) => {
              return <option value={i}>{i}</option>;
            })}
          </select>
          <Select
            showSearch
            value={selectedQuantity}
            onChange={(value) => setSelectedQuantity(value as number)}
          >
            {selectOptionQuantity.map((sq: number) => (
              <Option key={sq} value={sq}>
                {sq}
              </Option>
            ))}
          </Select>
        </div>
      </Col>
      {data.type === enumOffersOfferType.sale && (
        <Col span={7}>
          <p>
            <img src={Images.icoYellowStar.src} alt="" className="icon-small pr-5" />
            Crediti
          </p>
          <Input disabled value={data ? calcTotCredits() : 0} />
        </Col>
      )}
    </Row>
  );

  const renderVisibilityBox = () => {
    const renderItVisibility = (visibility: string | undefined) => {
      return visibility === 'public' ? 'Pubblica' : 'Privata';
    };
    return (
      <div className="box--grey-xl my-30">
        <dl>
          <dt>Visibilità:</dt>
          <dd>{renderItVisibility(data?.visibility)}</dd>
          <dt>Validità offerta:</dt>
          <dd>{data && data.endDate && moment(data.endDate).format('DD/MM/YYYY')}</dd>
        </dl>
      </div>
    );
  };

  const renderBody = () => {
    const renderOrderStatus = () => {
      if (order) {
        switch (order.status) {
          case enumOrdersStatus.open:
            return (
              <>
                <h3 className="status--open">
                  Prenotato {data.unitWeight && `(${getTotOrderWeight(data, order.units)} kg)`}
                </h3>
                <p className="status--open">{moment(order.createdAt).format('DD/MM/YYYY')}</p>
              </>
            );
          case enumOrdersStatus.accepted:
            return (
              <>
                <h3 className="status--accepted">
                  Confermato {data.unitWeight && `(${getTotOrderWeight(data, order.units)} kg)`}
                </h3>
                <p className="status--accepted">{moment(order.createdAt).format('DD/MM/YYYY')}</p>
              </>
            );
          case enumOrdersStatus.shared:
            return (
              <>
                <h3>
                  Condiviso {data.unitWeight && `(${getTotOrderWeight(data, order.units)} kg)`}
                </h3>
                <p>{moment(order.createdAt).format('DD/MM/YYYY')}</p>
              </>
            );
          case enumOrdersStatus.rejected:
            return (
              <>
                <h3 className="status--rejected">
                  Rifiutata {order.units} unità
                  {data.unitWeight && ` (${getTotOrderWeight(data, order.units)} kg)`}
                </h3>
                <p className="status--rejected">{moment(order.createdAt).format('DD/MM/YYYY')}</p>
              </>
            );
          default:
            return null;
        }
      }
      return null;
    };

    switch (view) {
      case enumViews.all:
        return (
          <>
            {renderDelivery(false)}
            {renderVisibilityBox()}
            <OfferDetailsInfoProduct data={data} specifications={specifications} />
            {isAdmin(role) && (
              <>
                <hr className="div" />
                <h2 className="h1">Info Donatore</h2>
                <OfferDetailsInfo data={data && data.donor} />
              </>
            )}
          </>
        );
      case enumViews.available:
        return (
          <>
            {renderQuantitySelect()}
            {renderDelivery()}
            <Row className="oDetails__delivery" justify="end" align="middle">
              {!hasCredits && (
                <p className="f-s-12 c-red mr-15">
                  Il credito residuo non è sufficiente per la prenotazione di questa quantità
                </p>
              )}
              <Button
                // eslint-disable-next-line prettier/prettier
                className={`${data.type === enumOffersOfferType.sale ? 'btn--yellow' : 'btn--orange'}`}
                disabled={!hasCredits || !selectedQuantity || !deliveryType}
                onClick={createOrder}
              >
                PRENOTA
              </Button>
            </Row>
            <OfferDetailsInfoProduct data={data} specifications={specifications} />
            <hr className="div" />
            <h2 className="h1">Info Donatore</h2>
            <OfferDetailsInfo data={data && data.donor} />
          </>
        );

      case enumViews.booked:
        return (
          <>
            {renderDelivery(false)}
            {/* order && order.status === enumOrdersStatus.accepted && renderShareSelect() */}
            <OfferDetailsInfoProduct data={data} specifications={specifications} />
            <hr className="div" />
            <h2 className="h1">Info Donatore</h2>
            <OfferDetailsInfo data={data && data.donor} />
          </>
        );
      case enumViews.mydonation:
        return (
          <>
            {renderVisibilityBox()}
            <OfferDetailsInfoProduct data={data} specifications={specifications} />
          </>
        );
      case enumViews.reservations:
        return (
          <>
            <hr className="div" />
            <Row className="oDetails__delivery" justify="space-between">
              <div className="oDetails__orderStatus">{renderOrderStatus()}</div>
              {order && order.status === enumOrdersStatus.open && (
                <div>
                  <Button className="btn--red" onClick={() => onOfferReject(order && order.id)}>
                    RIFIUTA
                  </Button>
                  <Button className="btn--green" onClick={() => onOfferConfirm(order && order.id)}>
                    CONFERMA
                  </Button>
                </div>
              )}
            </Row>
            {renderDelivery(false)}
            <OfferDetailsInfo data={order && order.noprofit} />
            <OfferDetailsInfoProduct data={data} specifications={specifications} />
            {renderVisibilityBox()}
          </>
        );
        return null;
      default:
        return null;
    }
  };

  const renderEditDeleteBtn = () => {
    const renderBtn = () => (
      <div>
        <Link to={enumRoutes.offerEditLink + data.id} className="ant-btn">
          <img src={Images.icoEdit.src} alt="" />
          MODIFICA
        </Link>
        <Button className="ml-10 btn--grey-xl" onClick={() => modalDeleteVisibileSet(true)}>
          <img src={Images.icoTrash.src} alt="" className="mr-5" />
          ELIMINA
        </Button>
      </div>
    );
    const renderOpenOrders = () => (
      <div className="c-red text-right" style={{ maxWidth: 200 }}>
        Ci sono ordini aperti. <br /> Non è possibile modificare o eliminare l&apos;offerta.
      </div>
    );
    switch (view) {
      case enumViews.all:
      case enumViews.mydonation:
        return hasOpenOrders ? renderOpenOrders() : renderBtn();
        break;
      default:
        return null;
    }
    return null;
  };

  return (
    <article className="oDetails">
      <header>
        <div className="oDetails__image">
          <img src={renderIcon(data).src} alt={renderIcon(data).alt} />
        </div>
        <div className="flex1">
          <h1>{data.subCategory && data.subCategory.name}</h1>
          <h2>{data.category && data.category.name}</h2>
          <Row align="middle" justify="space-between">
            <Row align="middle">
              <OffersCardUnit
                firstValue={
                  info
                    ? (data.totalUnits! - data.info.confirmedUnits).toLocaleString()
                    : data.availableUnits?.toLocaleString()
                }
                secondValue={
                  info && getSecondValueUnit(info, data.availableUnits!, data.totalUnits!)
                }
                orderedQuantity={order && order.units}
                orderStatus={order && order.status}
              />
              <strong>
                {capitalize(data.packagingType)} da {data.unitAmount}
                {data.measure && data.measure.name}
              </strong>
            </Row>
            <Row align="middle">
              {data.unitCost !== 0 && data.type !== enumOffersOfferType.donation && (
                <div className="mr-30">
                  <img src={Images.icoYellowStar.src} alt="" />
                  <span className="ml-10">{data.unitCost} crediti Unità</span>
                </div>
              )}
              {!isAdmin(role) && renderEditDeleteBtn()}
            </Row>
          </Row>
        </div>
      </header>
      {/* isDonor(role) && (
        <Row justify="end">
          <div className="oDetails__delete-error">
            Non puoi eliminare questa offerta perché ha già delle prenotazioni
          </div>
        </Row>
      ) */}

      {data.tags.length > 0 && (
        <Row justify="end">
          <div className="oCard__tags my-30">
            <div>
              {data.tags &&
                data.tags
                  .filter((t) => specifications[t])
                  .map((tag) => {
                    return (
                      // eslint-disable-next-line prettier/prettier
                      <SpecificationTag key={tag} specification={specifications[tag]} closable={false} handleClose={() => { }} />
                    );
                  })}
            </div>
          </div>
        </Row>
      )}
      {renderBody()}
      <ModalDeleteOffer
        onDelete={() => {
          onDelete(data.id);
          modalDeleteVisibileSet(false);
        }}
        onCancel={() => modalDeleteVisibileSet(false)}
        visible={modalDeleteVisibile}
      />
    </article>
  );
};

export default OfferDetails;
