/* eslint-disable import/prefer-default-export */
import React from 'react';
import moment from 'moment';

import { enumCardTypes } from '../enums';
import {
  iStatisticsFilters,
  iStatisticsReportItem,
  iDataMarkers,
  iTransaction,
} from '../interfaces';
import { getLatLng } from './here.helpers';
import { decodeCredits } from './offers.helpers';
import { getRoleType } from './privileges.helpers';

export const statisticsCardTypesSource = [
  { value: enumCardTypes.donations },
  { value: enumCardTypes.social },
  { value: enumCardTypes.environmental },
  { value: enumCardTypes.economic },
];

export const initStatisticsFilters: iStatisticsFilters = {
  city: '',
  to: '',
  from: '',
  state: '',
  donor: false,
};

export const statisticsTitles = {
  donor: {
    cards: {
      donations: (): any => 'Prodotti distribuiti',
      social: (): any => 'N° Pasti equivalenti',
      environmental: (): any => (
        <>
          CO<sup>2</sup> risparmiata
        </>
      ),
      economic: (): any => 'Prodotti Venduti',
    },
    histogram: {
      title: 'Istogramma vendite/donazioni effettuate',
      firstCol: 'Donazioni',
      secondCol: 'Vendite',
    },
    pies: {
      categories: 'Categorie transazioni effettuate',
      user: 'Beneficiari coinvolti',
    },
    map: 'Mappa transazioni effettuate',
  },
  noprofit: {
    cards: {
      donations: (): any => 'Prodotti ricevuti',
      social: (): any => 'N° Pasti equivalenti',
      environmental: (): any => (
        <>
          CO<sup>2</sup> risparmiata
        </>
      ),
      economic: (): any => 'Prodotti acquistati',
    },
    histogram: {
      title: 'Istogramma vendite/donazioni',
      firstCol: 'Donazioni',
      secondCol: 'Acquisti',
    },
    pies: {
      categories: 'Categorie transazioni ricevute',
      user: 'Aziende',
    },
    map: 'Mappa transazioni ricevute',
  },
  admin: {
    cards: {
      donations: (): any => ' Prodotti distribuiti',
      social: (): any => 'N° Pasti equivalenti',
      environmental: (): any => (
        <>
          CO<sup>2</sup> risparmiata
        </>
      ),
      economic: (): any => 'Prodotti venduti',
    },
    histogram: {
      title: 'Istogramma vendite/donazioni totali',
      firstCol: 'Donazioni',
      secondCol: 'Vendite',
    },
    pies: {
      categories: 'Categorie',
      user: 'Beneficiari',
    },
    map: 'Mappa transazioni',
  },
  pa: {
    cards: {
      donations: (): any => ' Prodotti distribuiti',
      social: (): any => 'N° Pasti equivalenti',
      environmental: (): any => (
        <>
          CO<sup>2</sup> risparmiata
        </>
      ),
      economic: (): any => 'Prodotti venduti',
    },
    histogram: {
      title: 'Istogramma vendite/donazioni totali',
      firstCol: 'Donazioni',
      secondCol: 'Vendite',
    },
    pies: {
      categories: 'Categorie',
      user: 'Beneficiari',
    },
    map: 'Mappa transazioni',
  },
};

export function renderColumnsData(date: string[], don: number[], ven: number[], titles: any): any {
  return {
    chart: {
      type: 'column',
      renderTo: 'chart',
    },
    title: {
      text: titles.histogram.title,
    },
    xAxis: {
      categories: date,
    },
    yAxis: {
      title: {
        text: 'Kg',
      },
    },
    colors: ['#EA4867', '#667079'],
    series: [
      {
        name: titles.histogram.firstCol,
        data: don,
        type: 'column',
      },
      {
        name: titles.histogram.secondCol,
        data: ven,
        type: 'column',
      },
    ],
  };
}

export function renderPieData({
  id,
  data,
  title,
}: {
  id: string;
  data: iStatisticsReportItem[];
  title: string;
}): any {
  const seriesData: any[] = data.map((i: iStatisticsReportItem) => {
    return {
      name: i.name,
      y: i.val,
    };
  });

  return {
    chart: {
      plotShadow: false,
      renderTo: id,
      type: 'pie',
    },
    title: {
      text: title,
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: 'Percentuale',
        colorByPoint: true,
        type: 'pie',
        data: seriesData,
      },
    ],
  };
}

export async function getLatLngArrayForMap(data: iStatisticsReportItem[]): Promise<iDataMarkers[]> {
  const array: Promise<iDataMarkers>[] = data.map(async (item: iStatisticsReportItem) => {
    const queryString = `qq=country=Italy;county=${item.name}`;
    const latLngFromHelper = await getLatLng(queryString);
    const arrayItem: iDataMarkers = {
      state: item.name,
      val: item.val,
      latLng: latLngFromHelper,
    };
    return arrayItem;
  });

  return Promise.all(array);
}

export function mapTransactionsData(data: iTransaction[]): iTransaction[] {
  const mapped = data.map((i: iTransaction) => {
    const newItem = { ...i };
    newItem.accepted_date = moment(i.accepted_date).format('DD-MM-YYYY');
    newItem.reservation_date = moment(i.reservation_date).format('DD-MM-YYYY');
    newItem.credit_earn = decodeCredits(i.credit_earn);
    newItem.credit_tot = decodeCredits(i.credit_tot);
    newItem.shipment_cost = decodeCredits(i.shipment_cost);
    newItem.credit = decodeCredits(i.credit);
    return newItem;
  });

  return mapped;
}
