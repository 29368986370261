import { iProfileSubscription, iProfileState } from '../interfaces';
import { enumRoles, enumSubscriptions } from '../enums';

const models = {
  profile: {
    read: 'all',
    edit: [enumRoles.noprofit, enumRoles.noprofitSecond, enumRoles.donor],
  },
};

export function isNoProfit(role: string | undefined): boolean {
  return (
    role === enumRoles.noprofit ||
    role === enumRoles.noprofitSub ||
    role === enumRoles.noprofitSecond ||
    role === enumRoles.noprofitSecondSub
  );
}

export function isNoProfitFirstLevel(role: string): boolean {
  return role === enumRoles.noprofit || role === enumRoles.noprofitSub;
}

export function isDonor(role: string | undefined): boolean {
  return role === enumRoles.donor || role === enumRoles.donorSub;
}

export function isAdmin(role: string): boolean {
  return role === enumRoles.admin;
}

export function isPA(role: string): boolean {
  return role === enumRoles.pa;
}

export function canEditProfile(role: string): boolean {
  return (
    role === enumRoles.noprofit || role === enumRoles.noprofitSecond || role === enumRoles.donor
  );
}

export function canReadProfileAfterLogin(role: string): boolean {
  return role !== enumRoles.admin;
}

export type roleTypes = 'donor' | 'noprofit' | 'admin' | 'pa';

export function getRoleType(role: string): roleTypes {
  let type: roleTypes = 'noprofit';
  if (isAdmin(role)) {
    type = 'admin';
  }
  if (isPA(role)) {
    type = 'pa';
  }
  if (isDonor(role)) {
    type = 'donor';
  }
  return type;
}

export function canSeeStatistics(
  role: string,
  userPlan: enumSubscriptions,
  recentSubDate: string | null,
): boolean {
  /* if (!userPlan) {
    return false;
  }
  return userPlan !== enumSubscriptions.donorFree || Boolean(recentSubDate); */
  // FIXED Show all users statistics
  return true;
}
