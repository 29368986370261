import React from 'react';
// IMPORTARE IN ORDINE ALFABETICO

import burger from './ico-burger.svg';
import logo from './logo-regusto.png';

import icoArrowDown from './ico-arrow-down.svg';
import icoAvatar from './ico-avatar.png';
import icoBack from './ico-back.svg';
import icoBell from './ico-bell.svg';
import icoCalendarEmpty from './ico-calendar-empty.svg';
import icoCardCloud from './ico-card-cloud.svg';
import icoCardEuro from './ico-card-euro.svg';
import icoCardHeart from './ico-card-heart.svg';
import icoCardLeaf from './ico-card-leaf.svg';
import icoCardMeals from './ico-card-meals.svg';
import icoCardWater from './ico-card-water.svg';
import icoCardWorld from './ico-card-world.svg';
import icoCheck from './ico-check.svg';
import icoClock from './ico-clock.svg';
import icoClose from './ico-close.svg';
import icoCloseDark from './ico-close-dark.svg';
import icoCopy from './ico-copy.svg';
import icoDelete from './ico-delete.svg';
import icoDeleteDark from './ico-delete-dark.svg';
import icoDelivery from './ico-delivery.svg';
import icoDiamond from './ico-diamond.svg';
import icoEdit from './ico-edit.svg';
import icoFilter from './ico-filter.svg';
import icoFood from './ico-type-food.svg';
import icoGraph from './ico-graph.svg';
import icoHelp from './ico-help.svg';
import icoinfo from './ico-info.svg';
import icoLeaf from './ico-leaf.svg';
import icoLeafWhite from './ico-leaf-white.svg';
import icoMedicine from './ico-type-medicine.svg';
import icoNonFood from './ico-type-non-food.svg';
import icoOfferte from './ico-offerte.svg';
import icoPickup from './ico-pickup.svg';
import icoSearch from './ico-search.svg';
import icoService from './ico-type-service.svg';
import icoSocialImpact from './ico-social-impact.svg';
import icoStarOutlined from './ico-star-outlined.svg';
import icoTrash from './ico-trash.svg';
import icoUser from './ico-user.svg';
import icoUserDark from './ico-user-dark.svg';
import icoUsers from './ico-users.svg';
import icoYellowStar from './ico-yellow-star.svg';

export default {
  burger: { src: burger, alt: '' },
  logo: { src: logo, alt: 'Regusto' },

  icoArrowDown: { src: icoArrowDown, alt: 'Avatar' },
  icoAvatar: { src: icoAvatar, alt: 'Avatar' },
  icoBack: { src: icoBack, alt: 'indietro' },
  icoBell: { src: icoBell, alt: 'indietro' },
  icoCalendarEmpty: { src: icoCalendarEmpty, alt: 'Prenotazioni' },
  icoCardCloud: { src: icoCardCloud, alt: '' },
  icoCardEuro: { src: icoCardEuro, alt: '' },
  icoCardHeart: { src: icoCardHeart, alt: '' },
  icoCardLeaf: { src: icoCardLeaf, alt: '' },
  icoCardMeals: { src: icoCardMeals, alt: '' },
  icoCardWater: { src: icoCardWater, alt: '' },
  icoCardWorld: { src: icoCardWorld, alt: '' },
  icoCheck: { src: icoCheck, alt: '' },
  icoClock: { src: icoClock, alt: 'Chiudi' },
  icoClose: { src: icoClose, alt: 'Chiudi' },
  icoCloseDark: { src: icoCloseDark, alt: 'Chiudi' },
  icoCopy: { src: icoCopy, alt: 'Categorie' },
  icoDelete: { src: icoDelete, alt: 'Elimina' },
  icoDeleteDark: { src: icoDeleteDark, alt: 'Elimina' },
  icoDelivery: { src: icoDelivery, alt: 'Consegna' },
  icoDiamond: { src: icoDiamond, alt: '' },
  icoEdit: { src: icoEdit, alt: 'Modifica' },
  icoFilter: { src: icoFilter, alt: 'Filtra' },
  icoFood: { src: icoFood, alt: 'Cibo' },
  icoGraph: { src: icoGraph, alt: 'Statistiche' },
  icoHelp: { src: icoHelp, alt: 'Aiuto' },
  icoinfo: { src: icoinfo, alt: 'Info' },
  icoLeaf: { src: icoLeaf, alt: 'co2' },
  icoLeafWhite: { src: icoLeafWhite, alt: 'co2' },
  icoMedicine: { src: icoMedicine, alt: 'Medicina' },
  icoNonFood: { src: icoNonFood, alt: 'Non cibo' },
  icoOfferte: { src: icoOfferte, alt: 'Offerte' },
  icoPickup: { src: icoPickup, alt: 'Ritira' },
  icoSearch: { src: icoSearch, alt: 'Search' },
  icoService: { src: icoService, alt: 'Servizio' },
  icoSocialImpact: { src: icoSocialImpact, alt: '' },
  icoStarOutlined: { src: icoStarOutlined, alt: '' },
  icoTrash: { src: icoTrash, alt: 'Elimina' },
  icoUser: { src: icoUser, alt: 'Profilo' },
  icoUserDark: { src: icoUserDark, alt: 'Profilo' },
  icoUsers: { src: icoUsers, alt: 'Profilo' },
  icoYellowStar: { src: icoYellowStar, alt: '' },
};

export function SvgCheck(): any {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
      <defs>
        <style>{'.prefix__a{fill:#373737}'}</style>
      </defs>
      <g transform="translate(0 2.444)">
        <rect
          className="prefix__a"
          width={9.855}
          height={3.323}
          rx={0.5}
          transform="rotate(45 -3.68 4.848)"
        />
        <rect
          className="prefix__a"
          width={15.544}
          height={3.323}
          rx={0.5}
          transform="rotate(135 8.513 4.903)"
        />
      </g>
      <path fill="none" d="M0 0h18v18H0z" />
    </svg>
  );
}

export function SvgDiamond(color: string): any {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
      <defs>
        <style>{`.s_${color}{fill: #${color}}`}</style>
      </defs>
      <path
        className={`s_${color}`}
        d="M5.3 1L9 6.042 12.7 1zM4.215 1.493L.581 6.444h7.267zM17.418 6.444l-3.634-4.951-3.634 4.951zM8.417 17.002V7.611H.636zM9.583 17.002l7.78-9.391h-7.78z"
      />
      <path fill="none" d="M0 0h18v18H0z" />
    </svg>
  );
}
