/* eslint-disable react/require-default-props */
import React from 'react';
import { Layout } from 'antd';
import ReactHelmet from 'react-helmet';

import './style.sass';
import { type } from 'os';

interface iProps {
  children: any;
}

const WidgetLayout = ({ children }: iProps) => {
  return (
    <Layout className="layout-widget">
      <ReactHelmet
        link={[
          {
            rel: 'stylesheet',
            href: 'https://cdnjs.cloudflare.com/ajax/libs/noUiSlider/15.6.0/nouislider.css',
          },
          {
            rel: 'stylesheet',
            href: 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.2/css/bootstrap.min.css',
          },
          {
            rel: 'stylesheet',
            href:
              'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-slider/11.0.2/css/bootstrap-slider.min.css',
          },
        ]}
        script={[
          {
            type: 'text/javascript',
            src: 'https://cdnjs.cloudflare.com/ajax/libs/jquery/2.2.4/jquery.min.js',
          },
        ]}
      />
      <div>{children}</div>
    </Layout>
  );
};

export default WidgetLayout;
