/* eslint-disable import/prefer-default-export */
import Config from '../config/Config';
import { Api } from '../libs/callApi';
import { iOfferApi, iOrderApi } from '../libs/interfaces';

const api = new Api(Config.apiBaseurl);

export const createOrderApi = async (body: iOrderApi): Promise<iOfferApi> => {
  const call = await api.makeCall({
    path: Config.apis.orders.create,
    method: 'POST',
    body,
    autorized: true,
  });
  return call;
};

export const acceptOrderApi = async (id: string): Promise<any> => {
  const call = await api.makeCall({
    path: `${Config.apis.orders.accept}/${id}`,
    method: 'POST',
    autorized: true,
  });
  return call;
};

export const rejectOrderApi = async (id: string): Promise<any> => {
  const call = await api.makeCall({
    path: `${Config.apis.orders.reject}/${id}`,
    method: 'POST',
    autorized: true,
  });
  return call;
};
