/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { Row, Col, Form, DatePicker, Input, Button } from 'antd';
import locale from 'antd/es/date-picker/locale/it_IT';

import { iStatisticsFilters } from '../../libs/interfaces';
import { isAdmin, isDonor } from '../../libs/helpers/privileges.helpers';
import { initStatisticsFilters } from '../../libs/helpers/statistics.helpers';
import { enumRoles } from '../../libs/enums';
import { debounce } from '../../libs/helpers/commons';

import './style.sass';

function setNewFilters(data: any, role: string) {
  const newFilters: iStatisticsFilters = {
    to: data.to ? data.to.format('YYYY-MM-DD') : '',
    from: data.from ? data.from.format('YYYY-MM-DD') : '',
    city: data.city ? data.city : '',
    state: data.state ? data.state : '',
    donor: isDonor(role) || isAdmin(role),
  };
  return newFilters;
}

interface iProps {
  initValues?: any;
  onApplyFilters: (v: iStatisticsFilters) => void;
  role: enumRoles;
  showCityState?: boolean;
}

const StatisticsFilter: React.FC<iProps> = ({
  initValues,
  onApplyFilters,
  role,
  showCityState,
}: iProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    const newFilters: iStatisticsFilters = setNewFilters(initValues, role);
    onApplyFilters(newFilters);
  }, []);

  const debouncedApply = useCallback(
    debounce((filters: iStatisticsFilters) => onApplyFilters(filters), 500),
    [],
  );

  function resetFilters() {
    form.setFieldsValue(initStatisticsFilters);
    debouncedApply(initStatisticsFilters);
  }

  function apply() {
    const values: any = form.getFieldsValue();
    const newFilters: iStatisticsFilters = setNewFilters(values, role);
    debouncedApply(newFilters);
  }

  return (
    <section className="filters">
      <Form form={form} layout="vertical" initialValues={initValues}>
        <Row>
          <Col lg={16}>
            <Row gutter={16}>
              <Col md={12}>
                <Form.Item label="Dal" name="from">
                  <DatePicker
                    placeholder="gg-mm-aaaa"
                    format="DD-MM-YYYY"
                    className="fullwidth"
                    locale={locale}
                  />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item label="Al" name="to">
                  <DatePicker
                    placeholder="gg/mm/aaaa"
                    format="DD-MM-YYYY"
                    className="fullwidth"
                    locale={locale}
                  />
                </Form.Item>
              </Col>
            </Row>
            {isAdmin(role) && showCityState && (
              <Row gutter={16}>
                <Col md={12}>
                  <Form.Item label="Provincia" name="state">
                    <Input />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item label="Comune" name="city">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Col>

          <Col lg={8}>
            <Row gutter={16} justify="end">
              <Button onClick={apply}>Applica filtri</Button>
              <Button onClick={resetFilters} className="btn--grey-l ml-15">
                Cancella filtri
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </section>
  );
};

StatisticsFilter.defaultProps = {
  initValues: initStatisticsFilters,
  showCityState: true,
};

export default StatisticsFilter;
