/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import { iProfileFormData } from '../interfaces/index';
import Config from '../../config/Config';

declare const window: any;

interface iLatLng {
  lat: number | null;
  lng: number | null;
}

export async function getLatLng(querystring: string): Promise<iLatLng> {
  let lat = null;
  let lng = null;

  const queryString = `${querystring}&apiKey=${Config.here_com_apikey}`;
  const url = 'https://geocode.search.hereapi.com/v1/geocode?';
  const call = await fetch(url + queryString, { method: 'GET' });

  const res = await call.clone().json();
  if (res.items?.length) {
    lat = res.items[0].position.lat;
    lng = res.items[0].position.lng;
  }

  /* ONLY IF HERE NOT WORK
  lat = 43.09897;
  lng = 12.30153;
  */

  return { lat, lng };
}

export async function getLatLngByAddress(data: iProfileFormData): Promise<iLatLng> {
  let queryString = 'qq=country=Italy';
  let lat = null;
  let lng = null;

  const { operationalCity, operationalZip, operationalStreet } = data;

  if (operationalCity && operationalZip && operationalStreet) {
    if (operationalCity) {
      queryString += `;city=${operationalCity.replace(' ', '+')}`;
    }
    if (operationalZip) {
      queryString += `;postalCode=${operationalZip.replace(' ', '+')}`;
    }
    if (operationalStreet) {
      const street = operationalStreet
        .toLowerCase()
        .replace(' ', '+')
        .replace('strada provinciale', 'sp')
        .replace('strada statale', 'ss');
      queryString += `;street=${street}`;
    }

    const dataLatLng = await getLatLng(queryString);
    lat = dataLatLng.lat;
    lng = dataLatLng.lng;
  }

  return { lat, lng };
}

function createBaseMarket(content: string) {
  const outerElement: any = document.createElement('div');
  const innerElement: any = document.createElement('div');

  outerElement.style.userSelect = 'none';
  outerElement.style.webkitUserSelect = 'none';
  outerElement.style.msUserSelect = 'none';
  outerElement.style.mozUserSelect = 'none';
  outerElement.style.cursor = 'default';

  innerElement.style.color = 'white';
  innerElement.style.backgroundColor = '#424E59';
  innerElement.style.border = '2px solid black';
  innerElement.style.font = 'normal 12px arial';
  innerElement.style.lineHeight = '12px';

  innerElement.style.padding = '5px';
  innerElement.style.borderRadius = '5px';

  // add negative margin to inner element
  // to move the anchor to center of the div
  innerElement.style.marginTop = '-10px';
  innerElement.style.marginLeft = '-10px';

  outerElement.appendChild(innerElement);

  // Add text to the DOM element
  innerElement.innerHTML = content;

  return outerElement;
}

function createMarker(content: string) {
  function changeOpacity(evt: any) {
    evt.target.style.opacity = 0.6;
  }

  function changeOpacityToOne(evt: any) {
    evt.target.style.opacity = 1;
  }
  const { H } = window;
  const domIcon: any = new H.map.DomIcon(createBaseMarket(content), {
    // the function is called every time marker enters the viewport
    onAttach: (clonedElement: any, dI: any, domMarker: any) => {
      clonedElement.addEventListener('mouseover', changeOpacity);
      clonedElement.addEventListener('mouseout', changeOpacityToOne);
    },
    // the function is called every time marker leaves the viewport
    onDetach: (clonedElement: any, dI: any, domMarker: any) => {
      clonedElement.removeEventListener('mouseover', changeOpacity);
      clonedElement.removeEventListener('mouseout', changeOpacityToOne);
    },
  });
  return domIcon;
}

export function addDomMarkers(map: any, dataMarkers: any) {
  // const bearsMarker = new H.map.DomMarker(
  //   { lat: 45.46796, lng: 9.18178 },
  //   { icon: createMarker('ciao') },
  // );
  // map.addObject(bearsMarker);

  const { H } = window;
  dataMarkers.forEach((m: any) => {
    const { latLng, val } = m;
    const newMarker = new H.map.DomMarker(
      { lat: latLng.lat, lng: latLng.lng },
      { icon: createMarker(val) },
    );
    map.addObject(newMarker);
  });
}
