/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row } from 'antd';

import { enumRoutes } from '../../libs/enums';
import { isNoProfit, isAdmin } from '../../libs/helpers/privileges.helpers';
import { getSubscriptionColor } from '../../libs/helpers/subscriptions.helpers';
import { userBeforeLogoutAction } from '../../store/features/userSlice';
import OffersCardUnit from '../offersCard/offersCardUnit';
import Images from '../../assets/images';

import './style.sass';

interface iProps {
  avatar: any;
  onUserClicked: (v: boolean) => void;
  showUserNav: boolean;
}

const HeaderNavUser: React.FC<iProps> = ({ avatar, onUserClicked, showUserNav }: iProps) => {
  const dispatch = useDispatch();
  const { profile, user } = useSelector((state: any) => state);
  const userRole = user.role;

  const { subscription } = profile;

  const objPlan = useMemo(() => {
    if (!subscription) {
      return null;
    }
    const { kg_month, kg_order } = subscription;
    const userPlan = subscription.subscription;
    const toUse = (kg_month - kg_order).toFixed(2);
    return {
      label: `PIANO ${userPlan.toUpperCase()}`,
      kgTot: kg_order,
      kgToUse: Number(toUse) >= 0 ? toUse : 0,
      percKg: 100 - Math.floor((kg_order * 100) / kg_month) || 1,
      color: getSubscriptionColor(userPlan),
    };
  }, [subscription]);

  return (
    <div className="l-nav__user">
      <button
        type="button"
        className="l-nav__user-name"
        onClick={() => onUserClicked(!showUserNav)}
      >
        {avatar()}
      </button>

      <nav>
        <ul>
          {!isAdmin(userRole) && (
            <>
              <li>
                <Link to={enumRoutes.profile}>
                  <img src={Images.icoUserDark.src} alt={Images.icoUser.alt} />
                  Profilo
                </Link>
              </li>
              {objPlan && (
                <li className="l-nav__plan">
                  <Link to="#" className={`bg-${objPlan.color}`}>
                    {objPlan.label}
                  </Link>
                  {/* isNoProfit(userRole) && (
                    <>
                      <Row justify="space-between" className="f-s-12 mt-10 mb-5">
                        <div className="c-light">{objPlan.kgTot.toFixed(2)} Kg</div>
                        <div className="c-green">{objPlan.kgToUse} Kg</div>
                      </Row>
                      <OffersCardUnit perc={objPlan.percKg} />
                    </>
                  ) */}
                </li>
              )}
            </>
          )}
          <li>
            <Link to={enumRoutes.changePassword}>Cambia password</Link>
          </li>
          <li>
            <span>
              <button
                type="button"
                onClick={() => {
                  dispatch(userBeforeLogoutAction());
                }}
              >
                Esci
              </button>
            </span>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default HeaderNavUser;
