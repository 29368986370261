/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Col, Form, Input, Modal, Row, Switch, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import QRCode from 'qrcode';
import * as speakeasy from 'speakeasy';
import Images from '../../assets/images';
import FormPhone from '../../components/forms/formPhone';
import { fileToBase64 } from '../../libs/helpers/base64';
import { getLatLngByAddress } from '../../libs/helpers/here.helpers';
import {
    formInitialProfileData,
    mapProfileData,
    mapProfileDataToPayload
} from '../../libs/helpers/profile.helpers';
import { iProfileData, iProfileFormData } from '../../libs/interfaces/index';
import Localize from '../../libs/localization';
import ProfileEditAdmin from './profile.edit.administrative';
import ProfileEditOperational from './profile.edit.operational';
import './style.sass';

const title = Localize('PAGES.PROFILE.TITLE_COMPLETE');

const validateMessages = {
    required: 'Campo obbligatorio',
    types: {
        email: 'Il campo non è un indirizzo email valido',
    },
};
interface iProps {
    org: any;
    onUpdate: (data: iProfileData) => void;
    complete: boolean;
    back: string | undefined;
}

const ProfileEdit: React.FC<iProps> = ({ org, onUpdate, complete, back }: iProps) => {
    const [form] = Form.useForm();
    const [data, formDataSet] = useState<iProfileFormData>(formInitialProfileData);
    const [originalSubAccountLength, originalSubAccountLengthSet] = useState<number>(0);
    const [avatar, avatarSet] = useState<any>('');
    const [modalValidateGeoCode, modalValidateGeoCodeSet] = useState<boolean>(false);
    const [modalShow2FA, modalShow2FASet] = useState<boolean>(false);
    const [wrongVerifyOTP, wrongVerifyOTPSet] = useState<boolean>(false);
    const [otpURL, otpURLSet] = useState<string>('');
    const [secretVerified, secretVerifiedSet] = useState<any>({});
    const [verifyOTP, verifyOTPSet] = useState<string>('');
    const history = useHistory();
    
    useEffect(() => {
      const newData: iProfileFormData = mapProfileData(org);
      form.setFieldsValue(newData);
      formDataSet(newData);
      originalSubAccountLengthSet(newData.subAccounts.length);
    }, [org]);

    useEffect(() => {
      if(modalShow2FA) {
        const secret: speakeasy.GeneratedSecret = speakeasy.generateSecret();
        const url: string = speakeasy.otpauthURL({ secret: secret.ascii, label: org.name.trim(), algorithm: 'sha1', issuer: 'Regusto APP', digits: 6 });
        QRCode.toDataURL(url, (_err: any, data_url: string) => {
          secretVerifiedSet(secret);
          otpURLSet(data_url);
        });
      }
    }, [modalShow2FA]);

    function copyData() {
        const currentData: iProfileFormData = form.getFieldsValue();
        const newData: iProfileFormData = {
            ...currentData,
            operationalCity: currentData.administrativeCity,
            operationalState: currentData.administrativeState,
            operationalPhone: currentData.administrativePhone,
            operationalStreet: currentData.administrativeStreet,
            operationalZip: currentData.administrativeZip,
        };
        form.setFieldsValue(newData);
    }

    async function onSendData() {
        const { lat, lng } = await getLatLngByAddress(form.getFieldsValue());
        if (!lat) {
            return modalValidateGeoCodeSet(true);
        }
        const currentData: iProfileFormData = form.getFieldsValue();
        currentData.lat = lat;
        currentData.lng = lng;

        if (avatar) {
            currentData.image = avatar;
        }
        return onUpdate(mapProfileDataToPayload(currentData));
    }

    function deleteSubAccount(num: number) {
        const currentData: iProfileFormData = form.getFieldsValue();
        const { subAccounts } = currentData;
        const filtered = subAccounts.filter((item: any, i: number) => i !== num);
        const newData = { ...currentData, subAccounts: filtered };
        form.setFieldsValue(newData);
    }

    async function setNewAvatar(file: any) {
        const image = await fileToBase64(file);
        avatarSet(image);
    }

    const propsUpload = {
        multiple: false,
        beforeUpload: (file: any) => {
            setNewAvatar(file);
            return false;
        },
    };
    const cancelUrl = back || `${history.location.pathname}`.replace('/edit', '');

    const doCleanFields = () => {
      modalShow2FASet(false);
      secretVerifiedSet({});
      otpURLSet('');
      verifyOTPSet('');
      wrongVerifyOTPSet(false);
    }

    const doCancel2FA = () => {
      doCleanFields();
      form.setFieldsValue({ ...form.getFieldsValue(), enabled2fa: org.enabled2fa, secret2fa: '' });
    };
    
    const doOk2FA = () => {
      const tokenValidates = speakeasy.totp.verify({
        secret: secretVerified.hex,
        encoding: 'hex',
        token: verifyOTP.toString(),
        window: 2
      });
      
      if(tokenValidates) {
        form.setFieldsValue({ ...form.getFieldsValue(), enabled2fa: true, secret2fa: secretVerified.base32 });
        doCleanFields();
      } else {
        wrongVerifyOTPSet(true);
      }
    };

    return (
        <>
            <Modal
                title="Attenzione"
                visible={modalValidateGeoCode}
                onCancel={() => modalValidateGeoCodeSet(false)}
                footer={() => null}
            >
                Ci sono stati dei problemi con la validazione dell&apos;indirizzo dei Dati operativi.
                <br />
                Si prega di verificare che indirizzo, città e CAP siano corretti.
            </Modal>
            {!complete && <h1 className="hl">{title}</h1>}
            <div className="profile__head">
                <Upload {...propsUpload} showUploadList={false}>
                    <div className="profile__avatar">
                        <div>
                            <img src={avatar || data.image || Images.icoAvatar.src} alt={Images.icoAvatar.alt} />
                        </div>
                        <img src={Images.icoEdit.src} alt={Images.icoEdit.alt} className="profile__ico-edit" />
                    </div>
                </Upload>
                <div>
                    <h2 className="h1">{data.name}</h2>
                    <p>Carica il logo dell&apos;azienda</p>
                </div>
            </div>

            <Form
                form={form}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout="vertical"
                name="profile"
                onFinish={onSendData}
                validateMessages={validateMessages}
            >
                <Form.Item name="lat" initialValue={data.lat || null} className="profile__field-hidden">
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="lng" initialValue={data.lng || null} className="profile__field-hidden">
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="secret2fa" initialValue={data.secret2fa || null} className="profile__field-hidden">
                    <Input type="hidden" />
                </Form.Item>
                <h2 className="h1">Utenze associate</h2>

                <div className="adduser">
                    <Form.List name="subAccounts">
                        {(fields, { add, remove }) => {
                            return (
                                <>
                                    {fields.map((field: any, i) => {
                                        return (
                                            <div className="box--grey-xl pb-0 mb-30 profile__sub" key={field.key}>
                                                {i + 1 > originalSubAccountLength && (
                                                    <button
                                                        type="button"
                                                        onClick={() => deleteSubAccount(i)}
                                                        className="profile_delete"
                                                    >
                                                        <img src={Images.icoDeleteDark.src} alt={Images.icoDeleteDark.alt} />
                                                    </button>
                                                )}
                                                <Row gutter={16}>
                                                    <Col sm={24} className="adduser__status">
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'active']}
                                                            label="Attivo"
                                                            fieldKey={[field.fieldKey, 'active']}
                                                        >
                                                            <Switch
                                                                size="small"
                                                                disabled={i === 0}
                                                                defaultChecked={
                                                                    data.subAccounts[i] ? data.subAccounts[i].active : false
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'email']}
                                                            label="Email"
                                                            fieldKey={[field.fieldKey, 'email']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    type: 'email',
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="Email" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col sm={12}>
                                                        <FormPhone
                                                            label="Numero di telefono"
                                                            placeholder=""
                                                            name={[field.name, 'phone']}
                                                            initialValue=""
                                                            required
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        );
                                    })}
                                    <Form.Item className="adduser__btn">
                                        <Button type="dashed" onClick={() => add()} className="btn--green">
                                            Aggiungi utente
                                        </Button>
                                    </Form.Item>
                                </>
                            );
                        }}
                    </Form.List>
                </div>

                <hr className="div mt-10" />

                <ProfileEditAdmin data={data} />

                <hr className="div mt-10" />

                <ProfileEditOperational copyData={copyData} data={data} />

                <hr className="div mt-10" />

                <Row justify="space-between">
                  <h2 className="h1 with-tooltip">
                    Autenticazione a due fattori
                  </h2>
                </Row>
                <Row gutter={16}>
                  <Col sm={24}>
                    <Form.Item
                      label="Attivo"
                      name="enabled2fa"
                      valuePropName="checked"
                      initialValue={data.enabled2fa}
                      rules={[{ required: false }]}
                    >
                      <Switch
                        size="small"
                        defaultChecked={
                          data.enabled2fa
                        }
                        onChange={(checked: any) => {
                          modalShow2FASet(checked);
                          if(!checked) {
                            form.setFieldsValue({ ...form.getFieldsValue(), secret2fa: '' });
                            doCleanFields();
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Modal
                  title="Imposta l'autenticazione a due fattori"
                  visible={modalShow2FA}
                  onCancel={doCancel2FA}
                  onOk={doOk2FA}
                  okText="Verifica"
                  okButtonProps={{ disabled: verifyOTP.length !== 6 }}
                  maskClosable={false}
                >
                  <p>
                    <h3>Passaggio 1:</h3>
                    Scansione il codice QR qui sotto con un generatore di token di tua scelta (ad es: Google Authenticator)
                  </p>
                  <br />
                  <div className='text-center'><img className='qrCode2FA' src={otpURL} alt='' /></div>
                  <br />
                  <p>
                    <h3>Passaggio 2:</h3>
                    Inserisci un token generato dall&apos;app:
                  </p>
                  <Input name='verifyOTP' className={wrongVerifyOTP ? 'bRed' : ''} value={verifyOTP} onChange={(ev: any) => { verifyOTPSet(ev.target.value); wrongVerifyOTPSet(false); }} minLength={6} maxLength={6} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() }}} />
                </Modal>

                <div className="form-fixed-buttons">
                    <div>
                        <div className="container">
                            <Row justify="end" align="middle">
                                <Link to={cancelUrl} className="ant-btn mr-20 btn--grey-xl">
                                    Annulla
                                    </Link>
                                <Button htmlType="submit">Salva</Button>
                            </Row>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    );
};

export default ProfileEdit;
