import React, { ReactNode } from 'react';
import './style.sass';

interface iProps {
  letter: string;
  children: ReactNode;
}

const FormItemWithLetter: React.FC<iProps> = ({ children, letter }: iProps) => {
  return <div className={`with-letters with-letters__${letter}`}>{children}</div>;
};

export default FormItemWithLetter;
