import React from 'react';
import { Form, Input } from 'antd';
import Config from '../../config/Config';

interface iProps {
  label?: string;
  placeholder?: string;
  name?: any;
  initialValue?: string;
  required?: boolean;
}

const FormProvince: React.FC<iProps> = ({
  label,
  placeholder,
  name,
  initialValue,
  required,
}: iProps) => {
  return (
    <Form.Item
      label={label}
      name={name}
      initialValue={initialValue}
      rules={[
        { required },
        ({ getFieldValue }: any) => ({
          validator(rule, value) {
            if (value) {
              const regex = Config.regex.province;
              if (regex.test(value)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Può contenere solo 2 lettere'));
            }
            return Promise.resolve();
          },
        }),
      ]}
    >
      <Input placeholder={placeholder} />
    </Form.Item>
  );
};

FormProvince.defaultProps = {
  label: 'Provincia',
  placeholder: 'Inserisci la provincia',
  name: 'province',
  initialValue: '',
  required: true,
};

export default FormProvince;
