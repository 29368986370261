import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Switch, Modal } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';

import { organizationSetStatusAsyncAction } from '../../store/features/organizationsSlice';
import { userAsyncImpersonatedAction } from '../../store/features/userSlice';
import { enumRoutes, enumOrganizationsTypes } from '../../libs/enums';

import Images from '../../assets/images';

import './style.sass';

interface iProps {
  organization: any;
  organizationType: enumOrganizationsTypes | undefined;
}

const OrganizationsCard: React.FC<iProps> = ({ organization, organizationType }: iProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { account, administrativeData, created_at, id, image, name } = organization;

  let city = '';
  let state = '';
  let street = '';
  let zip = '';

  if (administrativeData) {
    city = administrativeData.city;
    state = administrativeData.state;
    street = administrativeData.street;
    zip = administrativeData.zip;
  }

  function onChangeStatus(checked: boolean) {
    if (!checked) {
      setIsModalVisible(true);
    } else {
      dispatch(organizationSetStatusAsyncAction(`${id}/status/active`));
    }
  }

  function deactiveAccount() {
    dispatch(organizationSetStatusAsyncAction(`${id}/status/inactive`));
    setIsModalVisible(false);
  }

  function onImpersonateClicked() {
    dispatch(userAsyncImpersonatedAction(id));
  }

  function onCardClicked() {
    let newRoute = '';
    switch (organizationType) {
      case enumOrganizationsTypes.noprofit:
        newRoute = enumRoutes.organizationsNoProfitProfile.replace(':id', id);
        break;
      case enumOrganizationsTypes.pa:
        newRoute = enumRoutes.organizationsPaProfile.replace(':id', id);
        break;
      default:
        newRoute = enumRoutes.organizationsDonorsProfile.replace(':id', id);
    }
    history.push(newRoute);
  }

  const separator = street || zip || state ? '-' : '';
  const stateTxt = state ? `(${state})` : '';
  const date = moment(new Date(created_at)).format('DD/MM/YYYY');

  return (
    <article className="box box--grey-xl">
      <Row justify="space-between">
        <Col sm={12} className="organizations__card">
          <div className="organizations__id">
            <span
              role="button"
              title="Cliccami per copiare"
              tabIndex={0}
              onClick={() => {
                navigator.clipboard.writeText(id);
              }}
              onKeyDown={() => {
                navigator.clipboard.writeText(id);
              }}
            >
              ID:
            </span>
            &nbsp;{id}
          </div>
          <button type="button" onClick={onCardClicked}>
            <div className="organizations__img">
              <img
                src={image ? image.square_300.url : Images.icoAvatar.src}
                alt={Images.icoAvatar.alt}
                className="profile__avatar"
              />
            </div>
            <div>
              <h1>{name}</h1>
              <p className="f-s-17">
                {city} {separator} {street} {zip} {stateTxt}
              </p>
              <p>Data creazione: {date}</p>
            </div>
          </button>
        </Col>
        <Col sm={12} className="organizations__btns">
          <div>
            <Switch onChange={onChangeStatus} checked={account.status === 'active'} />
            Attiva
          </div>
          <button type="button" onClick={onImpersonateClicked} className="ant-btn ant-btn-primary">
            Entra
          </button>
        </Col>
      </Row>
      <Modal
        title="Sei sicuro di voler disabilitare l'account?"
        visible={isModalVisible}
        onOk={deactiveAccount}
        onCancel={() => setIsModalVisible(false)}
        cancelText="Annulla"
        okText="Disattiva"
        className="organizations__modal"
      >
        <h2>Sei sicuro di voler disabilitare l&apos;account?</h2>
      </Modal>
    </article>
  );
};

export default OrganizationsCard;
