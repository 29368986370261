/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Tooltip, Row } from 'antd';

import Images from '../../assets/images';

interface iProps {
  label?: string;
  tooltip?: string;
  required?: boolean;
}

const FormLabelWithTooltip: React.FC<iProps> = ({ label, required, tooltip }: iProps) => {
  return (
    <Row justify="space-between">
      {label}
      {required && <div> *</div>}
      <Tooltip title={tooltip}>
        <img className="tooltip__info" src={Images.icoinfo.src} alt={Images.icoinfo.alt} />
      </Tooltip>
    </Row>
  );
};

FormLabelWithTooltip.defaultProps = {
  label: 'Label',
  tooltip: 'Testo di prova per tooltip',
  required: false,
};

export default FormLabelWithTooltip;
