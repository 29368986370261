/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
// import Highcharts from 'highcharts';
import Highcharts from 'highcharts';

import { iStatisticsReportItem } from '../../libs/interfaces';
import { renderPieData } from '../../libs/helpers/statistics.helpers';

interface iProps {
  data: iStatisticsReportItem[];
  id: string;
  title: string;
}

const StatisticsPieChart: React.FC<iProps> = ({ data, id, title }: iProps) => {
  function renderHC() {
    Highcharts.chart(renderPieData({ id, data, title }));
  }

  useEffect(() => {
    renderHC();
  }, [data]);

  return <div className="my-50" id={id} />;
};

export default StatisticsPieChart;
