// eslint-disable-next-line import/prefer-default-export
export enum enumRoutes {
  changePassword = '/auth/change-password',
  login = '/auth/login',
  loginConfirm = '/auth/thankyou',
  newpassword = '/auth/new-password',
  newpasswordConfim = '/auth/new-password-send',

  profile = '/profile',
  profileEdit = '/profile/edit',

  organizations = '/organizations',
  // Donor
  organizationsDonors = '/organizations/companies',
  organizationsDonorsProfile = '/organizations/companies/:id/profile',
  organizationsDonorsProfileEdit = '/organizations/companies/:id/profile/edit',
  organizationsDonorsDeals = '/organizations/companies/:id/deals',
  organizationsDonorsSubscriptions = '/organizations/companies/:id/subscriptions',
  // No Profit
  organizationsNoProfit = '/organizations/noprofit',
  organizationsNoProfitProfile = '/organizations/noprofit/:id/profile',
  organizationsNoProfitProfileEdit = '/organizations/noprofit/:id/profile/edit',
  organizationsNoProfitLevel = '/organizations/noprofit/:id/livello',
  organizationsNoProfitDeals = '/organizations/noprofit/:id/deals',
  organizationsNoProfitDealsNoProfits = '/organizations/noprofit/:id/noprofitsdeals',
  organizationsNoProfitSubscriptions = '/organizations/noprofit/:id/subscriptions',
  organizationsNoProfitCredits = '/organizations/noprofit/:id/credits',
  // PA
  organizationsPa = '/organizations/pa',
  organizationsPaProfile = '/organizations/pa/:id/profile',
  organizationsPaProfileEdit = '/organizations/pa/:id/profile/edit',

  registrationNoprofit = '/auth/registration/noprofit',
  registrationDonor = '/auth/registration/donor',

  offers = '/offers',
  offersAll = '/offers/all',
  offersAvailable = '/offers/available',
  offersBooked = '/offers/booked',
  offersMyDonation = '/offers/my-donations',
  offersReservations = '/offers/reservations',
  offerNew = '/offer/new',
  offerEdit = '/offer/edit/:offerId',
  offerEditLink = '/offer/edit/',

  reservations = '/reservations',

  statistics = '/statistics',
  statisticsDashboard = '/statistics/dashboard',
  statisticsTransactions = '/statistics/transactions',

  subscriptions = '/subscriptions',
  categories = '/categories',

  messages = '/messages',
  widget = '/widget/:id/:from?/:to?',
}
