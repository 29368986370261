/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, ReactNode } from 'react';
import { Col, Row, Radio } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../../store';
import { enumBadges, enumSubscriptions, enumSubDonor, enumSubNoProfit } from '../../libs/enums';
import { isDonor } from '../../libs/helpers/privileges.helpers';
import {
  Badge,
  SubscriptionCard,
  SubscriptionContract,
  SubscriptionThank,
  TermsModal,
} from '../../components';
import {
  setShowModalAction,
  subscriptionsAsynRequestNewPlanAction,
} from '../../store/features/subscriptionSlice';

import './style.sass';

const donorsCards = [
  enumSubscriptions.donorFree,
  enumSubscriptions.donorReport,
  enumSubscriptions.donorAdvanced,
  enumSubscriptions.donorCustom,
];

const noProfitCards = [
  enumSubscriptions.noProfitFree,
  enumSubscriptions.noProfitOpportunity,
  enumSubscriptions.noProfitAbsolute,
  enumSubscriptions.noProfitCustom,
];

const Subscriptions: React.FC = () => {
  const dispatch = useDispatch();
  const { user, subscriptions } = useSelector((state: RootState) => state);
  const donor = isDonor(user.role);
  // modalOk is set after async Api call.
  const { modalOk } = subscriptions;
  const [modalThankVisible, modalThankVisibleSet] = useState<boolean>(false);
  const [month, monthSet] = useState<number>(1);
  const [modalTerms, modalTermsSet] = useState<boolean>(false);
  const [modalContract, modalContractSet] = useState<boolean>(false);
  const [contractHeader, contractHeaderSet] = useState<ReactNode>(null);
  const [planToSend, planToSendSet] = useState<enumSubDonor | enumSubNoProfit | null>(null);

  function closeModalFromStore() {
    dispatch(setShowModalAction(false));
  }

  useEffect(() => {
    return () => closeModalFromStore();
  }, []);

  useEffect(() => {
    modalThankVisibleSet(modalOk);
    if (modalOk) {
      modalContractSet(false);
    }
  }, [modalOk]);

  function onMoreInfo() {
    modalTermsSet(true);
  }

  function sendRequest() {
    if (planToSend) {
      dispatch(
        subscriptionsAsynRequestNewPlanAction({
          subscription_req: planToSend,
          payment: month ? 'month' : 'year',
        }),
      );
    }
  }

  function onContractSelected(plan: enumSubDonor | enumSubNoProfit, header: any) {
    planToSendSet(plan);
    contractHeaderSet(header);
    modalContractSet(true);
  }

  function renderCard(type: enumSubscriptions) {
    return (
      <Col lg={6} md={12} key={type}>
        <SubscriptionCard
          month={Boolean(month)}
          onContractSelected={onContractSelected}
          onMoreInfo={onMoreInfo}
          type={type}
        />
      </Col>
    );
  }

  return (
    <div className="subscriptions">
      <Row justify="center" align="middle" className="subscriptions__radio">
        <Radio.Group value={month} onChange={(v) => monthSet(v.target.value)}>
          <Radio value={1} className="subscriptions__month">
            Pagamento mensile
          </Radio>
          <Radio value={0} className="subscriptions__year">
            Pagamento annuale
          </Radio>
        </Radio.Group>
        <Badge text="Risparmia il 20%" type={enumBadges.green} />
      </Row>
      <Row gutter={16} className="mt-30">
        {donor ? (
          <>{donorsCards.map((type: enumSubscriptions) => renderCard(type))}</>
        ) : (
          <>{noProfitCards.map((type: enumSubscriptions) => renderCard(type))}</>
        )}
      </Row>
      <TermsModal visible={modalTerms} onCloseModal={() => modalTermsSet(false)} />
      <SubscriptionContract
        onClose={() => modalContractSet(false)}
        modalVisible={modalContract}
        onConfirm={sendRequest}
        header={contractHeader}
      />
      <SubscriptionThank modalVisible={modalThankVisible} onClose={() => closeModalFromStore()} />
    </div>
  );
};

export default Subscriptions;
