/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Button, Row } from 'antd';
import { useHistory } from 'react-router-dom';

import { userLoginAsyncAction } from '../../store/features/userSlice';
import Localize from '../../libs/localization';

import { forgotPasswordApi } from '../../api/user.api';

import { iLoginPayload } from '../../libs/interfaces';
import { enumRoutes } from '../../libs/enums';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const validateMessages = {
  required: 'Campo obbligatorio',
  types: {
    email: 'Il campo non è un indirizzo email valido',
  },
};

const initData: iLoginPayload = {
  email: '',
  password: '',
  otp: '',
};

const LoginScreen: React.FC = () => {
  const [forgot, forgotSet] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = useState<iLoginPayload>(initData);
  const [submitDisabled, submitDisabledSet] = useState<boolean>(true);
  const [controlsDisabled, controlsDisabledSet] = useState<boolean>(false);
  const [show2FACode, show2FACodeSet] = useState<boolean>(false);

  async function onSendForm() {
    const { email, password, otp } = data;
    const userLoginResponse: any = await dispatch(userLoginAsyncAction({ email, password, otp }));
    if (
      userLoginResponse.meta.requestStatus === 'fulfilled' &&
      userLoginResponse.payload.enabled2fa
    ) {
      controlsDisabledSet(true);
      show2FACodeSet(true);
    }
  }

  function checkButton() {
    const disabled = !data.email || !data.password;
    submitDisabledSet(disabled);
  }

  useEffect(() => {
    checkButton();
  }, [data]);

  function onValuesChange(values: any) {
    setData({ ...data, ...values });
  }

  async function forgotPassword() {
    const askForgotPassword = await forgotPasswordApi(data.email);
    if (askForgotPassword.payload?.ok) {
      history.push(enumRoutes.newpasswordConfim);
    }
  }

  return (
    <>
      <h1 className="mb-30">{Localize('LOGIN.TITLE')}</h1>
      {forgot ? (
        <Form
          {...layout}
          layout="vertical"
          name="password-forgot"
          onFinish={forgotPassword}
          validateMessages={validateMessages}
          onValuesChange={onValuesChange}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { type: 'email' },
              { required: true, message: 'Il campo email è obbligatorio' },
            ]}
          >
            <Input placeholder={Localize('FORMS.ERRORS.EMAIL')} />
          </Form.Item>

          <Row justify="end" align="middle">
            <Button onClick={() => forgotSet(false)} className="mr-15 btn--grey-xl">
              Annulla
            </Button>
            <Button htmlType="submit">Invia</Button>
          </Row>
        </Form>
      ) : (
        <Form
          {...layout}
          layout="vertical"
          name="login"
          initialValues={{ remember: true }}
          onFinish={onSendForm}
          onValuesChange={onValuesChange}
          validateMessages={validateMessages}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { type: 'email' },
              { required: true, message: 'Il campo email è obbligatorio' },
            ]}
          >
            <Input placeholder={Localize('FORMS.ERRORS.EMAIL')} />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Il campo password è obbligatorio' }]}
          >
            <Input.Password placeholder={Localize('FORMS.ERRORS.PASSWORD')} />
          </Form.Item>

          {show2FACode && (
            <Form.Item
              label="OTP"
              name="otp"
              rules={[{ required: true, message: 'Il campo otp è obbligatorio' }]}
            >
              <Input.Password placeholder={Localize('FORMS.ERRORS.OTP')} />
            </Form.Item>
          )}

          <Row justify="space-between" align="middle">
            <p>
              <button type="button" onClick={() => forgotSet(true)}>
                {Localize('LOGIN.FORGOT_PASSWORD')}
              </button>
            </p>
            <Button htmlType="submit" disabled={submitDisabled}>
              Accedi
            </Button>
          </Row>
        </Form>
      )}
    </>
  );
};

export default LoginScreen;
