/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import history from '../history';
import { getLoginApi, setImpersonateApi } from '../../api/user.api';

import { userImpersonateEmailAction } from './userActions';
import { profileAsynGetAction, profileResetAction } from './profileSlice';

import { iUserState, iLoginPayload } from '../../libs/interfaces';
import { enumRoutes } from '../../libs/enums';
import { canReadProfileAfterLogin, isPA } from '../../libs/helpers/privileges.helpers';
import { asyncThunkErrorHelper } from '../../libs/helpers/commons';

export const initialUserState: iUserState = {
  email: '',
  token: '',
  role: '',
  lang: 'it',
  realUser: null,
};
const slice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    userLoginAction: (state, action) => {
      return { ...state, ...action.payload };
    },
    userLogoutAction: () => {
      return initialUserState;
    },
    userImpersonateAction: (state, action) => {
      state.token = action.payload.token;
      state.role = action.payload.role;
      state.email = action.payload.email;
    },
    userSetRealUserAction: (state, action) => {
      state.realUser = action.payload;
    },
    userSetRealUserResetAction: (state) => {
      state.realUser = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userImpersonateEmailAction, (state, action) => {
      state.email = action.payload;
    });
  },
});

export const {
  userImpersonateAction,
  // userImpersonateEmailAction,
  userLoginAction,
  userLogoutAction,
  userSetRealUserAction,
  userSetRealUserResetAction,
} = slice.actions;

export default slice.reducer;

function goToAfterLogin(role: any) {
  const nextRoute: enumRoutes = isPA(role) ? enumRoutes.statistics : enumRoutes.offers;
  history.push(nextRoute);
}

export const userLoginAsyncAction = createAsyncThunk(
  'user/userLoginAsyncAction',
  async (payload: iLoginPayload, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await getLoginApi(payload);
      const user = {
        email: payload.email,
        role: response.role,
        token: response.token,
        enabled2fa: response.enabled2fa,
      };
      await dispatch(userLoginAction(user));
      if (canReadProfileAfterLogin(response.role)) {
        await dispatch(profileAsynGetAction(null));
      }
      if (response.authenticated) {
        goToAfterLogin(response.role);
      }
      return user;
    } catch (err) {
      history.push(enumRoutes.login);
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const userAsyncImpersonatedAction = createAsyncThunk(
  'user/userAsyncImpersonatedAction',
  async (payload: string, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await setImpersonateApi(payload);

      const { user } = getState() as { user: iUserState };
      const { token, role, email } = user;
      const realUser = { token, role, email };
      await dispatch(userSetRealUserAction(realUser));

      await dispatch(
        userImpersonateAction({
          token: response.token,
          role: response.role,
          email: '',
        }),
      );

      await dispatch(profileAsynGetAction(null));
      goToAfterLogin(response.role);
      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const userBeforeLogoutAction = () => (dispatch: any, getState: any): any => {
  const state = getState();
  if (state.user.realUser) {
    dispatch(userImpersonateAction(state.user.realUser));
    dispatch(userSetRealUserResetAction());
    dispatch(profileResetAction());
    history.push(enumRoutes.organizations);
  } else {
    dispatch(userLogoutAction());
    dispatch(profileResetAction());
  }
};

export const userImpersonateEmail = (email: string) => (dispatch: any): any => {
  dispatch(userImpersonateEmailAction(email));
};
