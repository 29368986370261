/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { Row, Col, Button } from 'antd';
import { capitalize } from 'lodash';
import moment from 'moment';

import { iSubRequestResponse } from '../../libs/interfaces';
import { enumSubCurrentStatus } from '../../libs/enums';

interface iProps {
  list: iSubRequestResponse[];
  onDeletePlan: (plan: iSubRequestResponse) => void;
  onPlanSelected: (plan: iSubRequestResponse) => void;
}

const SubscriptionsList: React.FC<iProps> = ({ list, onDeletePlan, onPlanSelected }: iProps) => {
  const boxes = useMemo(() => {
    return list.map((item: iSubRequestResponse) => {
      return (
        <Row
          className="box box--grey-xl mb-10"
          justify="space-between"
          align="middle"
          key={item.id}
        >
          <div className={`box-plan-${item.subscription}`}>
            <Row align="middle">
              <div>{`Piano ${capitalize(item.subscription)}`}</div>
              <div className="mx-20 c-light">-</div>
              <div className="c-light f-s-12">
                <div>pagamento: {item.payment === 'month' ? 'mensile' : 'annuale'}</div>
              </div>
            </Row>
            <div className="c-light f-s-12">
              <div>Attivazione: {moment(item.date_from).format('DD/MM/YYYY')}</div>
              <div>Scadenza: {moment(item.date_end).format('DD/MM/YYYY')}</div>
            </div>
          </div>
          {(item.current_status === enumSubCurrentStatus.now ||
            item.current_status === enumSubCurrentStatus.future) && (
            <div>
              <Button className="mr-15" onClick={() => onPlanSelected(item)}>
                MODIFICA
              </Button>
              <Button onClick={() => onDeletePlan(item)}>ELIMINA</Button>
            </div>
          )}
        </Row>
      );
    });
  }, [list]);
  return <>{boxes}</>;
};

export default SubscriptionsList;
