import { type } from '../../components/helpers/router';
import { AppLayout } from '../../components/layouts';
import { enumRoutes, enumRoles } from '../../libs/enums';
import Statistics from '../../views/statistics/statistics.view';
import Transactions from '../../views/transactions/transactions.view';

const RoutesStatistics = [
  {
    id: 'statistics',
    title: 'Statistiche',
    path: enumRoutes.statistics,
    layout: AppLayout,
    component: Statistics,
    type: type.AUTHENTICATION,
    redirectTo: enumRoutes.statisticsDashboard,
    roles: [
      enumRoles.admin,
      enumRoles.pa,
      enumRoles.noprofit,
      enumRoles.noprofitSub,
      enumRoles.noprofitSecond,
      enumRoles.noprofitSecondSub,
      enumRoles.donor,
      enumRoles.donorSub,
    ],
  },
  {
    id: 'statisticsDashboard',
    title: 'Statistiche',
    path: enumRoutes.statisticsDashboard,
    layout: AppLayout,
    component: Statistics,
    type: type.AUTHENTICATION,
    roles: [
      enumRoles.admin,
      enumRoles.pa,
      enumRoles.noprofit,
      enumRoles.noprofitSub,
      enumRoles.noprofitSecond,
      enumRoles.noprofitSecondSub,
      enumRoles.donor,
      enumRoles.donorSub,
    ],
  },
  {
    id: 'statisticsTransactions',
    title: 'Statistiche',
    path: enumRoutes.statisticsTransactions,
    layout: AppLayout,
    component: Transactions,
    type: type.AUTHENTICATION,
    roles: [
      enumRoles.admin,
      enumRoles.pa,
      enumRoles.noprofit,
      enumRoles.noprofitSub,
      enumRoles.noprofitSecond,
      enumRoles.noprofitSecondSub,
      enumRoles.donor,
      enumRoles.donorSub,
    ],
  },
];

export default RoutesStatistics;
