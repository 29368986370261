/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from 'react';
import { Row, Col, Modal, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize } from 'lodash';

import { getSubscriptionColor } from '../../../libs/helpers/subscriptions.helpers';
import { iProfileData, iSubPlan, iSubRequestResponse, iSubSetData } from '../../../libs/interfaces';
import { enumSubCurrentStatus } from '../../../libs/enums';
import { ProfileCard, SubscriptionsList, SubscriptionForm, Badge } from '../../../components';
import { RootState } from '../../../store';
import {
  subscriptionsAsynGetListAvailableAction,
  subscriptionsAsynGetUserHistoryPlansAction,
  resetUserAction,
  subscriptionsAsynSetPlanAction,
  subscriptionsAsynDeletePlanAction,
} from '../../../store/features/subscriptionSlice';
import Images, { SvgDiamond } from '../../../assets/images';

interface iProps {
  organization: iProfileData;
}

interface iDeletePayload {
  accountId: string;
  planId: string;
}

const OrganizationAllSubscription: React.FC<iProps> = ({ organization }: iProps) => {
  const dispatch = useDispatch();

  const [modalEditVisibility, modalEditVisibilitySet] = useState<boolean>(false);
  const [planToEdit, planToEditSet] = useState<iSubRequestResponse | undefined>();
  const [modalDeleteVisibility, modalDeleteVisibilitySet] = useState<boolean>(false);
  const [payloadDelete, payloadDeleteSet] = useState<iDeletePayload>();

  const { subscriptions } = useSelector((state: RootState) => state);
  const { listAvailable } = subscriptions;

  const listPlan = subscriptions.userHistoryPlans.filter(
    (i: iSubRequestResponse) => i.status === 'active',
  );
  const currentPlan = listPlan.find(
    (i: iSubRequestResponse) => i.current_status === enumSubCurrentStatus.now,
  );

  const requestPlan = subscriptions.userHistoryPlans.find(
    (i: iSubRequestResponse) => i.status === 'request',
  );

  const optionsPlans = listAvailable.filter(
    (i: iSubPlan) => i.name !== 'free' && i.name !== 'custom',
  );

  const profileCard = useMemo(() => {
    return organization ? <ProfileCard profile={organization} currentPlan={currentPlan} /> : null;
  }, [organization, currentPlan]);

  useEffect(() => {
    return () => {
      dispatch(resetUserAction());
    };
  }, []);

  useEffect(() => {
    if (organization?.id) {
      dispatch(subscriptionsAsynGetListAvailableAction(organization.id));
      dispatch(subscriptionsAsynGetUserHistoryPlansAction(organization.id));
    }
  }, [organization]);

  function onSendData(dataToSend: iSubSetData) {
    dispatch(subscriptionsAsynSetPlanAction(dataToSend));
  }

  function onCloseModal() {
    modalEditVisibilitySet(false);
  }

  function onPlanSelected(plan: iSubRequestResponse) {
    planToEditSet(plan);
    modalEditVisibilitySet(true);
  }

  function onDeleteClicked(plan: iSubRequestResponse) {
    const payload: iDeletePayload = {
      accountId: plan.account_id,
      planId: plan.id,
    };
    payloadDeleteSet(payload);
    modalDeleteVisibilitySet(true);
  }

  function onCloseModalDelete() {
    payloadDeleteSet(undefined);
    modalDeleteVisibilitySet(false);
  }

  function onDelete() {
    if (payloadDelete) {
      dispatch(subscriptionsAsynDeletePlanAction(payloadDelete));
      onCloseModalDelete();
    }
  }

  return (
    <div>
      <Row gutter={16}>
        <Col md={12}>{profileCard}</Col>
        <Col md={12}>
          {requestPlan && (
            <Row className="requestPlan">
              <div className="mr-10">
                <img src={Images.icoBell.src} alt="" />
              </div>
              <div>
                <Row>
                  <div>Richiesta attivazione</div>
                  <Badge
                    text={`Piano ${capitalize(requestPlan.subscription_req)}`}
                    image={SvgDiamond('ffffff')}
                    moreClass={`badge--plan bg-${getSubscriptionColor(
                      requestPlan.subscription_req,
                    )} ml-20`}
                  />
                </Row>
                <div>
                  Pagamento:{' '}
                  <strong>{requestPlan.payment === 'month' ? 'mensile' : 'annuale'}</strong>
                </div>
              </div>
              <div className="flex1 text-right">
                <Button onClick={() => onDeleteClicked(requestPlan)}>Elimina</Button>
              </div>
            </Row>
          )}
        </Col>
      </Row>
      <hr className="div my-30" />
      <h2 className="h1 mb-30">Piano subscription</h2>

      <SubscriptionForm
        onSendData={onSendData}
        optionsPlans={optionsPlans}
        organizationId={organization?.id ? organization?.id.toString() : ''}
        startPlan={requestPlan}
      />

      <hr className="div my-30" />
      <h2 className="h1 mb-30">Storico</h2>
      <SubscriptionsList
        list={listPlan}
        onPlanSelected={onPlanSelected}
        onDeletePlan={onDeleteClicked}
      />

      <Modal
        title="Modifica piano"
        visible={modalEditVisibility}
        onCancel={onCloseModal}
        footer={null}
        width={900}
      >
        <>
          <SubscriptionForm
            onSendData={onSendData}
            optionsPlans={optionsPlans}
            organizationId={organization?.id ? organization?.id.toString() : ''}
            startPlan={planToEdit}
            labelButton="MODIFICA PIANO"
          />
        </>
      </Modal>

      <Modal
        title="Attenzione!"
        visible={modalDeleteVisibility}
        onCancel={onCloseModalDelete}
        onOk={onDelete}
        width={500}
        cancelText="Non eliminare"
        okText="Elimina piano"
      >
        <>
          <h1>Sei sicuro di voler eliminare il piano?</h1>
        </>
      </Modal>
    </div>
  );
};

export default OrganizationAllSubscription;
