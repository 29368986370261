import React, { useEffect, useRef } from 'react';
import _, { capitalize } from 'lodash';
import {
  Form,
  Input,
  InputNumber,
  Row,
  Col,
  DatePicker,
  Button,
  Checkbox,
  Radio,
  Select,
} from 'antd';
import { Link } from 'react-router-dom';
import locale from 'antd/es/date-picker/locale/it_IT';
import { FormLabelWithTooltip } from '../../components';
import { enumOffersVisibility, enumRoutes } from '../../libs/enums';
import { offersDeliverySource } from '../../libs/helpers/offers.helpers';
import Config from '../../config/Config';
import { iVisibilityNoProfits } from '../../libs/interfaces';

const { TextArea } = Input;
const { Option } = Select;

interface iProps {
  isEditMode: boolean;
  withAgreements: boolean;
  wasNotPublic: boolean;
  shipment: boolean;
  form: any;
  agreementsForDonorWithDetails: any;
}

const OfferNewOthers: React.FC<iProps> = ({
  isEditMode,
  withAgreements,
  shipment,
  wasNotPublic,
  form,
  agreementsForDonorWithDetails,
}: iProps) => {
  const formCurrentValues = form.getFieldsValue();
  const refVisibility = useRef(formCurrentValues.visibility);

  useEffect(() => {
    if (formCurrentValues.visibility === enumOffersVisibility.public) {
      formCurrentValues.visibilityNoProfits = [];
    }

    if (refVisibility.current === enumOffersVisibility.public) {
      if (withAgreements) {
        formCurrentValues.visibilityNoProfits = _.map(agreementsForDonorWithDetails, 'nop_id');
      }
    }

    refVisibility.current = formCurrentValues.visibility;

    form.setFieldsValue(formCurrentValues);
  }, [formCurrentValues, form, agreementsForDonorWithDetails, withAgreements]);

  return (
    <>
      <Row gutter={16}>
        <Col sm={12}>
          <Form.Item label="Modalità consegna" name="deliveryType" rules={[{ required: true }]}>
            <Checkbox.Group>
              {offersDeliverySource.map((i) => (
                <Checkbox key={i.value} value={i.value}>
                  {i.label}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        </Col>
        {shipment && (
          <Col sm={12}>
            <Form.Item
              name="shipmentCost"
              label={
                <FormLabelWithTooltip
                  label="Crediti spedizione"
                  tooltip="Puoi indicare il costo relativo alla spedizione di ogni singolo lotto"
                />
              }
              rules={[
                () => ({
                  validator(rule, value) {
                    if (value) {
                      const regex = Config.regex.maxTwoDecimal;
                      if (regex.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('Può contenere un massimo di due cifre decimali'),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber placeholder="-" />
            </Form.Item>
            <p className="offerNew__hint">
              Il costo è relativo alla spedizione di ogni singolo lotto
            </p>
          </Col>
        )}
      </Row>

      <hr className="div" />

      <Row gutter={16}>
        <Col sm={12}>
          <Form.Item label="Validità offerta" name="endDate">
            <DatePicker
              placeholder="gg/mm/aaaa"
              format="DD-MM-YYYY"
              // disabled={isEditMode}
              locale={locale}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* {(withAgreements || wasNotPublic) && ( */}
      {true && (
        <>
          <hr className="div" />

          <Row gutter={16}>
            <Col sm={24}>
              <Form.Item
                name="visibility"
                rules={[{ required: true }]}
                className="offerNew__visibility"
                label={
                  <FormLabelWithTooltip label="Visibilità offerta" tooltip="Visibilità offerta" />
                }
              >
                <Radio.Group disabled={false}>
                  <Radio value={enumOffersVisibility.public}>
                    <h3>Pubblica</h3>
                    <p>Visibile a tutti gli enti Non Profit</p>
                  </Radio>
                  <Radio value={enumOffersVisibility.private}>
                    <h3>Privata</h3>
                    <p>Visibile solo ad enti con accordi privilegiati</p>
                  </Radio>
                  {/* <Radio value={enumOffersVisibility.timed}>
                    <h3>Privata a tempo</h3>
                    <p>
                      Visibile per “xh” solo ad enti con accordi privilegiati, dopo di che visibile
                      a tutti
                    </p>
                  </Radio> */}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          {form.getFieldsValue().visibility !== enumOffersVisibility.public && (
            <Row gutter={16}>
              <Col offset={8} sm={16}>
                <Form.Item label="Ente" name="visibilityNoProfits" rules={[{ required: true }]}>
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder="Seleziona l'ente"
                    filterOption={(input, option) =>
                      (option?.nop_name ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {agreementsForDonorWithDetails.map(
                      (agreementsForDonorWithDetail: iVisibilityNoProfits) => (
                        <Option
                          key={agreementsForDonorWithDetail.nop_id}
                          value={agreementsForDonorWithDetail.nop_id}
                          nop_name={agreementsForDonorWithDetail.nop_name}
                        >
                          {capitalize(agreementsForDonorWithDetail.nop_name)}
                        </Option>
                      ),
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
        </>
      )}
      <hr className="div" />

      <Form.Item label="Note" name="notes">
        <TextArea
          showCount
          maxLength={1000}
          rows={7}
          placeholder="Inserisci ulteriori dettagli non inseriti in precedenza"
        />
      </Form.Item>

      <Row justify="end" align="middle" className="my-30">
        <Link to={enumRoutes.offers} className="ant-btn mr-20 btn--grey-xl">
          ANNULLA
        </Link>
        <Button htmlType="submit">PUBBLICA OFFERTA</Button>
      </Row>
    </>
  );
};

export default OfferNewOthers;
