/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ComponentClass, ComponentType, FC, ReactElement, Suspense } from 'react';
import { connect, ConnectedComponent, useSelector } from 'react-redux';
import {
  Switch,
  Route,
  Redirect,
  withRouter,
  RouteComponentProps,
  StaticRouter,
} from 'react-router-dom';
import { pick, size } from 'lodash';

import MetaTags from '../metaTags/metaTags';

import { removeFromLocalStorage, getFromLocalStorage } from '../../libs/helpers/localstorage';

import Loading from '../loading/loading';

import { enumRoutes } from '../../libs/enums';
import { isNoProfit } from '../../libs/helpers/privileges.helpers';

export const type = {
  PUBLIC: 'PUBLIC',
  SECURED: 'SECURED',
  AUTHENTICATION: 'AUTHENTICATION',
};

export type RouteType = {
  id: string | number;
  path: string;
  type?: string;
  icon?: ReactElement | ConnectedComponent<any, any> | ComponentClass | ComponentType | string;
  exact?: boolean;
  redirectTo?: string;
  redirectToExternal?: string;
  children?: Array<any>;
  title?: string;
  component?: any;
  noFollow?: boolean;
  layout?: any;
  layoutOptions?: any;
  scopes?: Array<string>;
  showInMenu?: boolean;
};

export const getFlatRouteList = (routes: Array<RouteType>): any => {
  const routeList: Array<RouteType> = [];
  routes.map((route) => {
    if (route.path) {
      return routeList.push(route);
    }
    if (route.children && route.children.length > 0) {
      route.children.map((child) => {
        const newChild = { ...child };
        newChild.parent_id = route.id;
        if (newChild.path) {
          return routeList.push(newChild);
        }
        return null;
      });
    }
    return null;
  });
  // console.log(routeList)
  return routeList;
};

const drawComponent = (route: RouteType, props: any) => (
  <>
    <MetaTags
      title={`${route.title && typeof route.title === 'string' ? `${route.title}` : ''}`}
      noFollow={route.noFollow}
    />
    <Suspense fallback={<Loading coverBack />}>
      {route.layout ? (
        <route.layout
          {...{
            ...props,
            ...pick(route, ['id', 'parent_id', 'path', 'title', 'showInMenu']),
            ...route.layoutOptions,
          }}
        >
          <route.component {...props} />
        </route.layout>
      ) : (
        <route.component {...props} />
      )}
    </Suspense>
  </>
);

interface RouterProps {
  routes: Array<RouteType> | null;
  authorized: boolean;
  userRole: string;
}
const Router: React.FC<RouterProps & RouteComponentProps> = ({
  routes = null,
  authorized = false,
  userRole = '',
}: RouterProps) => {
  return (
    <>
      {routes ? (
        <>
          <Switch>
            {getFlatRouteList(routes).map((r: any) => (
              <Route
                key={r.path}
                path={r.path}
                exact={r.exact || true}
                render={(props) => {
                  const { location, match } = props;
                  const { pathname, hash, search } = location;
                  const nextRedirectUri = `${pathname || match.url}${hash || ''}${search || ''}`;
                  const ls = getFromLocalStorage();
                  const redirectUri = ls && (ls as any).redirectUri;

                  // if (r.redirectToExternal) {
                  //   console.log(1)
                  //   window.location = r.redirectToExternal;
                  //   return <Loading />;
                  // }

                  if (!authorized && r.type === type.AUTHENTICATION) {
                    return <Redirect to={enumRoutes.login} />;
                  }

                  if (r.roles && (!userRole || !r.roles.includes(userRole))) {
                    // return <Redirect to={enumRoutes.offers} />
                  }

                  // TODO serve per l'atterraggio dopo il login - Verificare metodo comune con la navigation
                  if (r.path === enumRoutes.offers) {
                    if (isNoProfit(userRole)) {
                      return <Redirect to={enumRoutes.offersAvailable} />;
                    }
                  }

                  if (r.redirectTo) {
                    return <Redirect to={r.redirectTo} />;
                  }

                  // if (authorized && r.type === type.AUTHENTICATION && r.roles) {
                  //   if (r.roles.includes(userRoles)) {
                  //     return drawComponent(r, props);
                  //   }
                  //   return (
                  //     <Redirect to='/home' />
                  //   );
                  // }

                  // if (authorized && r.type === type.AUTHENTICATION) {
                  //   console.log(4)
                  //   removeFromLocalStorage("redirectUri");
                  //   return <Redirect to={decodeURIComponent(redirectUri) || "/"} />;
                  // }

                  if (!r.component) {
                    return <Loading />;
                  }

                  // if (
                  //   r.type === type.SECURED &&
                  //   r.scopes &&
                  //   r.scopes.length > 0
                  // ) {
                  //   console.log(6)
                  //   return (r.scopes || []).some((s: string) =>
                  //     Object.keys(permissions).some((p) => p === s)
                  //   ) ? (
                  //     drawComponent(r, {
                  //       ...props,
                  //       permissions: (r.scopes || []).map((s: string) => ({
                  //         [s]: permissions[s],
                  //       })),
                  //     })
                  //   ) : (
                  //     <Redirect to="/forbidden" />
                  //   );
                  // }
                  const componentProps = { ...props };
                  if (r.params) {
                    componentProps.match.params = { ...props.match.params, ...r.params };
                  }

                  return drawComponent(r, componentProps);
                }}
              />
            ))}

            <Route key={-1} render={() => <Redirect to="/" />} />
          </Switch>
        </>
      ) : (
        <p>No routes</p>
      )}
    </>
  );
};

export default withRouter(Router);
