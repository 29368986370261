/* eslint-disable import/prefer-default-export */

export enum enumOffersCategoryType {
  food = 'food',
  nonfood = 'non-food',
  service = 'service',
  medicine = 'medicine',
}

export enum enumOffersDelivery {
  shipment = 'shipment',
  collection = 'collection',
}

export enum enumOffersOfferType {
  sale = 'sale',
  donation = 'donation',
}

export enum enumOffersDonationType {
  riskOfWaste = 'risk-of-waste',
  voluntaryDonation = 'voluntary-donation',
  returned = 'returned',
  slowMovers = 'slow-movers',
  obsolete = 'obsolete',
  defective = 'defective',
}

export enum enumOffersVisibility {
  public = 'public',
  private = 'private',
  timed = 'timed-private',
}

export enum enumOffersExpiryType {
  tmc = 'tmc',
  expiration = 'expiration',
}

export enum enumOffersStatus {
  withorders = 'with-orders',
  noorders = 'no-orders',
  completed = 'completed',
}
