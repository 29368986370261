import React from 'react';
import CardList from '../../components/subscriptionCard/cardList';

import { capitalizeFirstChar } from './commons';
import { getRoleType } from './privileges.helpers';
import { enumSubNoProfit, enumSubDonor, enumSubscriptions } from '../enums';

export function setPlanWithRole(plan: string, role: string): enumSubscriptions {
  const roleType = getRoleType(role);
  const finalString = (roleType + capitalizeFirstChar(plan)) as enumSubscriptions;
  return finalString;
}

export function getSubscriptionColor(plan: string): string {
  let color = 'green';
  switch (plan) {
    case enumSubDonor.report:
    case enumSubNoProfit.opportunity:
      color = 'yellow-d';
      break;
    case enumSubDonor.advanced:
    case enumSubNoProfit.absolute:
      color = 'blue';
      break;
    case enumSubDonor.custom:
    case enumSubNoProfit.custom:
      color = 'red';
      break;
    default:
      color = 'green';
  }
  return color;
}

export const planEnumToProfileEnum = {
  [enumSubscriptions.donorFree]: enumSubDonor.free,
  [enumSubscriptions.donorReport]: enumSubDonor.report,
  [enumSubscriptions.donorAdvanced]: enumSubDonor.advanced,
  [enumSubscriptions.donorCustom]: enumSubDonor.custom,
  [enumSubscriptions.noProfitFree]: enumSubNoProfit.free,
  [enumSubscriptions.noProfitOpportunity]: enumSubNoProfit.opportunity,
  [enumSubscriptions.noProfitAbsolute]: enumSubNoProfit.absolute,
  [enumSubscriptions.noProfitCustom]: enumSubNoProfit.custom,
};

const donorBasePlanList = () => {
  return (
    <>
      <CardList>
        Iscrizione <strong>gratuita</strong>
      </CardList>
      <CardList>
        Utilizzo <strong>gratuito</strong> e illimitato della piattaforma per la donazione e la
        vendita di prodotti
      </CardList>
    </>
  );
};

export const plansDataDonor: any = {
  [enumSubscriptions.donorFree]: {
    color: getSubscriptionColor(enumSubDonor.free),
    list: () => <>{donorBasePlanList()}</>,
    simpleTitle: true,
    title: 'Free',
  },
  [enumSubscriptions.donorReport]: {
    btnText: 'ATTIVA',
    color: getSubscriptionColor(enumSubDonor.report),
    hasInfo: true,
    list: () => (
      <>
        {donorBasePlanList()}
        <CardList diamond svgColor="FEBA2C">
          Pacchetto <strong>Report</strong> contenente i dati relativi agli indici d’impatto e alle
          statistiche dettagliate
        </CardList>
      </>
    ),
    price: 159,
    title: 'Report',
  },
  [enumSubscriptions.donorAdvanced]: {
    btnText: 'ATTIVA',
    color: getSubscriptionColor(enumSubDonor.advanced),
    hasInfo: true,
    list: () => (
      <>
        {donorBasePlanList()}
        <CardList>
          Pacchetto <strong>Report</strong> contenente i dati relativi agli indici d’impatto e alle
          statistiche dettagliate
        </CardList>
        <CardList diamond svgColor="226FB6">
          <strong>Import massimo e API</strong> per la gestione delle offerte
        </CardList>
      </>
    ),
    price: 269,
    title: 'Advanced',
  },
  [enumSubscriptions.donorCustom]: {
    btnText: 'CONTATTACI',
    color: getSubscriptionColor(enumSubDonor.custom),
    hasInfo: true,
    list: () => (
      <>
        <CardList diamond svgColor="EA4867">
          Per iscrizioni di più punti vendita o esigenze specifiche
        </CardList>
      </>
    ),
    simpleTitle: true,
    title: 'Custom',
  },
};

const nProfitBasePlanList = () => {
  return (
    <>
      <CardList>
        Iscrizione <strong>gratuita</strong>
      </CardList>
    </>
  );
};

export const plansDataNoProfit: any = {
  [enumSubscriptions.noProfitFree]: {
    color: getSubscriptionColor(enumSubNoProfit.free),
    list: () => (
      <>
        {nProfitBasePlanList()}
        <CardList>
          Utilizzo <strong>illimitato</strong> della piattaforma fino a <strong>30 kg</strong> di
          prodotti al mese
        </CardList>
      </>
    ),
    simpleTitle: true,
    title: 'Free',
  },
  [enumSubscriptions.noProfitOpportunity]: {
    btnText: 'ATTIVA',
    color: getSubscriptionColor(enumSubNoProfit.opportunity),
    hasInfo: true,
    list: () => (
      <>
        {nProfitBasePlanList()}
        <CardList diamond svgColor="FEBA2C">
          Utilizzo <strong>illimitato</strong> della piattaforma fino a <strong>2.000 kg </strong>
          di prodotti al mese
        </CardList>
      </>
    ),
    price: 29,
    title: 'Opportunity',
  },
  [enumSubscriptions.noProfitAbsolute]: {
    btnText: 'ATTIVA',
    color: getSubscriptionColor(enumSubNoProfit.absolute),
    hasInfo: true,
    list: () => (
      <>
        {nProfitBasePlanList()}
        <CardList diamond svgColor="226FB6">
          Utilizzo <strong>illimitato</strong> della piattaforma fino a <strong>4.000 kg </strong>
          di prodotti al mese
        </CardList>
      </>
    ),
    price: 59,
    title: 'Absolute',
  },
  [enumSubscriptions.noProfitCustom]: {
    btnText: 'CONTATTACI',
    color: getSubscriptionColor(enumSubNoProfit.custom),
    hasInfo: true,
    list: () => (
      <>
        <CardList diamond svgColor="EA4867">
          Per volumi superiori o esigenze specifiche
        </CardList>
      </>
    ),
    simpleTitle: true,
    title: 'Custom',
  },
};

export const plansData: any = {
  ...plansDataDonor,
  ...plansDataNoProfit,
};
