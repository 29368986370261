import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Button, Row } from 'antd';
import { toast } from 'react-toastify';

import { Api } from '../../libs/callApi';
import Localize from '../../libs/localization';
import { userLoginAsyncAction } from '../../store/features/userSlice';

import Config from '../../config/Config';

const api = new Api(Config.apiBaseurl);

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const NewPasswordScreen: React.FC = () => {
  const userData = useMemo(() => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const token: string = url.searchParams.get('token') || '';
    const email: string = url.searchParams.get('email') || '';
    return { token, email };
  }, []);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [submitDisabled, submitDisabledSet] = useState<boolean>(true);

  async function onSend() {
    const { password } = form.getFieldsValue();
    const dataToSend = { token: userData.token, newPassword: password };

    try {
      const call = await api.makeCall({
        path: Config.apis.auth.newpassword,
        method: 'POST',
        body: dataToSend,
        dispatch,
      });
      if (call.payload?.ok) {
        toast.success('La password è stata impostata.');
        dispatch(userLoginAsyncAction({ email: userData.email, password, otp: '' }));
      }
    } catch (err) {
      toast.error(err.response.label ? err.response.label.it : 'Si è verificato un errore');
    }
  }

  function onValuesChange(values: any) {
    const { password, confirm } = form.getFieldsValue();
    const isDisabled = Boolean(password && confirm);
    submitDisabledSet(!isDisabled);
  }

  return (
    <>
      <h1 className="mb-30">{Localize('PAGES.AUTH.NEWPASSWORD.TITLE')}</h1>
      <Form
        {...layout}
        form={form}
        layout="vertical"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onSend}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: Localize('FORMS.MANDATORY'),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value) {
                  const regex = Config.regex.password;
                  if (regex.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(Localize('PAGES.AUTH.NEWPASSWORD.ERROR_SECURITY')),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password placeholder="Inserisci la nuova password" />
        </Form.Item>

        <Form.Item
          name="confirm"
          label={Localize('PAGES.AUTH.NEWPASSWORD.PASSWORD_CONFIRM')}
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: Localize('FORMS.MANDATORY'),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(Localize('PAGES.AUTH.NEWPASSWORD.ERROR_CONFIRM')));
              },
            }),
          ]}
        >
          <Input.Password placeholder="Conferma la tua password" />
        </Form.Item>

        <Row justify="end">
          <Button htmlType="submit" disabled={submitDisabled}>
            {Localize('COMMON.CONFIRM')}
          </Button>
        </Row>
      </Form>
    </>
  );
};

export default NewPasswordScreen;
