/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { getProfileByIdApi, igetProfileReturn } from '../../../api/profile.api';
import { mapProfileData } from '../../../libs/helpers/profile.helpers';
import { iProfileData } from '../../../libs/interfaces';
import ProfileDetail from '../../profile/profile.detail';

interface iProps {
  organization: iProfileData;
}

const OrganizationNoProfitProfile: React.FC<iProps> = ({ organization }: iProps) => {
  const [org, setOrg] = useState<igetProfileReturn | undefined>(undefined);
  const reloadOrg = async () => {
    getProfileByIdApi(organization.id!)
      .then(setOrg)
      .catch((ex) => {
        console.error(ex);
      });
  };
  useEffect(() => {
    reloadOrg();
  }, []);

  if (!organization || !org) {
    return <div />;
  }

  const data = mapProfileData(org.data);

  return <ProfileDetail data={data} canEdit />;
};

export default OrganizationNoProfitProfile;
