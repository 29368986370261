/**
 * Transform file to Base64 string
 *
 * @author davide.mantovani
 *
 * @param {File} file Source file
 *
 * @returns {Promise} This method returns a promise, if resolved
 */
export const fileToBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

/**
 * Transform file to Base64 string
 *
 * @author davide.mantovani
 *
 * @param {String} base64 Source file
 *
 * @returns {Promise} This method returns a promise, if resolved
 */
export const base64ToFile = async (base64) => {
  return fetch(base64)
    .then((res) => res.blob())
    .then((blob) => {
      const file = new File([blob], 'File name');
      return file;
    });
};
