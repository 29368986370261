/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
import Config from '../config/Config';
import { Api } from '../libs/callApi';
import { iAgreement, iSpecification, iVisibilityNoProfits } from '../libs/interfaces';
import { manageErr, manageNoErr } from './util.api';

const api = new Api(Config.apiBaseurl);


export const getAgreementsForDonorApi = async (id?: string): Promise<iAgreement[]> => {
    try {
        const call = await api.makeCall({
            path: `${Config.apis.agreements.donor}${id ? `/${id}` : ''}`,
            method: 'GET',
            autorized: true,
        });
        return call.body;
    } catch (err) {
        manageNoErr(err);
        return [];
    }
};
export const getAgreementsForDonorWithDetails = async (id?: string): Promise<iVisibilityNoProfits[]> => {
    try {
        const call = await api.makeCall({
            path: `${Config.apis.agreements.details}${id ? `/${id}` : ''}`,
            method: 'GET',
            autorized: true,
        });
        return call.body;
    } catch (err) {
        manageNoErr(err);
        return [];
    }
};
export const getAgreementsForNoProfitApi = async (id?: string): Promise<iAgreement[]> => {
    try {
        const call = await api.makeCall({
            path: `${Config.apis.agreements.noprofit}${id ? `/${id}` : ''}`,
            method: 'GET',
            autorized: true,
        });
        return call.body;
    } catch (err) {
        manageNoErr(err);
        return [];
    }
};
export const createAgreementApi = async (donId: string, benId: string): Promise<void> => {
    try {
        const call = await api.makeCall({
            path: `${Config.apis.agreements.crud}/${donId}/${benId}`,
            method: 'POST',
            autorized: true,
        });
        return call;
    } catch (err) {
        return manageErr(err);
    }
};

export const deleteAgreementApi = async (id: string): Promise<iSpecification> => {
    try {
        const call = await api.makeCall({
            path: `${Config.apis.agreements.crud}/${id}`,
            method: 'DELETE',
            autorized: true,
        });
        return call;
    } catch (err) {
        return manageErr(err);
    }
};
