/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button } from 'antd';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { objFromUrlParams } from '../../libs/helpers/commons';

import './style.sass';

const MessageFromServer: React.FC = () => {
  const objUrl = objFromUrlParams();
  let StyleStatus = '';
  if (objUrl.status) {
    StyleStatus = objUrl.status === 'ok' ? 'has-status-ok' : 'has-status-ko';
  }

  return (
    <div className={`view-messages text-center ${StyleStatus}`}>
      {objUrl.message && <p>{objUrl.message}</p>}
    </div>
  );
};

export default MessageFromServer;
