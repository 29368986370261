/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React from 'react';
import _ from 'lodash';
import format from 'string-template';
import moment from 'moment';
import { getUserLocale } from 'get-user-locale';
import ReactMarkdown from 'react-markdown';
// import LocalizedString from 'react-localization';

import * as locales from '../config/locales';
import log from './helpers/logger';

import Config from '../config/Config';
// import logger from "./helpers/logger";
import { getFromLocalStorage } from './helpers/localstorage';

// Old code using react-localization
/* export default new LocalizedString(
  {
    ...locales.default,
  },
  {
    logsEnabled: Config.logger,
    pseudo: Config.logger,
  }
  ); */

// Loads locale on refresh
// If forceLocale is defined in config it is used and overwrites user's selection

export const getCurrentLocale = () => {
  const localStorageLocale: any = getFromLocalStorage('lang');

  let locale =
    (localStorageLocale && localStorageLocale.data) ||
    Config.forceLocale ||
    (Array.isArray(getUserLocale()) ? getUserLocale()[0] : getUserLocale()) ||
    'en';

  // Reloading changes to user locale
  if (new RegExp('^[a-z]{2}(-|_)[A-Z]{2}$').test(locale)) {
    locale = locale.substring(0, 2);
  } else if (!new RegExp('^[a-z]{2}$').test(locale)) {
    // Fallback
    locale = 'en';
  }
  return locale;
};

export const getCurrentLocaleExtended = () => {
  const localStorageLocale: any = getFromLocalStorage('lang');
  const browserLocale: any = Array.isArray(getUserLocale())
    ? getUserLocale()[0]
    : getUserLocale() || 'en_EN';

  return localStorageLocale && typeof localStorageLocale.data === 'string'
    ? `${localStorageLocale.data}-${browserLocale.substring(3)}`
    : browserLocale.replace('_', '-');
};

/**
 * Custom localization library
 *
 * @author davide.mantovani
 * @author michele.forchione
 *
 * @param {String|Date} search Can be the translation key or a Date
 * @param {Array|Object} params If the Localized string is a string template, pass an array or an object with keys (default: null)
 * @param {Boolean} markdown If it is needed to use Markdown interpreter - generated HTML code (default: false)
 */
const Localize = (
  search: any,
  params: any = null,
  markdown = false,
  array = false,
  returnObject = false,
  locale: any = getCurrentLocale(),
): any => {
  try {
    if (locale === 'en') {
      require(`moment/locale/${locale}-gb`);
    } else {
      require(`moment/locale/${locale}`);
    }
  } catch (e) {
    log.error(`MOMENT.JS: Locale ${locale} not found`);
  }

  // If Localize(Date, 'format')
  // Lib used https://www.npmjs.com/package/moment
  if (search instanceof Date && typeof params === 'string') {
    moment.locale(locale);
    // Return the formatted date
    return moment(search.getTime()).format(params);
  }

  if (typeof search === 'object') {
    return search[locale] || search.en || `No locale found for ${search}.${locale}`;
  }

  // If default text
  let str: any = _.get(locales.default, `${locale}.${search}`);

  if (returnObject) {
    return str;
  }

  if (array && Array.isArray(str)) {
    str = str.join('\n');
  }

  if ((Config.logger === false || Config.logger === 'false') && (!str || str === '')) {
    str = _.get(locales.default, `en.${search}`);
  }
  if (
    (Config.logger === true || Config.logger === 'true') &&
    (!str || str === '' || typeof str !== 'string')
  ) {
    // If the logging is enabled in config, it enables the random generation of strings to highlight missing values
    // TODO add MAP as a string cache
    str = _.get(locales.default, `en.${search}`);
    str =
      typeof str === 'string'
        ? str
            .split('')
            .sort(() => (Math.random() > 0.5 ? -1 : 1))
            .join('')
        : null;
    str = <span style={{ color: 'red' }}>{str || search}</span>;
  } else {
    // Check if it is needed to complete the {0} with {1}, ['sentence', 'params']
    // Support both array and object
    // Lib used https://www.npmjs.com/package/string-template
    if (Array.isArray(params) || params instanceof Object) {
      str = format(str, params);
    }

    // Check if it is _needed_ to interpret `markdown`
    // Supports all commands (even links!!1!!111!!) (see eg. https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet)
    // Lib used https://www.npmjs.com/package/react-markdown
    if (markdown) {
      str = <ReactMarkdown source={str} />;
    }
  }

  //  Return the resulted string
  return str || null;
};

interface LocalizedProps {
  search: any;
  params: any;
  markdown: boolean;
  array: boolean;
  returnObject: boolean;
  locale: any;
}

const Localized: React.FC<LocalizedProps> = ({
  search,
  params = null,
  markdown = false,
  array = false,
  returnObject = false,
  locale = getCurrentLocale(),
}) => Localize(search, params, markdown, array, returnObject, locale);

// This library supports also recoursive use, eg.:
//
// DATA
/// / {
/// /   "TEST": {
/// /     "LABEL": "# Label {0} _tested_ on {1}"
/// /   }
/// / }
//
// CODE
/// / Localize(
/// /   'TEST.LABEL',
/// /   ['test', Localize(new Date(), 'DD MMM YYYY hh:mm')],
/// /   true
/// / );

export default Localize;
export { Localized };
