import React from 'react';
import { Link } from 'react-router-dom';
import Localize from '../../libs/localization';

import './style.sass';

const ThankyouScreen: React.FC = () => {
  return (
    <div className="registration-thank">
      <h1 className="mb-30">{Localize('PAGES.AUTH.THANK.TITLE')}</h1>
      <p>{Localize('PAGES.AUTH.THANK.TXT1')}</p>
      <p>{Localize('PAGES.AUTH.THANK.TXT2')}</p>
    </div>
  );
};

export default ThankyouScreen;
