/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import moment from 'moment';

import { RootState } from '../../store';
import { initStatisticsFilters } from '../../libs/helpers/statistics.helpers';
import { isDonor, isAdmin, isNoProfit } from '../../libs/helpers/privileges.helpers';
import { iStatisticsFilters, iTransaction } from '../../libs/interfaces';
import {
  statisticsAsynGetTransactionsAction,
  resetTransactionsAction,
} from '../../store/features/statisticsSlice';
import StatisticsFilter from '../../components/statisticsFilters/statisticsFilters';

import './style.sass';

const baseCol = { sortable: true, filter: true, resizable: true };
const convDateToSort = (dateStr: string): string => {
  if (!dateStr) return dateStr;
  const arr = dateStr.split('-');
  if (arr.length !== 3) return dateStr;
  return `${arr[2]}-${arr[1]}-${arr[0]}`;
};
const comparatorDate = (valueA: string, valueB: string): number => {
  const va = convDateToSort(valueA);
  const vb = convDateToSort(valueB);
  if (va === vb) return 0;
  return va > vb ? 1 : -1;
};

const formatNumber = (num: any): string => {
  if (!num || num.value === undefined) return '';
  return num.value.toString().replace('.', ',');
};
function mapCols() {
  const cols = [
    { headerName: 'ID transazione', field: 'transaction_id', width: 350 },
    { headerName: 'Data prenotazione', field: 'reservation_date', comparator: comparatorDate },
    { headerName: 'Data accettazione', field: 'accepted_date', comparator: comparatorDate },
    { headerName: 'Tipologia', field: 'cat_type' },
    { headerName: 'Categoria', field: 'category' },
    { headerName: 'Sottocategoria', field: 'sub_category' },
    { headerName: 'Peso unità', field: 'format', valueFormatter: formatNumber },
    { headerName: 'Udm', field: 'udm', width: 100 },
    { headerName: 'Tipologia offerta', field: 'offer_type' },
    { headerName: 'Tipologia donazione', field: 'type_donation' },
    { headerName: 'Crediti per unità', field: 'credit', width: 160, valueFormatter: formatNumber },
    { headerName: 'Quantità totale', field: 'quantity', width: 150, valueFormatter: formatNumber },
    { headerName: 'Crediti totali', field: 'credit_tot', width: 140, valueFormatter: formatNumber },
    { headerName: 'Modalità di consegna', field: 'delivery' },
    { headerName: 'Crediti spedizione', field: 'shipment_cost', valueFormatter: formatNumber },
    {
      headerName: 'Peso totale (kg)',
      field: 'weight_tot',
      width: 160,
      valueFormatter: formatNumber,
    },
    { headerName: 'Kg CO2 risparmiata', field: 'kg_co2', valueFormatter: formatNumber },
    // { headerName: 'Crediti guadagnati', field: 'credit_earn' },
    { headerName: 'Comune donatore', field: 'don_city' },
    { headerName: 'Comune beneficiario', field: 'ben_city' },
    { headerName: 'Note', field: 'notes' },
    { headerName: 'Token blockchain', field: 'token_blockchain' },
  ];

  return cols.map((c: { headerName: string; field: string }) => {
    return { ...c, ...baseCol };
  });
}

const columns = mapCols();
const today = moment();
const start = moment().subtract(3, 'months');

const initFiltersValues = {
  ...initStatisticsFilters,
  from: start,
  to: today,
};

const Transactions: React.FC = () => {
  const dispatch = useDispatch();
  // const gridApi = useRef();
  const { user, statistics } = useSelector((state: RootState) => state);
  const { transactions } = statistics;

  const [data, dataSet] = useState<iTransaction[]>([]);
  const [tableColumns, tableColumnsSet] = useState<any>([]);
  const [gridApi, gridApiSet] = useState<any>(null);

  useEffect(() => {
    dataSet(transactions);
  }, [transactions]);

  function onGridReady(params: any) {
    // gridApi.current = params.api;
    gridApiSet(params.api);
  }

  function addUserColumns() {
    const current = [...columns];
    const { role } = user;
    const newItemDonor = {
      ...baseCol,
      headerName: 'Donatore',
      field: 'don_name',
    };
    const newItemNoProfit = {
      ...baseCol,
      headerName: 'Beneficiario',
      field: 'ben_name',
    };
    if (isDonor(role)) {
      current.splice(1, 0, newItemNoProfit);
    }
    if (isNoProfit(role)) {
      current.splice(1, 0, newItemDonor);
    }
    if (isAdmin(role)) {
      current.splice(1, 0, newItemDonor);
      current.splice(1, 0, newItemNoProfit);
    }
    tableColumnsSet(current);
  }

  useEffect(() => {
    addUserColumns();
    return () => {
      dispatch(resetTransactionsAction());
    };
  }, []);

  function callData(filters: iStatisticsFilters) {
    dispatch(statisticsAsynGetTransactionsAction(filters));
  }

  function onCsvClicked() {
    if (gridApi) {
      gridApi.exportDataAsCsv({
        processCellCallback: (cell: any) => {
          // Manipulate the value however you need.
          if (typeof cell.value === 'number' || typeof cell.value === 'bigint') {
            return formatNumber(cell);
          }
          return cell.value;
        },
      });
    }
  }

  return (
    <>
      <StatisticsFilter
        initValues={initFiltersValues}
        showCityState={false}
        role={user.role}
        onApplyFilters={(v: iStatisticsFilters) => callData(v)}
      />
      <div className="my-30">
        <Button onClick={onCsvClicked}>Download CSV</Button>
      </div>
      <div className="transactions ag-theme-alpine">
        {tableColumns.length > 0 && (
          <AgGridReact onGridReady={onGridReady} columnDefs={tableColumns} rowData={data} />
        )}
      </div>
    </>
  );
};

export default Transactions;
