import React, { useMemo } from 'react';
import { Row } from 'antd';
import moment from 'moment';

import { renderAvatar } from '../../libs/helpers/profile.helpers';
import './style.sass';
import { iProfileData } from '../../libs/interfaces';

interface iProps {
  profile: iProfileData;
}

const ProfileCardCredits: React.FC<iProps> = ({ profile }: iProps) => {
  const avatar = useMemo(() => {
    return renderAvatar(profile);
  }, [profile]);

  return (
    <Row className="profileCard">
      <div className="profileCard__avatar">{avatar}</div>
      <div className="c-light ml-20">
        <h1>{profile.name}</h1>
        <div className="m-0">Data creazione: {moment(profile.created_at).format('DD/MM/YYYY')}</div>
        <div className="m-0">ID: {profile.id}</div>
        <div className="m-0">Email: {profile.account?.email}</div>
        <div className="m-0">Crediti: {(profile.credits || 0) / 100}</div>
      </div>
    </Row>
  );
};

export default ProfileCardCredits;
