import { Moment } from 'moment';
import { enumSubNoProfit, enumSubDonor, enumSubCurrentStatus } from '../enums';

export interface iSubPlan {
  id: string;
  name: string;
  role: string;
  level: number;
  kg: number;
  price_month: number;
  price_year: number;
  custom: number;
}

export interface iSubState {
  modalOk: boolean;
  listAvailable: iSubPlan[];
  userHistoryPlans: iSubRequestResponse[];
}

export interface iSubRequestPayload {
  subscription_req: enumSubNoProfit | enumSubDonor;
  payment: 'year' | 'month';
}

export interface iSubSetData {
  account_id: string;
  date_from: string | Moment | null;
  payment: string;
  subscription: string;
  id?: string;
  subscription_req?: string;
}

export interface iSubRequestResponse {
  id: string;
  account_id: string;
  date_from: string | null;
  date_orig: string | null;
  subscription: string;
  subscription_req: string;
  status: string;
  payment: string;
  kg: number;
  created_at: string;
  updated_at: string;
  current_status: enumSubCurrentStatus | '';
  date_end: string | null;
}

export const retrieveCurrentPlan = (
  userHistoryPlans: iSubRequestResponse[],
): iSubRequestResponse | undefined => {
  const listPlan = userHistoryPlans.filter((i: iSubRequestResponse) => i.status === 'active');
  return listPlan.find((i: iSubRequestResponse) => i.current_status === enumSubCurrentStatus.now);
};
