import React from 'react';
import { Modal, Button, Row } from 'antd';
import './style.sass';

interface iProps {
  onDelete: () => void;
  onCancel: () => void;
  visible: boolean;
}

const ModalDeleteOffer: React.FC<iProps> = ({ onDelete, onCancel, visible }: iProps) => {
  return (
    <Modal
      onCancel={onCancel}
      visible={visible}
      modalRender={() => (
        <div className="modalDelete ant-modal-content">
          <p>Sei sicuro di voler eliminare questa offerta?</p>
          <Row justify="center">
            <Button className="btn--grey-xl" onClick={onCancel}>
              ANNULLA
            </Button>
            <Button onClick={onDelete}>ELIMINA</Button>
          </Row>
        </div>
      )}
    >
      <p>Sei sicuro di voler eliminare questa offerta?</p>
    </Modal>
  );
};

export default ModalDeleteOffer;
