/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';

interface iError {
  name: string;
  status: number;
  message?: string;
  id: string | number;
  date: Date | string;
  code?: string | number;
  stack?: any;
}

interface iAppState {
  loading: boolean;
  error: iError | null;
}

const initialState: iAppState = {
  loading: false,
  error: null,
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<iError | null>) => {
      state.error = action.payload;
    },
    appResetError: (state) => {
      state.error = null;
    },
  },
});

export const { setLoading, setError, appResetError } = slice.actions;

export const appSetLoadingAction = (value: boolean) => (dispatch: any) => {
  return dispatch(setLoading(value));
};

export default slice.reducer;
