import moment from 'moment';
import { upperFirst, toUpper } from 'lodash';
import { toast } from 'react-toastify';

export const capitalizeFirstChar = (str: string): string =>
  str.charAt(0).toUpperCase() + str.substring(1);

export const getAddress = () => `${global.location.protocol}//${global.location.host}/`;

export const wait = (durationMs: any) => new Promise((fulfill) => setTimeout(fulfill, durationMs));

export const refreshPage = () => {
  window.location.reload();
};

export const currencyDecimal = (value: any) => {
  return Number.parseFloat(value).toFixed(2).toString().concat(' €');
};

export const currencyNoDecimal = (value: any) => {
  return Number.parseFloat(value).toFixed(0).toString().concat('€');
};

export const isMobileDevice = () => window.screen.width < 767;

export const isTouchScreen = () => !!('ontouchstart' in window || navigator.maxTouchPoints);

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const scrollToId = (id: any) => {
  const element = document.getElementById(id);
  // if (element) element.scrollIntoView();
  if (element) {
    const top = element.offsetTop;
    window.scrollTo({ top });
  }
};

export const getAge = (birthdate: any) => moment().diff(moment(birthdate), 'years');

export const turnKeyIntoLabel = (key: any) =>
  // eslint-disable-next-line no-useless-escape
  key && upperFirst(key.replace(/(\_)/g, ' '));

export const turnIntoErrorCode = (code: string) =>
  code && toUpper(code.replace(/[A-Za-z0-9]+/g, ' '));

export const isEmptyOrSpaces = (str: string) => {
  return str === null || str.match(/^ *$/) !== null || str.length === 0;
};

export function debounce(func: any, timeout: number): any {
  let timer: any;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
}

export function asyncThunkErrorHelper(rejectWithValue: any, err: any): any {
  const errorMessage = err.response.label ? err.response.label.it : 'Si è verificato un errore';
  toast.error(errorMessage);
  return rejectWithValue(err);
}

export function dynamicSort(property: any): any {
  let sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    // eslint-disable-next-line no-param-reassign
    property = property.substr(1);
  }
  // eslint-disable-next-line func-names
  return function (a: any, b: any) {
    /* 
      next line works with strings and numbers,
      * and you may want to customize it to your needs
    */
    // eslint-disable-next-line no-nested-ternary
    const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

// export function numberWithCommas(x: string): string {
//   let toReturn = x.toString();
//   const pattern = /(-?\d+)(\d{3})/;
//   while (pattern.test(x)) toReturn = x.replace(pattern, '$1.$2');
//   return toReturn;
// }

export function numberWithDot(x: number): string {
  if (!x) {
    return '0';
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function objFromUrlParams(): any {
  const urlSearch = window.location.search;
  let obj: any = {};
  const locationSearch = urlSearch.substring(1);
  if (locationSearch) {
    obj = JSON.parse(
      `{"${decodeURI(locationSearch)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`,
    );
  }
  return obj;
}
