import React from 'react';
import { Form, Input } from 'antd';
import Config from '../../config/Config';

interface iProps {
  label?: string;
  placeholder?: string;
  name?: any;
  initialValue?: string;
  required?: boolean;
}

const FormCity: React.FC<iProps> = ({
  label,
  placeholder,
  name,
  initialValue,
  required,
}: iProps) => {
  return (
    <Form.Item
      label={label}
      name={name}
      initialValue={initialValue}
      rules={[
        { required },
        ({ getFieldValue }: any) => ({
          validator(rule, value) {
            if (value) {
              const regex = Config.regex.city;
              if (regex.test(value)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Può contenere solo lettere o spazi'));
            }
            return Promise.resolve();
          },
        }),
      ]}
    >
      <Input placeholder={placeholder} />
    </Form.Item>
  );
};

FormCity.defaultProps = {
  label: 'Città',
  placeholder: 'Inserisci la città',
  name: 'city',
  initialValue: '',
  required: true,
};

export default FormCity;
