import Rollbar from 'rollbar';
import Config from '../../config/Config';

const toEnabled = Config.environment.label === 'Test' || Config.environment.label === 'Production';
const rollbarConfig = {
  enabled: toEnabled,
  accessToken: 'f1b35a7de187427ab6c038ee46d9938c',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: Config.environment.label,
    client: {
      javascript: {
        source_map_enabled: true,
      },
    },
  },
};

const rollbar = new Rollbar(rollbarConfig);

export default rollbar;
