import Config from '../config/Config';
import { Api } from '../libs/callApi';
import {
  iStatisticsFilters,
  iWidgetFilters,
  iWidgetCards,
  iStatisticsCards,
  iStatisticsColumns,
  iStatisticsReportItem,
} from '../libs/interfaces';

const api = new Api(Config.apiBaseurl);

export const getStatisticsWidgetApi = async (
  filters: iWidgetFilters | null,
): Promise<iWidgetCards> => {
  const call = await api.makeCall({
    path: Config.apis.statistics.widget,
    method: 'GET',
    autorized: false,
    uriParams: filters,
  });
  return call.body;
};

export const getStatisticsCardsApi = async (
  filters: iStatisticsFilters | null,
): Promise<iStatisticsCards> => {
  const call = await api.makeCall({
    path: Config.apis.statistics.cards,
    method: 'GET',
    autorized: true,
    uriParams: filters,
  });
  return call.body;
};

export const getStatisticsColumnsApi = async (
  filters: iStatisticsFilters | null,
): Promise<iStatisticsColumns> => {
  const call = await api.makeCall({
    path: Config.apis.statistics.columns,
    method: 'GET',
    autorized: true,
    uriParams: filters,
  });
  return call.body;
};

export const getStatisticsPieCategoriesApi = async (
  filters: iStatisticsFilters | null,
): Promise<iStatisticsReportItem[]> => {
  const call = await api.makeCall({
    path: Config.apis.statistics.pieCategory,
    method: 'GET',
    autorized: true,
    uriParams: filters,
  });

  return call.body;
};

export const getStatisticsPieDonorsApi = async (
  filters: iStatisticsFilters | null,
): Promise<iStatisticsReportItem[]> => {
  const call = await api.makeCall({
    path: Config.apis.statistics.pieDonor,
    method: 'GET',
    autorized: true,
    uriParams: filters,
  });

  return call.body;
};

export const getStatisticsMapApi = async (
  filters: iStatisticsFilters | null,
): Promise<iStatisticsReportItem[]> => {
  const call = await api.makeCall({
    path: Config.apis.statistics.map,
    method: 'GET',
    autorized: true,
    uriParams: filters,
  });

  return call.body;
};

export const getStatisticsTransactionsApi = async (
  filters: iStatisticsFilters | null,
): Promise<any[]> => {
  const call = await api.makeCall({
    path: Config.apis.statistics.transactions,
    method: 'GET',
    autorized: true,
    uriParams: filters,
  });

  return call.body;
};
