import React from 'react';
import { Form, Input, Row, Col, InputNumber } from 'antd';

import Config from '../../config/Config';
import { FormItemWithLetter, FormLabelWithTooltip } from '../../components';
import { enumOffersDonationType } from '../../libs/enums';

interface iProps {
  formValues: any;
  isEditMode: boolean;
}

const OfferNewQuantity: React.FC<iProps> = ({ isEditMode, formValues }: iProps) => {
  return (
    <>
      <Row gutter={16}>
        <Col sm={12}>
          <Form.Item label="Quantità totale" name="totalUnits" rules={[{ required: true }]}>
            <InputNumber placeholder="Inserisci la quantità totale disponibile" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col sm={12}>
          <Form.Item
            name="minUnits"
            rules={[
              { required: true },
              () => ({
                validator(rule, value) {
                  const total = formValues.totalUnits;
                  if (value) {
                    if (total % value === 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('Il campo deve essere un sottomultiplo della Quantità totale'),
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            label={
              <FormLabelWithTooltip
                label="Quantità lotto"
                tooltip="Indica la quantità minima ordinabile, questo numero deve essere obbligatoriamente un sottomultiplo della quantità totale disponibile"
              />
            }
          >
            <InputNumber placeholder="Inserisci la minima quantità ordinabile" />
          </Form.Item>
        </Col>
        <Col sm={12}>
          <Row gutter={16}>
            <Col sm={18}>
              <FormItemWithLetter letter="cm">
                <Form.Item
                  label="Ingombro lotto"
                  name="unitSize"
                  rules={[
                    () => ({
                      validator(rule, value) {
                        if (value) {
                          const regex = Config.regex.size;
                          if (regex.test(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              'Deve contenere larghezza - altezza - profondità senza spazi tra numeri e -',
                            ),
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input placeholder="larghezza - altezza - profondità" />
                </Form.Item>
              </FormItemWithLetter>
            </Col>
            <Col sm={6}>
              <FormItemWithLetter letter="Kg">
                <Form.Item
                  name="unitWeight"
                  rules={[
                    {
                      required:
                        formValues.typeDonation !== enumOffersDonationType.voluntaryDonation,
                    },
                  ]}
                  label="Peso lotto"
                >
                  <InputNumber placeholder="-" />
                </Form.Item>
              </FormItemWithLetter>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default OfferNewQuantity;
