/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { profileAsynGetAction } from './profileSlice';
import { asyncThunkErrorHelper } from '../../libs/helpers/commons';

import {
  requestScriptionsApi,
  getUserListAvailableApi,
  getUserHistoryPlansApi,
  setPlanApi,
  deletePlanApi,
} from '../../api/subscriptions';
import {
  iSubState,
  iSubRequestPayload,
  iSubRequestResponse,
  iSubPlan,
  iSubSetData,
} from '../../libs/interfaces';

const initialState: iSubState = {
  modalOk: false,
  listAvailable: [],
  userHistoryPlans: [],
};

const slice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setShowModalAction: (state, action: PayloadAction<boolean>) => {
      state.modalOk = action.payload;
    },
    setListAvailableAction: (state, action: PayloadAction<iSubPlan[]>) => {
      state.listAvailable = action.payload;
    },
    setUserHistoryPlansAction: (state, action: PayloadAction<iSubRequestResponse[]>) => {
      state.userHistoryPlans = action.payload;
    },
    resetUserAction: (state) => {
      state.userHistoryPlans = [];
      state.listAvailable = [];
    },
  },
});

export const {
  setShowModalAction,
  setListAvailableAction,
  setUserHistoryPlansAction,
  resetUserAction,
} = slice.actions;
export default slice.reducer;

export const subscriptionsAsynRequestNewPlanAction = createAsyncThunk(
  'subscriptions/subscriptionsAsynRequestNewPlanAction',
  async (payload: iSubRequestPayload, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setShowModalAction(false));
      const response: {
        payload: any;
        body: iSubRequestResponse;
      } = await requestScriptionsApi(payload);

      if (response.payload?.ok) {
        dispatch(setShowModalAction(true));
        await dispatch(profileAsynGetAction(null));
      }
      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const subscriptionsAsynGetListAvailableAction = createAsyncThunk(
  'subscriptions/subscriptionsAsynGetListAvailableAction',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response: {
        payload: any;
        body: iSubPlan[];
      } = await getUserListAvailableApi(id);

      if (response.payload?.ok) {
        dispatch(setListAvailableAction(response.body));
      }
      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const subscriptionsAsynGetUserHistoryPlansAction = createAsyncThunk(
  'subscriptions/subscriptionsAsynGetUserHistoryPlansAction',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response: {
        payload: any;
        body: iSubRequestResponse[];
      } = await getUserHistoryPlansApi(id);

      if (response.payload?.ok) {
        dispatch(setUserHistoryPlansAction(response.body));
      }
      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const subscriptionsAsynSetPlanAction = createAsyncThunk(
  'subscriptions/subscriptionsAsynSetPlanAction',
  async (body: iSubSetData, { rejectWithValue, dispatch }) => {
    try {
      const response: {
        payload: any;
        body: iSubRequestResponse;
      } = await setPlanApi(body);

      if (response.payload?.ok) {
        const id = response.body.account_id;
        dispatch(subscriptionsAsynGetUserHistoryPlansAction(id));
      }
      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);

export const subscriptionsAsynDeletePlanAction = createAsyncThunk(
  'subscriptions/subscriptionsAsynDeletePlanAction',
  async (payload: { accountId: string; planId: string }, { rejectWithValue, dispatch }) => {
    try {
      const { accountId, planId } = payload;
      const response: any = await deletePlanApi(accountId, planId);

      if (response.payload?.ok) {
        dispatch(subscriptionsAsynGetUserHistoryPlansAction(accountId));
      }
      return response;
    } catch (err) {
      return asyncThunkErrorHelper(rejectWithValue, err);
    }
  },
);
