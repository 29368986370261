/* eslint-disable no-template-curly-in-string */

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Form, Input, Button, Row, Checkbox, Col } from 'antd';
import { toast } from 'react-toastify';
import PasswordStrengthBar from 'react-password-strength-bar';

import Localize from '../../libs/localization';
import Config from '../../config/Config';
import { Api } from '../../libs/callApi';
import { iRegistration } from '../../libs/interfaces';
import { PrivacyModal, TermsModal } from '../../components';
import { enumRoutes } from '../../libs/enums';

const api = new Api(Config.apiBaseurl);

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const initData = {
  name: '',
  email: '',
  phone: '',
  password: '',
  terms: false,
  privacy: false,
};

const validateMessages: any = {
  required: '${label} è obbligatorio!',
  types: {
    email: '${label} non è un email valida!',
  },
};

interface iType {
  label: string;
  data: string;
  [key: string]: string;
}

const types: any = {
  donor: {
    label: 'Azienda',
    data: 'donor',
  },
  noprofit: {
    label: 'Ente Non profit',
    data: 'noprofit',
  },
};

interface iProps {
  match: any;
}

const RegistrationScreen: React.FC<iProps> = ({ match }: iProps) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState<iRegistration>(initData);
  const [submitDisabled, submitDisabledSet] = useState<boolean>(true);
  const [type, typeSet] = useState<iType | null>();
  const [modalTerms, modalTermsSet] = useState<boolean>(false);
  const [modalPrivacy, modalPrivacySet] = useState<boolean>(false);

  useEffect(() => {
    const { params } = match;
    if (params.organizationsType as string) {
      typeSet(types[params.organizationsType]);
    }
  }, [match]);

  async function onSend() {
    const dataToSend = {
      name: data.name,
      email: data.email,
      role: type?.data,
      phone: data.phone || '',
      password: data.password || '',
    };

    try {
      const call = await api.makeCall({
        path: Config.apis.auth.registration,
        method: 'POST',
        body: dataToSend,
        dispatch,
      });
      if (call.payload?.ok) {
        history.push(enumRoutes.loginConfirm);
      }
    } catch (err) {
      toast.error(err.response.label ? err.response.label.it : 'Si è verificato un errore');
    }
  }

  function checkButton() {
    const disabled = !data.name || !data.email || !data.privacy || !data.terms;
    submitDisabledSet(disabled);
  }

  useEffect(() => {
    checkButton();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  function onValuesChange(values: any) {
    setData({ ...data, ...values });
  }

  return (
    <>
      <h1 className="mb-30">
        {Localize('PAGES.AUTH.REGISTRATION.TITLE')} {type?.label}
      </h1>
      <Form
        {...layout}
        form={form}
        layout="vertical"
        name="registration"
        initialValues={data}
        onFinish={onSend}
        onValuesChange={onValuesChange}
        validateMessages={validateMessages}
      >
        <Form.Item label={`Nome ${type?.label || ''}`} name="name" rules={[{ required: true }]}>
          <Input
            placeholder={`${Localize('PAGES.AUTH.REGISTRATION.NAME_PLACEHOLDER')}${type?.label}`}
          />
        </Form.Item>

        <Form.Item label="E-mail" name="email" rules={[{ type: 'email', required: true }]}>
          <Input placeholder={Localize('PAGES.AUTH.REGISTRATION.EMAIL_PLACEHOLDER')} />
        </Form.Item>

        <Form.Item
          label={Localize('PAGES.AUTH.REGISTRATION.PHONE')}
          name="phone"
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value) {
                  const regex = Config.regex.phone.it;
                  if (regex.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Il campo deve iniziare con +39 seguito da soli numeri'),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input type="text" placeholder={Localize('PAGES.AUTH.REGISTRATION.PHONE_PLACEHOLDER')} />
        </Form.Item>

        <Form.Item
          label={Localize('PAGES.AUTH.REGISTRATION.PASSWORD')}
          name="password"
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (value) {
                  const regex = Config.regex.password;
                  if (regex.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'La password deve avere almeno 8 caratteri, un numero, una lettera minuscola, una lettera maiuscola e un carattere speciale tra i seguenti: !@#$%^&*.:',
                    ),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password placeholder={Localize('PAGES.AUTH.REGISTRATION.PASSWORD_PLACEHOLDER')} />
        </Form.Item>

        <PasswordStrengthBar
          password={form.getFieldValue('password')}
          minLength={8}
          scoreWords={['debole', 'debole', 'insicura', 'buona', 'sicura']}
          shortScoreWord="troppo corta"
        />

        <Form.Item
          name="terms"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) => {
                return value
                  ? Promise.resolve()
                  : Promise.reject(new Error('Il campo è obbligatorio'));
              },
            },
          ]}
        >
          <Checkbox>
            Accetto i
            <button type="button" onClick={() => modalTermsSet(true)} className="text-underline">
              Termini e le Condizioni
            </button>{' '}
            <span className="required-sign">*</span>
          </Checkbox>
        </Form.Item>

        <Form.Item
          name="privacy"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) => {
                return value
                  ? Promise.resolve()
                  : Promise.reject(new Error('Il campo è obbligatorio'));
              },
            },
          ]}
        >
          <Checkbox>
            Accetto la
            <button type="button" onClick={() => modalPrivacySet(true)} className="text-underline">
              Privacy Policy
            </button>
            <span className="required-sign">*</span>
          </Checkbox>
        </Form.Item>

        <Row justify="space-between" align="middle">
          <Col sm={12}>
            <p>
              Hai gia un account? <Link to={enumRoutes.login}>Accedi</Link>
            </p>
          </Col>
          <Col sm={12} className="text-right">
            <Button htmlType="submit" disabled={submitDisabled}>
              Registrati
            </Button>
          </Col>
        </Row>
      </Form>
      <TermsModal visible={modalTerms} onCloseModal={() => modalTermsSet(false)} />
      <PrivacyModal visible={modalPrivacy} onCloseModal={() => modalPrivacySet(false)} />
    </>
  );
};

export default RegistrationScreen;
