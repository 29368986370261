/* eslint-disable react/prop-types */
import React from 'react';

import './loading.scss';

const Loading = ({ fixed = true, coverBack = false }) => {
  return (
    <div
      className={`loading animation fadein-blur ${fixed ? 'fixed' : 'static'} ${
        coverBack ? 'cover' : ''
      }`}
    >
      <svg
        version="1.1"
        id="loader-1"
        xmlns={{
          svg: 'http://www.w3.org/2000/svg',
          xlink: 'http://www.w3.org/1999/xlink',
        }}
        x="0px"
        y="0px"
        width="40px"
        height="40px"
        viewBox="0 0 50 50"
        style={{ enableBackground: 'new 0 0 50 50' }}
        xml={{ space: 'preserve' }}
      >
        <path d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z" />
      </svg>
    </div>
  );
};

export default Loading;
